import React from "react";
import "./viewingRequests.css";
import Icons from "../../Components/Common/Icons";
import { convertToTitleCase, formattedPrice } from "../../utils/constants";
import { Dropdown } from "react-bootstrap";
import { HiDotsHorizontal } from "react-icons/hi";

const getDropdownItems = (status, isAgentConfirmed, isCustomerConfirmed) => {
  let dropdownItems = [];

  if (status === "PENDING" && isAgentConfirmed && isCustomerConfirmed) {
    dropdownItems = [
      { label: "View Details", action: "viewDetails" },
      { label: "Acknowledge Booking", action: "acknowledgeBooking" },
      { label: "Cancel Booking", action: "cancelBooking" },
    ];
  } else if (
    status === "HOUSE_INSPECTION_COMPLETED" &&
    isAgentConfirmed &&
    isCustomerConfirmed
  ) {
    dropdownItems = [
      { label: "View Details", action: "viewDetails" },
    ];
  } else if (status === "CUSTOMER_RESCHEDULED" && !isAgentConfirmed) {
    dropdownItems = [
      { label: "View Details", action: "viewDetails" },
      { label: "Confirm Booking", action: "confirmBooking" },
      { label: "Reschedule Booking", action: "rescheduleBooking" },
      { label: "Cancel Booking", action: "cancelBooking" },
    ];
  } else if (status === "AGENT_RESCHEDULED" && !isCustomerConfirmed) {
    dropdownItems = [
      { label: "View Details", action: "viewDetails" },
      { label: "Reschedule Booking", action: "rescheduleBooking" },
      { label: "Cancel Booking", action: "cancelBooking" },
    ];
  } else if (status === "VIEWED" && isAgentConfirmed && isCustomerConfirmed) {
    dropdownItems = [
      { label: "View Details", action: "viewDetails" },
      { label: "Rebook Inspection", action: "rebookInspection" },
    ];
  } else if (
    status === "AGENT_CANCELLED_BOOKING" ||
    status === "CUSTOMER_CANCELLED_BOOKING"
  ) {
    dropdownItems = [
      { label: "View Details", action: "viewDetails" },
      { label: "Rebook Inspection", action: "rebookInspection" },
    ];
  } else {
    dropdownItems = [
      { label: "View Details", action: "viewDetails" },
      { label: "Confirm Booking", action: "confirmBooking" },
      { label: "Reschedule Booking", action: "rescheduleBooking" },
      { label: "Cancel Booking", action: "cancelBooking" },
    ];

    if (
      status?.isAgentConfirmed &&
      status?.isCustomerConfirmed
    ) {
      dropdownItems.push({
        label: "Mark as Viewed",
        action: "markAsViewed",
      });
    }
  }

  return dropdownItems;
};

const MobileInspectionCardDetails = ({
  id,
  title,
  bookingStatus,
  address,
  price,
  date,
  time,
  fee,
  constructionStatus,
  profileName,
  image,
  profilePhoneNumber,
  isAgentConfirmed,
  isCustomerConfirmed,
  handleAction, 
}) => {
  const defaultImage =
    "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

  const dropdownItems = getDropdownItems(
    bookingStatus,
    isAgentConfirmed,
    isCustomerConfirmed
  );

  return (
    <div className="mobile_inspection_card my-4">
      <p className="mobile_inspection_card_title">
        INSPECTION FOR {title && convertToTitleCase(title)?.toUpperCase()}
      </p>
      <div className="d-sm-flex gap-3 mobile_inspection_card_details">
        <div>
          <div className="greenSuccessPill icon_container">
            <Icons iconName={"booking"} />
            <p>{convertToTitleCase(bookingStatus)}</p>
          </div>
          <div className="mobile_inspection_card_img">
            <img src={image} className="h-100 w-100" />
          </div>
          <p className="m-0">{address}</p>
          <p className="mt-2 mb-0 fw-bold" style={{ color: "#0A7E32" }}>
            ₦ {price && formattedPrice(price)}
          </p>
          <div className="d-flex justify-content-between align-items-center d-sm-block my-1 my-sm-2">
            <div className="icon_container">
              <Icons iconName={"underConstruction"} />
              <p className="pt-1 fw-bold" style={{ color: "#0A7E32" }}>
                {constructionStatus &&
                  convertToTitleCase(constructionStatus)?.toUpperCase()}
              </p>
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#F0F0F0",
                      borderRadius: "50%",
                      width: "1.9rem",
                      height: "1.9rem",
                      padding: "0",
                    }}
                  >
                    <HiDotsHorizontal
                      className="fw-bold"
                      style={{ height: "2.8rem", color: "#707070" }}
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {dropdownItems.map((item) => (
                    <Dropdown.Item
                      key={item.action}
                      onClick={() => handleAction(id, item.action)}
                      className={
                        item.action === "cancelBooking" ? "text-danger" : ""
                      }
                    >
                      {item.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        <div>
          <h6>{new Date(date)?.toDateString()}</h6>
          <p>Proposed Date</p>
          <hr></hr>

          <h6>
            {new Date(time)?.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </h6>
          <p>Proposed Time</p>
          <hr></hr>

          <h6>{fee ? <>₦ {formattedPrice(fee)}</> : "Free"}</h6>
          <p>Inspection Fee</p>
          <hr></hr>

          <div className="d-flex gap-2">
            <div className="mobile_inspection_card_profil_img">
              <img src={defaultImage} className="w-100 h-100" />
            </div>
            <div>
              <div className="d-flex align-items-center gap-2">
                <p className="m-0 fw-bold">{profileName}</p>
                <span className="circle_bar"></span>
                <p className="m-0 fw-bold">{profilePhoneNumber}</p>
              </div>
              <p className="mt-2 mb-0">Customer</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileInspectionCardDetails;
