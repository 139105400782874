import { createContext, useMemo, useState } from "react";

const AuthenticationContext = createContext({});

export const getUser = () => JSON.parse(sessionStorage.getItem("user"));
export const AuthenticationProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(getUser);

  const values = useMemo(
    () => ({ authUser, setAuthUser }),
    [authUser, setAuthUser]
  );

  return (
    <AuthenticationContext.Provider value={values}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContext;
