import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../../hooks/useAxiosBase";
import useAuth from "../../../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useDeleteImages = (houseId) => {
  const { postRequest } = useAxiosBase();
  const navigate = useNavigate();

  const deleteHouseImages = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `bank/house/${houseId}/delete-images`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          return;
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.statusCode === 200) {
        // toast.success("Created Successfully!");
      }
      if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
        toast.error(res?.data?.value?.message);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  return { deleteHouseImages };
};

export default useDeleteImages;
