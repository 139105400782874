import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

export const useGetAllPlans = (page) => {
    const { getRequest } = useAxiosBase();
  
    return useQuery({
      queryKey: ["get-all-plans"],
      queryFn: () =>
        getRequest({ url: `/developer/mortgage-plan/get-all?pageNumber=${page}` }).then(
          (res) => res?.data?.value?.value
        ),
      staleTime: 50000,
    });
  };
  