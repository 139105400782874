import React, { useEffect, useState } from "react";
import image from "../../assets/images/signup.jpg";
import { ImageWrapper } from "../../Components/Common/StyledComponents/style";
import { LoginContainer } from "./style";
import FormInput from "../../Components/Common/FormInput";
import Button from "../../Components/Common/Button";
import { colors } from "../../utils/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import modelShema from "./entity";
import useLogin from "../../hooks/useLogin";
import { useNavigate } from "react-router";

const Login = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(modelShema) });
  const navigate = useNavigate();
  const appInstance = useLogin(reset);

  const onSubmit = (data) => {
    data.type = "BANK";
    appInstance.mutate(data);
  };

  const handleSignup = () => navigate("/account/signup");
  const handleForgotPassword = () => navigate("/account/forgot-password");

  const [isVisible, setIsVisible] = useState(false);
  const togglePassword = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    document.title = `Giddaa - Login`;
  }, []);

  return (
    <LoginContainer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 d-none d-md-flex img-container p-0">
            <ImageWrapper>
              <img className="img-fluid" src={image} alt="imaget" />
            </ImageWrapper>
          </div>
          <div className="postion-relative form-container col-md-6 d-flex align-items-center h-100 p-3 p-md-4">
          <img
              src="https://s3.amazonaws.com/appforest_uf/f1675031132769x885606220423525500/GiddaaLogoGreen.svg"
              alt="logo"
              width={90}
              height={70}
              className="position-absolute"
              style={{ top: "2.5rem", left: "1rem" }}
            />
                
              <div className="form-wrapper w-100">
              <h1 className="mt-5 text-md-start">
                  Welcome to Giddaa for Banks
                </h1>
                <p className="py-2">
                  Login to start selling and managing your properties available for
                  mortgage
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <FormInput
                      labelName={"Email"}
                      lefticonname={"envolope"}
                      register={register("email")}
                      error={errors?.email?.message}
                      placeholder={"Enter email address"}
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div className="position-relative">
                    <FormInput
                      labelName={"Password"}
                      lefticonname={"password"}
                      righticonname={isVisible ? "viewings" : "eyeoff"}
                      type={isVisible ? "text" : "password"}
                      register={register("password")}
                      placeholder="Enter Password"
                      error={errors?.password?.message}
                      required
                      onClick={() => togglePassword()}
                    />
                    <sapn
                      className="position-absolute forgot_password anchor"
                      onClick={handleForgotPassword}
                    >
                      Forgot Password
                    </sapn>
                  </div>
                  <div className="text-center pt-4 pt-md-4 pt-sm-5 d-flex flex-row justify-content-between align-items-center">
                    <hr />
                    <Button
                      btnText={"Log In"}
                      isLoading={appInstance.isLoading}
                      disabled={appInstance.isLoading}
                    />
                    <hr />
                  </div>
                  <div className="mt-3 text-center">
                    <p>
                      Don't have an account?{" "}
                      <strong
                        className="anchor"
                        role="button"
                        onClick={handleSignup}
                      >
                        Sign Up
                      </strong>
                    </p>
                  </div>
                </form>
              </div>
          </div>
        </div>
      </div>
    </LoginContainer>
  );
};

export default Login;
