import styled from "styled-components";
import { colors } from "../../../utils/constants";

export const TextWrapper = styled.div`
  position: relative;

  .required {
    color: ${colors.red};
  }
  .textarea {
    background: ${colors.grey};
    background: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : colors.grey};
    border: ${`2px solid ${colors.grey}`};
    font-size: 14px;
    color: #4b4b4b;
    transition: all 0.25s ease;
    box-shadow: none !important;
  }

  .textarea:focus {
    border: ${`2px solid ${colors.darkGreen}`} !important;
    background: white !important;
  }

  .textarea::placeholder {
    font: normal normal normal 1rem "Red Hat Display";
    /* font-style: italic; */
    font-size: 14px !important;
    text-align: start;
    margin: 0 !important;
    padding: 0 !important;
  }

  .textarea:focus::placeholder {
    color: transparent !important;
  }

  .textarea:hover {
    background: white !important;
    border: ${`2px solid ${colors.darkGreen}`};
  }

  .form_label {
    font-size: 14px;
    font: normal normal bold 13px "Red Hat Display";
    letter-spacing: 0.04px;
    padding: 0 16px 0 8px;
  }

  .form-error {
    position: absolute;
    left: 0;
    bottom: -1.2rem;

    padding: 0 16px 0 8px;
    font-family: "Red Hat Display";
    font-size: 13px;
    font-weight: 500;
    color: ${colors.red};
  }
`;
