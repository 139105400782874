import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const CardWrapper = styled.div`
  border-radius: 10px;
  height: ${(props) => (props.height ? `${props.height} !important` : "")};
  box-shadow: 0px 1px 3px #0000001a !important;
  overflow: hidden;
  position: relative;
  border: ${(props) =>
    props.isAdded ? `1px solid ${colors.primary}` : "none"};
    cursor: pointer;

.developer_card_header {
    position: relative;
    height: 15rem;
    overflow: hidden;
  }

  .developer_card_details {
    padding: 0.8rem;
  }

  .developer_logo {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    border: 1px solid gray;
    overflow: hidden;
  }

  .developer_card_name {
    font-size: 1.4rem;
  }
`;
