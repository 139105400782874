import React, { useEffect, useState } from "react";
import { colors } from "../../../utils/constants";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { useQuery } from "@tanstack/react-query";
import { asCurrency, getDate } from "../utils/constants";
import Spinner from "../../../Components/Spinner";
import { CgCardDiamonds } from "react-icons/cg";
import TabGroup from "../../../Components/TabGroup";
import { bankStatementColumn, bankStatementTableData } from "./mockData";
import InfoTable from "../../../Components/Common/InfoTable";
import useGetBankStatements from "../hooks/useGetBankStatements";
import { convertToTitleCase, formattedPrice } from "../../../utils/constants";
import { FaRegCircleQuestion } from "react-icons/fa6";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import CenteredText from "../../../Components/CenteredText";

const BankStatement = ({
  applicationId,
  customerName,
  application,
  bankStatement,
  durationInMonths,
}) => {
  const { getRequest } = useAxiosBase();

  const [totalSavings, setTotalSavings] = useState();
  const [totalSavingsRatio, setTotalSavingsRatio] = useState();
  const [mostFrequentStart, setMostFrequentStart] = useState();
  const [mostFrequentEnd, setMostFrequentEnd] = useState();
  const [monthtlyFow, setMonthlyFlow] = useState();
  const [expenseBreakdown, setExpenseBreakdown] = useState();

  const [showValidationDescription, setShowValidationDescription] = useState();
  const [openSettingsOtherIncomeSources, setOpenSettingsOtherIncomeSources] =
    useState(true);
  const [openExpenseBreakdown, setOpenExpenseBreakdown] = useState(true);

  useEffect(() => {
    const mostFrequentBalance =
      bankStatement?.transactionPatternAnalysis?.most_frequent_balance_range;
    if (mostFrequentBalance) {
      const [startString, endString] = mostFrequentBalance
        .split("to")
        .map((item) => item.trim());
      const startNumber = parseInt(startString, 10);
      const endNumber = parseInt(endString, 10);

      setMostFrequentStart(startNumber);
      setMostFrequentEnd(endNumber);
    }

    if (bankStatement) {
      const calcTotalSavings =
        bankStatement?.cashFlowAnalysis?.total_credit_turnover -
        bankStatement?.cashFlowAnalysis?.total_debit_turnover +
        bankStatement?.spendAnalysis?.total_spend
          ?.total_spend_on_savings_and_investments;
      const calcTotalSavingRatio =
        (bankStatement?.cashFlowAnalysis?.total_credit_turnover -
          bankStatement?.cashFlowAnalysis?.total_debit_turnover) /
        bankStatement?.cashFlowAnalysis?.total_credit_turnover;

      setTotalSavings(formattedPrice(calcTotalSavings?.toFixed(3)));
      setTotalSavingsRatio(calcTotalSavingRatio?.toFixed(3));

      const monthlyInflows =
        bankStatement?.cashFlowAnalysis?.monthly_inflow || [];
      const monthlyOutflows =
        bankStatement?.cashFlowAnalysis?.monthly_outflow || [];

      // Iterate over each month in monthlyInflows and calculate the corresponding outflow
      const monthlyFlowData = monthlyInflows.map((inflow, index) => {
        const outflow = monthlyOutflows[index]?.amount || 0;
        const saving = inflow.amount - outflow;
        const savingRatio = saving / inflow.amount;

        return {
          incomeSalary: inflow.amount,
          month: inflow.month_name,
          year: inflow.year,
          saving,
          expenses: outflow,
          savingRatio,
        };
      });

      setMonthlyFlow(monthlyFlowData);

      const expenseBreakdownData = bankStatement?.spendAnalysis?.total_spend;

      // Mapping of old keys to new keys
      const keyMapping = {
        total_spend_on_transfer: "Transfer",
        total_spend_on_atm_and_pos_transactions: "ATM and POS Transactions",
        total_spend_on_ussd: "USSD",
        total_spend_on_gambling: "Gambling",
        total_spend_on_airtime_and_data: "Airtime and Data",
        total_spend_on_utilities: "Utilities",
        total_spend_on_online_and_web: "Online and Web",
        total_spend_on_transportation: "Transportation",
        total_spend_on_health: "Health",
        total_spend_on_travel: "Travel",
        total_spend_on_entertainment: "Entertainment",
        total_spend_on_hospitality_and_food: "Hospitality and Food",
        total_spend_on_rent: "Rent",
        total_spend_on_insurance: "Insurance",
        total_spend_on_charges_and_stamp_duty: "Charges and Stamp Duty",
        total_spend_on_doubleernational_transactions:
          "International Transactions",
        total_monthly_expenses: "Total Monthly Expenses",
        total_recurring_expense: "Total Recurring Expense",
        total_expenses: "Total Expenses",
        total_spend_on_savings_and_investments: "Savings and Investments",
        total_spend_on_crypto: "Crypto",
        total_spend_on_others: "Others",
      };

      // Check if expenseBreakdownData is not null or undefined
      if (expenseBreakdownData) {
        const expenseBreakdown = Object.entries(expenseBreakdownData).map(
          ([oldKey, value]) => ({
            category: keyMapping[oldKey] || oldKey,
            value,
          })
        );

        setExpenseBreakdown(expenseBreakdown);
      }
    }
  }, [bankStatement]);
  // if (isLoading) return <Spinner />;

  return (
    <div className="row px-2">
      <div className="col-9 pe-4">
        <TabGroup>
          {/* TRANSACTION ANALYSIS */}
          <div label="TRANSACTION ANALYSIS">
            <div className="d-flex flex-wrap column-gap-4">
              <div>
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  TOTAL TRANSACTIONS{" "}
                </p>
                <p className="m-0">
                  {" "}
                  {formattedPrice(
                    bankStatement?.transactionPatternAnalysis
                      ?.total_number_transactions
                  ) ?? 0}{" "}
                </p>
              </div>
              <div>
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  % CREDIT TRANSACTIONS{" "}
                </p>
                <p className="m-0">
                  {" "}
                  {bankStatement?.transactionPatternAnalysis
                    ?.credit_transactions ?? 0}
                  %{" "}
                </p>
              </div>
              <div>
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  % DEBIT TRANSACTIONS{" "}
                </p>
                <p className="m-0">
                  {" "}
                  {bankStatement?.transactionPatternAnalysis
                    ?.debit_transactions ?? 0}
                  %{" "}
                </p>
              </div>
              <div>
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  MOST FREQUENT BALANCE RANGE{" "}
                </p>
                <p className="m-0">
                  {mostFrequentStart
                    ? `₦ ${formattedPrice(mostFrequentStart)}`
                    : "₦ 0"}{" "}
                  -{" "}
                  {mostFrequentEnd
                    ? `₦ ${formattedPrice(mostFrequentEnd)}`
                    : "₦ 0"}
                </p>
              </div>
            </div>
            <div style={{ fontFamily: '"Red Hat Display", sans-serif' }}>
              <InfoTable
                columns={bankStatementColumn}
                dataCollection={monthtlyFow}
              />
            </div>
          </div>

          {/* CASHFLOW ANALYSIS */}
          <div label="CASHFLOW ANALYSIS">
            <div className="row">
              <div className="col-md-3 pe-0">
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  TOTAL INCOME{" "}
                </p>
                <p>
                  {" "}
                  ₦{" "}
                  {formattedPrice(
                    bankStatement?.cashFlowAnalysis?.total_credit_turnover
                  ) ?? 0}{" "}
                </p>
              </div>
              <div className="col-md-3 pe-0">
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  TOTAL EXPENSES{" "}
                </p>
                <p>
                  {" "}
                  ₦{" "}
                  {formattedPrice(
                    bankStatement?.cashFlowAnalysis?.total_debit_turnover
                  ) ?? 0}{" "}
                </p>
              </div>
              <div className="col-md-3 pe-0">
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  AVERAGE MONTHLY INCOME{" "}
                </p>
                <p>
                  {" "}
                  ₦
                  {formattedPrice(
                    bankStatement?.cashFlowAnalysis?.average_monthly_credits
                  ) ?? 0}{" "}
                </p>
              </div>
              <div className="col-md-3 pe-0">
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  AVERAGE MONTHLY EXPENSES{" "}
                </p>
                <p>
                  {" "}
                  ₦{" "}
                  {formattedPrice(
                    bankStatement?.cashFlowAnalysis?.average_monthly_debits
                  ) ?? 0}{" "}
                </p>
              </div>
              <div className="col-md-3 pe-0">
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  AVERAGE MONTHLY BALANCE{" "}
                </p>
                <p>
                  {" "}
                  ₦{" "}
                  {formattedPrice(
                    bankStatement?.cashFlowAnalysis?.average_monthly_balance
                  ) ?? 0}{" "}
                </p>
              </div>
              <div className="col-md-3 pe-0">
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  SALARY EARNER{" "}
                </p>
                <p>
                  {" "}
                  {bankStatement?.incomeAnalysis?.is_salary_earner ?? "No"}{" "}
                </p>
              </div>
              <div className="col-md-3 pe-0">
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  EXPECTED SALARY PAYMENT DAY{" "}
                </p>
                {!bankStatement?.incomeAnalysis?.expected_salary_payment_day ? (
                  <p>No expected salary</p>
                ) : (
                  <p>
                    {" "}
                    On day{" "}
                    {
                      bankStatement?.incomeAnalysis?.expected_salary_payment_day
                    }{" "}
                    of each month{" "}
                  </p>
                )}
              </div>
              <div className="col-md-3 pe-0">
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  LAST SALARY PAYMENT
                </p>
                <p> ₦ 0 - ₦ 0 </p>
              </div>
              <div className="col-md-3 pe-0">
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  AVERAGE OTHER INCOME{" "}
                </p>
                <p className="m-0">
                  {" "}
                  ₦{" "}
                  {formattedPrice(
                    bankStatement?.incomeAnalysis?.average_other_income
                  ) ?? 0}{" "}
                </p>
                <div
                  className="d-flex gap-2 position-relative"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setOpenSettingsOtherIncomeSources(
                      !openSettingsOtherIncomeSources
                    )
                  }
                >
                  <p
                    className="m-0 mt-1"
                    style={{
                      color: colors.darkGreen,
                      fontSize: "0.7rem",
                      // marginTop: "20px",
                    }}
                  >
                    View Other Income Sources
                  </p>
                  <span>
                    {openSettingsOtherIncomeSources && (
                      <div
                        className="animated toolTipText shadow-sm"
                        style={{
                          left: "0",
                          top: "2rem",
                          bottom: "0",
                          height: "200px",
                          padding: "0",
                          zIndex: "9999",
                          backgroundColor: "#ffffff",
                          borderRadius: "5px",
                          overflowY: "auto",
                        }}
                      >
                        <div className="description-text m-0">
                          <div
                            className="row m-0 mb-2 py-2 pe-1"
                            style={{ backgroundColor: "#F2F0F0" }}
                          >
                            <p
                              className="col-3 mb-0"
                              style={{ fontSize: "0.7rem" }}
                            >
                              DATE
                            </p>
                            <p
                              className="col-6 mb-0 text-center"
                              style={{ fontSize: "0.7rem" }}
                            >
                              DESCRIPTION
                            </p>
                            <p
                              className="col-3 text-end mb-0"
                              style={{ fontSize: "0.7rem" }}
                            >
                              AMOUNT
                            </p>
                          </div>

                          <div className="">
                            {bankStatement?.incomeAnalysis?.salary_transactions
                              ?.length > 0 ? (
                              bankStatement?.incomeAnalysis?.salary_transactions?.map(
                                (data, index) => (
                                  <div key={index} className="row m-0 border-bottom">
                                    <p
                                      className="col-3 my-2"
                                      style={{ fontSize: "0.7rem" }}
                                    >
                                      {data.date ?? "---"}
                                    </p>
                                    <p
                                      className="col-6 my-2"
                                      style={{ fontSize: "0.7rem" }}
                                    >
                                      {data.description}
                                    </p>
                                    <p
                                      className="col-3 text-end my-2"
                                      style={{ fontSize: "0.7rem" }}
                                    >
                                     ₦ {formattedPrice(data.amount) ?? 0}
                                    </p>
                                  </div>
                                )
                              )
                            ) : (
                              <div className="mt-2">
                                <CenteredText
                                  title={"No other income sources available"}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {openSettingsOtherIncomeSources ? (
                      <MdOutlineKeyboardArrowUp size={15} />
                    ) : (
                      <MdOutlineKeyboardArrowDown size={15} />
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-3 pe-0">
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  NO OF SALARY PAYMENTS{" "}
                </p>
                <p>
                  {" "}
                  {
                    bankStatement?.incomeAnalysis?.number_of_salary_payments
                  }{" "}
                </p>
              </div>
              <div className="col-md-3 pe-0">
                <p
                  className="m-0"
                  style={{ fontSize: "0.8rem", color: colors.gray }}
                >
                  {" "}
                  NO OF OTHER INCOME SOURCES{" "}
                </p>
                <p>
                  {" "}
                  {
                    bankStatement?.incomeAnalysis
                      ?.number_of_other_income_payments
                  }{" "}
                </p>
              </div>
            </div>
            <div style={{ fontFamily: '"Red Hat Display", sans-serif' }}>
              <InfoTable
                columns={bankStatementColumn}
                dataCollection={monthtlyFow}
              />
            </div>
          </div>

          {/* SPENT ANALYSIS */}
          <div label="SPEND ANALYSIS">
            <div className="d-md-flex justify-content-betweeen">
              <div className="row m-0">
                <div className="col-6 col-md-3">
                  <p
                    className="m-0"
                    style={{ fontSize: "0.8rem", color: colors.gray }}
                  >
                    {" "}
                    TOTAL SPENT{" "}
                  </p>
                  <p>
                    {" "}
                    ₦{" "}
                    {bankStatement?.spendAnalysis?.total_spend?.total_expenses?.toLocaleString(
                      "en-US"
                    ) ?? 0}{" "}
                  </p>
                </div>
                <div className="col-6 col-md-3">
                  <p
                    className="m-0"
                    style={{ fontSize: "0.8rem", color: colors.gray }}
                  >
                    {" "}
                    HIGHEST SPENT{" "}
                  </p>
                  <p>
                    {" "}
                    ₦{" "}
                    {bankStatement?.spendAnalysis?.average_spend?.highest_spend?.toLocaleString(
                      "en-US"
                    ) ?? 0}
                  </p>
                </div>
                <div className="col-6 col-md-3">
                  <p
                    className="m-0"
                    style={{ fontSize: "0.8rem", color: colors.gray }}
                  >
                    {" "}
                    AVG MONTHLY EXPENSES{" "}
                  </p>
                  <p>
                    {" "}
                    ₦{" "}
                    {bankStatement?.spendAnalysis?.average_spend?.average_monthly_total_expenses?.toLocaleString(
                      "en-US"
                    ) ?? 0}{" "}
                  </p>
                </div>
                <div className="col-6 col-md-3">
                  <p
                    className="m-0"
                    style={{ fontSize: "0.8rem", color: colors.gray }}
                  >
                    {" "}
                    HIGHEST SPENDING MONTH{" "}
                  </p>
                  <p>
                    {" "}
                    {bankStatement?.spendAnalysis?.average_spend
                      ?.month_with_highest_spend ?? "---"}{" "}
                  </p>
                </div>
                <div className="col-6 col-md-3">
                  <p
                    className="m-0"
                    style={{ fontSize: "0.8rem", color: colors.gray }}
                  >
                    {" "}
                    MOST FREQUENT EXPENSE CATEGORY{" "}
                  </p>
                  <p>
                    {" "}
                    {convertToTitleCase(
                      bankStatement?.spendAnalysis?.average_spend
                        ?.most_frequent_spend_category
                    ) ?? "---"}{" "}
                  </p>
                </div>
                <div className="col-6 col-md-3">
                  <p
                    className="m-0"
                    style={{ fontSize: "0.8rem", color: colors.gray }}
                  >
                    {" "}
                    MOST FREQUENT EXPENSE
                  </p>
                  <p>
                    {" "}
                    {convertToTitleCase(
                      bankStatement?.spendAnalysis?.average_spend
                        ?.most_recurring_expense
                    ) ?? "---"}{" "}
                  </p>
                </div>
              </div>
              <div
                className=""
                onClick={() => setOpenExpenseBreakdown(!openExpenseBreakdown)}
              >
                <div
                  className="position-relative fw-semibold"
                  style={{
                    border: "1px solid #335F32",
                    borderRadius: "8px",
                    backgroundColor: "#F8F8F8",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                >
                  <p
                    className="text-center m-0"
                    style={{ color: colors.darkGreen, fontSize: "0.8rem" }}
                  >
                    {" "}
                    View Full Expense Breakdown{" "}
                    <span>
                      {" "}
                      {openExpenseBreakdown ? (
                        <MdOutlineKeyboardArrowUp size={15} />
                      ) : (
                        <MdOutlineKeyboardArrowDown size={15} />
                      )}
                    </span>
                  </p>
                  <span className="">
                    {openExpenseBreakdown && (
                      <div
                        className="animated toolTipText shadow-sm"
                        style={{
                          right: "0",
                          top: "3rem",
                          bottom: "0",
                          width: "15rem",
                          height: "200px",
                          padding: "0",
                          zIndex: "9999",
                          backgroundColor: "#ffffff",
                          borderRadius: "5px",
                          overflowY: "auto",
                        }}
                      >
                        <p className="description-text m-0">
                          <div
                            className="row m-0 mb-2 py-2"
                            style={{ backgroundColor: "#F2F0F0" }}
                          >
                            <p
                              className="col-6 text-start mb-0"
                              style={{ fontSize: "0.7rem" }}
                            >
                              EXPENSE
                            </p>
                            <p
                              className="col-6 text-start mb-0"
                              style={{ fontSize: "0.7rem" }}
                            >
                              AMOUNT
                            </p>
                          </div>

                          <div className="">
                            {expenseBreakdown
                              ?.length > 0 ? (
                              expenseBreakdown?.map(
                                (data, index) => (
                                  <div key={index}  className="row m-0 border-bottom">
                                    <p
                                      className="col-6 text-start my-2"
                                      style={{ fontSize: "0.7rem" }}
                                    >
                                      {data.category ?? "---"}
                                    </p>
                                    <p
                                      className="col-6 text-start my-2"
                                      style={{ fontSize: "0.7rem" }}
                                    >
                                      ₦ {formattedPrice(data.value?.toFixed()) ?? 0}
                                    </p>
                                  </div>
                                )
                              )
                            ) : (
                              <div className="mt-2">
                                <CenteredText
                                  title={"No expense breakdown available."}
                                />
                              </div>
                            )}
                          </div>
                        </p>
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div style={{ fontFamily: '"Red Hat Display", sans-serif' }}>
              <InfoTable
                columns={bankStatementColumn}
                dataCollection={monthtlyFow}
              />
            </div>
          </div>
        </TabGroup>
      </div>

      <div className="col-3">
        <div className=" rounded border border-container">
          <div
            className="d-flex justify-content-center p-2"
            style={{ backgroundColor: "#F0F0F0" }}
          >
            <p
              className="text-center pe-2 fw-bold m-0"
              style={{
                color: colors.darkGreen,
                fontSize: "0.8rem",
                paddingTop: "5px",
              }}
            >
              {" "}
              BANK STATEMENT NAME VALIDATION{" "}
            </p>
            <span className="position-relative" style={{ cursor: "pointer" }}>
              {showValidationDescription && (
                <div
                  className="animated toolTipText"
                  style={{ right: "-4rem", bottom: "-6.2rem" }}
                >
                  <p className="description-text m-0">
                    This section helps validate if the bank statement that was
                    submitted by the customer is indeed their bank statement. We
                    validate the name on the bank statement against the
                    customer’s Giddaa’s profile.
                  </p>
                </div>
              )}
              <FaRegCircleQuestion
                size="1rem"
                onMouseEnter={() => setShowValidationDescription(true)}
                onMouseLeave={() => setShowValidationDescription(false)}
              />
            </span>
          </div>
          <div className="d-flex justify-content-between p-3">
            <div className="">
              <p
                className="m-0 mb-2 fw-semibold"
                style={{ fontSize: "0.8rem", color: "#707070" }}
              >
                NAME ON STATEMENT
              </p>
              <p className="m-0 fw-bold" style={{ fontSize: "0.8rem" }}>
                {convertToTitleCase(bankStatement?.clientFullName)}
              </p>
            </div>
            <span style={{ width: "2px", backgroundColor: "#F0F0F0" }}></span>
            <div>
              <p
                className="m-0 mb-2 fw-semibold"
                style={{ fontSize: "0.8rem", color: "#707070" }}
              >
                CUSTOMER NAME
              </p>
              <p className="m-0 fw-bold" style={{ fontSize: "0.8rem" }}>
                {convertToTitleCase(customerName)}
              </p>
            </div>
          </div>
        </div>

        <div
          className="rounded-4 border border-container p-3 mt-3"
          style={{ backgroundColor: "#FAFAFA" }}
        >
          <div className="h4 text-dark">Remarks</div>
          <div className="container">
            <div className="row rounded border border-container">
              <div className="col-5 rounded-start p-4  left-card">
                <h5 className="text-success text-center">SAVINGS RATIO</h5>
              </div>
              <div className="col-7 p-4 right-card">
                <h5>
                  {" "}
                  {totalSavingsRatio
                    ? 0
                    : totalSavingsRatio === "NaN"
                    ? 0
                    : totalSavingsRatio}
                  %
                </h5>
                <p>
                  Total Saved — {`₦${totalSavings}`} over the last{" "}
                  {durationInMonths} months
                </p>
              </div>
            </div>
            <div className="row mt-4">
              <h5>Savings & Risk</h5>
              <p className="bank-desc">
                {customerName} was able to save{" "}
                {bankStatement?.averageSavingRatio?.toFixed(2) ?? 0}% of their
                income over {durationInMonths} months.
                {/* This represents a medium saving attitude and
              would mean they would present a medium-low risk of default. */}
              </p>
              <span className="d-flex justify-content-between p-2">
                <div className="d-flex ">
                  <div>
                    <CgCardDiamonds color="black bg-danger" size={"25"} />
                  </div>
                  <p className="m-0 ps-1">
                    It appears that {customerName}{" "}
                    {bankStatement?.spendAnalysis?.total_spend
                      ?.total_spend_on_gambling > 0
                      ? "has a"
                      : "has no"}{" "}
                    gambling habit as their statement contains{" "}
                    {formattedPrice(
                      bankStatement?.spendAnalysis?.total_spend
                        ?.total_spend_on_gambling
                    ) ?? 0}{" "}
                    amount spent on gambling over the last {durationInMonths}{" "}
                    months.
                  </p>
                </div>
              </span>
            </div>{" "}
            <div className="row mt-4">
              <h5>Sensitivity Analysis</h5>
              {/* <p className="bank-desc">
                Given {customerName} savings of ₦{totalSavings}, they will{" "}
                {totalSavings < application?.mortgagePMTCalculation?.monthly &&
                  "not"}{" "}
                be capable of making ₦
                {formattedPrice(
                  application?.mortgagePMTCalculation?.monthly.toFixed(3)
                )}{" "}
                payments on {application?.mortgagePlan.name} should they lose
                their income.
                The required monthly payment is 090909
              </p> */}

              <p>
                Given {customerName} savings of ₦{totalSavings}, they will{" "}
                {totalSavings < application?.mortgagePMTCalculation?.monthly
                  ? "not"
                  : ""}{" "}
                be capable of making{" "}
                <span className="fw-semibold">
                  {(
                    (bankStatement?.accountBalance +
                      bankStatement?.spendAnalysis?.total_spend
                        ?.total_spend_on_savings_and_investments) /
                    application?.mortgagePMTCalculation?.monthly
                  ).toFixed(2)}{" "}
                </span>
                payments on {application?.mortgagePlan.name} should they lose
                their income.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankStatement;
