import { applicationsManagement } from "./applicationAccess";
import { applicationReviewManagement } from "./reviewAccessManagement";
import { prospectAccess } from "./prospectsAccessManagement";
import { partnershipManagement } from "./partnershipAccessManagement";
import { planManagement } from "./planAccessManagement";
import { portfolioManagement } from "./portfolioAccessManagement";
import { transactionManagement } from "./transactionAccessManagement";
import { organizationAccessManagement } from "./organizationAccessManagement";
import { teamManagement } from "./teamMemberAccessManagement";
import { roleManagement } from "./roleAccessManagement";
import { propertyManagement } from "./properties";

const setPermission = (
  userAccess,
  {
    setAccess,
    setAccessHouseViewing,
    setAccessCreateHouse,
    setAccessUpdateHouse,
    setAccessDeleteHouse,
    setAccessEstateViewing,
    setAccessCreateEstate,
    setAccessUpdateEstate,
    setAccessDeleteEstate,

    // APPLICATION ACCESS MANAGEMENT
    setAccessViewApplication,
    setAccessValidateApplicationDocument,
    setAccessReviseApplicationDocument,
    setAccessInvalidateApplicationDocument,
    setAccessSendApplicationForReview,
    setAccessViewApplicationsForReview,
    setAccessAcceptApplicationForReview,
    setAccessRejectApplicationForReview,

    // VIEWING REQUESTS MANAGEMENT
    setAccessViewViewingRequests,
    setAccessSendViewingRequestReminder,

    //  PROSPECTS MANAGEMENT
    setAccessViewProspects,
    setAccessSendProspectReminder,

    // PARTNERSHIP MANAGEMENT
    setAccessViewPartners,
    setAccessInvitePartner,
    setAccessAddPartner,
    setAccessAcceptPartnerRequest,
    setAccessDeclinePartnerRequest,
    setAccessRevokePartner,
    setAccessDeletePartner,

    // PLAN MANAGEMENT
    setAccessViewPlans,
    setAccessCreatePlan,
    setAccessUpdatePlan,
    setAccessDeletePlan,

    // PORTFOLIO MANAGEMENT
    setAccessViewPortfolios,

    // TRANSACTION MANAGEMENT
    setAccessViewTransaction,
    setAccessViewTransactionReciept,
    setDownloadTransactionReciept,
    setAccessViewRepaymentSchedule,
    setAccessRemindCustomer,

    // ORGANIZATION MANAGEMENT
    setAccessViewOrganizationProfile,
    setAccessUpdateOrganizationProfile,
    setAccessViewOrganizationDocuments,
    setAccessAddOrganizationDocument,

    // TEAM MEMBER MANAGEMENT
    setAccessViewTeamMember,
    setAccessCreateTeamMember,
    setAccessUpdateTeamMember,
    setAccessDeleteTeamMember,

    // ROLE MANAGEMENT
    setAccessViewRole,
    setAccessCreateRole,
    setAccessUpdateRole,
    setAccessDeleteRole,
  }
) => {
  setAccessHouseViewing(propertyManagement(userAccess)?.viewHouseAccess);
  setAccessCreateHouse(propertyManagement(userAccess)?.createEstateAccess);
  setAccessUpdateHouse(propertyManagement(userAccess)?.updateHouseAccess);
  setAccessDeleteHouse(propertyManagement(userAccess)?.deleteHouseAccess);
  setAccessEstateViewing(propertyManagement(userAccess)?.viewEstateAccess);
  setAccessCreateEstate(propertyManagement(userAccess)?.createEstateAccess);
  setAccessUpdateEstate(propertyManagement(userAccess)?.updateEstateAccess);
  setAccessDeleteEstate(propertyManagement(userAccess)?.deleteEstateAccess);

  // APPLICATION ACCESS MANAGEMENT
  setAccessViewApplication(
    applicationsManagement(userAccess)?.viewApplicationAccess
  );
  setAccessValidateApplicationDocument(
    applicationsManagement(userAccess)?.validateApplicationDocumentAccess
  );
  setAccessReviseApplicationDocument(
    applicationsManagement(userAccess)?.reviseApplicationDocumentAccess
  );
  setAccessInvalidateApplicationDocument(
    applicationsManagement(userAccess)?.invalidateApplicationDocumentAccess
  );
  setAccessSendApplicationForReview(
    applicationsManagement(userAccess)?.sendApplicationForReviewAccess
  );
  setAccessAcceptApplicationForReview(
    applicationsManagement(userAccess)?.acceptApplicationForReviewAccess
  );
  setAccessRejectApplicationForReview(
    applicationsManagement(userAccess)?.rejectApplicationForReviewAccess
  );
  setAccessViewApplicationsForReview(
    applicationsManagement(userAccess)?.viewApplicationsForReviewAccess
  );

  // VIEWING REQUESTS MANAGEMENT
  setAccessViewViewingRequests(
    applicationReviewManagement(userAccess)?.viewViewingRequestsAccess
  );
  setAccessSendViewingRequestReminder(
    applicationReviewManagement(userAccess)?.sendViewingRequestReminderAccess
  );

  //  PROSPECTS MANAGEMENT
  setAccessViewProspects(prospectAccess(userAccess)?.viewProspectsAccess);
  setAccessSendProspectReminder(
    prospectAccess(userAccess)?.sendProspectReminderAccess
  );

  // PARTNERSHIP MANAGEMENT
  setAccessViewPartners(partnershipManagement(userAccess)?.viewPartnersAccess);
  setAccessInvitePartner(
    partnershipManagement(userAccess)?.invitePartnerAccess
  );
  setAccessAddPartner(partnershipManagement(userAccess)?.addPartnerAccess);
  setAccessAcceptPartnerRequest(
    partnershipManagement(userAccess)?.acceptPartnerRequestAccess
  );
  setAccessDeclinePartnerRequest(
    partnershipManagement(userAccess)?.declinePartnerRequestAccess
  );
  setAccessRevokePartner(
    partnershipManagement(userAccess)?.revokePartnerAccess
  );
  setAccessDeletePartner(
    partnershipManagement(userAccess)?.deletePartnerAccess
  );

  // PLAN MANAGEMENT
  setAccessViewPlans(planManagement(userAccess)?.viewPlansAccess);
  setAccessCreatePlan(planManagement(userAccess)?.createPlanAccess);
  setAccessUpdatePlan(planManagement(userAccess)?.updatePlanAccess);
  setAccessDeletePlan(planManagement(userAccess)?.deletePlanAccess);

  // PORTFOLIO MANAGEMENT
  setAccessViewPortfolios(
    portfolioManagement(userAccess)?.viewPortfolionsAccess
  );

  // TRANSACTION MANAGEMENT
  setAccessViewTransaction(
    transactionManagement(userAccess)?.viewTransactionAccess
  );
  setAccessViewTransactionReciept(
    transactionManagement(userAccess)?.viewTransactionRecieptAccess
  );
  setDownloadTransactionReciept(
    transactionManagement(userAccess)?.downloadTransactionRecieptAccess
  );
  setAccessViewRepaymentSchedule(
    transactionManagement(userAccess)?.viewRepaymentScheduleAccess
  );
  setAccessRemindCustomer(
    transactionManagement(userAccess)?.remindCustomerAccess
  );

  // ORGANIZATION MANAGEMENT
  setAccessViewOrganizationProfile(
    organizationAccessManagement(userAccess)?.viewOrganizationProfileAccess
  );
  setAccessUpdateOrganizationProfile(
    organizationAccessManagement(userAccess)?.updateOrganizationProfileAccess
  );
  setAccessViewOrganizationDocuments(
    organizationAccessManagement(userAccess)?.viewOrganizationDocumentsAccess
  );
  setAccessAddOrganizationDocument(
    organizationAccessManagement(userAccess)?.addOrganizationDocumentAccess
  );

  // TEAM MEMBER MANAGEMENT
  setAccessViewTeamMember(teamManagement(userAccess)?.viewTeamMemberAccess);
  setAccessCreateTeamMember(teamManagement(userAccess)?.createTeamMemberAccess);
  setAccessUpdateTeamMember(teamManagement(userAccess)?.updateTeamMemberAccess);
  setAccessDeleteTeamMember(teamManagement(userAccess)?.deleteTeamMemberAccess);

  // ROLE MANAGEMENT
  setAccessViewRole(roleManagement(userAccess)?.viewRoleAccess);
  setAccessCreateRole(roleManagement(userAccess)?.createRoleAccess);
  setAccessUpdateRole(roleManagement(userAccess)?.updateRoleAccess);
  setAccessDeleteRole(roleManagement(userAccess)?.deleteRoleAccess);
};

export default setPermission;
