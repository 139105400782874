import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "./useAxiosBase";

const useHouse = (page) => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["bankHouses"],
    queryFn: () =>
      getRequest({
        url: `/bank/house/get-all?pageNumber=${page}`,
      }).then((res) => res.data?.value?.value),
  });
};

export default useHouse;
