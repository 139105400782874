import { isNullOrEmpty } from "../constants";

export const portfolioManagement = (userAccess) => {
  if (isNullOrEmpty(userAccess)) return null;
  const portfolionManagement =
    userAccess?.find((x) => x.id === "portfolio_management")?.rules || [];

  return {
    viewPortfolionsAccess:
      portfolionManagement.find((x) => x.id === "view_portfolio") || {},
  };
};
