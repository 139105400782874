import apiClient, { authClient } from "../helpers/apiClient";

class HttpService {
  _endpoint = "";
  _client;
  constructor(endpoint, client = false) {
    this._endpoint = endpoint;
    this._client = client ? authClient : apiClient;
  }
  getAll() {
    const controller = new AbortController();
    const request = this._client.get(this._endpoint, {
      signal: controller.signal,
    });
    return { request, cancel: () => controller.abort() };
  }

  get(id) {
    const controller = new AbortController();
    const request = this._client.get(`${this._endpoint}/${id}`, {
      signal: controller.signal,
    });
    return { request, cancel: () => controller.abort() };
  }

  delete(id) {
    return this._client.delete(this._endpoint + id);
  }

  create(entity) {
    return this._client.post(this._endpoint, entity);
  }
  update(entity) {
    return this._client.put(this._endpoint + entity?.id, entity);
  }
}

const create = (endpoint, client) => new HttpService(endpoint, client);
export default create;
