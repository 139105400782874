import React, { useState, useEffect, useRef } from "react";
import usePermission from "../../../hooks/usePermissions";
import { useNav } from "../../../hooks/useNav";
import Button from "../../../Components/Common/Button";
import { colors } from "../../../utils/constants";
import DocumentsPreview from "../../../Components/DocumentsPreview";
import Modal from "../../../Components/Common/Modal";
import Spinner from "../../../Components/Spinner";
import UploadDocument from "../../../Components/Common/UploadDocument";
import useGetDocuments from "../../../hooks/useGetDocuments";
import useUploadDocument from "./hooks/useUploadDocument";
import useBankDocuments from "./hooks/useGetDocuments";
import useDeleteDocument from "./hooks/useDeleteDocument";
import Dropdown from "../../../Components/Common/Dropdown";
import { getBase64Image, fileExtension } from "../../../utils/imageUtils";
import CenteredText from "../../../Components/CenteredText";
import { toast } from "react-toastify";
import LicenseAndCertificationDoc from "../../../Components/Common/License&CertificationDoc";
import SkeletonLoader from "../../../Components/SkeletonLoader";

const Documents = () => {
  useNav({ mainTitle: "Documents" });

  const { accessViewOrganizationDocuments, accessAddOrganizationDocument } =
    usePermission();

  const docRef = useRef();
  const [documentId, setDocumentId] = useState();
  const [documentName, setDocumentName] = useState("");

  const { data: documentsToUpload } = useGetDocuments();
  const { update } = useUploadDocument();
  const {
    data: bankDocuments,
    error,
    isLoading,
    refetch,
    isRefetching,
  } = useBankDocuments({ onSuccess: (data) => {} });

  const { deleteDocument } = useDeleteDocument(documentId);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [files, setFiles] = useState([]);
  const [optionId, setOptionId] = useState("");

  const handleSelectedDocument = (docId) => {
    setOptionId(docId);
  };

  const handleFileChange = async (event) => {
    if (!optionId) return;

    const file = event.target.files[0];

    // Check if the uploaded file is a PDF
    if (!file.type.includes("pdf")) {
      toast.error("Please upload a PDF file");
      return;
    }

    URL.revokeObjectURL(file);
    const base64 = await getBase64Image(file);
    let newImage = {
      base64: base64,
      optionId: optionId,
      extension: fileExtension(file),
      name: file.name,
    };

    setFiles([newImage, ...files]);
  };

  const handleDeleteDocument = (doc) => {
    setDocumentId(doc.id);
    setDocumentName(doc.optionId);
    setDeleteModal(true);
  };

  const SubmitDocuments = async () => {
    update.mutate(files, {
      onSuccess: () => {
        refetch();
        setFiles([]);
        setOptionId("");
        setShowUploadModal(false);
      },
    });
  };

  if (error) return "error";

  return (
    <div className="container-fluid">
      {accessViewOrganizationDocuments?.value ? (
        <>
          {accessAddOrganizationDocument?.value && (
            <div className="text-end mt-4">
              <Button
                btnText={"Add Documents"}
                onBtnClick={() => setShowUploadModal(true)}
              />
            </div>
          )}

          <div className="row">
            {isLoading || isRefetching ? (
              <div className="col-md-2">
                <LicenseAndCertificationDoc
                  isLoading={isLoading || isRefetching}
                  extension={<SkeletonLoader />}
                  document={<SkeletonLoader />}
                  deleteDoc
                />
              </div>
            ) : bankDocuments?.length > 0 ? (
              bankDocuments?.map((doc) => (
                <div className="col-md-2" key={doc.id}>
                  <LicenseAndCertificationDoc
                    name={doc.option.name}
                    extension={"PDF"}
                    document={doc.document}
                    deleteDoc
                    handleDelete={() => handleDeleteDocument(doc)}
                  />
                </div>
              ))
            ) : (
              <div className="mt-5">
                <CenteredText title={"No Documents Available"}>
                  You have not uploaded any documents yet. <br />
                  Please add documents using the "Add Documents" button above.
                </CenteredText>
              </div>
            )}
          </div>

          <Modal
            show={showUploadModal}
            closeModal={() => {
              setFiles([]);
              setOptionId(null);
              setShowUploadModal(false);
            }}
            title={"Add Document"}
            confirmModal={SubmitDocuments}
            cancelButtonLabel={"Cancel"}
            confirmButtonLabel={"Save"}
            closeModalIcon={true}
            width={"100%"}
            height={"100%"}
            crossLine={false}
            isBtnLoading={update.isLoading}
            disabled={!optionId || files.length === 0 || update.isLoading}
          >
            <div className="container">
              <form className="row justify-content-center mt-5">
                <div className="row justify-content-center">
                  <div className="col-md-7">
                    <Dropdown
                      required
                      labelName={"Document Name"}
                      placeholder={"Select a document type"}
                      options={documentsToUpload}
                      onChange={(e) => handleSelectedDocument(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-5 mt-2">
                    <UploadDocument
                      docRef={docRef}
                      variant={"upload"}
                      disabled={!optionId}
                      docObject={files}
                      type="application/pdf"
                      handleChange={handleFileChange}
                    />
                  </div>
                </div>
              </form>
            </div>
          </Modal>

          <Modal
            show={deleteModal}
            title={"Delete Document"}
            confirmModal={() =>
              deleteDocument.mutate(null, {
                onSuccess: () => {
                  refetch();
                  setDeleteModal(false);
                },
              })
            }
            closeModal={() => setDeleteModal(false)}
            cancelButtonLabel="Cancel"
            confirmButtonLabel="Delete"
            isBtnLoading={deleteDocument.isLoading}
            disabled={deleteDocument.isLoading}
            backgroundcolor={colors.dangerLight}
            bordercolor={colors.danger}
            btntextcolor={colors.danger}
            colorHover={colors.danger}
            subTitle={"Are you sure you want to delete this document?"}
          />
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view organization documents. Please
            contact the organization administrator for assistance.
          </CenteredText>
        </div>
      )}
    </div>
  );
};

export default Documents;
