import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useUpadateTeamMember = (appUserId) => {
  const { putRequest } = useAxiosBase();

  const updateMember = useMutation({
    mutationKey: ["update-team", appUserId],
    mutationFn: (data) =>
      putRequest({
        url: `/bank/user/${appUserId}/update`,
        data: data,
      }).then((res) => {
        console.log("DANGExxxxxxR", res.data?.value);
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Updated Succefully!");
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.value?.statusCode === 200) {
        toast.success("Updated Succefully!");


        console.log("session", res)
      }
      if ([400, 404, 500].includes(res?.data?.value?.value?.statusCode)) {
        toast.error(res?.data?.value?.value?.message);
      }
    },
    onError: (error) => {
      console.log("ERRROR", error);
    },
  });

  return { updateMember };
};

export default useUpadateTeamMember;
