import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const ModalWrapper = styled.div`
  .show {
    display: block;
    background: rgba(0, 0, 0, 0.6);
    animation: show-box 0.3s ease-in-out;
    backdrop-filter: blur(0.5px);
  }

  @keyframes show-box {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .modal.show .modal-dialog {
    transform: none;
  }

  .modal-content {
    position: relative;
    width: ${(props) => props.width ?? "500px"};
    height: ${(props) => props.height ?? ""};
    border-radius: 8px;
    padding-top: 0.4rem;

    justify-content: center;
    top: ${(props) => (props.$wide ? "12%" : "0px")};
  }

  .modal-dialog-centered {
    justify-content: center;
    padding-top: 3rem;
    height: 100%;
  }

  .modal-footer {
    border: none;
    background-color: ${(props) =>
      props.footerBankground
        ? `${props.footerBankground} !important`
        : "#F2F0F0"};
    padding: 1.2rem;
  }

  .title {
    /* font-weight: 900; */
    font-family: ${(props) => props.titleFont ?? "Millik"};
    font-size: ${(props) => props.titleFontSize ?? "24px"};
    color: ${(props) => props.titleColor ?? colors.darkGreen};
    margin: 0 !important;
    margin-bottom: 0rem !important;
  }

  .subtitle {
    font-family: "Red Hat Display", sans-serif;
    font-size: 14px;
  }

  .modal-back-btn {
    position: absolute;
    right: left;
    top: 1rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .close-btn {
    position: absolute;
    right: 0;
    top: 0.8rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .close-btn > span > svg {
    width: 25px;
  }
`;
