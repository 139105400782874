import React, { useEffect, useState } from "react";
import usePermission from "../../hooks/usePermissions";
import { useNav } from "../../hooks/useNav";
import InfoCard from "../../Components/Common/InfoCard/index";
import Button from "../../Components/Common/Button";
import {
  colors,
  convertToTitleCase,
  formattedPrice,
  months,
  years,
} from "../../utils/constants";
import InfoTable from "../../Components/Common/InfoTable";
import { defaultImage } from "./constants";
import Spinner from "../../Components/Spinner";
import CenteredText from "../../Components/CenteredText";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import useProspect from "./hooks/useProspect";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import { FaRegCircleQuestion } from "react-icons/fa6";
import CellSelector from "../../Components/Common/CellSelector";
import TableLoader from "../../Components/Common/TableLoader";
import SkeletonLoader from "../../Components/SkeletonLoader";

const Prospect = () => {
  useNav({
    mainTitle: "My Prospects",
    subTitle:
      "A Prospect is anyone who indicates a strong interest in your property.",
  });

  const { accessViewProspects, accessSendProspectReminder } = usePermission();

  const [pageNumber, setPageNumber] = useState(1);
  const [reminderName, setReminderName] = useState("");
  const { getProspects, sendProspectReminder } = useProspect(
    reminderName,
    pageNumber
  );
  const { data: prospects, isLoading, isRefetching, refetch } = getProspects;

  const [loadingTable, setLoadingTable] = useState(false);

  const handleSendReminder = (prospectId, row) => {
    setReminderName(`${row.customer?.firstName} ${row.customer?.lastName}`);
    toast.warning("Sending Reminder");
    sendProspectReminder.mutate(prospectId);
  };

  // PROSPECT COLUMN
  const prospectColumns = (handleSendReminder, loading) => {
    const columns = [
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            CUSTOMER
          </p>
        ),
        sortable: true,
        wrap: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row.customer?.firstName !== null ? (
              `${row.customer?.firstName}  ${row.customer?.lastName}`
            ) : (
              <div>
                <div className="text-end">
                  <FaRegCircleQuestion size="0.8em" />
                </div>
                Anonymous Users
              </div>
            )}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            PROPERTIES
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {
              row.house?.name ?? "---"
              // <>
              //   <span className="fw-semibold">
              //     {convertToTitleCase(row.house?.type)}
              //   </span>{" "}
              //   <span className="circle_bar"></span> {row.house?.cityName}{" "}
              //   {row.house?.stateName}
              // </>
            }
          </CellSelector>
        ),
        wrap: true,
      },
      // {
      //   name: (
      //     <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
      //       AGE
      //     </p>
      //   ),
      //   sortable: true,
      //   grow: 0.5,
      //   selector: (row) => (
      //     <CellSelector loading={loading}>{row.customer?.age}</CellSelector>
      //   ),
      // },
      // {
      //   name: (
      //     <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
      //       INCOME
      //     </p>
      //   ),
      //   sortable: true,
      //   grow: 0.5,
      //   selector: (row) => (
      //     <CellSelector loading={loading}>{row.customer?.income}</CellSelector>
      //   ),
      // },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            NO OF VIEWS
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>{row.viewCount}</CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            MONTHLY AFFORDABILITY
          </p>
        ),
        sortable: true,
        wrap: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row.customer?.firstName !== null ? (
              <>
                ₦{" "}
                {formattedPrice(row.customer?.affordability?.bankPlan) ??
                  0}
              </>
            ) : (
              <>---</>
            )}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            REGION
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row.customer?.firstName !== null ? (
              row.customer.withInNigeria ? (
                "Within Nigeria"
              ) : (
                "Outside Nigeria"
              )
            ) : (
              <>---</>
            )}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            LAST ENGAGEMENT
          </p>
        ),
        sortable: true,
        wrap: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {new Date(row.dateCreated).toDateString()}
          </CellSelector>
        ),
      },
    ];

    // Check if accessSendProspectReminder.value is true before adding the action column
    if (accessSendProspectReminder?.value) {
      columns.push({
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            ACTION
          </p>
        ),
        sortable: false,
        grow: 0.5,
        center: true,
        compact: true,
        selector: (row) => (
          <Dropdown>
            <Dropdown.Toggle
              id={`dropdown-${row.id}`}
              style={{ background: "transparent", border: "none" }}
            >
              <HiDotsVertical className="fw-bold text-dark" />
            </Dropdown.Toggle>

            {!loading && (
              <Dropdown.Menu className="position-fixed">
                <Dropdown.Item onClick={() => handleSendReminder(row.id, row)}>
                  Send Reminder
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        ),
      });
    }

    return columns;
  };

  useEffect(() => {
    setLoadingTable(isRefetching);
  }, [isRefetching]);

  useEffect(() => {
    if (pageNumber) {
      refetch();
    }
  }, [pageNumber]);

  return (
    <div className="">
      {accessViewProspects?.value ? (
        <>
          <div className="d-flex gap-4 mt-4 px-2">
            <div className="">
            <InfoCard
                mainValue={
                  isLoading || isRefetching ? (
                    <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
                  ) : (
                    prospects?.totalRecords ?? "0"
                  )
                }
                description={
                  isLoading || isRefetching ? (
                    <SkeletonLoader />
                  ) : (
                    "Total Prospects"
                  )
                }
              />
            </div>
          </div>
          <div className="px-2 mb-4">
            {isLoading ? (
               <div className="mt-4">
               <TableLoader />
             </div>
            ) : !prospects?.data?.length ? (
              <div className="mt-5">
                <CenteredText title={"You have no prospects at this time"}>
                  {" "}
                  When a customer shows interest in any of your properties,
                  their information will be displayed here.
                </CenteredText>
              </div>
            ) : (
              <div className="">
                <InfoTable
                  columns={prospectColumns(
                    (rowId, row) => handleSendReminder(rowId, row),
                    loadingTable
                  )}
                  dataCollection={prospects?.data}
                  loadingTable={loadingTable}
                  paginationTotalRows={prospects?.totalRecords}
                  onChangePage={(page) => setPageNumber(page)}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view estates. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}
    </div>
  );
};

export default Prospect;
