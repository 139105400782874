import React from "react";
import noimage from "../../../../noimage.png";

const EstateCards = ({ estate, gotoEstate }) => {
  const image = (image) => {
    if (image) {
      if (image?.length > 0) {
        return image[0]?.document;
      }
    }
    return noimage;
  };

  return (
    <div className="card estate-card" onClick={gotoEstate}>
      <div className="card-body estate-img">
        <img
          src={image(estate?.images)}
          alt={estate?.name}
          className="img-fluid"
        />
      </div>
      <div className="estate-details px-2">
        <div className="row estate-title mt-2" title={estate?.name}>
          <h6>
            {estate?.name?.length > 18
              ? `${estate?.name?.substring(0, 18)}...`
              : estate?.name}
          </h6>
        </div>
        <div className="row estate-desc" title={estate?.address}>
          <p className="m-0">
            {estate?.address?.length > 30
              ? `${estate?.address?.substring(0, 30)}...`
              : estate?.address}
          </p>
        </div>
        <div className="row estate-hou">
          <span className="fw-semibold">
            {estate?.houses?.length}{" "}
            {estate?.houses?.length > 1 ? "HOUSES" : "HOUSE"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EstateCards;
