import { useQuery } from "@tanstack/react-query";
import useAuth from "../../../../../hooks/useAuth";
import useAxiosBase from "../../../../../hooks/useAxiosBase";

const useGetPendingEstates = () => {
  const { authUser } = useAuth();
  const { getRequest } = useAxiosBase();
  const organizationId = authUser?.user?.organizationId;

  return useQuery({
    queryKey: ["devEstatesPending", organizationId],
    queryFn: () =>
      getRequest({
        url: "bank/estate/PENDING/get-all",
      }).then((res) => res.data?.value?.value?.data),
    retry: 2,
  });
};

export default useGetPendingEstates;