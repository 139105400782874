import { styled } from "styled-components";
import { colors } from "../../../utils/constants";


export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: ${(props) => props.height || "300px"};
  border: 1px solid #eaeaea;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #0000001a;
  background-color: #fff;
  cursor: pointer;
  /* transition: transform 0.3s; */

  /* &:hover {
    transform: scale(1.05);
  } */

  .card-top {
    position: relative;
  } 

  .doc-bage {
    top: 5px;
    right: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem !important;
  }

  .doc-img {
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .doc-icon {
    position: absolute;
    top: 50%;
    left: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    transform: translate(-50%, -50%);
    font-size: 15px;
    border-radius: 50%;
    background-color: #fff;
    padding: 8px;
    border: 1px solid #eaeaea;
    opacity: 0.7;
  }

  .card-body {
    /* padding: 3px 5px 10px; */
    position: relative;
  }

  .doc-title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin: auto;
    margin-bottom: 8px;
  }

  .doc-desc {
    font-size: 14px;
    color: #666;
    text-align: center;
    margin: auto;
    margin-bottom: 12px;
  }

  .doc-btn {
    position: absolute;
    bottom: 0;
    display: inline-block;
    border: ${(props) => props.borderColor ? `1px solid ${props.borderColor}` : "1px solid #b80605"};
    background-color: ${(props) => props.backgroundColor ? `${props.backgroundColor}` : '#ffe0e0'};
    color: ${(props) => props.color ? `${props.color}` : '#b80605'};
    font-size: 13px;
    border-radius: 20px;
    padding: 3px 12px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      /* background-color: ${colors.primaryDark}; */
    }
  }
`;
