import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../../hooks/useAxiosBase";

const useGenerateQRCode = (houseId) => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["QR Code"],
    queryFn: () =>
      getRequest({ url: `bank/house/get-qrcode/${houseId}` }).then(
        (res) => res.data.value?.value
      ),
    staleTime: 50000,
  });
};

export default useGenerateQRCode;