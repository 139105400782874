import React from "react";

const HeaderTitle = ({ title, children }) => {
  return (
    <div className="row  mb-3">
      <div className="col-8 col-md-8 col-sm-6 pt-2">
        <h3>{title}</h3>
      </div>
      {children}
    </div>
  );
};

export default HeaderTitle;
