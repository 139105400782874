import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useCreateRoles = () => {
  const { postRequest } = useAxiosBase();

  const createRole = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `/bank/role/create`,
        data: data,
      }).then((res) => {
        console.log("DANGExxxxxxR", res);
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Created Succefully!");
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success("Created Succefully!");
      }
      if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
        toast.error(res?.data?.value?.message);
      }
    },
    onError: (error) => {
      console.log("ERRROR", error);
      toast.error(error);
    },
  });

  return { createRole };
};

export default useCreateRoles;
