import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useCancelBooking = (houseViewingId) => {
  const { putRequest } = useAxiosBase();

  const cancelBooking = useMutation({
    mutationKey: ["cancel-booking", houseViewingId],
    mutationFn: (data) =>
      putRequest({
        url: `bank/house-viewing/${houseViewingId}/cancel`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { cancelBooking };
};

export default useCancelBooking;
