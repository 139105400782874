import { keyframes } from "styled-components";
import styled from "styled-components";

// Keyframe animation for loader
const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const VerifyingLoaderWrapper = styled.div`
  position: ${(props) =>
    props.position ? `${props.position} !important` : "fixed"};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.backgroundColor
      ? `${props.backgroundColor} !important`
      : "rgba(0, 0, 0, 0.5)"};
  z-index: 50 !important;

  /* Center the loader */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Red Hat Display", sans-serif;

  .verifyingLoader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid green; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: ${loaderAnimation} 2s linear infinite; /* Apply animation */
  }

  .title {
    font-family: "Red Hat Display", sans-serif;
    font-weight: bold !important;
    margin: 0 !important;
    margin-top: 0.3rem !important;
  }

  .dots {
    color: white;
  }
`;
