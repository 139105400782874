import React from "react";
import CheckInput from "../../../../Components/Common/CheckInput";

const TRow = ({ label, name, checked, handler }) => {
  return (
    <div className="table-row-wrapper  border-bottom border-bottom-1 py-3 mx-4">
      <div className="">
        <CheckInput
          name={name}
          checked={checked}
          checkTick={true}
          label={label}
          width={"23px"}
          height={"23px"}
          bcolor={"#0000001A"}
          bordercolor={"#CECECE"}
          onChange={(e) => handler(e)}
        />
      </div>
    </div>
  );
};

export default TRow;
