import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../../hooks/useAxiosBase";

const useGetPedingEstateAndHouseCount = () => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["pendingCount"],
    queryFn: () =>
      getRequest({
        url: `bank/get-pending-property-summary`,
      }).then((res) => res.data?.value?.value),
    retry: 2,
  });
};

export default useGetPedingEstateAndHouseCount;