import CompanyDetails from "./CompanyDetails";
import DocumentSubmission from "./DocumentSubmission";
import PropertyViewing from "./PropertyViewing";
import SelectMortgageBank from "./SelectDevelopers";

export const organisationType = {
  bank: "bank",
  developer: "developer",
};

export const widgetType = [
  {
    title: "Company Details",
    subTitle: "[STEP 1]",
    component: (formData, setFormData, formErrors, setFormErrors) => (
      <CompanyDetails
        data={formData}
        setData={setFormData}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    ),
  },
  {
    title: "Select Developers",
    subTitle: "[STEP 2]",
    component: (formData, setFormData, formErrors, setFormErrors) => (
      <SelectMortgageBank
        data={formData}
        setData={setFormData}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    ),
  },
  {
    title: "Document Submission",
    subTitle: "[STEP 3]",
    component: (formData, setFormData, formErrors, setFormErrors) => (
      <DocumentSubmission
        data={formData}
        setData={setFormData}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    ),
  },
  {
    title: "Viewing Settings",
    subTitle: "[STEP 4]",
    component: (formData, setFormData) => (
      <PropertyViewing data={formData} setData={setFormData} />
    ),
  },
];
