import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useInviteDeveloper = () => {
  const { postRequest } = useAxiosBase();

  const inviteDeveloper = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `/bank/partnership/send-email-invite`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Developer invitation sent successfully!");
        } else {
          toast.error("Failed to send developer invitation. Please try again.");
        }
      }),
    onSuccess: (res) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success("Developer invitation sent successfully!");
      } else {
        toast.error("Failed to send developer invitation. Please try again.");
      }
    },
    onError: (error) => {
      console.error("Error sending developer invitation:", error);
      toast.error("An error occurred while sending the developer invitation.");
    },
  });

  return { inviteDeveloper };
};

export default useInviteDeveloper;
