import { convertToTitleCase, formattedPrice } from "../../../utils/constants";




export const bankStatementMockData = {
  metadata: {
    statusMessage: "SUCCESSFUL",
    statusCode: "200",
    timeStamp: "7/4/2022 6:25:52 PM",
    responseType: "INSIGHTS",
    hmacMessage:
      "7a4e291442a5fa3ab7a1f570b220c6c5e91552d46e32c35da5985ec9c8c403fd8ba452c1b23c7f4b43729691a70640ac8c86d93c129ee07377ee9ba4b46c7836",
  },
  statementName: "Mandatory Statement Name",
  statementKey: 1,
  statementUniqueCode: "67hgcd56",
  affordabilityResponse: null,
  creditScoreResponse: null,
  insightsResponse: {
    Key: 1,
    UniqueCode: "67hgcd56",
    Name: null,
    Source: null,
    ClientFullName: "Periculum ",
    ClientPhoneNumber: null,
    AccountType: "CURRENT",
    AccountBalance: 423075.10999999838,
    AccountId: "0799249523",
    AccountName: null,
    BankName: "MBS",
    FormatIdentifier: "mbsAccessBank",
    StartDate: "2021-12-01T00:00:00",
    EndDate: "2022-06-09T00:00:00",
    CreatedDate: "2022-07-04T18:25:51.4807661Z",
    ProcessingStatus: "PROCESSED",
    ConfidenceOnParsing: null,
  },
  TransactionPatternAnalysis: {
    last_day_of_credit: "2022-06-06",
    last_day_of_debit: "2022-06-08",
    "%_debit_transactions": 0.81,
    "%_credit_transactions": 0.19,
    total_number_transactions: 272,
    "%_transactions_less_than_10k": 0.43,
    "%_transactions_between_10k_to_100k": 0.33,
    "%_transactions_between_100k_to_500k": 0.21,
    "%_transactions_between_500k_1m": 0.02,
    "%_transactions_greater_than_1m": 0.01,
    "%_days_with_low_transaction_amount(<=10k)": 0.73,
    "%_balance_less_than_10k": 0.21,
    "%_balance_between_10k_to_100k": 0.26,
    "%_balance_between_100k_to_500k": 0.28,
    "%_balance_between_500k_1m": 0.15,
    "%_balance_greater_than_1m": 0.1,
    "%_days_with_low_balance(<=10k)": 0.41,
    most_frequent_balance_range: "100000 to 500000 ",
    most_frequent_transaction_range: "<10000",
    number_of_card_request: 0,
    top_incoming_transfer: null,
    top_outgoing_transfer: null,
    most_frequent_debit_transfer: "musa",
    most_frequent_credit_transfer: "emmanuel",
    return_cheque: 0,
    missing_transactions: "Yes",
    balance_breakdown: [
      {
        min: -1000000000000000000.0,
        max: 10000,
        percentage_of_transactions_in_bucket: 0.21,
        BucketType: null,
      },
      {
        min: 10000,
        max: 100000,
        percentage_of_transactions_in_bucket: 0.263,
        BucketType: null,
      },
      {
        min: 100000,
        max: 500000,
        percentage_of_transactions_in_bucket: 0.278,
        BucketType: null,
      },
      {
        min: 500000,
        max: 1000000,
        percentage_of_transactions_in_bucket: 0.153,
        BucketType: null,
      },
      {
        min: 1000000,
        max: 1000000000000000000.0,
        percentage_of_transactions_in_bucket: 0.096,
        BucketType: null,
      },
    ],
    transaction_breakdown: [
      {
        min: -1000000000000000000.0,
        max: 10000,
        percentage_of_transactions_in_bucket: 0.42,
        BucketType: null,
      },
      {
        min: 10000,
        max: 100000,
        percentage_of_transactions_in_bucket: 0.324,
        BucketType: null,
      },
      {
        min: 100000,
        max: 500000,
        percentage_of_transactions_in_bucket: 0.231,
        BucketType: null,
      },
      {
        min: 500000,
        max: 1000000,
        percentage_of_transactions_in_bucket: 0.014,
        BucketType: null,
      },
      {
        min: 1000000,
        max: 1000000000000000000.0,
        percentage_of_transactions_in_bucket: 0.011,
        BucketType: null,
      },
    ],
    "%_days_with_transactions_in_smallest_bucket": 0.727,
    "%_days_with_balances_in_smallest_bucket": 0.409,
    no_of_self_transfer_inflows: 136,
    no_of_self_transfer_outflows: 38,
    self_transfer_inflow_amount: 17159550,
    self_transfer_outflow_amount: 21802085.9,
    self_transfer_inflow_transactions: [
      { date: "2021-10-12 00:00:00", amount: 279000 },
      { date: "2022-01-20 00:00:00", amount: 100000 },
      { date: "2022-04-13 00:00:00", amount: 900000 },
      { date: "2022-04-13 00:00:00", amount: 10000 },
    ],
    self_transfer_outflow_transactions: [
      { date: "2022-05-04 00:00:00", amount: 50026.88 },
      { date: "2022-05-05 00:00:00", amount: 3000053.75 },
      { date: "2022-05-06 00:00:00", amount: 350053.75 },
      { date: "2022-05-06 00:00:00", amount: 40026.88 },
    ],
  },
  CashFlowAnalysis: {
    total_credit_turnover: 12948620.38,
    total_debit_turnover: 12772716.89,
    average_monthly_credits: 1849802.91,
    average_monthly_debits: 1824673.84,
    average_weekly_credits: 462450.73,
    average_weekly_debits: 456168.46,
    average_monthly_balance: 293564.44,
    average_weekly_balance: 170502.36,
    no_transacting_months: 7,
    monthly_outflow: [
      { year: "2021", month_name: "December", amount: 2345790.36 },
      { year: "2022", month_name: "January", amount: 347886.27 },
      { year: "2022", month_name: "February", amount: 4389260.55 },
      { year: "2022", month_name: "March", amount: 1422634.78 },
      { year: "2022", month_name: "April", amount: 1171527.2 },
      { year: "2022", month_name: "May", amount: 1156203.5 },
      { year: "2022", month_name: "June", amount: 1939414.23 },
    ],
    monthly_inflow: [
      { year: "2021", month_name: "December", amount: 2336364.56 },
      { year: "2022", month_name: "January", amount: 1031537.34 },
      { year: "2022", month_name: "February", amount: 3645000 },
      { year: "2022", month_name: "March", amount: 1432508.74 },
      { year: "2022", month_name: "April", amount: 1167143.33 },
      { year: "2022", month_name: "May", amount: 1150999.79 },
      { year: "2022", month_name: "June", amount: 2185066.62 },
    ],
    valid_credit: 10525081.21,
    period_in_statement: "December - June",
    year_in_statement: "2021, 2022",
    first_day: "2021-12-01",
    last_day: "2022-06-08",
    closing_balance: 247171.62,
  },
  BehavioralAnalysis: {
    inflow_outflow_rate: {
      month_on_month: "Negative Cash Flow",
    },
    overall_inflow_outflow_rate: "Positive Cash Flow",
    total_loan_amount: 2399145.16,
    total_repayment_amount: 178639.26,
    loan_inflow_rate: null,
    loan_repayment_to_inflow_rate: 0.01,
    number_credit_loan_transactions: null,
    number_debit_repayment_transactions: null,
    gambling_status: "No Gambling Transactions Found",
    gambling_rate: 0,
    account_activity: 0.4,
    percentage_of_inflow_irregularity: 0.2,
    average_monthly_loan_amount: 599786.29,
    average_monthly_loan_repayment_amount: 89319.63,
    number_loan_transactions: 4,
    number_repayment_transactions: 3,
    loan_to_inflow_rate: 0.33,
    account_sweep: "No",
    number_of_betting_transactions: 0,
    loan_transactions: [
      {
        date: "2021-08-11 00:00:00",
        amount: 3000000,
        description: "renmoney loan disbursement for loan id 163003223",
      },
      {
        date: "2022-01-27 00:00:00",
        amount: 3778000,
        description: "baobab micro finance bank 090136 loan disbursement",
      },
      {
        date: "2022-04-11 00:00:00",
        amount: 5000,
        description:
          "trf loan frm agbo anthony ugochukwu to nwiboko anayo andrew",
      },
    ],
    loan_repayment_transactions: [
      {
        date: "2021-08-11 00:00:00",
        amount: 3000000,
        description: "renmoney loan disbursement for loan id 163003223",
      },
      {
        date: "2022-01-27 00:00:00",
        amount: 3778000,
        description: "baobab micro finance bank 090136 loan disbursement",
      },
      {
        date: "2022-04-11 00:00:00",
        amount: 5000,
        description:
          "trf loan frm agbo anthony ugochukwu to nwiboko anayo andrew",
      },
    ],
  },
  IncomeAnalysis: {
    salary_transactions: [
      {
        date: "2021-12-07 00:00:00",
        description:
          "primuslite pp hall h066 november2021 salary hall 7 real estate ltd acb  emmanuel musa",
        amount: 156291.23,
      },
      {
        date: "2021-12-24 00:00:00",
        description:
          "primuslite pp hall h7011 december 2021 management contract hall 7 real estate ltd acb  emmanuel",
        amount: 425000,
      },
      {
        date: "2021-12-24 00:00:00",
        description:
          "primuslite pp hall h107 december2021 performance pay hall 7 real estate ltd acb  emmanuel musa",
        amount: 487500,
      },
      {
        date: "2021-12-24 00:00:00",
        description:
          "primuslite pp hall h066 december2021 salary hall 7 real estate ltd acb  emmanuel musa",
        amount: 214643.33,
      },
      {
        date: "2022-01-02 00:00:00",
        description:
          "primuslite pp hall 16th month salary hall 7 real estate ltd  emmanuel musa",
        amount: 262500,
      },
      {
        date: "2022-01-31 00:00:00",
        description:
          "primuslite pp hall h107 january2022 performance pay hall 7 real estate ltd acb  emmanuel musa",
        amount: 487500,
      },
      {
        date: "2022-01-31 00:00:00",
        description:
          "primuslite pp hall h066 january2022 salary hall 7 real estate ltd acb  emmanuel musa",
        amount: 214643.33,
      },
      {
        date: "2022-02-03 00:00:00",
        description:
          "primuslite pp hall h7011 january2022 management contract hall 7 real estate ltd acb  emmanuel",
        amount: 475000,
      },
      {
        date: "2022-03-04 00:00:00",
        description:
          "accessdev hall 7 real estate limited 637819820924325230 4 for feb 2022 130798085",
        amount: 487500,
      },
      {
        date: "2022-04-05 00:00:00",
        description:
          "accessdev hall 7 real estate limited 637846604276224554 3 for feb 2022 132696945",
        amount: 487500,
      },
      {
        date: "2022-05-07 00:00:00",
        description:
          "accessdev hall 7 real estate limited 637867362884651867 3 for april 2022 134148346",
        amount: 487500,
      },
      {
        date: "2022-06-01 00:00:00",
        description:
          "accessdev hall 7 real estate limited 637895123878093589 3 for may 2022 136012870",
        amount: 487500,
      },
    ],
    average_predicted_salary: 389423.16,
    is_salary_earner: "Yes",
    expected_salary_payment_day: 31,
    frequency_of_salary_payments: ">1",
    last_date_of_salary_payment: "2022-06-01",
    number_of_salary_payments: 12,
    has_other_income: "Yes",
    other_income_transactions: [
      {
        date: "2022-01-31",
        description:
          "primuslite pp hall holiday bonus hall 7 real estate l  emmanuel musa",
        amount: 42500,
      },
    ],
    average_other_income: 6071.43,
    number_of_other_income_payments: 1,
    net_average_monthly_earning: 0,
    lowest_salary: 156291.23,
    most_recent_salary: "487500.0",
  },
  SpendAnalysis: {
    average_spend: {
      average_monthly_spend_on_transfer: 1613700.44,
      average_monthly_spend_on_atm_and_pos_transactions: 0,
      average_monthly_spend_on_ussd: 857.14,
      average_monthly_spend_on_gambling: 0,
      average_monthly_spend_on_airtime_and_data: 3918.57,
      average_monthly_spend_on_utilities: 6063.57,
      average_monthly_spend_on_online_and_web: 0,
      average_monthly_spend_on_transportation: 0,
      average_monthly_spend_on_health: 0,
      average_monthly_spend_on_travel: 0,
      average_monthly_spend_on_entertainment: 0,
      average_monthly_spend_on_hospitality_and_food: 3650.54,
      average_monthly_spend_on_rent: 0,
      average_monthly_spend_on_insurance: 21.43,
      average_monthly_spend_on_charges_and_stamp_duty: 1707.93,
      average_monthly_spend_on_international_transactions: 0,
      average_monthly_total_expenses: 1822965.91,
      average_monthly_recurring_expense: 0,
      average_monthly_spend_on_savings_and_investments: 0,
      average_monthly_spend_on_crypto: 0,
      average_monthly_spend_on_others: 240208.12,
      most_recurring_expense: "transfer_transactions",
      most_frequent_spend_category: "transfer_transactions",
      month_with_highest_spend: "2/2022",
      highest_spend: 4389260.55,
      most_frequent_expense: "nextgenusr trf frm emmanuel musa emmanuel musa",
      most_frequent_expense_amount: 30026.88,
    },
    total_spend: {
      total_spend_on_transfer: 11295903.08,
      total_spend_on_atm_and_pos_transactions: 0,
      total_spend_on_ussd: 6000,
      total_spend_on_gambling: 0,
      total_spend_on_airtime_and_data: 27430,
      total_spend_on_utilities: 42445,
      total_spend_on_online_and_web: 0,
      total_spend_on_transportation: 0,
      total_spend_on_health: 0,
      total_spend_on_travel: 0,
      total_spend_on_entertainment: 0,
      total_spend_on_hospitality_and_food: 25553.76,
      total_spend_on_rent: 150,
      total_spend_on_insurance: 0,
      total_spend_on_charges_and_stamp_duty: 11955.5,
      total_spend_on_international_transactions: 0,
      total_monthly_expenses: 12760761.39,
      total_recurring_expense: 0,
      total_expenses: 12760761.39,
      total_spend_on_savings_and_investments: 0,
      total_spend_on_crypto: 0,
      total_spend_on_others: 1681456.81,
    },
    atm_location: [],
  },
};

export const bankStatementColumn = [
  {
    name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}> YEAR </p>,
    sortable: true,
    selector: (row) => row.year || "--",
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}> MONTH </p>,
    sortable: true,
    selector: (row) => row.month || "--",
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}> SAVINGS </p>,
    sortable: true,
    selector: (row) => <>₦ {formattedPrice(row.saving?.toFixed(2))}</> || "--",
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}> INCOME </p>,
    sortable: true,
    selector: (row) => <>₦ {formattedPrice(row.incomeSalary?.toFixed(2))}</> || "--",
  },
  // {
  //   name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}> INCOME - OTHER </p>,
  //   sortable: true,
  //   selector: (row) => row.incomeOther || "--",
  // },
  {
    name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}> EXPENSES </p>,
    sortable: true,
    selector: (row) => <>₦ {formattedPrice(row.expenses?.toFixed(2))}</> || "--",
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}> SAVINGS RATIO </p>,
    sortable: true,
    selector: (row) => <>{formattedPrice(row.savingRatio?.toFixed(2))}%</> || "--",
  },
];

export const bankStatementTableData = [
  {
    year: "2023",
    month: "June",
    incomeSalary: "₦200,000",
    incomeOther: "₦500,000",
    savings: "₦240,000",
    expenses: "₦360,000",
    savingsRatio: "80%",
  },
  {
    year: "2023",
    month: "June",
    incomeSalary: "₦200,000",
    incomeOther: "₦500,000",
    savings: "₦240,000",
    expenses: "₦360,000",
    savingsRatio: "20%",
  },
  {
    year: "2023",
    month: "July",
    incomeSalary: "₦200,000",
    incomeOther: "₦500,000",
    savings: "₦240,000",
    expenses: "₦360,000",
    savingsRatio: "80%",
  },
  {
    year: "2023",
    month: "August",
    incomeSalary: "₦200,000",
    incomeOther: "₦500,000",
    savings: "₦240,000",
    expenses: "₦360,000",
    savingsRatio: "80%",
  },
  {
    year: "2023",
    month: "September",
    incomeSalary: "₦200,000",
    incomeOther: "₦500,000",
    savings: "₦240,000",
    expenses: "₦360,000",
    savingsRatio: "45%",
  },
  {
    year: "2023",
    month: "October",
    incomeSalary: "₦200,000",
    incomeOther: "₦500,000",
    savings: "₦240,000",
    expenses: "₦360,000",
    savingsRatio: "-80%",
  },
  {
    year: "2023",
    month: "November",
    incomeSalary: "₦200,000",
    incomeOther: "₦500,000",
    savings: "₦240,000",
    expenses: "₦360,000",
    savingsRatio: "-57%",
  },
];
