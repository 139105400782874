import React from "react";
import DataTable from "react-data-table-component";
import Skeleton from "react-loading-skeleton";

const TableLoader = () => {
  // Define the columns with skeleton loaders
  const columns = [
    {
      name: "S/N",
      selector: (row) => (
        <Skeleton
          count={1}
          height={"1rem"}
          baseColor={"#f0f0f0"}
          style={{ position: "absolute", width: "97%" }}
        />
      ),
    },
  ];

  // Generate fake rows with skeleton loaders
  const generateSkeletonRows = (numRows) => {
    return Array.from({ length: numRows }, (_, index) => ({
      id: index,
    }));
  };

  // Number of skeleton rows to display
  const skeletonRows = generateSkeletonRows(1); // Adjust the number of rows as needed

  return (
    <div className="dt-bn">
      <DataTable
        columns={columns}
        data={skeletonRows}
        fixedHeader
        striped
        pagination
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        highlightOnHover
        persistTableHead
      />
    </div>
  );
};

export default TableLoader;
