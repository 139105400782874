import React from "react";
import { detailCardTypes } from "./constants";

const DetailCard = ({
  title,
  subtitle,
  subtitlePrefix,
  children,
  className,
  variant = detailCardTypes.DEFAULT,
  isBold = false,
}) => {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  return (
    <div className={className}>
      <div>
        <h5>{title}</h5>
        {variant === detailCardTypes.PRICE ? (
          <p className="fw-bolder m-0 fs-4 text-success">
            <p className="text-black h5 text-uppercase fw-bold">Price:</p>{" "}
            {formatter.format(subtitle ?? 0)}
          </p>
        ) : (
          <p className={`m-0 ${isBold ? "fw-bold" : ""}`}>
            {subtitlePrefix}
            {subtitle}
          </p>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default DetailCard;
