import axios from "axios";

const baseAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
  //   withCredentials: true,
});

export default baseAxios;
