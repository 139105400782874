import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useUploadDocuments = (organizationId, organizationType) => {
  const { postRequest } = useAxiosBase();
  const navigate = useNavigate();

  const uploadingDocuments = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `public/organization/${organizationId}/${organizationType}/upload-document`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          return;
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.statusCode === 200) {
      }
      if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
        toast.error(res?.data?.value?.message);
      }
    },
    onError: (error) => {
      console.log("ERRROR", error);
      toast.error(error);
    },
  });

  return { uploadingDocuments };
};

export default useUploadDocuments;
