import React, { useEffect, useState } from "react";
import { useNav } from "../../../hooks/useNav";
import FormInput from "../../../Components/Common/FormInput";
import Dropdown from "../../../Components/Common/Dropdown";
import Spinner from "../../../Components/Spinner";
import FooterButtons from "../../../Components/Common/FooterButtons";
import { defalultOptions, settingsOptions } from "../viewingRequestConstant";
import Button from "../../../Components/Common/Button";
import useAuth from "../../../hooks/useAuth";
import useEditViewing from "../hooks/useEditViewing";
import useGetEditViewing from "../hooks/useGetEditViewing";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import { colors } from "../../../utils/constants";
import { formattedPrice, onlyNumbers } from "../../../utils/constants";

const EditViewing = () => {
  useNav({ mainTitle: "House Inspection Settings", hasBackButton: true });
  const { putRequest } = useAxiosBase();
  const { authUser } = useAuth();

  const organisationId = authUser?.user?.organizationId;
  const organisationName = authUser?.user?.organization.name;

  const { data: getEditViewingAmount, isLoading} = useGetEditViewing();
  const [viewingAmount, setViewingAmount] = useState("");
  const { editViewing } = useEditViewing();

  const [isDisabled, setIsDisabled] = useState(true);
  const [dropDownValue, setDropdownValue] = useState(false);

  const handleChange = (event) => {
    if (event.target.value === "Yes") {
      setIsDisabled(false);
      setDropdownValue(true);
    } else {
      setViewingAmount(0);
      setIsDisabled(true);
      setDropdownValue(false);
    }
  };

  const handleAmountChange = (name, value) => {
    // Remove non-numeric characters except comma
    const formattedValue = value.replace(/[^\d,]/g, "");
    setViewingAmount(formattedValue);
  };

  const handleClick = () => {
    const value = viewingAmount === "" ? 0 : viewingAmount;
    const description =
      value === 0
        ? "No charge for property viewings"
        : `Charge for property viewings: N${value}`;

    let data = {
      name: organisationName,
      description: description,
      fixedOrPercentage: "FIXED",
      value: value,
      organizationId: organisationId,
      identifier: "VIEWING_REQUEST_AMOUNT",
    };

    if (getEditViewingAmount?.id) {
      data = {
        ...data,
        id: getEditViewingAmount?.id,
      };
    }
    // console.log(data);
    editViewing.mutate(data);
  };

  useEffect(() => {
    setViewingAmount(getEditViewingAmount?.value);
    if (getEditViewingAmount?.value > 1) {
      setIsDisabled(false);
    }
  }, [getEditViewingAmount]);
  
  if (isLoading) return <div style={{height: "60vh"}}> <Spinner loadingText={"Loading House Inspection Settings..."} /> </div>;

  return (
    <>
         <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-6 col-md-6">
              <Dropdown
                labelName={"Do your agents charge for property inspections?"}
                required
                placeholder={getEditViewingAmount?.value > 1 ? "Yes" : "No"}
                options={settingsOptions}
                onChange={handleChange}
              />
            </div>
            <div className="col-6 col-md-4"
             onBlur={(e) =>
              setViewingAmount(
                !onlyNumbers(e.target.value)
                  ? formattedPrice(viewingAmount)
                  : formattedPrice(e.target.value)
              )
            }>
              <FormInput
                labelName={"How Much Do they Charge?"}
                required
                placeholder="N5000"
                disabled={isDisabled}
                value={viewingAmount}
                onChange={(name, value) => handleAmountChange(name, value)}
              />
            </div>
          </div>

          <FooterButtons
            saveLabel={"Update"}
            handleClick={handleClick}
            loading={editViewing.isLoading}
            disabled={editViewing.isLoading}
          />
        </div>
    </>
  );
};

export default EditViewing;
