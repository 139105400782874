import React, { useState } from 'react';
import './style.css';

const TabGroup = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const handleClick = (e, newActiveTab) => {
    e.preventDefault();
    setActiveTab(newActiveTab);
  };

  return (
    <div className="tab-group">
      <ul className="tab-list">
        {children.map((tab) => (
          <li
            key={tab.props.label}
            className={tab.props.label === activeTab ? 'tab is-active' : 'tab'}
            onClick={(e) => handleClick(e, tab.props.label)}
          >
            {tab.props.label}
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {children.map((one) => {
          if (one.props.label !== activeTab) return undefined;
          return one.props.children;
        })}
      </div>
    </div>
  );
};

export default TabGroup;
