import { createBrowserRouter } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import MortgageApplication from "./Pages/Application/MyApplications";
import Developers from "./Pages/Developers";
import Plans from "./Pages/Plans";
import MortgagePlanDetails from "./Pages/Plans/MortgagePlanDetails";
import Earnings from "./Pages/Earnings";
import CreateMortagagePlan from "./Pages/Plans/CreateMortagagePlan";
import App from "./App";
import Login from "./Pages/Login";
import ForogtPassword from "./Pages/ForgotPassword";
import RequireAuth from "./Pages/RequireAuth";
import Prospect from "./Pages/Prospect";
import Portfolio from "./Pages/Portfolio";
import PortfolioDetails from "./Pages/Portfolio/PortfolioDetails";
import ViewingRequests from "./Pages/ViewingRequests";
import EditViewing from "./Pages/ViewingRequests/Edit";
import Agents from "./Pages/ViewingRequests/Agents";
import MortgageApplicationDetails from "./Pages/Application/ApplicationDetails";
import PropertyDocuments from "./Pages/PropertyDocuments";
import OfferLetterList from "./Pages/PropertyDocuments/offerLetterList";
import TitleDocumentList from "./Pages/PropertyDocuments/TitleDocumentList";
import Company from "./Pages/Settings/Company";
import Profile from "./Pages/Settings/Profile";
import Password from "./Pages/Settings/Password";
import Team from "./Pages/Settings/Team";
import Documents from "./Pages/Settings/Documents";
import PermissionsAndRoles from "./Pages/Settings/PermissionsAndRoles";
import ApplicationReview from "./Pages/ApplicationReview";
import Signup from "./Pages/Signup";
import Wizzard from "./Pages/Signup/wizzard";
import ResetPassword from "./Pages/ResetPassword";
import Applications from "./Pages/Application/Applications";
import MyApplication from "./Pages/Application/MyApplications";
import Permissions from "./Pages/Settings/PermissionsAndRoles/Permissions";
import DeveloperDetails from "./Pages/Developers/DeveloperDetails";
import InviteTeamMember from "./Pages/InviteTeamMember";
import Transactions from "./Pages/Transactions";
import Payments from "./Pages/Settings/Payments";
import MortgagePlanViewDetails from "./Pages/Plans/MortgagePlanViewDetails";
import MyProperties from "./Pages/Properties/MyProperties";
import PartnerProperties from "./Pages/Properties/PartneredProperties";
import CreateEstate from "./Pages/Properties/MyProperties/Estate/createEstate";
import CreateHouse from "./Pages/Properties/MyProperties/House/createHouse";
import MyEstate from "./Pages/Properties/MyProperties/Estate/index";
import MyHouse from "./Pages/Properties/MyProperties/House/index";
import Estate from "./Pages/Properties/PartneredProperties/Estate";
import House from "./Pages/Properties/PartneredProperties/House";
import PartnershipTerms from "./Pages/Settings/PartnershipTerms/index";
import NotificationConfiguration from "./Pages/Settings/NotificationConfiguration/index";
import HousePlans from "./Pages/Properties/MyProperties/House/HousePlans";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RequireAuth />,
    children: [
      {
        path: "/",
        element: <App />,
        children: [
          {
            element: <Dashboard />,
            path: "",
            titleName: "Dashboard",
          },
          {
            path: "/properties",
            children: [
              {
                path: "/properties/my-properties",
                element: <MyProperties />,
                titleName: "Properties",
              },
              {
                path: "/properties/my-properties/estate/:id",
                element: <MyEstate />,
              },
              {
                path: "/properties/my-properties/house/:id",
                element: <MyHouse />,
              },
              {
                path: "/properties/my-properties/create-estate",
                element: <CreateEstate />,
              },
              {
                path: "/properties/my-properties/house/plans/:houseId",
                element: <HousePlans />,
              },
              {
                path: "/properties/my-properties/house/plans/:houseId/:id",
                element: <MortgagePlanDetails />,
              },
              {
                path: "/properties/my-properties/house/plans/create/:houseId",
                element: <CreateMortagagePlan />,
              },
              {
                path: "/properties/my-properties/create-house/:estateId?",
                element: <CreateHouse />,
              },
              {
                path: "/properties/partnered-developer-properties",
                element: <PartnerProperties />,
                titleName: "Properties",
              },
              {
                path: "/properties/partnered-developer-properties/estate/:id",
                element: <Estate />,
              },
              {
                path: "/properties/partnered-developer-properties/house/:id",
                element: <House />,
              },
            ],
          },
          {
            path: "/applications",
            children: [
              {
                path: "",
                element: <MyApplication />,
                titleName: "Applications",
              },
              {
                path: "/applications/:planId",
                element: <Applications />,
                titleName: "Applications",
              },
              {
                path: "/applications/details/:id",
                element: <MortgageApplicationDetails />,
              },
            ],
          },
          {
            path: "/developers",
            children: [
              {
                path: "",
                element: <Developers />,
                titleName: "Developers",
              },
              {
                path: "/developers/:id",
                element: <DeveloperDetails />,
                titleName: "Organization Permissions",
              },
            ],
          },
          {
            element: <ApplicationReview />,
            path: "/application-review",
            titleName: "Application Review",
          },
          {
            path: "/portfolio",
            children: [
              { path: "", element: <Portfolio /> },
              { path: "/portfolio/details/:id", element: <PortfolioDetails /> },
            ],
            titleName: "Offer Letter & Title Documents",
          },
          {
            element: <Transactions />,
            path: "/transactions-earnings",
            titleName: "Transactions & Earnings",
          },
          {
            path: "/plans",
            children: [
              {
                path: "",
                element: <Plans />,
              },
              {
                path: "/plans/edit/:id",
                element: <MortgagePlanDetails />,
              },
              {
                path: "/plans/view/:id",
                element: <MortgagePlanViewDetails />,
              },
              {
                path: "/plans/create",
                element: <CreateMortagagePlan />,
              },
            ],
            titleName: "Plans",
          },
          {
            path: "/prospects",
            element: <Prospect />,
            titleName: "Prospect",
          },
          {
            path: "/offer-letter",
            children: [
              { path: "", element: <PropertyDocuments /> },
              { path: "/offer-letter/list", element: <OfferLetterList /> },
              {
                path: "/offer-letter/property-documents",
                element: <TitleDocumentList />,
              },
            ],
            titleName: "Offer Letter & Property Documents",
          },
          {
            path: "/house-inspections",
            children: [
              { path: "", element: <ViewingRequests /> },
              { path: "/house-inspections/agents", element: <Agents /> },
              {
                path: "/house-inspections/edit",
                element: <EditViewing />,
                titleName: "Edit House Inspections",
              },
            ],
            titleName: "House Inspections",
          },
          {
            element: <Earnings />,
            path: "/earnings",
            titleName: "Earnings",
          },
          {
            path: "/settings/company",
            element: <Company />,
          },
          {
            path: "/settings/payments",
            element: <Payments />,
          },
          {
            path: "/settings/profile",
            element: <Profile />,
          },
          {
            path: "/settings/account",
            element: <Password />,
          },
          {
            path: "/settings/team",
            element: <Team />,
          },
          {
            path: "/settings/partnership-terms",
            element: <PartnershipTerms />,
          },
          {
            path: "/settings/documents",
            element: <Documents />,
          },
          {
            path: "/settings/notification-configuration",
            element: <NotificationConfiguration />,
          },
          {
            path: "/settings/permission-and-roles",
            children: [
              {
                path: "",
                element: <PermissionsAndRoles />,
                titleName: "Organization Roles",
              },
              {
                path: "/settings/permission-and-roles/:id",
                element: <Permissions />,
                titleName: "Organization Permissions",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/account/login",
    element: <Login />,
  },
  {
    path: "/account/forgot-password",
    element: <ForogtPassword />,
  },
  {
    path: "/account/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/account/complete-registration",
    element: <InviteTeamMember />,
  },
  {
    path: "/account/signup",
    element: <Signup />,
  },
  {
    path: "/account/onboarding",
    element: <Wizzard />,
  },
]);

export default router;
