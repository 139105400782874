import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useCreateNotificationConfiguration = () => {
  const { postRequest } = useAxiosBase();

  const createNotificationConfiguration = useMutation({
    mutationKey: ["create-notification-configuration"],
    mutationFn: (data) =>
      postRequest({
        url: `bank/notification-configuration/create`,
        data: data,
      }),
      onSuccess: (res) => {
        console.log("res", res)
        if (res?.data?.statusCode === 200) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { createNotificationConfiguration };
};

export default useCreateNotificationConfiguration;
