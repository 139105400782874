import { useContext } from "react";
import { createContext, useState } from "react";

export const ApplicationContext = createContext();
export const Application1Context = createContext();

export const useToggle = () => useContext(ApplicationContext);
export const useToggler = () => useContext(Application1Context);

export const AppContextProvider = ({ children }) => {
  const [toggleValue, setToggleValue] = useState(false);

  const toggler = () => {
    setToggleValue(!toggleValue);
  };

  return (
    <ApplicationContext.Provider value={toggleValue}>
      <Application1Context.Provider value={toggler}>
        {children}
      </Application1Context.Provider>
    </ApplicationContext.Provider>
  );
};
