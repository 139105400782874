import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "./useAxiosBase";

const useCity = ({ stateId }) => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["city", stateId],
    queryFn: () =>
      getRequest({
        url: `/public/city/${stateId}/get-all`,
        params: {
          PageSize: 1000,
          PageNumber: 1,
        },
      }).then((res) => {
        return res.data?.value?.value.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      }),
    staleTime: 50000,
  });
};
export default useCity;
