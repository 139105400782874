import React from "react";
import { VerifyingLoaderWrapper } from "./style";

const VerifyingLoader = ({ title, ...rest }) => {
  return (
    <VerifyingLoaderWrapper className={rest.className} {...rest}>
      <div className="d-flex gap-2">
        <p className="verifyingLoader"></p>
        <p className="title">
          {title}
          <span className="dots">...</span>{" "}
        </p>
      </div>
    </VerifyingLoaderWrapper>
  );
};

export default VerifyingLoader;
