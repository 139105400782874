import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useUpdatePlans = (planId, houseId) => {
  const { putRequest } = useAxiosBase();
  const navigate = useNavigate();

  const updatePlans = useMutation({
    mutationKey: ["update-plan", planId],
    mutationFn: (data) =>
      putRequest({
        url: `/bank/mortgage-plan/${planId}/update`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Plan updated successfully");
          if (houseId) {
            navigate(`/properties/my-properties/house/plans/${houseId}}`);
          } else {
            navigate("/plans");
          }
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { updatePlans };
};

export default useUpdatePlans;

