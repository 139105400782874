import React, { useState } from "react";
import "../properties.css";
import { useNav } from "../../../hooks/useNav";
import EstateCollection from "./Estate/EstateCollection";
import HouseCollection from "./House/HouseCollection";
import useGetPedingEstateAndHouseCount from "./House/hooks/useGetPedingEstateAndHouseCount";

const PartnerProperties = () => {
  const { data: pendingCount, refetch: refetchPendingCount } =
    useGetPedingEstateAndHouseCount();

  useNav({
    mainTitle: "Partner Properties",
    isProp: true,
    houses: pendingCount?.acceptedEstateCount ?? 0,
    estates: pendingCount?.acceptedHouseCount ?? 0,
  });

  return (
    <div className="container-fluid align-items-center mt-5">
      <EstateCollection
        refetchPendingCount={refetchPendingCount}
        pendingCount={pendingCount?.pendingEstateCount}
      />

      <HouseCollection
        refetchPendingCount={refetchPendingCount}
        pendingCount={pendingCount?.pendingHouseCount}
      />
    </div>
  );
};

export default PartnerProperties;
