import React, { useState, useEffect, useRef } from "react";
import { useNav } from "../../../hooks/useNav";
import FormInput from "../../../Components/Common/FormInput";
import MultitextInput from "../../../Components/Common/MultitextInput";
import { colors } from "../../../utils/constants";
import Spinner from "../../../Components/Spinner";
import Button from "../../../Components/Common/Button";
import FooterButtons from "../../../Components/Common/FooterButtons";
import Icons from "../../../Components/Common/Icons";
import "../Company/company.css";
import useGetCompany from "./hooks/useGetCompany";
import { useForm } from "react-hook-form";
import updateModelSchema from "./models/updateCompanyModel";
import useSaveSingleImage from "../../../hooks/useSaveSingleImage";
import useEditCompany from "./hooks/useEditCompanyDetails";
import useAllStates from "../../../hooks/useAllStates";
import useCity from "../../../hooks/useCity";
import Dropdown from "../../../Components/Common/Dropdown";
import { bankList } from "../../../Components/Common/BankList/bankListConstant";
import { convertToTitleCase } from "../../../utils/constants";
import Modal from "../../../Components/Common/Modal";
import { toast } from "react-toastify";
import SkeletonLoader from "../../../Components/SkeletonLoader";
import CustomTextEditor from "../../../Components/Common/CustomRichTextEditor";

const sanitizeHtml = (html) => {
  // Perform sanitization here (you can use libraries like DOMPurify for better sanitization)
  return html?.replace(/<script.*?>.*?<\/script>/g, "");
};

const Company = () => {
  useNav({ mainTitle: "Company Profile" });
  const imageRef = useRef();
  const coverImageRef = useRef();

  const {
    data: companyDetails,
    error,
    isLoading,
    refetch: refetchCompany,
    isRefetching,
  } = useGetCompany();
  const { updateSettings } = useEditCompany();

  const [update, setUpdate] = useState(false);
  const { data: states } = useAllStates({ onSuccess: (data) => {} });
  const [bankCode, setBankCode] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [stateName, setStateName] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const {
    data: citys,
    isLoading: isCityLoading,
    refetch,
  } = useCity({ stateId });

  const [banks, setBanks] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [bankLogo, setBankLogo] = useState();
  const [bankCoverImg, setBankCoverImg] = useState();
  const [companyDescription, setCompanyDescription] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const sanitizedDescription = sanitizeHtml(companyDetails?.description);

  const handleSelectedBank = (bankCode) => {
    if (bankCode) {
      const bankName = bankList.find((x) => x.bankCode === bankCode).bankName;
      setBankName(convertToTitleCase(bankName));
      setBankCode(bankCode);
    }
  };

  const { imageToDisplay, imageObject, handleChange } = useSaveSingleImage({
    optionsId: "BANK_LOGO",
  });

  const {
    imageToDisplay: coverImageToDisplay,
    imageObject: coverImageObject,
    deleteImage: deleteCoverImage,
    handleChange: coverImageHandleChange,
  } = useSaveSingleImage({
    optionsId: "BANK_COVER_IMAGE",
  });

  const handleDeleteCoverImage = () => {
    deleteCoverImage();
  };

  const { register, handleSubmit, reset } = useForm(updateModelSchema);
  const onSubmit = (data) => {
    if (!imageToDisplay && !bankLogo) {
      return toast.error("Logo is ");
    }
    if (!coverImageToDisplay && !bankCoverImg) {
      return toast.error("Cover image is ");
    }

    data.yearsInOperation = parseInt(data.yearsInOperation, 10);
    data.bankCode = bankCode;
    data.bankName = bankName;
    data.cityId = cityId;
    data.description = companyDescription ?? null;
    data.logo = imageObject ? { ...imageObject[0] } : null;
    data.coverImage = coverImageObject ? { ...coverImageObject[0] } : null;

    // console.log("submit", data);
    updateSettings.mutate(data, {
      onSuccess: () => {
        setUpdate(false);
        refetchCompany();
      },
    });
  };

  useEffect(() => {
    if (bankList) {
      const updatedBankList = bankList.map((bank) => ({
        id: bank.bankCode,
        name: convertToTitleCase(bank.bankName),
      }));

      setBanks(updatedBankList);
    }

    if (states) {
      const updatedStateList = states.map((state) => ({
        label: state.id,
        value: state.name,
      }));

      setStateList(updatedStateList);
    }

    if (citys) {
      const updatedCityList = citys.map((city) => ({
        label: city.id,
        value: city.name,
      }));

      setCityList(updatedCityList);
    }
  }, [citys, states]);

  useEffect(() => {
    if (companyDetails) {
      setBankCode(companyDetails.bankCode);
      setBankName(convertToTitleCase(companyDetails?.bankName));
      setStateId(convertToTitleCase(companyDetails?.city?.stateId));
      setCompanyDescription(companyDetails?.description);

      setCityId(companyDetails.city?.id);
      setCityName(companyDetails?.city?.name);
      setStateName(convertToTitleCase(companyDetails?.city?.stateId));
      setStateId(companyDetails?.city?.stateId);

      if (companyDetails?.logo) {
        setBankLogo(companyDetails?.logo);
      }
      if (companyDetails?.coverImage) {
        setBankCoverImg(companyDetails?.coverImage);
      }
      reset(companyDetails);
    }
  }, [companyDetails, reset]);

  if (error) return "error";
  return (
    <>
      {update ? (
        <div className="container-fluid">
          {companyDetails && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="company-details row mt-4">
                <div className="col-md-6 col-lg-4 mb-3">
                  <FormInput
                    required
                    labelName={"Organization Name"}
                    lefticonname="terrace"
                    placeholder={"eg. God Is Good Realty"}
                    register={register("name")}
                    name="name"
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  {" "}
                  <FormInput
                    labelName={"Email"}
                    type={"email"}
                    required
                    lefticonname="envolope"
                    placeholder={"eg. john.anakson@gmail.com"}
                    register={register("emailAddress")}
                    name="emailAddress"
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <FormInput
                    required
                    labelName={"Phone Number"}
                    type={"phone"}
                    lefticonname="phone"
                    placeholder={"eg. O9065718291"}
                    register={register("phoneNumber")}
                    name="phoneNumber"
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <Dropdown
                    required
                    labelName={"State"}
                    lefticonname="bank"
                    value={stateName}
                    options={states}
                    onChange={(e) => {
                      let id = e.target.value;
                      if (id) {
                        const stateName = states?.find((x) => x.id === id).name;
                        refetch();
                        setStateName(stateName);
                        setStateId(id);
                      }
                    }}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <Dropdown
                    required
                    labelName={"City"}
                    lefticonname={"bank"}
                    value={cityName}
                    isLoading={isCityLoading}
                    disabled={citys?.length < 0}
                    options={citys && citys}
                    onChange={(e) => {
                      let id = e.target.value;
                      if (id) {
                        const cityName = citys?.find((x) => x.id === id).name;
                        refetch();
                        setCityName(cityName);
                        setCityId(id);
                      }
                    }}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  {" "}
                  <FormInput
                    required
                    labelName={"Years in Operation"}
                    lefticonname="bank"
                    placeholder={"eg. 4"}
                    register={register("yearsInOperation")}
                    name="yearsInOperation"
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-4 mb-md-3">
                  <Dropdown
                    required
                    labelName={"Bank Name"}
                    value={bankName}
                    lefticonname="bank"
                    options={banks}
                    name="bankName"
                    register={register("bankName")}
                    onChange={(e) => handleSelectedBank(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  {" "}
                  <FormInput
                    required
                    labelName={"Bank Account Number"}
                    placeholder={"eg. 1234567890"}
                    lefticonname="bank"
                    name="accountNumber"
                    register={register("accountNumber")}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3 form-content">
                  <div className="row d-flex justify-content-end">
                    <div className="col-md-6 d-flex justify-content-center flex-column align-items-center">
                      <label
                        htmlFor="company_logo"
                        className="form-label ml-4 custom-form-label"
                      >
                        Logo <span className=" text-danger">*</span>
                      </label>
                      <div className="company_logo_wrapper">
                        <div
                          className="company_logo empty-img"
                          onClick={() => imageRef.current.click()}
                        >
                          {bankLogo ? (
                            <img
                              src={bankLogo}
                              alt="companyLogo"
                              className=" rounded-circle"
                              height={90}
                              width={90}
                            />
                          ) : imageToDisplay ? (
                            <img
                              src={imageToDisplay}
                              alt="companyLogo"
                              className=" rounded-circle"
                              height={90}
                              width={90}
                            />
                          ) : (
                            <Icons
                              iconName="addImage"
                              className={"empty-img-svg"}
                            />
                          )}
                        </div>
                        <input
                          type="file"
                          ref={imageRef}
                          className="input-image"
                          accept="image/*"
                          onChange={(e) => {
                            setBankLogo(null);
                            handleChange(e);
                          }}
                          name="logo"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 form-content">
                      <div className="color-pallete">
                        <label
                          htmlFor="exampleColorInput"
                          className="form-label ml-4 custom-form-label"
                        >
                          Brand Color <span className=" text-danger">*</span>
                        </label>
                        <input
                          type="color"
                          className=" form-control form-control-lg color-picker"
                          title="Choose your color"
                          {...register("brandColorCode")}
                          name="brandColorCode"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-3 mt-3 mt-md-0">
                  <MultitextInput
                    required
                    label={"Address"}
                    placeholder={"Update Company Address"}
                    lefticonname={"bank"}
                    name="address"
                    register={register("address")}
                  />
                </div>
                {/* <div className="col-md-6 col-lg-4 mb-3 mt-5 mt-md-0">
               {" "}
               <MultitextInput
                 label={"Company Description"}
                 register={register("description")}
                 name={"description"}
                 placeholder={
                   "eg. We are a realty firm located in Abuja and has developed over 23 estate all around Nigeria."
                 }
               />
             </div> */}
                <div className="col-md-6 col-lg-8 mt-3 mt-md-0">
                  <label
                    htmlFor="featuredImage"
                    className="form-label ml-4 custom-form-label"
                  >
                    Cover Image <span className=" text-danger">*</span>
                  </label>
                  <div>
                    <div
                      className="company-img-preview"
                      onClick={() => coverImageRef?.current?.click()}
                      role="button"
                    >
                      {bankCoverImg ? (
                        <>
                          <img
                            src={bankCoverImg}
                            alt="companyCoverPhoto"
                            className="w-100"
                          />
                          <span className="delete-company-image-btn">
                            <Button
                              btnText={<Icons iconName={"delete"} />}
                              bordercolor={colors.black}
                              borderHover={colors.red}
                              backgroundcolor={colors.white}
                              backgroundHover={colors.dangerLight}
                              colorHover={colors.danger}
                              color={colors.danger}
                              iconcolorHover={colors.danger}
                              padding={"0"}
                              bordersize={2}
                              btntextcolor={colors.red}
                              width={"40px"}
                              height={"43px"}
                              type={"button"}
                              onBtnClick={() => setShowDeleteModal(true)}
                            />
                          </span>
                        </>
                      ) : coverImageToDisplay ? (
                        <>
                          {" "}
                          <img
                            src={coverImageToDisplay}
                            alt="companyCoverPhoto"
                          />
                          <span className="delete-company-image-btn">
                            <Button
                              btnText={<Icons iconName={"delete"} />}
                              bordercolor={colors.black}
                              borderHover={colors.red}
                              backgroundcolor={colors.white}
                              backgroundHover={colors.dangerLight}
                              colorHover={colors.danger}
                              color={colors.danger}
                              iconcolorHover={colors.danger}
                              padding={"0"}
                              bordersize={2}
                              btntextcolor={colors.red}
                              width={"40px"}
                              height={"43px"}
                              type={"button"}
                              onBtnClick={() => setShowDeleteModal(true)}
                              // onBtnClick={() => handleDeleteCoverImage()}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Icons
                            iconName="addImage"
                            className={"empty-img-svg"}
                          />
                          <input
                            type="file"
                            ref={coverImageRef}
                            className="input-image"
                            accept="image/*"
                            onChange={(e) => {
                              setBankCoverImg(null);
                              coverImageHandleChange(e);
                            }}
                            name="featuredImage"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  <CustomTextEditor
                    required
                    height={"20rem"}
                    labelName={"Description"}
                    value={companyDescription}
                    placeholder={
                      "eg. We are a realty firm located in Abuja and has developed over 23 estate all around Nigeria."
                    }
                    onChange={(e) => setCompanyDescription(e)}
                  />
                </div>
              </div>

              <FooterButtons
                cancelBtn={() => setUpdate(false)}
                largeLoader={updateSettings.isLoading}
                loading={updateSettings.isLoading}
                disabled={updateSettings.isLoading}
              />
            </form>
          )}
        </div>
      ) : (
        <div className="container-fluid">
          <div className="d-flex justify-content-end pt-2 pb-3">
            <Button
              btnText={"Update"}
              lefticonname={"edit"}
              iconcolor={colors.primary}
              btntextcolor={colors.primary}
              backgroundcolor={colors.white}
              iconcolorHover={colors.hover}
              colorHover={colors.hover}
              borderHover={colors.hover}
              backgroundHover={colors.white}
              onBtnClick={() => setUpdate(true)}
            />
          </div>
          <div className="row">
            <div className="col-6 col-sm-4 col-md-3 mb-5">
              <h6
                className="fw-bold"
                style={{ textTransform: "Uppercase", fontSize: "14px" }}
              >
                Name
              </h6>
              <p>
                {isLoading || isRefetching ? (
                  <SkeletonLoader width={"10rem"} />
                ) : (
                  companyDetails?.name
                )}
              </p>
            </div>

            <div className="col-6 col-sm-4 col-md-3 mb-5">
              <h6
                className="fw-bold"
                style={{ textTransform: "Uppercase", fontSize: "14px" }}
              >
                Email
              </h6>
              <p>
                {isLoading || isRefetching ? (
                  <SkeletonLoader width={"10rem"} />
                ) : (
                  companyDetails?.emailAddress
                )}
              </p>
            </div>

            <div className="col-6 col-sm-4 col-md-3 mb-5">
              <h6
                className="fw-bold"
                style={{ textTransform: "Uppercase", fontSize: "14px" }}
              >
                Phone Number
              </h6>
              <p>
                {isLoading || isRefetching ? (
                  <SkeletonLoader width={"10rem"} />
                ) : (
                  companyDetails?.phoneNumber
                )}
              </p>
            </div>

            <div className="col-6 col-sm-4 col-md-3 mb-5">
              <h6
                className="fw-bold"
                style={{ textTransform: "Uppercase", fontSize: "14px" }}
              >
                State
              </h6>
              <p>
                {isLoading || isRefetching ? (
                  <SkeletonLoader width={"10rem"} />
                ) : (
                  convertToTitleCase(companyDetails?.city?.stateId)
                )}
              </p>
            </div>

            <div className="col-6 col-sm-4 col-md-3 mb-5">
              <h6
                className="fw-bold"
                style={{ textTransform: "Uppercase", fontSize: "14px" }}
              >
                City
              </h6>
              <p>
                {isLoading || isRefetching ? (
                  <SkeletonLoader width={"10rem"} />
                ) : (
                  companyDetails?.city?.name
                )}
              </p>
            </div>

            <div className="col-6 col-sm-4 col-md-3 mb-5">
              <h6
                className="fw-bold"
                style={{ textTransform: "Uppercase", fontSize: "14px" }}
              >
                Years In Operation
              </h6>
              <p>
                {isLoading || isRefetching ? (
                  <SkeletonLoader width={"10rem"} />
                ) : (
                  companyDetails?.yearsInOperation
                )}
              </p>
            </div>

            <div className="col-6 col-sm-4 col-md-3 mb-5">
              <h6
                className="fw-bold"
                style={{ textTransform: "Uppercase", fontSize: "14px" }}
              >
                Bank Name
              </h6>
              <p>
                {isLoading || isRefetching ? (
                  <SkeletonLoader width={"10rem"} />
                ) : (
                  companyDetails?.bankName
                )}
              </p>
            </div>

            <div className="col-6 col-sm-4 col-md-3 mb-5">
              <h6
                className="fw-bold"
                style={{ textTransform: "Uppercase", fontSize: "14px" }}
              >
                Bank Account Number
              </h6>
              <p>
                {isLoading || isRefetching ? (
                  <SkeletonLoader width={"10rem"} />
                ) : (
                  companyDetails?.accountNumber
                )}
              </p>
            </div>

            <div className="col-6 col-sm-4 col-md-3 mb-5">
              <h6
                className="fw-bold"
                style={{ textTransform: "Uppercase", fontSize: "14px" }}
              >
                Logo
              </h6>
              {isLoading || isRefetching ? (
                <SkeletonLoader
                  height={"6rem"}
                  width={"6rem"}
                  borderRadius={"50%"}
                />
              ) : (
                <img
                  src={bankLogo}
                  alt="companyLogo"
                  className=" rounded-circle"
                  height={90}
                  width={90}
                />
              )}
            </div>

            <div
              className="col-6 col-sm-4 col-md-3 mb-5"
              style={{ height: "6rem" }}
            >
              <h6
                className="fw-bold"
                style={{ textTransform: "Uppercase", fontSize: "14px" }}
              >
                Brand Color
              </h6>
              {isLoading || isRefetching ? (
                <SkeletonLoader
                  height={"6rem"}
                  width={"8rem"}
                  borderRadius={"15px"}
                />
              ) : (
                <input
                  type="color"
                  value={companyDetails?.brandColorCode}
                  className="form-control form-control-lg color-picker"
                  title="Choose your color"
                  disabled
                />
              )}
            </div>

            <div className="col-6 col-sm-4 col-md-3 mb-5">
              <h6
                className="fw-bold"
                style={{ textTransform: "Uppercase", fontSize: "14px" }}
              >
                Address
              </h6>
              <p>
                {isLoading || isRefetching ? (
                  <SkeletonLoader width={"10rem"} />
                ) : (
                  companyDetails?.address
                )}
              </p>
            </div>

            {companyDetails?.coverImage && (
              <div className="col-6 col-sm-4 col-md-3 mb-5 company-img-preview">
                <h6
                  className="fw-bold"
                  style={{ textTransform: "Uppercase", fontSize: "14px" }}
                >
                  Cover Image
                </h6>
                {isLoading || isRefetching ? (
                  <SkeletonLoader height={"10rem"} borderRadius={"15px"} />
                ) : (
                  <img
                    src={companyDetails?.coverImage}
                    alt="companyCoverPhoto"
                    disabled
                  />
                )}
              </div>
            )}

            {companyDetails?.description && (
              <div>
                <div className="col-12 mb-5">
                  <h6
                    className="fw-bold"
                    style={{ textTransform: "Uppercase", fontSize: "14px" }}
                  >
                    Description
                  </h6>
                  {isLoading || isRefetching ? (
                    <SkeletonLoader width={"10rem"} />
                  ) : (
                    <div
                      className="company-description"
                      dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* DELETE MODAL  */}
      <Modal
        show={showDeleteModal}
        title={"Delete Estate"}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Delete"}
        closeModal={() => setShowDeleteModal(false)}
        confirmModal={() => {
          setBankCoverImg(null);
          handleDeleteCoverImage();
          setShowDeleteModal(false);
        }}
      >
        {/* <h5 className="text-center">You are about to delete an estate</h5> */}
        <div className="text-center">
          Delete this cover image?{" "}
          <strong className="text-danger">This action can't be undone</strong>
        </div>
      </Modal>
    </>
  );
};

export default Company;
