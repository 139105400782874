import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useDeleteDocument = (documentId) => {
  const { getRequest } = useAxiosBase();

  const deleteDocument = useMutation({
    mutationKey: ["delete-document", documentId],
    mutationFn: (data) =>
      getRequest({
        url: `/bank/delete-document/${documentId}`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Document succefully deleted!");
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.value?.statusCode === 200) {
        toast.success("Document succefully deleted!");
      }
      if ([400, 404, 500].includes(res?.data?.value?.value?.statusCode)) {
        toast.error(res?.data?.value?.value?.message);
      }
    },
    onError: (error) => {
     toast.error(error);
    },
  });

  return { deleteDocument };
};

export default useDeleteDocument;
