import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useAcknowledgeBooking = (houseViewingId) => {
  const { getRequest } = useAxiosBase();

  const acknowledgeBooking = useMutation({
    mutationKey: ["acknowledg-booking", houseViewingId],
    mutationFn: (data) =>
      getRequest({
        url: `bank/house-viewing/${houseViewingId}/confirm`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { acknowledgeBooking };
};

export default useAcknowledgeBooking;
