import React, { useState } from "react";
import Button from "../../Components/Common/Button";
import DocumentDownload from "../../Components/Common/DocumentDownload";
import { toast } from "react-toastify";
import useAxiosBase from "../../hooks/useAxiosBase";
import { useNavigate } from "react-router-dom";
const DocumentHeader = ({
  title,
  subTitle,
  leftTitle,
  rightTitle,
  documentId,
  applicationId,
  showValidate,
}) => {
  const { getRequest } = useAxiosBase();
  const navigate = useNavigate();
  const [validationIsLoading, setValidationIsLoading] = useState(false);

  const handleValidate = async (appId, documentId) => {
    setValidationIsLoading(true);
    await getRequest({
      url: `/developer/application/${appId}/validate-document/${documentId}`,
    })
      .then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
          setTimeout(navigate(0), 1500);
          return;
        }
        toast.error("Validation Failed!");
      })
      .finally(() => setValidationIsLoading(false));
  };

  return (
    <div className="row d-flex align-items-start g-0 ms-4">
      {leftTitle && <div className="col-md-3"> {leftTitle} </div>}

      <div className={`text-center ${leftTitle ? "col-md-6" : "col-md-12"}`}>
        <h4>{title}</h4>
        <p className="m-0" style={{ fontSize: "13px", fontWeight: "bold" }}>
          {subTitle}
        </p>
      </div>

      {rightTitle && <div className="col-md-3"> {rightTitle} </div>}
      {/* {showValidate && (
        <div className="col-md-4">
          <div className="d-flex align-items-center gap-4">
            <div className="">
              <Button
                btnText={"Validate"}
                width={"90"}
                height={"30px"}
                padding={"0"}
                borderradius={"50"}
                isLoading={validationIsLoading}
                onBtnClick={() => handleValidate(applicationId, documentId)}
              />
            </div>
            <div className="">
              <DocumentDownload name={"Download Document"} icon={"file"} />
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default DocumentHeader;
