import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useGetEditViewing = () => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["edit-viewing-amount"],
    queryFn: () =>
      getRequest({
        url: "/admin/system-configuration/get-by-identifier/VIEWING_REQUEST_AMOUNT",
      }).then((res) => res.data?.value?.value),
    staleTime: 50000,
  });
};
export default useGetEditViewing;