import * as yup from "yup";

export const invitOrganisation = yup
  .object({
    organisationName: yup.string().max(50).required("Name is a required field"),
    organisationEmail: yup
      .string()
      .email("Not a valid email address")
      .required("Email is a required field"),
    organisationPhone: yup
      .string()
      .required("Phone number is a required field"),
  })
  .required();
