import React, { useState } from "react";
import { useNav } from "../../../hooks/useNav";
import Icons from "../../../Components/Common/Icons";
import "../Agents/agents.css";
import { data, status } from "./agentsDummyData";
import CenteredText from "../../../Components/CenteredText";

const Agents = () => {
  useNav({ mainTitle: "House Inspections" });
  const defaultImage =
    "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

  const [activeStatus, setActiveStatus] = useState("active");
  const [viewData, setViewData] = useState(
    data.filter((x) => x.status.toLocaleLowerCase() === "active")
  );
  const handleStatus = (e, id) => {
    setActiveStatus(e.target.textContent.toLocaleLowerCase());
    let incomingData = [...data];
    if (id === "active") {
      setViewData(
        incomingData.filter((x) => x.status.toLocaleLowerCase() === id)
      );
      return;
    }
    setViewData(
      incomingData.filter((x) => x.status.toLocaleLowerCase() !== "active")
    );
  };
  return (
    <div className="container-fluid">
      <div className="container mt-5">
        <div className="row justify-content-center tab">
          {status.map((stat) => (
            <div
              key={stat.id}
              className={
                stat.id === activeStatus
                  ? `col-md-3 active-tab`
                  : `col-md-3 inactive-tab`
              }
            >
              <span onClick={(e) => handleStatus(e, stat.id)}>{stat.name}</span>
            </div>
          ))}
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-md-8">
            {viewData.length > 0 ? (
              viewData.map((view) => (
                <div className="card m-4" key={view.id}>
                  <div className="card-header text-primary">
                    This viewing is scheduled for {view.viewingTime} ,{" "}
                    {view.viewingDate} at {view.propertyAddress}
                  </div>
                  <div className="card-body text-primary row">
                    <div className="col-md-4  left">
                      <div className="row justify-content-center text-center align-content-between">
                        <span className="text-uppercase prop-text">
                          Customer Details
                        </span>
                        <img
                          src={view.customerImage ?? defaultImage}
                          alt="agent"
                          className="img-fluid mt-2 customer-image"
                        />
                        <span className="mt-3 customer-name">
                          {view.customerName}
                        </span>
                        <div className="d-flex flex-row justify-content-evenly mt-5 customer-contact">
                          <Icons iconName={"phone"} />
                          <a href={`tel:${view.customerPhoneNumber}`}>
                            Call Customer
                          </a>
                        </div>
                      </div>
                      <hr height="100%" width="0%" />
                    </div>

                    <div className="col-md-8 right">
                      <hr />
                      <div className="row">
                        <span className="text-uppercase prop-text">
                          PROPERTY DETAILS
                        </span>
                        <div className="container">
                          <div className="row p-3">
                            <div className="col-4">
                              <img
                                src={view.propertyImage ?? defaultImage}
                                alt="property"
                                className="img-fluid rounded right-image"
                              />
                            </div>
                            <div className="col-8 right-prop-details">
                              <p>{view.propertyDescription}</p>
                              <div className="row">
                                {view.features.length > 0 &&
                                  view?.features?.map((x) => (
                                    <div
                                      className="col-4 d-flex flex-row justify-content-evenly feature"
                                      key={x.id}
                                    >
                                      <Icons iconName={x.icon} />
                                      <span className="icon-name">
                                        {x.name}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                              <div className="row">
                                <div className="col-2 icon">
                                  <Icons iconName={"location"} />
                                </div>
                                <div className="col-10">
                                  <span className="address">
                                    {view.propertyAddress}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <CenteredText
                title={"You have no house inspections at this time"}
              >
                {" "}
                When a customer shows interest in a property, their information
                will be displayed here.
              </CenteredText>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agents;
