import React, { useState, useEffect } from "react";
import usePermission from "../../../hooks/usePermissions";
import { useNav } from "../../../hooks/useNav";
import InfoTable from "../../../Components/Common/InfoTable";
import Modal from "../../../Components/Common/Modal";
import Button from "../../../Components/Common/Button";
import useAuth from "../../../hooks/useAuth";
import { teamColumns } from "./constant";
import { useForm } from "react-hook-form";
import FormInput from "../../../Components/Common/FormInput";
import InfoCard from "../../../Components/Common/InfoCard";
import CenteredText from "../../../Components/CenteredText";
import DefaultDropdown from "../../../Components/Common/Dropdown";
import useGetTeam from "./hooks/useGetTeam";
import useGetRole from "./hooks/useGetRole";
import useSendInvite from "./hooks/useSendInvite";
import useUpadateTeam from "./hooks/useUpdateTeam";
import useDeleteTeam from "./hooks/useDeleteTeam";
import useAddViewingAgent from "./hooks/useAddViewingAgent";
import { convertToTitleCase } from "../../../utils/constants";
import TableLoader from "../../../Components/Common/TableLoader";
import SkeletonLoader from "../../../Components/SkeletonLoader";
import useAddContactStaff from "./hooks/useAddContactStaff";

const Team = () => {
  useNav({
    mainTitle: "Team Members",
    subTitle: "Add, edit, and delete staff members working in your company.",
  });

  const {
    accessViewTeamMember,
    accessCreateTeamMember,
    accessUpdateTeamMember,
    accessDeleteTeamMember,
  } = usePermission();

  const { authUser } = useAuth();
  const appUserId = authUser?.user?.organization?.id;

  const [roleId, setRoleId] = useState("");
  const [roleName, setRoleName] = useState();
  const [memberName, setMemberName] = useState();
  const [selectedRole, setSelectedRole] = useState(null);
  const [teamMemberId, setTeamMemberId] = useState("");
  const [teamMemberRole, setTeamMemberRole] = useState([]);
  const [viewingAgentStatus, setViewingAgentStatus] = useState(false);
  const [contactStaffStatus, setContactStaffStatus] = useState(true);


  const [pagination, setPagination] = useState(1);
  const {
    data: teamMembers,
    error,
    isLoading,
    refetch,
    isRefetching,
  } = useGetTeam(pagination);

  const { data: orgRoles } = useGetRole();

  const { inviteMember } = useSendInvite();
  const { updateMember } = useUpadateTeam(teamMemberId);
  const { deleteTeamMember } = useDeleteTeam(teamMemberId);
  const { addViewingAgent } = useAddViewingAgent(
    teamMemberId,
    viewingAgentStatus
  );
  const { addContactStaff } = useAddContactStaff(teamMemberId, contactStaffStatus);


  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showViewingAgentModal, setShowViewingAgentModal] = useState(false);
  const [showContactStaffModal, setShowContactStaffModal] =
    useState(false);
  const [viewingAgentModalType, setViewingAgentModalType] = useState(false);

  const { register, handleSubmit, setValue, watch } = useForm();
  const onSubmitInvite = (data) => {
    data.roleId = roleId;

    // console.log("data", data)
    inviteMember.mutate(data, {
      onSuccess: () => {
        refetch();
        setShowAddMemberModal(false);
        setValue("email", null);
        // setRoleId("");
      },
    });
  };

  const handleAction = (rowId, action) => {
    setTeamMemberId(rowId);
    const member = teamMembers?.data?.find((member) => member.id === rowId);
    setRoleName(member?.applicationRole?.name);
    setMemberName(member?.name);

    if (action === "add") {
      setViewingAgentModalType("add");
      setViewingAgentStatus("true");
      setShowViewingAgentModal(true);
    } else if (action === "remove") {
      setViewingAgentModalType("remove");
      setViewingAgentStatus(false);
      setShowViewingAgentModal(true);
    } else if (action === "changeRole") {
      setShowEditModal(true);
    } else if (action === "staff") {
      setShowContactStaffModal(true);
    } else {
      setShowDeleteModal(true);
    }
  };

  useEffect(() => {
    if (orgRoles) {
      const roleOptions = orgRoles?.data?.map((role) => ({
        id: role.id,
        name: convertToTitleCase(role.name),
      }));
      setTeamMemberRole(roleOptions);
    }
  }, [orgRoles]);

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination]);

  if (error)
    return (
      <div className="mt-5">
        <CenteredText
          title={"A problem happened when retrieving the team member."}
        >
          Please verify your network connection and reload the page.
        </CenteredText>
      </div>
    );
  return (
    <div className="px-2">
      {accessViewTeamMember?.value ? (
        <>
          {accessCreateTeamMember?.value && (
            <div className="pe-3 text-end">
              <Button
                btnText={"Invite"}
                disabled={!orgRoles}
                onBtnClick={() => setShowAddMemberModal(!showAddMemberModal)}
              />
            </div>
          )}

          <div className="">
            <InfoCard
              mainValue={
                isLoading || isRefetching ? (
                  <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
                ) : (
                  teamMembers?.totalRecords ?? "0"
                )
              }
              description={
                isLoading || isRefetching ? (
                  <SkeletonLoader />
                ) : (
                  "Total Team members"
                )
              }
            />
          </div>

          {isLoading ? (
            <div className="mt-4">
              <TableLoader />
            </div>
          ) : teamMembers?.data?.length > 0 ? (
            <div className="mt-2">
              <div className="">
                <InfoTable
                  columns={teamColumns(
                    (rowId, action) => handleAction(rowId, action),
                    isRefetching
                  )}
                  dataCollection={teamMembers?.data}
                  loadingTable={isRefetching}
                  paginationTotalRows={teamMembers?.totalRecords}
                  onChangePage={(page) => setPagination(page)}
                />
              </div>
            </div>
          ) : (
            <div className="mt-5">
              <CenteredText title={"You have no team member at this time"}>
                Reload the page after adding a member or check your network
                connection.
              </CenteredText>
            </div>
          )}
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view prospects. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}

      {/* INVITE MODAL */}
      <Modal
        show={showAddMemberModal}
        closeModal={() => {
          setShowAddMemberModal(false);
          setValue("email", null);
          // setRoleId("");
        }}
        title={"Invite Team Member"}
        confirmButtonLabel={"Invite"}
        cancelButtonLabel={"Cancel"}
        confirmModal={handleSubmit(onSubmitInvite)}
        isBtnLoading={inviteMember.isLoading}
        disabled={inviteMember.isLoading || !roleId || !watch("email")}
      >
        <form className="d-flex flex-column">
          <div className="col-12 m-auto">
            <DefaultDropdown
              required={true}
              labelName={"Role"}
              placeholder={"Select a Role"}
              options={teamMemberRole}
              onChange={(e) => setRoleId(e.target.value)}
            />
          </div>
          <div className="col-12 m-auto">
            <FormInput
              required
              placeholder={"eg. paul@gmail.com"}
              labelName={"Email"}
              name="email"
              register={register("email")}
            />
          </div>
        </form>
      </Modal>

      {/* CHANGE ROLE MODAL */}
      <Modal
        show={showEditModal}
        title={"Change Team Member Role"}
        confirmButtonLabel={"Update"}
        cancelButtonLabel={"Cancel"}
        confirmModal={() =>
          updateMember.mutate(selectedRole, {
            onSuccess: () => {
              refetch();
              setShowEditModal(false);
            },
          })
        }
        closeModal={() => setShowEditModal(false)}
        isLoading={updateMember.isLoading}
        disabled={!selectedRole || updateMember.isLoading}
      >
        <DefaultDropdown
          required={true}
          labelName={"Team Members Role"}
          options={teamMemberRole}
          value={roleName}
          onChange={(e) => {
            let id = e.target.value;
            if (id) {
              const roleName = teamMemberRole.find((x) => x.id === id).name;
              setRoleName(roleName);
              setSelectedRole(id);
            }
          }}
        />
      </Modal>

      {/* VIEWING AGENT MODAL */}
      <Modal
        show={showViewingAgentModal}
        closeModal={() => setShowViewingAgentModal(false)}
        title={`${
          viewingAgentModalType === "add" ? "Confirm" : "Remove"
        } As Inspection Agent.`}
        confirmModal={() =>
          addViewingAgent.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowViewingAgentModal(false);
            },
          })
        }
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={viewingAgentModalType === "add" ? "Yes" : "Remove"}
        isBtnLoading={addViewingAgent.isLoading}
        disabled={addViewingAgent.isLoading}
      >
        <div className="text-center">
          You are about to {viewingAgentModalType === "add" ? "add" : "remove"}{" "}
          <strong>{memberName}</strong> as an{" "}
          <strong> Inspection Agent.</strong>
        </div>
      </Modal>

      {/* CONTACT STAFF MODAL */}
      <Modal
        show={showContactStaffModal}
        closeModal={() => setShowContactStaffModal(false)}
        title={"Add as Contact Staff"}
        confirmModal={() =>
          addContactStaff.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowContactStaffModal(false);
            },
          })
        }
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Add"}
        isBtnLoading={addContactStaff.isLoading}
        disabled={addContactStaff.isLoading}
      >
        <div className="text-center">
          You are about to make{" "} <strong>{memberName}</strong> a{" "}
          <strong> Contact Staff.</strong>
        </div>
      </Modal>

      {/* DELETE MODAL */}
      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={"Delete Team Member. "}
        confirmModal={() =>
          deleteTeamMember.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowDeleteModal(false);
            },
          })
        }
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Delete"}
        isBtnLoading={deleteTeamMember.isLoading}
        disabled={deleteTeamMember.isLoading}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p>
                Are you sure you want to delete?{" "}
                <strong className="text-danger">
                  This action can't be undone.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Team;
