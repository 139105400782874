import * as yup from "yup";

export const model = yup.object({
  name: yup
    .string()
    .required("Name is a required field")
    .min(3, "Name must be at least 3 characters"),
});

export const modelSchema = yup.object(model);
