import React from "react";
import { FiUploadCloud, FiDownloadCloud } from "react-icons/fi";
import { colors } from "../../../utils/constants";

const UploadDocument = ({
  title,
  docRef,
  docObject,
  handleChange,
  header,
  badgeText,
  badgeTextBackgroundColor,
  badgeTextColor,
  variant,
  height,
  width,
  required,
  disabled,
  onClick,
  type = "image/*",
}) => {
  const cardStyles = {
    height: height,
    width: width,
    cursor: disabled ? "not-allowed" : "pointer",
    // backgroundColor: disabled ? "#f8f9fa" : "white",
  };

  return (
    <>
      <div>
        <h6 className="fw-bold" style={{ fontSize: "0.8rem" }}>
          {header} {required && <span className="required">*</span>}
        </h6>
      </div>
      <div
        className={`card border-1 shadow-sm border-success ${
          disabled ? "disabled" : ""
        }`}
        style={cardStyles}
        onClick={!disabled ? () => docRef?.current?.click() : undefined}
        role={!disabled ? "button" : undefined}
      >
        <div className="bg-white border-bottom-0">
          {badgeText && (
            <span
              className="badge rounded-pill m-2 mb-0"
              style={{
                backgroundColor: badgeTextBackgroundColor,
                color: badgeTextColor,
              }}
            >
              {badgeText}
            </span>
          )}
        </div>
        <span
          className="text-center card-subtitle fw-bold pb-2 pt-5"
          style={{ fontSize: "0.7rem" }}
        >
          {docObject?.length > 0 ? docObject[0]?.name : title}
        </span>
        <div
          className="card-footer text-success fw-bolder fs-6 p-2"
          role="button"
          // onClick={() => docRef.current.click()}
        >
          {variant === "download" ? (
            <div onClick={onClick}>
              <FiDownloadCloud className="mx-2" />
              <small className="card-muted" style={{ wordWrap: "break-word" }}>
                {"Click to Download"}
              </small>
            </div>
          ) : (
            <div>
              <FiUploadCloud className="m-3 me-1" />
              <small
                className="card-muted"
                style={{ wordWrap: "break-word", fontSize: "0.7rem" }}
              >
                {"Click to Upload"}
              </small>
              <input
                type="file"
                className="input-image"
                ref={docRef}
                alt=""
                accept={type}
                disabled={disabled}
                onChange={(e) => handleChange(e)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadDocument;
