import { isNullOrEmpty } from "../constants";

export const applicationsManagement = (userAccess) => {
  if (isNullOrEmpty(userAccess)) return null;
  const access =
    userAccess?.find((x) => x.id === "application_management")?.rules || [];
  return {
    viewApplicationAccess:
      access?.find((x) => x.id === "view_applications") || {},
    validateApplicationDocumentAccess:
      access?.find((x) => x.id === "validate_application_document") || {},
    reviseApplicationDocumentAccess:
      access?.find((x) => x.id === "revise_application_document") || {},
    invalidateApplicationDocumentAccess:
      access?.find((x) => x.id === "invalidate_application_document") || {},
    sendApplicationForReviewAccess:
      access?.find((x) => x.id === "send_application_for_review") || {},
    viewApplicationsForReviewAccess:
      access?.find((x) => x.id === "view_applications_for_review") || {},
    acceptApplicationForReviewAccess:
      access?.find((x) => x.id === "accept_application_for_review") || {},
    rejectApplicationForReviewAccess:
      access?.find((x) => x.id === "reject_application_for_review") || {},
  };
};
