import CenteredText from "../../../Components/CenteredText";
import Badges from "../../../Components/Common/Badges";
import FooterButtons from "../../../Components/Common/FooterButtons";
import { colors, formattedPrice } from "../../../utils/constants";
import { HiDotsVertical } from "react-icons/hi";
import { toApplicationNumber, yearRange } from "../../../utils/generalUtils";
import Dropdown from "react-bootstrap/Dropdown";
import {
  applicationStatus,
  applicationStatusIndex,
} from "../../Application/utils/applicationStatus";
import { asCurrency } from "../../Application/utils/constants";
import Button from "../../../Components/Common/Button";
import { convertToTitleCase } from "../../../utils/constants";

export const defaultImage =
  "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80";

export const transactionsColumn = (handleOmit, handleButtonClick) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ID
      </p>
    ),
    sortable: true,
    selector: (row) => `${toApplicationNumber(row.application?.applicationId)}`,
    grow: 0.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        CUSTOMER
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => `${row.customer?.firstName} ${row.customer?.lastName}`,
    grow: 1.1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        AMOUNT
      </p>
    ),
    sortable: true,
    selector: (row) => asCurrency(row.amount, "NGN"),
    wrap: true,
    grow: 1.2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TYPE
      </p>
    ),
    sortable: true,
    selector: (row) => `${convertToTitleCase(row.transactionType)}`,
    wrap: true,
    grow: 1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY
      </p>
    ),
    sortable: true,
    selector: (row) => `${row.house?.cityName}, ${row.house?.stateName}`,
    wrap: true,
    grow: 2.1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PLAN
      </p>
    ),
    sortable: true,
    selector: (row) => `${row.mortgagePlan?.name}`,
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DATE
      </p>
    ),
    sortable: true,
    selector: (row) =>
      row.dueDate ? new Date(row.dueDate).toDateString() : "--",
    wrap: true,
    grow: 1.5,
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}></p>,
    sortable: false,
    grow: 0.5,
    center: true,
    selector: (row) => (
      <Dropdown>
        <Dropdown.Toggle
          id={`dropdown-${row.id}`}
          style={{ background: "transparent", border: "none" }}
        >
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="position-fixed">
          {applicationStatusIndex[row.status] >=
            applicationStatusIndex.REVIEW_APPROVED ||
          applicationStatusIndex[row?.status] ===
            applicationStatusIndex.REVIEW_REJECTED ? (
            <Dropdown.Item onClick={() => handleButtonClick(row.applicationId)}>
              View Receipt
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={() => handleButtonClick(row.applicationId)}>
              Download Receipt
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

export const successfulTransactionsColumn = (handleOmit, handleButtonClick) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ID
      </p>
    ),
    sortable: true,
    selector: (row) => `${toApplicationNumber(row.application?.applicationId)}`,
    grow: 0.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        CUSTOMER
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => `${row.customer?.firstName} ${row.customer?.lastName}`,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TOTAL PAID
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <>
        ₦{formattedPrice(row.amount)}
        <p
          className="m-0"
          style={{ fontSize: "11px", color: colors.darkGreen }}
        >
          {" "}
          ({(row.amount / row.amount) * 100}%)
        </p>
      </>
    ),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        EARNINGS
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <>
        ₦{formattedPrice(row.organizationAmount)}
        <p
          className="m-0"
          style={{ fontSize: "11px", color: colors.darkGreen }}
        >
          {" "}
          ({(row.organizationAmount / row.amount) * 100}%)
        </p>
      </>
    ),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        GIDDAA
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <>
        ₦{formattedPrice(row.giddaaAmount)}
        <p
          className="m-0"
          style={{ fontSize: "11px", color: colors.darkGreen }}
        >
          {" "}
          ({(row.giddaaAmount / row.amount) * 100}%)
        </p>
      </>
    ),
    wrap: true,
    grow: 1.2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TYPE
      </p>
    ),
    sortable: true,
    selector: (row) => `${convertToTitleCase(row.transactionType)}`,
    wrap: true,
    grow: 1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY
      </p>
    ),
    sortable: true,
    // selector: (row) => `${row.house?.cityName}, ${row.house?.stateName}`,
    selector: (row) => row.house?.name ?? "---",
    wrap: true,
    grow: 2.1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PLAN
      </p>
    ),
    sortable: true,
    selector: (row) => `${row.mortgagePlan?.name}`,
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PAYMENT DATE
      </p>
    ),
    sortable: true,
    selector: (row) =>
      row.dueDate ? new Date(row.dueDate).toDateString() : "--",
    wrap: true,
    grow: 1.5,
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}></p>,
    sortable: false,
    grow: 0.5,
    center: true,
    selector: (row) => (
      <Dropdown>
        <Dropdown.Toggle
          id={`dropdown-${row.id}`}
          style={{ background: "transparent", border: "none" }}
        >
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="position-fixed">
          {row.status >= "REVIEW_APPROVED" ||
          row?.status === "REVIEW_REJECTED" ? (
            <Dropdown.Item onClick={() => handleButtonClick(row.applicationId)}>
              View Receipt
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={() => handleButtonClick(row.applicationId)}>
              Download Receipt
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

export const expectedTransactionsColumn = (handleOmit, handleButtonClick) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ID
      </p>
    ),
    sortable: true,
    selector: (row) => `${toApplicationNumber(row.application?.applicationId)}`,
    grow: 0.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        CUSTOMER
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => `${row.customer?.firstName} ${row.customer?.lastName}`,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        AMOUNT DUE
      </p>
    ),
    sortable: true,
    selector: (row) => (row.amount ? asCurrency(row.amount, "NGN") : "---"),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TYPE
      </p>
    ),
    sortable: true,
    selector: (row) => convertToTitleCase(row.transactionType) ?? "---",
    wrap: true,
    grow: 1.2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY
      </p>
    ),
    sortable: true,
    // selector: (row) => `${row.house?.cityName}, ${row.house?.stateName}`,
    selector: (row) => row.house?.name ?? "---",
    wrap: true,
    grow: 2.1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        NEXT PAYMENT
      </p>
    ),
    sortable: true,
    selector: (row) =>
      row.dueDate ? new Date(row.dueDate).toDateString() : "--",
    wrap: true,
    grow: 1.5,
  },
  // {
  //   name: (
  //     <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
  //       PAYMENT TRACKER
  //     </p>
  //   ),
  //   sortable: true,
  //   selector: (row) => row.paymentTracker,
  //   wrap: true,
  //   grow: 1.5,
  // },
  {
    name: <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}></p>,
    sortable: false,
    grow: 0.5,
    center: true,
    selector: (row) => (
      <Dropdown>
        <Dropdown.Toggle
          id={`dropdown-${row.id}`}
          style={{ background: "transparent", border: "none" }}
        >
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="position-fixed">
          <Dropdown.Item onClick={() => handleButtonClick(row.applicationId)}>
            View Repayment Schedule
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

export const missedPaymentColumn = (handleButtonClick) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ID
      </p>
    ),
    sortable: true,
    selector: (row) => `${toApplicationNumber(row.application?.applicationId)}`,
    grow: 0.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        CUSTOMER
      </p>
    ),
    sortable: true,
    selector: (row) => `${row.customer?.firstName} ${row.customer?.lastName}`,
    grow: 1.1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        AMOUNT
      </p>
    ),
    sortable: true,
    selector: (row) => asCurrency(row.amount, "NGN"),
    wrap: true,
    grow: 1.2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TYPE
      </p>
    ),
    sortable: true,
    selector: (row) => `${convertToTitleCase(row.transactionType)}`,
    wrap: true,
    grow: 1.2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY
      </p>
    ),
    sortable: true,
    // selector: (row) => `${row.house?.cityName}, ${row.house?.stateName}`,
    selector: (row) => row.house?.name ?? "---",
    wrap: true,
    grow: 2.1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PLAN
      </p>
    ),
    sortable: true,
    selector: (row) => `${row.mortgagePlan?.name}`,
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DATE
      </p>
    ),
    sortable: true,
    selector: (row) =>
      row.dueDate ? new Date(row.dueDate).toDateString() : "--",
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        {" "}
        DAYS OVER DUE
      </p>
    ),
    sortable: true,
    selector: (row) => `${row.daysOverDue} Days`,
    wrap: true,
    grow: 1.2,
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}></p>,
    sortable: false,
    grow: 0.5,
    center: true,
    cell: (row) => (
      <Dropdown>
        <Dropdown.Toggle
          id={`dropdown-${row.id}`}
          style={{ background: "transparent", border: "none" }}
        >
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {!row.isPaid && new Date(row.dueDate) < new Date() && (
            <Dropdown.Item
              onClick={() => handleButtonClick(row.id, "warn")}
              style={{
                fontFamily: "'Red Hat Display', sans-serif",
                padding: "10px",
              }}
            >
              Send Warning
            </Dropdown.Item>
          )}

          {!row.isPaid && new Date(row.dueDate) > new Date() && (
            <Dropdown.Item
              onClick={() => handleButtonClick(row.id, "remind")}
              style={{
                fontFamily: "'Red Hat Display', sans-serif",
                padding: "5px 10px",
              }}
            >
              Remind Customer
            </Dropdown.Item>
          )}
          {applicationStatusIndex[row.status] >=
            applicationStatusIndex.REVIEW_APPROVED ||
          applicationStatusIndex[row?.status] ===
            applicationStatusIndex.REVIEW_REJECTED ? (
            <Dropdown.Item
              onClick={() => handleButtonClick(row.applicationId)}
              style={{
                fontFamily: "'Red Hat Display', sans-serif",
                padding: "10px",
                borderBottom: `1px solid ${colors.grey}`,
              }}
            >
              View Receipt
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={() => handleButtonClick(row.applicationId)}>
              Download Receipt
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

export const repaymentColumn = (handleButtonClick) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        MONTH
      </p>
    ),
    sortable: true,
    center: true,
    selector: (row) => row.month,
    grow: 1.1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DUE DATE
      </p>
    ),
    sortable: true,
    selector: (row) =>
      row.dueDate ? new Date(row.dueDate)?.toDateString() : "--",
    wrap: true,
    grow: 1.2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        START BALANCE
      </p>
    ),
    sortable: true,
    selector: (row) => `₦ ${formattedPrice(row.beginningBalance?.toFixed(2))}`,
    wrap: true,
    grow: 1.2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        MONTHLY PAYMENT
      </p>
    ),
    sortable: true,
    selector: (row) => `₦ ${formattedPrice(row.pmt?.toFixed(2))}`,
    wrap: true,
    grow: 2.1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        MONTHLY INTEREST
      </p>
    ),
    sortable: true,
    selector: (row) => `₦ ${formattedPrice(row.ipmt?.toFixed(2))}`,
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        MONTHLY PRINCIPAL
      </p>
    ),
    sortable: true,
    selector: (row) => `₦ ${formattedPrice(row.ppmt?.toFixed(2))}`,
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ENDING BALANCE
      </p>
    ),
    sortable: true,
    selector: (row) => `₦ ${formattedPrice(row.endingBalance?.toFixed(2))}`,
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ACTIONS
      </p>
    ),
    sortable: false,
    grow: 0.5,
    center: true,
    cell: (row) => (
      <Dropdown>
        <Dropdown.Toggle
          id={`dropdown-${row.id}`}
          style={{ background: "transparent", border: "none" }}
        >
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => handleButtonClick(row.applicationId)}
            style={{
              fontFamily: "'Red Hat Display', sans-serif",
              padding: "10px",
              borderBottom: `1px solid ${colors.grey}`,
            }}
          >
            Remind Customer
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleButtonClick(row.applicationId)}>
            Send Warning
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];
