import { Dropdown } from "react-bootstrap";
import CellSelector from "../../Components/Common/CellSelector";
import {
  formattedPrice,
  convertToTitleCase,
  colors,
} from "../../utils/constants";
import { HiDotsVertical } from "react-icons/hi";

export const defaultImage =
  "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

export const columns = (loading) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        Name
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row?.name}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TYPE
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {convertToTitleCase(row?.type) ?? "---"}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ADDRESS
      </p>
    ),
    grow: 2,
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row?.address}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PRICE
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading} className={"text-success"}>
        ₦ {formattedPrice(row?.price) ?? "---"}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        COMPLETION STATUS
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {convertToTitleCase(row?.completionStatus) ?? "---"}
      </CellSelector>
    ),
  },
];

export const plansColumns = (loading) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PLAN
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row?.name}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row?.property ?? "---"}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DELIVERY LEVEL
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row?.deliveryLevel ?? "---"}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DELIVERY PRICE
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>₦ {formattedPrice(row?.maxLoanAmount)}</>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DOWNPAYMENT
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>{row?.equityPercentage ?? 0}%</>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PERIOD
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>{row?.loanTenor} months</>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        INTEREST RATE
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>{row?.interestRate}%</>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DATE CREATED
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {new Date(row.dateCreated).toDateString()}
      </CellSelector>
    ),
  },
];
