import styled from "styled-components";

export const Wrapper = styled.div`
margin-top: 10rem;
  .footerButtons {
    position: ${(props) => (props.position ? props.position : "fixed")};
    left: ${(props) => (props.left ? props.left : "var(--sidebar-width)")};
    bottom: 0;
    right: 0 !important;

    display: flex;
    justify-content: center;
    /* width: ${(props) => props.width ?? "100%"}; */
    height: ${(props) => (props.height ? props.height : "100px")};
    background: #f0f0f0;
    padding-top: 8px;
    z-index: 50 !important;
    transition: 0.25s all ease;
  }
`;
