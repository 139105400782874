import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useCompleteRegistration = () => {
  const { putRequest } = useAxiosBase();
  const navigate = useNavigate();

  const completeRegistration = useMutation({
    mutationFn: (data) =>
      putRequest({
        url: `/account/complete-registration`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
          setTimeout(() => navigate("/account/login"), 3000);
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success(res?.data?.value?.message);
        setTimeout(() => navigate("/account/login"), 3000);
      }
      if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
        toast.error(res?.data?.value?.message);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  return { completeRegistration };
};

export default useCompleteRegistration;
