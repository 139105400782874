import styled, { css } from "styled-components";
import { colors } from "../../../utils/constants";

export const ButtonWrapper = styled.button`
  width: ${(props) => (props.width ? `${props.width} !important` : "")};
  min-width: ${(props) => (props.width ? `${props.width}` : "90px")};
  height: ${(props) => (props.height ? `${props.height} !important` : "")};
  background: ${(props) =>
    props.disabled
      ? colors.darkGray
      : props.backgroundcolor
      ? props.backgroundcolor
      : colors.darkGreen};
  color: ${(props) =>
    props.disabled
      ? colors.darkGray
      : props.btntextcolor
      ? props.btntextcolor
      : colors.darkGreen};
  border: ${(props) =>
    props.bordercolor
      ? `2px solid ${props.bordercolor} !important`
      : `2px solid ${colors.primary}`};
  border-radius: ${(props) =>
    props.borderradius ? `${props.borderradius}px` : "100px"};
  box-shadow: 0px 3px 6px #00000041;
  border-color: ${(props) =>
    props.disabled
      ? colors.darkGray
      : props.bordercolor
      ? props.bordercolor
      : colors.darkGreen};
  margin: ${(props) => props.margin ?? "0px"};
  opacity: 0.7;
  transition: all 0.3s ease;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        opacity: 1;
        background-color: ${(props) =>
          props.backgroundHover
            ? `${props.backgroundHover} !important`
            : colors.primary};
        color: ${(props) =>
          props.colorHover ? props.colorHover : colors.white};
        border: ${(props) =>
          props.borderHover
            ? `2px solid ${props.borderHover} !important`
            : `2px solid ${colors.primary}`};
      }
    `}

  ${(props) =>
    !props.disabled &&
    css`
      &:hover .button_text {
        color: ${(props) =>
          props.colorHover ? props.colorHover : colors.white};
      }
    `}

  .button_label_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    padding: ${(props) => (props.padding ? props.padding : "10px")};
  }

  .button_text {
    font: normal normal bold
      ${(props) => (props.btnTextFont ? `${props.btnTextFont}px` : "13px")}
      "Red Hat Display";
    color: ${(props) =>
      props.disabled
        ? colors.transparentWhite
        : props.btntextcolor
        ? props.btntextcolor
        : colors.white};
    /* margin-top: ${(props) =>
      props.marginTop ? props.marginTop : "0.2rem"}; */
  }

  .button_icon,
  .button_text {
    svg {
      fill: ${(props) => (props.iconcolor ? props.iconcolor : colors.dark)};
      width: ${(props) =>
        props.iconWidth ? `${props.iconWidth} !important` : "19px"};
      height: ${(props) =>
        props.iconHeight ? `${props.iconHeight} !important` : "25px"};
    }
  }

  &:hover .button_icon,
  &:hover .button_text {
    svg {
      fill: ${(props) =>
        props.iconcolorHover
          ? `${props.iconcolorHover} !important`
          : colors.white};
      /* width: 25px; */
    }
  }

  @media only screen and (min-width: 310px) and (max-width: 700px) {
    /* height: 6vh !important; */
    /* width: 90px !important; */
    min-width: ${(props) => (props.width ? `${props.width}` : "90px")};
    .button_label_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      padding: ${(props) => (props.padding ? props.padding : "8px 10px")};
    }

    .button_text {
      font: normal normal bold 11px "Red Hat Display";
      color: ${(props) => props.btntextcolor ?? colors.white};
    }
    .button_icon {
      svg {
        fill: ${(props) => props.iconcolor ?? colors.green};
        width: 17px;
      }
    }
  }
`;
