export const HOUSETYPE = "House Type";

export const sliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export function addDays(date, days) {
  const result = new Date(date);
  return days === -1
    ? result.setDate(result.getFullYear())
    : result.setDate(result.getDate() + days);
}

export const getHouseType = (features) => {
  let result;
  features?.map((feature) => {
    if (feature?.name?.includes(HOUSETYPE)) {
      result = feature?.icon?.toUpperCase();
    }
  });
  return result;
};