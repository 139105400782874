import React, { useEffect, useState } from "react";
import usePermission from "../../../../hooks/usePermissions";
import { useNav } from "../../../../hooks/useNav";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import TSubHeader from "../Table/TSubHeader";
import TRow from "../Table/TRow";
import TDivider from "../Table/TDivider";
import useGetRoles from "../hooks/useGetRoles";
import useUpdateRolePermissions from "../hooks/useUpdateRolePermissions";
import { useForm } from "react-hook-form";
import FooterButtons from "../../../../Components/Common/FooterButtons";
import Spinner from "../../../../Components/Spinner";
import { convertToTitleCase } from "../../../../utils/constants";
import { toast } from "react-toastify";

const Permissions = () => {
  const navigate = useNavigate();

  const { accessUpdateRole } = usePermission();

  useNav({
    mainTitle: "Permissions",
    subTitle:
      "Set the permission levels and access different members of your team have in your organization.",
    hasBackButton: true,
  });
  const params = useParams();
  const roleId = params.id;
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");

  const [permissionData, setPermissionData] = useState([]);
  const {
    data: roleData,
    refetch: refetchRoles,
    isLoading,
    error,
  } = useGetRoles();
  const { updateRolePermissions } = useUpdateRolePermissions(roleId);

  // HANDLE UPDATE ROLE PERMISSIONS
  const { register, handleSubmit } = useForm();
  const onSubmit = () => {
    const updatedData = { ...permissionData };
    const hasPermissions = updatedData?.access?.some((permission) =>
      permission.rules.some((rule) => rule.value)
    );

    if (!hasPermissions) {
      return toast.error("Please add at least one permission to this role.");
    }
    updatedData.access = JSON.stringify(updatedData.access);

    updateRolePermissions.mutate(updatedData);
  };

  const handlePermissionChecked = (value, index1, index) => {
    setPermissionData((prevPermissionData) => {
      const updatedPermissionData = { ...prevPermissionData };

      if (updatedPermissionData && updatedPermissionData.access) {
        const updatedAccess = [...updatedPermissionData.access];
        updatedAccess[index1] = {
          ...updatedAccess[index1],
          rules: updatedAccess[index1].rules.map((rule, i) => ({
            ...rule,
            value: i === index ? value : rule.value,
          })),
        };

        return { ...updatedPermissionData, access: updatedAccess };
      }

      return { ...prevPermissionData };
    });
  };

  const hadnleEditRole = () => {
    if (roleData?.data) {
      const selectedRole = roleData?.data?.find((role) => role.id === roleId);
      setRoleName(selectedRole?.name);
      setRoleDescription(selectedRole?.description);
      if (selectedRole) {
        const parsedAccess = JSON.parse(selectedRole.access);
        setPermissionData({ ...selectedRole, access: parsedAccess });
      }
    }
  };

  useEffect(() => {
    hadnleEditRole();
  }, [roleData?.data, roleId]);

  return (
    <div className="container-fluid mt-5">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <h5 className="mb-0"> {convertToTitleCase(roleName)} Role</h5>
          <p> {roleDescription}</p>
          <table className="table">
            {permissionData?.access?.map((permission, index1) => (
              <div key={index1} className="">
                <TSubHeader title={permission?.groupName} />

                <div className="d-flex flex-wrap">
                  {permission?.rules.map((rule, index) => (
                    <div
                      key={index}
                      className="col-12 col-sm-6 col-md-4 col-xxl-3"
                    >
                      <TRow
                        label={rule.name}
                        name={rule.name}
                        checked={rule.value}
                        handler={(e) =>
                          handlePermissionChecked(
                            e?.target?.checked,
                            index1,
                            index
                          )
                        }
                      />
                    </div>
                  ))}
                </div>

                <TDivider />
              </div>
            ))}
          </table>
        </>
      )}

      {accessUpdateRole.value && (
        <FooterButtons
          saveLabel={"Update"}
          cancelBtn={() => navigate("/settings/permission-and-roles")}
          handleClick={handleSubmit(onSubmit)}
          loading={updateRolePermissions.isLoading}
          disabled={updateRolePermissions.isLoading}
        />
      )}
    </div>
  );
};

export default Permissions;
