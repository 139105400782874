import React from "react";
import { LicenseAndCertificationDocWrapper } from "./style";
import Icons from "../Icons";
import { saveAs } from "file-saver";
import Button from "../Button";
import { colors } from "../../../utils/constants";
import SkeletonLoader from "../../SkeletonLoader";

const LicenseAndCertificationDoc = ({
  name,
  labelName,
  extension,
  document,
  required,
  deleteDoc,
  handleDelete,
  isLoading,
}) => {
  // Remove extension from the name
  const nameWithoutExtension = name?.replace(/\.[^/.]+$/, "");

  return (
    <>
      {labelName && (
        <label htmlFor={name} className="form-label ml-4 custom-form-label">
          {labelName} {required && <span className="required">*</span>}
        </label>
      )}
      <LicenseAndCertificationDocWrapper>
        <div className="docImg py-2">
          {isLoading ? (
            <div style={{ flex: "1" }}>
              <SkeletonLoader height={"4rem"} borderRadius={"15px"} />
            </div>
          ) : (
            <Icons iconName={"docIcon"} />
          )}
        </div>

        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <p className="name">{nameWithoutExtension}</p>
        )}
        <p className="extension">{extension}</p>

        <div className="d-flex justify-content-between align-items-center mt-2 w-100">
          {isLoading ? (
            <div style={{ flex: "1" }}>
              <SkeletonLoader height={"2rem"} borderRadius={"15px"} />
            </div>
          ) : (
            <>
              {document && (
                <div
                  className="d-flex gap-2 w-100"
                  style={{ justifyContent: deleteDoc ? "start" : "end" }}
                >
                  <div style={{ cursor: "pointer" }} className="viewDoc">
                    <a href={document} target="_blank">
                      <Icons iconName={"viewings"} className="docIcon" />
                    </a>
                  </div>
                  <div
                    onClick={() => saveAs(document, name)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icons iconName={"documentDownload"} className="docIcon " />
                  </div>
                </div>
              )}

              {deleteDoc && (
                <div className="d-flex justify-content-end w-100">
                  <Button
                    btnText={<Icons iconName={"delete"} />}
                    bordercolor={colors.black}
                    borderHover={colors.red}
                    backgroundcolor={colors.white}
                    backgroundHover={colors.dangerLight}
                    colorHover={colors.danger}
                    color={colors.danger}
                    iconcolorHover={colors.danger}
                    padding={"0"}
                    marginTop={"0"}
                    bordersize={2}
                    btntextcolor={colors.red}
                    iconWidth={"15px"}
                    width={"30px"}
                    height={"30px"}
                    type={"button"}
                    onBtnClick={handleDelete}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </LicenseAndCertificationDocWrapper>
    </>
  );
};

export default LicenseAndCertificationDoc;
