import React from "react";
import { CardWrapper } from "./cardStyle";

const InfoCard = ({
  mainValue,
  description,
  ...rest
}) => {
  return (
    <CardWrapper {...rest}>
      <p className="card_title mb-2 text-center mt-2 w-100">{mainValue}</p>
      <p className="card_description text-center w-100">{description}</p>
    </CardWrapper>
  );
};

export default InfoCard;
