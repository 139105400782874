import React from "react";
import useAxiosBase from "./useAxiosBase";
import { useQuery } from "@tanstack/react-query";

const useGetDocuments = () => {
    const { getRequest } = useAxiosBase();

    return useQuery({
        queryKey: ["all-bocuments"],
        queryFn: () =>
            getRequest({ url: `/public/option-type/BANK_REGISTRATION_DOCUMENT_TYPE_UPLOAD/options` }).then((res) => {
                return res?.data?.value?.value;
            }),
    });
};

export default useGetDocuments; 
