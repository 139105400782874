import noimage from "../noimage.png";
import defaultHouse from "../house.svg";

export const TitleName = (newTitle) =>
  (document.title = `Property Developers | ${newTitle}`);

export const getImage = (image) => {
  if (image) {
    if (image.length > 0) {
      return image[0]?.document;
    }
  }
  return defaultHouse;
};

export const getSingleImage = (image) => {
  if (image) {
    return image;
  }
  return noimage;
};

export const yearRange = (start, stop, step) => {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  );
};

export function toSentenceCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const isNil = (value) =>
  value === undefined || value === null || value === "" || value === " ";

export const arryEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
};

export const isArrayWithMultipleElements = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
};

export const applicationNumber = (appId) => appId?.substr(0, 5).toUpperCase();
export const toApplicationNumber = (appId) => appId.substr(0, 5).toUpperCase();

export const toDate = (value) => {
  return new Date(value).toDateString();
};
