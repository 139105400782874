import React from "react";

const CenteredText = ({ children, title }) => {
  return (
    <div className="col-md-12 text-center d-flex flex-column align-items-center justify-content-center">
      <p className="text-center fw-bold">{title}</p>
      <p className="text-center fw-light text-wrap w-50">{children}</p>
    </div>
  );
};

export default CenteredText;
