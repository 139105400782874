import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import router from "./router";
import { AuthenticationProvider } from "./Context/AuthenticationContext";
import { AuthPermissonProvider } from "./Context/PermissionContext";
import { Slide, ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { SignUpContextProvider } from "./Context/SignUpContext";
// import Hotjar from "@hotjar/browser";
// import mixpanel from "mixpanel-browser";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
// const developerAppId = process.env.REACT_APP_HOT_JAR;
// const hotjarVersion = 6;
// const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

// Hotjar.init(developerAppId, hotjarVersion);
// mixpanel.init(mixpanelToken, {
//   debug: true,
//   track_pageview: true,
//   persistence: "localStorage",
// });

const emptyCache = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
};

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthenticationProvider>
        <AuthPermissonProvider>
          <SignUpContextProvider>
            <RouterProvider router={router} />
            <ToastContainer
              theme={"light"}
              transition={Slide}
              autoClose={5000}
              position="top-right"
            />
          </SignUpContextProvider>
        </AuthPermissonProvider>
      </AuthenticationProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
emptyCache();
