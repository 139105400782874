import * as yup from "yup";

const updateModelSchema = yup
  .object({
    name: yup.string().required("Name is required."),
    emailAddress: yup.string().email("Invalid email format.").required("Email address is required."),
    phoneNumber: yup.string().required("Phone number is required.").max(11, "Phone number must be at most 11 characters."),
    // bankCode: yup.string().required("Bank code is required."),
    accountNumber: yup
      .number()
      .required("Account number is required.")
      .typeError("Account number must be a valid number.")
      .transform((value, originalValue) => (originalValue === "" ? undefined : value)),
    address: yup.string().required("Address is required."),
    // cityId: yup.string().required("City ID is required."),
    logo: yup.object().shape({
      base64: yup.string().required("Logo base64 is required."),
      ownerId: yup.string(),
      optionId: yup.string().required("Logo option ID is required."),
      extension: yup.string().required("Logo extension is required."),
      description: yup.string(),
      name: yup.string().required("Logo name is required."),
      extraProperties: yup.string(),
      revisionId: yup.string(),
    }).required("Logo is required."),
    featureImage: yup.object().shape({
      base64: yup.string().required("Feature image base64 is required."),
      ownerId: yup.string(),
      optionId: yup.string().required("Feature image option ID is required."),
      extension: yup.string().required("Feature image extension is required."),
      description: yup.string(),
      name: yup.string().required("Feature image name is required."),
      extraProperties: yup.string(),
      revisionId: yup.string(),
    }),
    brandColorCode: yup.string(),
    description: yup.string(),
    yearsInOperation: yup.number().required("Years in operation is required."),
  })
  .required("Update model data is required.");

export default updateModelSchema;
