import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import { TitleName } from "../../utils/generalUtils";
import Navbar from "../Navbar";
import "../MainLayout/mainLayout.css";
// import Footer from "../Footer";

const MainLayout = ({ pages }) => {
  const location = useLocation();
  const SetTitleDocument = (path, pageTitle) => {
    if (location.pathname === path) {
      TitleName(pageTitle);
    }
  };
  return (
    <div className="app-item  position-relative">
      <div id="layoutSidenav" className="layoutSidenav">
        <div id="layoutSidenav_content">
          <main className="main-wrapper">
            <Navbar />
            <div className="container-fluid w-auto">
              <Outlet />
            </div>
            {/* <div className="pt-5"><Footer /></div> */}
          </main>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
