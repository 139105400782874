import React from "react";
import useAxiosBase from "../../../../../hooks/useAxiosBase";
import { useQuery } from "@tanstack/react-query";

const useGetEstateHouses = (estateId) => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["estateHouse"],
    queryFn: () =>
      getRequest({
        url: `public/estate/${estateId}/get-houses`,
      }).then((res) => res.data?.value?.value),
    retry: 1,
  });
};

export default useGetEstateHouses;
