import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useGetCreditReport = (applicationId) => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["credit-report"],
    queryFn: () =>
      getRequest({
        url: `/bank/application/${applicationId}/get-analyzed-credit-report`,
      }).then((response) => response?.data?.value?.value),
    onError: (res) => {
      if (res?.statusCode === 404) {
        toast.error("Network Error!");
      }
    },
    staleTime: 5000,
    refetchOnWindowFocus: false,
  });
};

export default useGetCreditReport;
