import Developers from "./Pages/Developers";
import Plans from "./Pages/Plans";
import MortgagePlanDetails from "./Pages/Plans/MortgagePlanDetails";
import Earnings from "./Pages/Earnings";
import CreateMortagagePlan from "./Pages/Plans/CreateMortagagePlan";
import Prospect from "./Pages/Prospect";
import Portfolio from "./Pages/Portfolio";
import PortfolioDetails from "./Pages/Portfolio/PortfolioDetails";
import ViewingRequests from "./Pages/ViewingRequests";
import EditViewing from "./Pages/ViewingRequests/Edit";
import MortgageApplicationDetails from "./Pages/Application/ApplicationDetails";
import Company from "./Pages/Settings/Company";
import Profile from "./Pages/Settings/Profile";
import Password from "./Pages/Settings/Password";
import Team from "./Pages/Settings/Team";
import Documents from "./Pages/Settings/Documents";
import PermissionsAndRoles from "./Pages/Settings/PermissionsAndRoles";
import ApplicationReview from "./Pages/ApplicationReview";
import Signup from "./Pages/Signup";
import Wizzard from "./Pages/Signup/wizzard";
import ResetPassword from "./Pages/ResetPassword";
import Applications from "./Pages/Application/Applications";
import MyApplication from "./Pages/Application/MyApplications";
import Permissions from "./Pages/Settings/PermissionsAndRoles/Permissions";
import DeveloperDetails from "./Pages/Developers/DeveloperDetails";
import MortgagePlanViewDetails from "./Pages/Plans/MortgagePlanViewDetails";
import MyProperties from "./Pages/Properties/MyProperties";
import CreateEstate from "./Pages/Properties/MyProperties/Estate/createEstate";
import CreateHouse from "./Pages/Properties/MyProperties/House/createHouse";
import MyEstate from "./Pages/Properties/MyProperties/Estate/index";
import MyHouse from "./Pages/Properties/MyProperties/House/index";
import Estate from "./Pages/Properties/PartneredProperties/Estate";
import House from "./Pages/Properties/PartneredProperties/House";
import Dashboard from "./Pages/Dashboard";

const Routes = [
  {
    element: <Dashboard />,
    path: "/",
    titleName: "Dashboard",
  },
  {
    element: <CreateHouse />,
    path: "/properties/my-properties/create-house",
    titleName: "Create House",
  },
  {
    element: <MyHouse />,
    path: "/properties/my-properties/house/:id",
    titleName: "My House",
  },
  {
    element: <CreateEstate />,
    path: "/properties/my-properties/create-estate",
    titleName: "Create Estate",
  },
  {
    path: "/properties/my-properties/estate/:id",
    element: <MyEstate />,
    titleName: "My Estate",
  },
];

export default Routes;
