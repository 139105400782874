import DocumentValidation from "../TabComponent/DocumentValidation";
import PaymentPlan from "../TabComponent/PaymentPlan";
import PropertyViewed from "../TabComponent/PropertyViewed";
import Completed from "../TabComponent/Completed";
import {
  LOAN_TERM_OFFER,
  applicationStatus,
  applicationStatusIndex,
} from "./applicationStatus";
import { toSentenceCase, toDate } from "../../../utils/generalUtils";
import Payments from "../TabComponent/Payments";
import {
  colors,
  convertToTitleCase,
  formattedPrice,
} from "../../../utils/constants";
import LegalSearch from "../TabComponent/LegalSearch";
import { TabFlow1, TabFlow2, TabFlow3 } from "./TabsToDisplay";

export const EMPLOYMENT_LETTER = "employment letter";
export const IDENTIFICATION = "Identification";
export const BANK_STATEMENT = "Bank Statement";
export const BIRTH_CERTIFICATE = "Birth Certification";
export const TAX_CERTIFICATION = "Tax Certification";
export const CREDIT_CHECK = "Credit Check";

export const applicationColumnsData = [
  {
    mortgagePlan: "NHF",
    numberOfApplications: 12,
    openApplications: 3,
    lastApplicationDate: "20th October 2023",
    actionRequired: true,
  },
  {
    mortgagePlan: "[Custom Plan Name]",
    numberOfApplications: 4,
    openApplications: 1,
    lastApplicationDate: "25th September 2023",
    actionRequired: true,
  },
  {
    mortgagePlan: "[Custom Plan Name]",
    numberOfApplications: 4,
    openApplications: 1,
    lastApplicationDate: "25th October 2023",
    actionRequired: false,
  },
];

const getValidation = (
  application,
  requiredDocuments,
  step,
  currentStep = -1
) => {
  if (application) {
    if (!requiredDocuments?.length > 0) {
      return true;
    }
    var docValid = application?.documents?.every(
      (doc) => doc.isValidated === true
    );
    var revisions =
      application?.documents?.filter(
        (docs) => docs?.revisions?.some((r) => r?.isActive) === true
      )?.length > 0;

    // var steps = step !== currentStep;

    return docValid && !revisions ? true : false;
  }
  return false;
};

export const tabs = (
  requiredDocuments = [],
  application = null,
  steps,
  setSteps,
  showSubmitModal,
  setShowSubmitModal,
  refetch,
  isRefetching
) => {
  if (
    application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
    application?.mortgagePlan?.ownerType === "BANK" &&
    application?.house?.ownerType === "DEVELOPER"
  ) {
    return TabFlow1(
      requiredDocuments,
      application,
      steps,
      setSteps,
      showSubmitModal,
      setShowSubmitModal,
      refetch,
      isRefetching
    );
  } else if (
    (application?.mortgagePlan?.type === "MORTGAGE_PLAN" ||
      application?.mortgagePlan?.type === "NHF_PLAN" ||
      application?.mortgagePlan?.type === "RENT_TO_OWN") &&
    application?.mortgagePlan?.ownerType === "DEVELOPER" &&
    application?.house?.ownerType === "DEVELOPER"
  ) {
    return TabFlow2(
      requiredDocuments,
      application,
      steps,
      setSteps,
      showSubmitModal,
      setShowSubmitModal,
      refetch,
      isRefetching
    );
  } else {
    return TabFlow3(
      requiredDocuments,
      application,
      steps,
      setSteps,
      showSubmitModal,
      setShowSubmitModal,
      refetch,
      isRefetching
    );
  }
};

export const documentTypes = [
  {
    name: "Credit Check",
    label: "CREDIT_CHECK",
    icon: "creditCheck",
    type: "system",
    verifyBtn: false,
  },
  {
    name: "Peer Reviews",
    label: "PEER_REVIEWS",
    icon: "documents",
    type: "system",
    verifyBtn: false,
  },
  {
    name: "Employment Letter",
    label: "EMPLOYMENT_LETTER",
    icon: "documents",
    type: "system",
    verifyBtn: true,
  },
  {
    name: "Identification",
    label: "IDENTIFICATION",
    icon: "documents",
    type: "other",
    verifyBtn: true,
  },
  {
    name: "Bank Statement",
    label: "BANK_STATEMENT",
    icon: "documents",
    type: "system",
    verifyBtn: true,
  },
  {
    name: "Birth Certification",
    label: "BIRTH_CERTIFICATE",
    icon: "documents",
    type: "system",
    verifyBtn: true,
  },
  {
    name: "Tax Certification",
    label: "TAX_CERTIFICATION",
    icon: "documents",
    type: "other",
    verifyBtn: true,
  },
];

export const columns = [
  {
    name: "NAME",
    sortable: true,
    selector: (row) => row?.full_name,
  },
  {
    name: "ROLE",
    sortable: true,
    selector: (row) => row?.title,
  },
  {
    name: "EMAIL",
    sortable: true,
    selector: (row) => row.email,
  },
  {
    name: "SOURCE",
    sortable: true,
    selector: (row) => row.email,
  },
  {
    name: "PHONE NO",
    sortable: true,
    selector: (row) => row.organization_phone,
  },
  {
    name: "LINK",
    sortable: true,
    selector: (row) => row.linkedin_url,
  },
];

export const columnData = [
  {
    id: 1,
    name: "Joseph Kalu",
    role: "-",
    email: "jpsephkalu@imbg.com",
    source: "Employment Letter",
    phoneNo: "09078813989",
  },
  {
    id: 2,
    name: "Jimmy Kranster",
    role: "Human Resources",
    email: "jimmyk@imbg.com",
    source: "Website",
    phoneNo: "09078813989",
  },
  {
    id: 3,
    name: "Chisom Obinna",
    role: "Administrative Assistant",
    email: "chisom@imbg.com",
    source: "LinkedIn",
    phoneNo: "090788139887",
  },
  {
    id: 3,
    name: "Chisom Obinna",
    role: "Administrative Assistant",
    email: "chisom@imbg.com",
    source: "LinkedIn",
    phoneNo: "090788139887",
  },
  {
    id: 3,
    name: "Chisom Obinna",
    role: "Administrative Assistant",
    email: "chisom@imbg.com",
    source: "LinkedIn",
    phoneNo: "090788139887",
  },
  {
    id: 3,
    name: "Chisom Obinna",
    role: "Administrative Assistant",
    email: "chisom@imbg.com",
    source: "LinkedIn",
    phoneNo: "090788139887",
  },
  {
    id: 3,
    name: "Chisom Obinna",
    role: "Administrative Assistant",
    email: "chisom@imbg.com",
    source: "LinkedIn",
    phoneNo: "090788139887",
  },
  {
    id: 3,
    name: "Chisom Obinna",
    role: "Administrative Assistant",
    email: "chisom@imbg.com",
    source: "LinkedIn",
    phoneNo: "090788139887",
  },
  {
    id: 3,
    name: "Chisom Obinna",
    role: "Administrative Assistant",
    email: "chisom@imbg.com",
    source: "LinkedIn",
    phoneNo: "090788139887",
  },
];

export const creditAndDebitProfileColumn = (viewLoanHistory) => [
  {
    name: <p>SOURCE</p>,
    sortable: true,
    selector: (row) => convertToTitleCase(row.source),
  },
  {
    name: <p>TOTAL INSTITUTIONS</p>,
    sortable: true,
    selector: (row) => row.totalNoOfInstitutions,
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        TOTAL LOANS
      </p>
    ),
    sortable: true,
    selector: (row) => row.totalNoOfLoans,
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        ACTIVE LOANS
      </p>
    ),
    sortable: true,
    selector: (row) => row.totalNoOfActiveLoans,
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        CLOSED LOANS
      </p>
    ),
    sortable: true,
    selector: (row) => row.totalNoOfClosedLoans,
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        TOTAL DELINQUENT FACILITES
      </p>
    ),
    sortable: true,
    selector: (row) => row?.performanceStatus,
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        TOTAL BORROWED
      </p>
    ),
    sortable: true,
    selector: (row) => asCurrency(row.totalBorrowed, "NGN"),
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        TOTAL OUTSTANDING
      </p>
    ),
    sortable: true,
    selector: (row) => asCurrency(row.totalOutstanding, "NGN"),
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        TOTAL PERFORMING LOANS
      </p>
    ),
    sortable: true,
    selector: (row) => row?.performingLoans,
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        MONTHLY INSTALMENTS
      </p>
    ),
    sortable: true,
    selector: (row) => asCurrency(row.totalMonthlyInstallment ?? 0, "NGN"),
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        TOTAL OVERDUE
      </p>
    ),
    sortable: true,
    selector: (row) => row?.overdueAccounts || "---",
  },
];

export const loanHistoryColumn = [
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        PROVIDER
      </p>
    ),
    sortable: true,
    wrap: true,
    grow: 1.5,
    selector: (row) => <p className="m-0">{row?.loanProvider}</p>,
  },
  // {
  //   name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}>TYPE</p>,
  //   sortable: true,
  //   selector: (row) => row?.type,
  // },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        AMOUNT
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <p className="m-0">₦ {formattedPrice(row?.loanAmount)}</p>
    ),
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        OVERDUE AMOUNT
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <p className="m-0">₦ {formattedPrice(row?.overdueAmount)}</p>
    ),
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        STATUS
      </p>
    ),
    sortable: true,
    grow: 0.5,
    selector: (row) => <p className="m-0">{row?.status}</p>,
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        PERFORMANCE STATUS
      </p>
    ),
    sortable: true,
    wrap: true,
    grow: 1.2,
    selector: (row) => <p className="m-0">{row?.performanceStatus}</p>,
  },
  {
    name: (
      <p className="p-0 m-0" style={{ fontSize: "12px" }}>
        OUTSTANDING BALANCE
      </p>
    ),
    sortable: true,
    center: true,
    selector: (row) => (
      <p className="m-0">₦ {formattedPrice(row?.outstandingBalance)}</p>
    ),
  },
  // {
  //   name: <p className="p-0 m-0" style={{fontSize: "12px"}}>START DATE</p>,
  //   sortable: true,
  //   selector: (row) => row?.dateAccountOpened,
  // },
];

export const displayValidationValue = (documents) => {
  const result = Math.round(
    (documents?.filter((doc) => doc.isValidated === true)?.length /
      documents?.length) *
      100
  );
  return isNaN(result) ? 0 : result;
};

export const stringDisplayValidation = (documents) =>
  displayValidationValue(documents) >= 30
    ? `${displayValidationValue(documents)}% validated`
    : displayValidationValue(documents) <= 0
    ? 0
    : `${displayValidationValue(documents)}%`;

export const displayCreditCheckValues = (scores) => {
  let data = [];
  let dataTable = [];
  for (let property in scores) {
    if (property === "loanHistory" || property === "loanPerformance") {
      scores[property].map((x) => {
        dataTable.push({
          source: x.source,
          value: x.value,
        });
      });
      continue;
    }

    // scores[property].map((item) => {
    //   data.push({
    //     source: item.source, // Push the source value from each item in the array
    //     value: [{ property: property, value: item.value }],
    //   });
    // });

    data.push({
      title: toSentenceCase(
        property.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2")
      ),
      value: scores[property],
      property: property,
    });
  }
  return { data, dataTable };
};

export const getDate = (input, type) => {
  let date = new Date(input);
  return type === "y"
    ? date.getFullYear()
    : type === "m"
    ? date.toLocaleString("en-us", { month: "long" })
    : date.getDay();
};

export const asCurrency = (input, currency) => {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: currency,
  });

  return formatter.format(input);
};
