import React from "react";
import { CardWrapper } from "./cardStyle";
import noimage from "../../../noimage.png";
import { convertToTitleCase } from "../../../utils/constants";
import Icons from "../../../Components/Common/Icons";

const DeveloperCard = ({ developer }) => {
  const image = (image) => {
    if (image) {
      return image;
    }
    return noimage;
  };

  return (
    <CardWrapper>
      <div className="developer_card_header">
        <img
          src={image(developer?.coverImage)}
          alt={developer?.address}
          className="img-fluid h-100 w-100"
        />
        <div className="developer_logo">
          <img
            src={image(developer?.logo)}
            alt={developer?.address}
            className="img-fluid h-100 w-100"
          />
        </div>
      </div>

      <div className="developer_card_details pt-4">
        <p className="m-0 text-center fw-bold developer_card_name mb-2">
          {developer.name}
        </p>
        <div className="d-flex justify-content-center align-items-center gap-3 specification my-2">
          <div className="d-flex gap-2">
            <Icons iconName={"estate"} />
            <p className="m-0 mt-1">
              {developer.houseCount ?? 0}{" "}
              {developer.houseCount > 1 ? "Houses" : "House"}
            </p>
          </div>
          <div className="d-flex gap-2">
            <Icons iconName={"houses"} />
            <p className="m-0 mt-1">
              {developer.estateCount ?? 0}{" "}
              {developer.estateCount > 1 ? "Estates" : "Estate"}
            </p>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default DeveloperCard;
