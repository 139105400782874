import React, { useState, useEffect } from "react";
import { months, mtAppcolumns, mtAppdata, years } from "../../utils/constants";
import { useNav } from "../../hooks/useNav";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import InfoCard from "../../Components/Common/InfoCard";
import InfoTable from "../../Components/Common/InfoTable";
import Modal from "../../Components/Common/Modal/index";
import { useNavigate, useParams } from "react-router-dom";
import useGetApplications from "./hooks/useGetApplications";
import useHandleApplication from "./hooks/useHandleApplication";
import Spinner from "../../Components/Spinner";
import { toast } from "react-toastify";
import CenteredText from "../../Components/CenteredText";
import useGetAllApplications from "./hooks/useGetAllApplications";
import SkeletonLoader from "../../Components/SkeletonLoader";
import TableLoader from "../../Components/Common/TableLoader"

const Applications = () => {
  const navigate = useNavigate();

  
  const [omit, setOmit] = useState(true);
  const [appId, setAppId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [unHandledApplications, setUnHandledApplications] = useState(0);
  const params = useParams();
  const planId = params?.planId;
  const [spanText, setSpanText] = useState("Show All Fields");
  const [planName, setPlanName] = useState();

  const { data: allApplications } = useGetAllApplications();

  const [pagination, setPagination] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);

  const {
    data: applications,
    isLoading,
    refetch: refetchApplications,
    isRefetching: isRefetchingApplications,
  } = useGetApplications(pagination, planId);

  const {
    data: appData,
    refetch,
    isLoading: loadingApp,
    isRefetching,
    isFetching,
  } = useHandleApplication(appId);

  const handleApplicationBtnClick = (rowId) => {
    setAppId(rowId);
    setShowModal(true);
  };

  const handleViewDetails = (rowId) => {
    setAppId(rowId);
    navigate(`/applications/details/${rowId}`);
  };

  const handleApplication = () => {
    refetch(appId);
  };

  const handleRowClicked = (row, e) => {
    if (row?.handler?.handlerId) {
      navigate(`/applications/details/${row.applicationId}`);
      return;
    }
    toast.error(
      "This application cannot be viewed until it is handled. To handle the application, click the actions menu and click handle"
    );
  };

  useEffect(() => {
    if (allApplications) {
      const matchedApplication = allApplications.find(
        (app) => app.mortgagePlan?.id === planId
      );
      if (matchedApplication) {
        setPlanName(matchedApplication.mortgagePlan?.name);
      }
    }
  }, [allApplications, planId]);

  useEffect(() => {
    if (appData?.length > 0) {
      navigate(`/applications/details/${appId}`);
    }

    if (applications?.data?.length > 0) {
      setUnHandledApplications(
        applications?.data?.filter((x) => x?.handler?.handlerId === null)
          ?.length
      );
    }
  }, [appData, appId, navigate, applications?.data]);

  useNav({
    mainTitle:
      applications?.data?.length > 0
        ? `Applications - ${planName ?? ""}`
        : `Applications`,
    subTitle: `These are applications made by customers for ${
      applications?.data?.length < 2 ? "property" : "properties"
    } through your ${planName ?? ""}.`,
    hasBackButton: true,
  });

  useEffect(() => {
    setLoadingTable(isRefetchingApplications);
  }, [isRefetchingApplications]);

  useEffect(() => {
    if (pagination) {
      refetchApplications();
    }
  }, [pagination]);

  return (
    <div className="ps-2">
      <div className="col-lg-4 col-md-3 col-sm-2 mt-4">
        <InfoCard
         mainValue={
          isLoading || isRefetching ? (
            <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
          ) : (
            unHandledApplications ?? "0"
          )
        }
        description={
          isLoading || isRefetching ? (
            <SkeletonLoader />
          ) : (
            "Applications not yet handled"
          )
        }
        />
      </div>

      <div className="mb-4">
        {isLoading ? (
          <div className="mt-4 pe-3">
            <TableLoader />
          </div>
        ) : !applications?.data?.length ? (
          <div className="mt-5">
            <CenteredText title={"No Applications"}>
              There are no applications at this time
            </CenteredText>
          </div>
        ) : (
          <div className="pe-3 mt-2">
            <InfoTable
              columns={mtAppcolumns(
                false,
                handleApplicationBtnClick,
                handleViewDetails,
                isRefetchingApplications
              )}
              dataCollection={applications?.data}
              rightTextAsButton={true}
              pointerOnHover={true}
              highlightOnHover={true}
              loadingTable={loadingTable}
              paginationTotalRows={applications?.totalRecords}
              onChangePage={(page) => setPagination(page)}
              onRowClicked={handleRowClicked}
            />
          </div>
        )}
      </div>

      <Modal
        show={showModal}
        title={"Handle Open Application"}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Handle Application"}
        closeModal={() => setShowModal(false)}
        confirmModal={handleApplication}
        isBtnLoading={!loadingApp || isFetching}
        disabled={!loadingApp || isFetching}
      >
        <p className="text-center">
          Are you sure you want to handle this Application? By handling the
          application, you will be the sole user capable of handling the
          application until the application has been concluded or you transfer
          the application to a different staff in your organization.
        </p>
      </Modal>
    </div>
  );
};

export default Applications;
