import React from "react";
import DetailCard from "../../../Components/Common/DetailCard";
import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const BirthCertificate = ({ applicationDocumentId }) => {
  const { getRequest } = useAxiosBase();
  useQuery({
    queryKey: ["bank_statement", applicationDocumentId],
    queryFn: getRequest({
      url: `/developer/application/${applicationDocumentId}/get-analyzed-credit-report`,
    }).then((res) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success(res?.data?.value?.message);
      }
    }),
  });
  return (
    <div className="container mt-5">
      <div className="row justify-content-center d-flex">
        <div className="col-8">
          <DetailCard
            title={"Birth Date"}
            subtitle={"23rd January 1992 (30 Years old)"}
          />
        </div>
        <div className="col-4 justify-content-end">
          <DetailCard
            title={"Payment Periods"}
            subtitle={
              "360 Possible Payment Periods (Given their age, and the [Plan Name] loan tenor."
            }
          />
        </div>
        <div className="col-12 mt-5 ">
          <DetailCard title={"Born In"} subtitle={"Lagos"} />
        </div>
      </div>
    </div>
  );
};

export default BirthCertificate;
