export const bankList = [
  {
    id: 0,
    bankCode: "000",
    bankName: "CENTRAL BANK OF NIGERIA",
  },
  {
    id: 1,
    bankCode: "011",
    bankName: "FIRST BANK OF NIGERIA PLC",
  },
  {
    id: 2,
    bankCode: "023",
    bankName: "NIGERIA INTERNATIONAL BANK (CITIBANK)",
  },
  {
    id: 3,
    bankCode: "030",
    bankName: "UNION BANK OF NIGERIA PLC",
  },
  {
    id: 4,
    bankCode: "033",
    bankName: "UBA PLC",
  },
  {
    id: 5,
    bankCode: "035",
    bankName: "WEMA BANK PLC",
  },
  {
    id: 6,
    bankCode: "039",
    bankName: "STANBIC IBTC BANK PLC",
  },
  {
    id: 7,
    bankCode: "044",
    bankName: "ACCESS BANK PLC",
  },
  {
    id: 8,
    bankCode: "050",
    bankName: "ECOBANK NIGERIA PLC",
  },
  {
    id: 9,
    bankCode: "057",
    bankName: "ZENITH BANK PLC",
  },
  {
    id: 10,
    bankCode: "058",
    bankName: "GUARANTY TRUST BANK",
  },
  {
    id: 11,
    bankCode: "068",
    bankName: "STANDARD CHARTERED BANK NIGERIA LTD",
  },
  {
    id: 12,
    bankCode: "070",
    bankName: "FIDELITY BANK PLC",
  },
  {
    id: 13,
    bankCode: "076",
    bankName: "POLARIS BANK PLC",
  },
  {
    id: 14,
    bankCode: "101",
    bankName: "PROVIDUS BANK",
  },
  {
    id: 15,
    bankCode: "214",
    bankName: "FIRST CITY MONUMENT BANK PLC",
  },
  {
    id: 16,
    bankCode: "215",
    bankName: "UNITY BANK PLC",
  },
  {
    id: 17,
    bankCode: "232",
    bankName: "STERLING BANK PLC",
  },
  {
    id: 18,
    bankCode: "301",
    bankName: "JAIZ BANK",
  },
  {
    id: 19,
    bankCode: "459",
    bankName: "CORONATION MERCHANT BANK LIMITED",
  },
  {
    id: 20,
    bankCode: "480",
    bankName: "JUBILEE BANK",
  },
  {
    id: 21,
    bankCode: "510120013",
    bankName: "KANO MICROFINANCE BANKS",
  },
  {
    id: 22,
    bankCode: "511080016",
    bankName: "ESO SAVINGS LOANS PLC",
  },
  {
    id: 23,
    bankCode: "511080026",
    bankName: "ASO SAVINGS AND LOANS",
  },
  {
    id: 24,
    bankCode: "511080036",
    bankName: "NATIONAL HOUSING FUND",
  },
  {
    id: 25,
    bankCode: "511080106",
    bankName: "GAA-AKANBI MICRO FINANCE BANK",
  },
  {
    id: 26,
    bankCode: "512170012",
    bankName: "CLASSIC MICROFINANCE BANK",
  },
  {
    id: 27,
    bankCode: "512170022",
    bankName: "SOLID ROCK MFB OKE ONA ABEOKUTA",
  },
  {
    id: 28,
    bankCode: "512170032",
    bankName: "LAVENDER MICROFINANCE BANK LTD",
  },
  {
    id: 29,
    bankCode: "512170042",
    bankName: "EBIGI MICROFINANCE BANK",
  },
  {
    id: 30,
    bankCode: "512170052",
    bankName: "EFOTAMODI/OGUNOLA MICROFINANCE BANK",
  },
  {
    id: 31,
    bankCode: "512170062",
    bankName: "EIYEPE MICROFINANCE BANK",
  },
  {
    id: 32,
    bankCode: "512170072",
    bankName: "EJOSE MICROFINANCE BANK LTD",
  },
  {
    id: 33,
    bankCode: "512170082",
    bankName: "EMAZING GRACE MICROFINANCE BANK",
  },
  {
    id: 34,
    bankCode: "512170092",
    bankName: "CATLAND MICROFINANCE BANK LTD",
  },
  {
    id: 35,
    bankCode: "512170102",
    bankName: "OGUN STATE MICROFINANCE BANKS",
  },
  {
    id: 36,
    bankCode: "512170112",
    bankName: "UKUOMBE MICROFINANCE BANK LTD",
  },
  {
    id: 37,
    bankCode: "512170122",
    bankName: "URUWON MICROFINANCE BANK",
  },
  {
    id: 38,
    bankCode: "512170132",
    bankName: "USO-E MICROFINANCE BANK",
  },
  {
    id: 39,
    bankCode: "512170142",
    bankName: "EPPLE MICROFINANCE BANK",
  },
  {
    id: 40,
    bankCode: "512170152",
    bankName: "OFONYIN MICROFINANCE BANK",
  },
  {
    id: 41,
    bankCode: "512170162",
    bankName: "OMODI-IMOSAN MICROFINANCE BANK LTD",
  },
  {
    id: 42,
    bankCode: "512170172",
    bankName: "OJEBU-IFE COMMUNITY BANK NIG. LTD",
  },
  {
    id: 43,
    bankCode: "512170182",
    bankName: "OJEBU-IMUSIN MICROFINANCE BANK LTD",
  },
  { id: 44, bankCode: "512170192", bankName: "OKENNE MICROFINANCE BANK LTD" },
  { id: 45, bankCode: "512170202", bankName: "OGUN STATE MICROFINANCE BANKS" },
  { id: 46, bankCode: "512170212", bankName: "OLISAN MICROFINANCE BANK LTD" },
  {
    id: 47,
    bankCode: "512170222",
    bankName: "OMOWO MICROFINANCE BANK NIG LTD",
  },
  { id: 48, bankCode: "512170232", bankName: "ONTERLAND MICROFINANCE BANK" },
  { id: 49, bankCode: "512170242", bankName: "OPERU MICROFINANCE BANK LTD" },
  { id: 50, bankCode: "512170252", bankName: "OTELE MICROFINANCE BANK LTD" },
  { id: 51, bankCode: "512170262", bankName: "OGUN STATE MICROFINANCE BANKS" },
  { id: 52, bankCode: "512170272", bankName: "OGUN STATE MICROFINANCE BANKS" },
  { id: 53, bankCode: "512170282", bankName: "OGUN STATE MICROFINANCE BANKS" },
  { id: 54, bankCode: "512170292", bankName: "MALPOLY MICROFINANCE BANK" },
  { id: 55, bankCode: "512170302", bankName: "MOLUSI MICROFINANCE BANK LTD" },
  { id: 56, bankCode: "512170312", bankName: "NEW IMAGE MFB EGBA ODEDA" },
  {
    id: 57,
    bankCode: "512170322",
    bankName: "COMBINED BENEFITS MICROFINANCE BANK",
  },
  { id: 58, bankCode: "512170332", bankName: "OGUN STATE MICROFINANCE BANKS" },
  { id: 59, bankCode: "512170342", bankName: "AMU COMMUNITY BANK LTD" },
  { id: 60, bankCode: "512170352", bankName: "ARISUN MFB" },
  {
    id: 61,
    bankCode: "512170362",
    bankName: "RIVERSIDE MICROFINANCE BANK LTD",
  },
  { id: 62, bankCode: "512170372", bankName: "SAGAM MICROFINANCE BANK" },
  { id: 63, bankCode: "512170382", bankName: "TRUST MFB EGBA OWODE ABEOKUTA" },
  { id: 64, bankCode: "512170392", bankName: "OGUN STATE MICROFINANCE BANKS" },
  {
    id: 65,
    bankCode: "512170402",
    bankName: "ACON SUCCESS MICROFINANCE BANK LTD",
  },
  { id: 66, bankCode: "512170412", bankName: "UNAAB MICROFINANCE BANK" },
  { id: 67, bankCode: "512170422", bankName: "WEST-END MICROFINANCE BANK" },
  { id: 68, bankCode: "512170432", bankName: "HONEY MICROFINANCE BANK" },
  { id: 69, bankCode: "512170442", bankName: "EGOSASA MICROFINANCE BANK" },
  {
    id: 70,
    bankCode: "512170452",
    bankName: "ESTRA POLARIS MICROFINANCE BANK LTD",
  },
  { id: 71, bankCode: "512170462", bankName: "OGUN STATE MICROFINANCE BANKS" },
  { id: 72, bankCode: "512170472", bankName: "OGUN STATE MICROFINANCE BANKS" },
  { id: 73, bankCode: "512170492", bankName: "STAR MICROFINANCE BANK" },
  { id: 74, bankCode: "512170502", bankName: "CENTAGE SAVINGS AND LOANS" },
  { id: 75, bankCode: "512170522", bankName: "OGUN STATE MICROFINANCE BANKS" },
  { id: 76, bankCode: "512170542", bankName: "GATEWAY SAVINGS AND LOANS LTD" },
  { id: 77, bankCode: "512170552", bankName: "NACRDB ABIGI" },
  { id: 78, bankCode: "512170562", bankName: "NACRDB AYETORO" },
  { id: 79, bankCode: "512170572", bankName: "NACRDB IMEKO" },
  { id: 80, bankCode: "512170582", bankName: "NACRDB ABEOKUTA" },
  { id: 81, bankCode: "512170592", bankName: "OGUN STATE MICROFINANCE BANKS" },
  { id: 82, bankCode: "512170602", bankName: "NACRDB OTA" },
  { id: 83, bankCode: "512170612", bankName: "NACRDB ODEDA ABEOKUTA" },
  { id: 84, bankCode: "512170622", bankName: "NACRDB AGO-IWOYE" },
  { id: 85, bankCode: "512170632", bankName: "NACRDB SAGAM" },
  { id: 86, bankCode: "512170652", bankName: "ONTEGRATED MICROFINANCE BANK" },
  { id: 87, bankCode: "512170662", bankName: "OROLU MIRCOFINANCE BANK LTD" },
  { id: 88, bankCode: "513210013", bankName: "RIVERS MICROFINANCE BANKS" },
  { id: 89, bankCode: "514040013", bankName: "EDO MICROFINANCE BANKS" },
  {
    id: 90,
    bankCode: "515150013",
    bankName: "LAGOS BUILDING INVESTMENT CO.LTD",
  },
  { id: 91, bankCode: "515150023", bankName: "SKYE MORTGAGE" },
  {
    id: 92,
    bankCode: "515150033",
    bankName: "UNION HOMES SAVINGS AND LOANS PLC",
  },
  { id: 93, bankCode: "515150043", bankName: "NIGERIA POLICE FORCE" },
  { id: 94, bankCode: "515150053", bankName: "NIGERIA POLICE FORCE" },
  { id: 95, bankCode: "515150063", bankName: "LAGOS MICROFINANCE BANKS" },
  { id: 96, bankCode: "515150073", bankName: "SPECSMFB" },
  { id: 97, bankCode: "516290013", bankName: "OSUN MICROFINANCE BANKS" },
  { id: 98, bankCode: "516290023", bankName: "AAU MICRO-FINANCE BANK" },
  {
    id: 99,
    bankCode: "517150014",
    bankName: "ARITA BASHORUN MICROFINANCE BANK",
  },
  { id: 100, bankCode: "517150024", bankName: "MULTIVEST MICROFINANCE BANK" },
  { id: 101, bankCode: "517150034", bankName: "OGBOORA MFB" },
  { id: 102, bankCode: "517150044", bankName: "PACESETTERS MICROFINANCE BANK" },
  { id: 103, bankCode: "517150054", bankName: "CIVIC MICROFINANCE BANK" },
  { id: 104, bankCode: "517150064", bankName: "ALOGBON MFB" },
  { id: 105, bankCode: "517150074", bankName: "CREST MFB" },
  { id: 106, bankCode: "517150084", bankName: "EYETE MFB" },
  { id: 107, bankCode: "517150094", bankName: "EKESAN MFB" },
  { id: 108, bankCode: "518150014", bankName: "YOBE SAVINGS & LOANS LIMITED" },
  {
    id: 109,
    bankCode: "518150024",
    bankName: "NIG AGRIC COOP & RURAL DEV BANK LTD",
  },
  { id: 110, bankCode: "518150034", bankName: "YOBE MICROFINANCE BANK" },
  { id: 111, bankCode: "518150044", bankName: "YOBE MICROFINANCE BANK" },
  { id: 112, bankCode: "518150054", bankName: "YOBE MICROFINANCE BANK" },
  { id: 113, bankCode: "518150064", bankName: "YOBE MICROFINANCE BANK" },
  { id: 114, bankCode: "519290019", bankName: "ZION MFB" },
  { id: 115, bankCode: "520150010", bankName: "AURS MICRO FINANCE BANK" },
  {
    id: 116,
    bankCode: "520150020",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 117,
    bankCode: "520150030",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 118,
    bankCode: "520150040",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 119,
    bankCode: "520150050",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 120,
    bankCode: "520150060",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 121,
    bankCode: "520150070",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 122,
    bankCode: "520150080",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 123,
    bankCode: "520150090",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 124,
    bankCode: "520150100",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 125,
    bankCode: "520150110",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 126,
    bankCode: "520150120",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 127,
    bankCode: "520150130",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 128,
    bankCode: "520150140",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 129,
    bankCode: "520150150",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 130,
    bankCode: "520150160",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 131,
    bankCode: "520150170",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  {
    id: 132,
    bankCode: "520150180",
    bankName: "KWARA STATE MICROFINANCE BANKS",
  },
  { id: 133, bankCode: "521150010", bankName: "OKOLE MICROFINANCE BANK" },
  { id: 134, bankCode: "521150020", bankName: "SUNBEAM MICROFINANCE BANK LTD" },
  { id: 135, bankCode: "521150030", bankName: "ERAMOKO MFB LIMITED" },
  { id: 136, bankCode: "522150010", bankName: "JIGAWA SAVINGS & LOANS LTD" },
  { id: 137, bankCode: "580000010", bankName: "JUBILEE LIFE MORTGAGE BANK" },
  { id: 138, bankCode: "590000001", bankName: "POCKET MONEY" },
];
