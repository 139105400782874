import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";

const useGetRoles = () => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["roles"],
    queryFn: () =>
      getRequest({ url: `/bank/role/get-all` }).then(
        (res) => res.data.value?.value
      ),
    staleTime: 5000,
  });
};

export default useGetRoles;