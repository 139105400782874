import { useQuery } from "@tanstack/react-query";
import React from "react";
import useAxiosBase from "./useAxiosBase";

const useGetPlanDocuments = (mortgagePlanId) => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["mortgage-plans-required-document", mortgagePlanId],
    queryFn: () =>
      getRequest({
        url: `developer/mortgage-plan/${mortgagePlanId}/get-mortgage-plan-documents`,
      }).then((res) => {
        return res.data?.value?.value ?? null;
      }),
    refetchOnWindowFocus: false,
  });
};

export default useGetPlanDocuments;
