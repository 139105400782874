import React from "react";
import { BsDatabaseFillCheck } from "react-icons/bs";
import { colors } from "../../../utils/constants";
import Icons from "../../../Components/Common/Icons";
import {
  applicationStatus,
  applicationStatusIndex,
} from "../utils/applicationStatus";
import { MdCancel } from "react-icons/md";

const Completed = ({ application }) => {
  const isRejected =
    application?.status ===
    applicationStatus[applicationStatusIndex.REVIEW_REJECTED];
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-7 p-4">
          <div className="card border-opacity-25 shadow">
            <div className="card-body">
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  {console.log(
                    "XXXXXX",
                    applicationStatus[applicationStatusIndex.REVIEW_REJECTED]
                  )}
                  {isRejected ? (
                    <MdCancel color={colors.red} size={50} />
                  ) : (
                    <Icons iconName={"checkmark"} />
                  )}
                </div>
                <div className="col-12 mt-4  d-flex justify-content-center">
                  {isRejected ? (
                    <h4 className="text-danger">APPLICATION REJECTED</h4>
                  ) : (
                    <h4>COMPLETED</h4>
                  )}
                </div>
              </div>
              {isRejected ? (
                <div className="my-5"></div>
              ) : (
                <div className="row mt-5">
                  <div className="col-6 col-md-6 d-flex justify-content-center">
                    <h4 className="text-uppercase">Disburstment Status:</h4>
                  </div>
                  <div className="col-6">
                    <div className="container">
                      <div className="row">
                        <div className="col-2">
                          <BsDatabaseFillCheck
                            color={colors.darkGreen}
                            size={25}
                          />
                        </div>
                        <div className="col-10">
                          <small className="medium-text-bold-success text-uppercase">
                            Funds Disbursed
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Completed;