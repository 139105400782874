import React from "react";
import Icons from "../../../Components/Common/Icons";
import Button from "../../../Components/Common/Button";
import { colors } from "../../../utils/constants";

const LegalSearch = ({ application }) => {
  return (
    <>
      {application?.transactions?.isPaid && (
        <div className="my-4">
          <h4>Legal Search and Due Diligence</h4>
          <div className="d-flex flex-column align-items-center my-4">
            <div className="text-center">
              <Icons iconName={"legalSearchPayment"} />
              <p className="fw-bold my-2">
                Legal Search Payment Has Been Successfully Made
              </p>
              <p className="mt-3 mx-auto px-md-5">
              <strong>{application?.customer?.name}</strong>{" "} has made a legal search payment for{" "}
                <strong>{application?.house?.name ?? "this property"}</strong>{" "}
                located at <strong>{application?.house?.address}</strong>,{" "}
                developed by <strong>{application?.organization?.name}</strong>.{" "} Kindly process the search and
                proceed with the application if the result of the search is
                satisfactory with your policies and procedures by clicking the
                "proceed with application" button. If it is unsatisfactory, for
                whatever reason, end the application by clicking the "end
                application" button at the top of the page.
              </p>
            </div>
            <div className="legal-search_payment_card">
              <div className="d-flex justify-content-between w-100">
                <p className="mb-0 fw-semibold">AMOUNT TO BE PAID</p>
                <p className="mb-0 fw-bold">N 100, 000</p>
              </div>
              <hr className="my-2"></hr>
              <p className="mb-0 fw-bold">Amount Paid</p>
              <h4 className="mb-0" style={{ color: colors.green }}>
                N 100, 000
              </h4>
              <Button
                btnText={"DOWNLOAD RECEIPT"}
                righticonname={"documentDownload"}
                iconcolor={colors.white}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LegalSearch;