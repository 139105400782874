import React, { useEffect, useState } from "react";
import AffordabilityTag from "../../../Components/Common/AffordabilityTag";
import { asCurrency, getDate } from "../../Application/utils/constants";
import { colors } from "../../../utils/constants";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { formattedPrice } from "../../../utils/constants";

const FinancialCapacity = ({ financeData, application }) => {
  const [colorCode, setColorCode] = useState();
  const [debtIncomeRatioDescriptionText, setDebtIncomeRatioDescriptionText] =
    useState();
  const [showDebtIncomeRatioDescription, setShowDebtIncomeRatioDescription] =
    useState();
  const [
    showRealAffordabilityDescription,
    setShowRealAffordabilityDescription,
  ] = useState();

  const returnDatate = (financeData) => {
    let startDate = financeData?.startDate;

    let endDate = financeData?.endDate;

    const diff =
      new Date(startDate).getMonth() - new Date(endDate).getMonth() + 1;
    return (
      <>
        <p>
          {`${getDate(startDate, "m")
            .toString()
            .slice(0, 3)
            .toUpperCase()} - ${getDate(endDate, "m")
            .toString()
            .slice(0, 3)
            .toUpperCase()} ${getDate(startDate, "y")}`}
        </p>
        <p>{`(${diff} ${diff > 1 ? "Months" : "Month"})`}</p>
      </>
    );
  };

  useEffect(() => {
    if (application && financeData) {
      if (financeData?.dtiRatio?.toFixed() <= 33) {
        setColorCode(colors.darkGreen);
        setDebtIncomeRatioDescriptionText(
          "This means the customer's debt is within 33% in relation to their income and they are within the acceptable industry standard of 33% leveraged by most banks and within the 50% used by most developers."
        );
      } else if (
        financeData?.dtiRatio?.toFixed() > 33 &&
        financeData?.dtiRatio?.toFixed() <= 50
      ) {
        setColorCode("#C3B40A");
        setDebtIncomeRatioDescriptionText(
          "This means the customer's debt is above 33% in relation to their income and they are above the acceptable industry standard of 33% leveraged by most banks but not above the 50% used by most developers."
        );
      } else {
        setColorCode(colors.danger);
        setDebtIncomeRatioDescriptionText(
          "This means the customer's debt is above 50% in relation to their income and they are above the acceptable industry standard of 50% for developer payment plans."
        );
      }
    }
  }, [application, financeData]);

  return (
    <>
      <div className="mt-4">
        <div className="row m-0">
          <div className="col-4 ps-0">
            <div className="card border-opacity-25">
              <div className="card-header text-dark py-0 my-0">
                <h5 className="text-center mx-auto py-0 my-0">
                  Affordability & Debt-to-Income Ratio
                </h5>
              </div>
              <div className="card-body">
                {/* <div className="row">
                  <p>
                    <small>
                      Amount Affordable By The Applicant On{" "}
                      <b>[{financeData?.mortgage?.name}]</b> Given Their Age{" "}
                      <strong>{financeData?.age}</strong>, And Monthly Net
                      Income{" "}
                      <b>
                        ({asCurrency(financeData?.averageMonthlyIncome, "NGN")}
                        ).
                      </b>
                    </small>{" "}
                  </p>
                </div> */}
                <div className="d-flex justify-content-between py-2">
                  <div className="col-6 mb-2">
                    <p className="my-0 py-0 fw-bold">NET MONTHLY INCOME</p>
                    <p className="my-0 py-0">
                      ₦
                      {formattedPrice(financeData?.netMonthlyIncome?.toFixed())}
                    </p>
                  </div>
                  <div className="col-6 mb-2">
                    <p className="my-0 py-0 fw-bold">MONTHLY AFFORDABILITY</p>
                    <p className="my-0 py-0">
                      ₦
                      {formattedPrice(
                        financeData?.netMonthlyAffordability?.toFixed()
                      )}
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="col-6 mb-2">
                    <p className="my-0 py-0 fw-bold">AGE</p>
                    <p className="my-0 py-0">{financeData?.age}</p>
                  </div>
                  <div className="col-6 mb-2">
                    <p className="my-0 py-0 fw-bold">REQUIRED PAYMENTS</p>
                    <p className="my-0 py-0">
                      ₦{formattedPrice(financeData?.monthlyPayment?.toFixed())}
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="col-6 mb-2">
                    <div className="d-flex gap-1">
                      <p className="my-0 py-0 fw-bold mt-2">
                        REAL AFFORDABILITY
                      </p>
                      <span
                        className="pointer position-relative"
                        style={{ cursor: "pointer" }}
                      >
                        {showRealAffordabilityDescription && (
                          <div
                            className="animated toolTipText"
                            style={{ left: "-10rem" }}
                          >
                            <p className="description-text m-0">
                              Real affordability refers to the consumer's
                              ability to realistically afford the costs
                              associated with purchasing and owning a home. It
                              takes into account the individual's financial
                              situation, including income, expenses, debts,
                              savings, and other financial obligations, to
                              determine whether homeownership is feasible and
                              sustainable.
                            </p>
                          </div>
                        )}
                        <FaRegCircleQuestion
                          size="0.8rem"
                          onMouseEnter={() =>
                            setShowRealAffordabilityDescription(true)
                          }
                          onMouseLeave={() =>
                            setShowRealAffordabilityDescription(false)
                          }
                        />
                      </span>
                    </div>
                    <p className="my-0 py-0">
                      ₦
                      {formattedPrice(
                        financeData?.realAffordability?.toFixed()
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="card-footer">
                <div
                  className="d-flex fw-bold text-center py-2"
                  style={{
                    borderTop: `1px solid ${colors.grey}`,
                  }}
                >
                  <span
                    className="px-2 pe-1 pointer position-relative"
                    style={{ cursor: "pointer" }}
                  >
                    {showDebtIncomeRatioDescription && (
                      <div className="animated toolTipText">
                        <p className="description-text m-0">
                          {debtIncomeRatioDescriptionText}
                        </p>
                      </div>
                    )}
                    <FaRegCircleQuestion
                      size="1rem"
                      onMouseEnter={() =>
                        setShowDebtIncomeRatioDescription(true)
                      }
                      onMouseLeave={() =>
                        setShowDebtIncomeRatioDescription(false)
                      }
                    />
                  </span>
                  <h6
                    className="fw-bold"
                    style={{ paddingTop: "6px", color: colorCode }}
                  >
                    Debt To Income Ratio — {financeData?.dtiRatio?.toFixed()}% (
                    {application?.mortgagePlan?.type === "DEVELOPER_PLAN" &&
                    financeData?.dtiRatio?.toFixed() < 50
                      ? "Within"
                      : "Above"}
                    {application?.mortgagePlan?.type === "DEVELOPER_PLAN"
                      ? "50"
                      : "33"}
                    % Benchmark)
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="col-8 ps-0">
            <div className="card border-opacity-25">
              <div className="card-header text-dark text-center">
                <h5 className="m-auto">Affordability Description</h5>
              </div>
              <div className="card-body">
                <p className="text-center">
                  {financeData?.customerName} is purchasing a home on{" "}
                  {financeData?.mortgagePlanName} which has an interest rate of{" "}
                  {financeData?.interestRate?.toFixed()}% and a downpayment of{" "}
                  {financeData?.equityPercentage?.toFixed()}% with a maximum
                  payment period of {financeData?.loanTenor} months.{" "}
                  {financeData?.customerName} earns an average of ₦
                  {formattedPrice(financeData?.averageMonthlyIncome?.toFixed())}{" "}
                  every month and is currently {financeData?.age} years old (
                  {new Date(financeData?.customerDateOfBirth)?.toDateString()}),
                  and has outstanding debts of ₦
                  {financeData?.outstandingDebt?.toFixed()}. Based on this
                  information, we have calculated the amortization for the
                  purchase of this property on {financeData?.mortgagePlanName}.{" "}
                  {financeData?.customerName} is set to pay ₦
                  {formattedPrice(financeData?.monthlyPayment.toFixed())} for{" "}
                  {financeData?.loanTenor} months. Ngutor{" "}
                  {financeData?.isAffordable ? "can" : "can not"} afford to
                  purchase this property given their monthly affordability ( ₦
                  {formattedPrice(
                    financeData?.netMonthlyAffordability?.toFixed()
                  )}
                  ) is {financeData?.isAffordable ? "greater" : "less"} than the
                  monthly required payments ( ₦
                  {formattedPrice(financeData?.monthlyPayment.toFixed())}),
                  income, age, and debt profile.
                  {/* {financeData?.affordabilityDescription}.{" "} new Date(row.dateCreated).toDateString()
                  <strong>
                    {financeData?.affordabilityDescriptionFinalDecision}
                  </strong> */}
                </p>
              </div>
              <div className="card-footer">
                <div className="row position-relative pe-3">
                  <div className="col-2 col-md-2 py-2">
                    <AffordabilityTag
                      text={
                        financeData?.isAffordable
                          ? "Affordable"
                          : "Unaffordable"
                      }
                      variant={!financeData?.isAffordable && "danger"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="row m-0">
          <div className="col-7 ps-0">
            <div className="card border-opacity-25">
              <div className="card-header text-dark text-center">
                {" "}
                <h5 className=" m-auto">Savings & Spending Habits</h5>
              </div>
              <div className="card-body">
                <div className="row text-center">
                  <h3>{financeData?.savingRatio?.toFixed()}%</h3>
                  <p className="text-secondary fw-bold">
                    {financeData?.customerName}'s Savings Ratio
                  </p>
                </div>
                <div className="row text-center">
                  <div className="col-3">
                    <h6>TOTAL INCOME</h6>
                    <p>
                      <strong>
                        ₦{formattedPrice(financeData?.totalIncome?.toFixed())}
                      </strong>
                    </p>
                  </div>
                  <div className="col-3">
                    <h6>EXPENSES</h6>
                    <p>
                      <strong>
                        ₦{formattedPrice(financeData?.totalExpense?.toFixed())}
                      </strong>
                    </p>
                  </div>
                  <div className="col-3">
                    <h6>BALANCE</h6>
                    <p>
                      <strong>
                        ₦{formattedPrice(financeData?.balance?.toFixed())}
                      </strong>
                    </p>
                  </div>
                  <div className="col-3">
                    <h6>PERIOD</h6>
                    <p>{returnDatate(financeData)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-5 ps-0">
            <div className="card border-opacity-25">
              <div className="card-header text-dark">
                <h5 className="text-center m-auto">Sensitivity Analysis</h5>
              </div>
              <div className="card-body">
                <div className="row text-center">
                  <h4>{asCurrency(financeData?.balance, "NGN")}</h4>
                  <p className="mt-2 text-secondary fw-bold">
                    {financeData?.customerName}'s Balance
                  </p>
                </div>
                <div className="row">
                  <p className="text-center">
                    {financeData?.sensitivityAnalysis}.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialCapacity;
