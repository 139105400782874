export const data = [
  {
    id: "1",
    customerName: "John Okafor",
    customerPhoneNumber: "08060031246",
    customerImage: "https://picsum.photos/id/64/4326/2884",
    viewingDate: "Saturday, October 2022",
    viewingTime: "5 PM",
    propertyAddress: "No 23 Joseph Waku Street Gwarimpa,Abuja.",
    propertyImage: "https://picsum.photos/id/164/1200/800",
    propertyDescription:
      "This is a 3 bedroom house located in the Sahara estate off Citec Villa, Gwarimpa, Abuja. This building is among one of 120 buildings",
    features: [
      { id: "1", name: "2 bed", icon: "bedrooms" },
      { id: "2", name: "3 bath", icon: "bathrooms" },
      { id: "3", name: "7,000 Sqm", icon: "sqm" },
      { id: "4", name: "terrace", icon: "terrace" },
      { id: "5", name: "Unfurnished", icon: "furnished_unfurnished" },
      { id: "6", name: "Ac", icon: "ac" },
      {
        id: "7",
        name: "Gated Compound",
        icon: "gated_compound_gated_community",
      },
    ],
    status: "Active",
  },
  {
    id: "2",
    customerName: "Samantha Oku",
    customerPhoneNumber: "08060031246",
    customerImage: "https://picsum.photos/200/300",
    viewingDate: "Saturday, October 2022",
    viewingTime: "5 PM",
    propertyAddress: "No 23 Joseph Waku Street Gwarimpa,Abuja.",
    propertyImage: "https://picsum.photos/200/400",
    propertyDescription:
      "This is a 3 bedroom house located in the Sahara estate off Citec Villa, Gwarimpa, Abuja. This building is among one of 120 buildings",
    features: [
      { id: "1", name: "2 bed", icon: "bedrooms" },
      { id: "2", name: "3 bath", icon: "bathrooms" },
      { id: "3", name: "7,000 Sqm", icon: "sqm" },
      { id: "4", name: "terrace", icon: "terrace" },
      { id: "5", name: "Unfurnished", icon: "furnished_unfurnished" },
      { id: "6", name: "Ac", icon: "ac" },
      {
        id: "7",
        name: "Gated Compound",
        icon: "gated_compound_gated_community",
      },
    ],
    status: "Active",
  },
  {
    id: "3",
    customerName: "John Maduokwo",
    customerPhoneNumber: "08060031246",
    customerImage: "https://picsum.photos/200/300",
    viewingDate: "Saturday, October 2022",
    viewingTime: "5 PM",
    propertyAddress: "No 23 Joseph Waku Street Gwarimpa,Abuja.",
    propertyImage: "https://picsum.photos/200/401",
    propertyDescription:
      "This is a 3 bedroom house located in the Sahara estate off Citec Villa, Gwarimpa, Abuja. This building is among one of 120 buildings",
    features: [
      { id: "1", name: "2 bed", icon: "bedrooms" },
      { id: "2", name: "3 bath", icon: "bathrooms" },
      { id: "3", name: "7,000 Sqm", icon: "sqm" },
      { id: "4", name: "terrace", icon: "terrace" },
      { id: "5", name: "Unfurnished", icon: "furnished_unfurnished" },
      { id: "6", name: "Ac", icon: "ac" },
      {
        id: "7",
        name: "Gated Compound",
        icon: "gated_compound_gated_community",
      },
    ],
    status: "Active",
  },
  {
    id: "4",
    customerName: "Sam Smith",
    customerPhoneNumber: "08060031246",
    customerImage: "https://picsum.photos/200/300",
    viewingDate: "Saturday, October 2022",
    viewingTime: "5 PM",
    propertyAddress: "No 23 Joseph Waku Street Gwarimpa,Abuja.",
    propertyImage: "https://picsum.photos/200/100",
    propertyDescription:
      "This is a 3 bedroom house located in the Sahara estate off Citec Villa, Gwarimpa, Abuja. This building is among one of 120 buildings",
    features: [
      { id: "1", name: "2 bed", icon: "bedrooms" },
      { id: "2", name: "3 bath", icon: "bathrooms" },
      { id: "3", name: "7,000 Sqm", icon: "sqm" },
      { id: "4", name: "terrace", icon: "terrace" },
      { id: "5", name: "Unfurnished", icon: "furnished_unfurnished" },
      { id: "6", name: "Ac", icon: "ac" },
      {
        id: "7",
        name: "Gated Compound",
        icon: "gated_compound_gated_community",
      },
    ],
    status: "Past",
  },
  {
    id: "5",
    customerName: "Simbi Ali",
    customerPhoneNumber: "08060031246",
    customerImage: "https://picsum.photos/100/300",
    viewingDate: "Saturday, October 2022",
    viewingTime: "5 PM",
    propertyAddress: "No 23 Joseph Waku Street Gwarimpa,Abuja.",
    propertyImage: "https://picsum.photos/200/100",
    propertyDescription:
      "This is a 3 bedroom house located in the Sahara estate off Citec Villa, Gwarimpa, Abuja. This building is among one of 120 buildings",
    features: [
      { id: "1", name: "2 bed", icon: "bedrooms" },
      { id: "2", name: "3 bath", icon: "bathrooms" },
      { id: "3", name: "7,000 Sqm", icon: "sqm" },
      { id: "4", name: "terrace", icon: "terrace" },
      { id: "5", name: "Unfurnished", icon: "furnished_unfurnished" },
      { id: "6", name: "Ac", icon: "ac" },
      {
        id: "7",
        name: "Gated Compound",
        icon: "gated_compound_gated_community",
      },
    ],
    status: "Active",
  },
];

export const status = [
  { id: "active", name: "Active" },
  { id: "past", name: "Past" },
];
