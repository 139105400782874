import Completed from "../TabComponent/Completed";
import DocumentValidation from "../TabComponent/DocumentValidation";
import LegalSearch from "../TabComponent/LegalSearch";
import PaymentPlan from "../TabComponent/PaymentPlan";
import Payments from "../TabComponent/Payments";
import { applicationStatusIndex } from "./applicationStatus";

const getValidation = (
  application,
  requiredDocuments,
  step,
  currentStep = -1
) => {
  if (application) {
    if (!requiredDocuments?.length > 0) {
      return true;
    }
    var docValid = application?.documents?.every(
      (doc) => doc.isValidated === true
    );
    var revisions =
      application?.documents?.filter(
        (docs) => docs?.revisions?.some((r) => r?.isActive) === true
      )?.length > 0;

    // var steps = step !== currentStep;

    return docValid && !revisions ? true : false;
  }
  return false;
};

export const TabFlow1 = (
  requiredDocuments = [],
  application = null,
  steps,
  setSteps,
  showSubmitModal,
  setShowSubmitModal,
  refetch,
  isRefetching
) => ({
  flow: "tabFlow1",
  tabs: [
    {
      id: [applicationStatusIndex.LEGAL_SEARCH],
      name: "LEGAL SEARCH",
      status:
        steps === applicationStatusIndex.LEGAL_SEARCH
          ? "active"
          : steps > applicationStatusIndex.LEGAL_SEARCH
          ? "completed"
          : "pending",
      component: (
        <>
          <LegalSearch application={application} />
          <DocumentValidation
            appId={application?.id}
            application={application}
            documents={application?.documents}
            customer={application?.customer}
            refetch={refetch}
            isRefetching={isRefetching}
          />
        </>
      ),
      buttonOption: {
        primary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          application?.status === "LEGAL_SEARCH"
            ? "Upload Legal Search Report"
            : null,
        secondary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          application?.status === "LEGAL_SEARCH"
            ? "End Application"
            : null,
      },
    },
    {
      id: [applicationStatusIndex.OFFER_LETTER_REQUEST],
      name: "OFFER LETTER UPLOAD",
      status:
        steps === applicationStatusIndex.OFFER_LETTER_REQUEST
          ? "active"
          : steps > applicationStatusIndex.OFFER_LETTER_REQUEST
          ? "completed"
          : "pending",
      component: "",
      buttonOption: {
        primary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          application?.status === "OFFER_LETTER_REQUEST"
            ? "Request Offer Letter"
            : null,
        secondary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          application?.status === "OFFER_LETTER_REQUEST"
            ? "End Application"
            : null,
      },
    },
    {
      id: [
        applicationStatusIndex.IDENTITY_VERIFICATION,
        applicationStatusIndex.DOCUMENT_SUBMISSION,
      ],
      name: "IDENTITY VERIFICATION & DOCUMENT SUBMISSION",
      status:
        steps === applicationStatusIndex.IDENTITY_VERIFICATION
          ? "active"
          : steps > applicationStatusIndex.IDENTITY_VERIFICATION
          ? "completed"
          : "pending",
      component: "",
      buttonOption: {
        primary: null,
        secondary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          (application?.status === "IDENTITY_VERIFICATION" ||
            application?.status === "DOCUMENT_SUBMISSION")
            ? "End Application"
            : null,
      },
      validated: !getValidation(
        application,
        requiredDocuments,
        steps,
        applicationStatusIndex.PENDING
      ),
    },
    {
      id: [applicationStatusIndex.PENDING],
      name: "DOCUMENT VALIDATION",
      status:
        steps === applicationStatusIndex.PENDING &&
        application?.documents?.filter((x) => x.isValidated === false)
          ? "active"
          : steps > applicationStatusIndex.PENDING
          ? "completed"
          : "pending",
      component: (
        <DocumentValidation
          appId={application?.id}
          application={application}
          documents={application?.documents}
          customer={application?.customer}
          refetch={refetch}
          isRefetching={isRefetching}
        />
      ),
      buttonOption: {
        primary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          application?.status === "PENDING"
            ? "Send for Review"
            : null,
        secondary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          application?.status === "PENDING"
            ? "End Application"
            : null,
      },
      validated: !getValidation(
        application,
        requiredDocuments,
        steps,
        applicationStatusIndex.PENDING
      ),
    },
    {
      id: [
        applicationStatusIndex.UNDER_REVIEW,
        applicationStatusIndex.REVIEW_REJECTED,
      ],
      name: "APPLICATION REVIEW",
      status:
        steps === applicationStatusIndex.UNDER_REVIEW
          ? "active"
          : steps > applicationStatusIndex.UNDER_REVIEW
          ? "completed"
          : "pending",
      component: (
        <DocumentValidation
          appId={application?.id}
          application={application}
          documents={application?.documents}
          customer={application?.customer}
          refetch={refetch}
        />
      ),
      buttonOption: {
        primary: null,
        secondary: null,
      },
      validated: !getValidation(
        application,
        requiredDocuments,
        steps,
        applicationStatusIndex.UNDER_REVIEW
      ),
    },
    {
      id: [
        applicationStatusIndex.REVIEW_APPROVED,
        applicationStatusIndex.LOAN_TERM_OFFER,
        applicationStatusIndex.LOAN_TERM_ACCEPTED,
        applicationStatusIndex.OFFER_LETTER_UPLOADED,
        applicationStatusIndex.MORTGAGE_TERMS_UPLOADED,
      ],
      name: "SUBMIT DETAILED TERMS",
      status:
        steps === applicationStatusIndex.REVIEW_APPROVED
          ? "active"
          : steps > applicationStatusIndex.REVIEW_APPROVED
          ? "completed"
          : "pending",
      component: (
        <>
          <DocumentValidation
            appId={application?.id}
            application={application}
            documents={application?.documents}
            customer={application?.customer}
            refetch={refetch}
          />
          <PaymentPlan
            application={application}
            refetch={refetch}
            showSubmitModal={showSubmitModal}
            setShowSubmitModal={setShowSubmitModal}
          />
        </>
      ),
      buttonOption: {
        primary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          (application?.status === "REVIEW_APPROVED"
            ? "Send Loan Terms"
            : application?.status === "OFFER_TERMS"
            ? "Update Loan Terms"
            : application?.status === "TERMS_ACCEPTED"
            ? "Upload Loan Terms Offer"
            : null),
        secondary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          [
            "REVIEW_APPROVED",
            "OFFER_TERMS",
            "TERMS_ACCEPTED",
            "UPLOADED_OFFER",
          ].includes(application?.status)
            ? "End Application"
            : null,
      },
      validated: false,
    },
    {
      id: [
        applicationStatusIndex.PENDING_FEE_PAYMENT,
        applicationStatusIndex.PENDING_EQUITY_PAYMENT,
      ],
      name: "PAYMENT",
      status:
        steps === applicationStatusIndex.PENDING_FEE_PAYMENT ||
        steps === applicationStatusIndex.PENDING_EQUITY_PAYMENT
          ? "active"
          : steps > applicationStatusIndex.PENDING_FEE_PAYMENT ||
            steps > applicationStatusIndex.PENDING_EQUITY_PAYMENT
          ? "completed"
          : "pending",
      component: (
        <>
          <DocumentValidation
            appId={application?.id}
            application={application}
            documents={application?.documents}
            customer={application?.customer}
            refetch={refetch}
          />
          <Payments application={application} />
        </>
      ),
      buttonOption: application?.mortgagePlan?.type === "DEVELOPER_PLAN" && "",
      validated: true,
    },
    {
      id: [
        applicationStatusIndex.COMPLETED,
        applicationStatusIndex.REVIEW_REJECTED,
      ],
      name: "COMPLETED",
      status:
        steps === applicationStatusIndex.COMPLETED
          ? "active"
          : steps > applicationStatusIndex.COMPLETED
          ? "completed"
          : "pending",
      component: (
        <>
          <DocumentValidation
            appId={application?.id}
            application={application}
            documents={application?.documents}
            customer={application?.customer}
            refetch={refetch}
          />
          <Completed application={application} />
        </>
      ),
      validated: false,
    },
  ],
});

export const TabFlow2 = (
  requiredDocuments = [],
  application = null,
  steps,
  setSteps,
  showSubmitModal,
  setShowSubmitModal,
  refetch,
  isRefetching
) => ({
  flow: "tabFlow2",
  tabs: [
    {
      id: [applicationStatusIndex.LEGAL_SEARCH],
      name: "DUE DILIGENCE",
      status:
        steps === applicationStatusIndex.LEGAL_SEARCH
          ? "active"
          : steps > applicationStatusIndex.LEGAL_SEARCH
          ? "completed"
          : "pending",
      component: <LegalSearch application={application} />,
      buttonOption: {
        primary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          application?.status === "LEGAL_SEARCH"
            ? "Upload Legal Search Report"
            : null,
        secondary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          application?.status === "LEGAL_SEARCH"
            ? "End Application"
            : null,
      },
    },
    {
      id: [
        applicationStatusIndex.IDENTITY_VERIFICATION,
        applicationStatusIndex.DOCUMENT_SUBMISSION,
      ],
      name: "DOCUMENT SUBMISSION",
      status:
        steps === applicationStatusIndex.IDENTITY_VERIFICATION
          ? "active"
          : steps > applicationStatusIndex.IDENTITY_VERIFICATION
          ? "completed"
          : "pending",
      component: "",
      buttonOption: {
        primary: null,
        secondary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          (application?.status === "IDENTITY_VERIFICATION" ||
            application?.status === "DOCUMENT_SUBMISSION")
            ? "End Application"
            : null,
      },
      validated: !getValidation(
        application,
        requiredDocuments,
        steps,
        applicationStatusIndex.PENDING
      ),
    },
    {
      id: [applicationStatusIndex.PENDING],
      name: "DOCUMENT VALIDATION",
      status:
        steps === applicationStatusIndex.PENDING &&
        application?.documents?.filter((x) => x.isValidated === false)
          ? "active"
          : steps > applicationStatusIndex.PENDING
          ? "completed"
          : "pending",
      component: (
        <DocumentValidation
          appId={application?.id}
          application={application}
          documents={application?.documents}
          customer={application?.customer}
          refetch={refetch}
          isRefetching={isRefetching}
        />
      ),
      buttonOption: {
        primary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          application?.status === "PENDING"
            ? "Send for Review"
            : null,
        secondary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          application?.status === "PENDING"
            ? "End Application"
            : null,
      },
      validated: !getValidation(
        application,
        requiredDocuments,
        steps,
        applicationStatusIndex.PENDING
      ),
    },
    {
      id: [
        applicationStatusIndex.UNDER_REVIEW,
        applicationStatusIndex.REVIEW_REJECTED,
      ],
      name: "APPLICATION REVIEW",
      status:
        steps === applicationStatusIndex.UNDER_REVIEW
          ? "active"
          : steps > applicationStatusIndex.UNDER_REVIEW
          ? "completed"
          : "pending",
      component: (
        <DocumentValidation
          appId={application?.id}
          application={application}
          documents={application?.documents}
          customer={application?.customer}
          refetch={refetch}
        />
      ),
      buttonOption: {
        primary: null,
        secondary: null,
      },
      validated: !getValidation(
        application,
        requiredDocuments,
        steps,
        applicationStatusIndex.UNDER_REVIEW
      ),
    },
    {
      id: [applicationStatusIndex.UPLOADED_OFFER],
      name: "SEND OFFER LETTER",
      status:
        steps === applicationStatusIndex.UPLOADED_OFFER
          ? "active"
          : steps > applicationStatusIndex.UPLOADED_OFFER
          ? "completed"
          : "pending",
      component: (
        <>
          <DocumentValidation
            appId={application?.id}
            application={application}
            documents={application?.documents}
            customer={application?.customer}
            refetch={refetch}
          />
          <PaymentPlan
            application={application}
            refetch={refetch}
            showSubmitModal={showSubmitModal}
            setShowSubmitModal={setShowSubmitModal}
          />
        </>
      ),
      buttonOption: {
        primary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          (application?.status === "REVIEW_APPROVED"
            ? "Send Loan Terms"
            : application?.status === "OFFER_TERMS"
            ? "Update Loan Terms"
            : application?.status === "TERMS_ACCEPTED"
            ? "Upload Loan Terms Offer"
            : null),
        secondary:
          application?.mortgagePlan?.type === "MORTGAGE_PLAN" &&
          [
            "REVIEW_APPROVED",
            "OFFER_TERMS",
            "TERMS_ACCEPTED",
            "UPLOADED_OFFER",
          ].includes(application?.status)
            ? "End Application"
            : null,
      },
      validated: false,
    },
    {
      id: [
        applicationStatusIndex.OFFER_ACCEPTED,
        applicationStatusIndex.BANK_REJECTED,
      ],
      name: "BANK APPROVAL",
      status:
        steps === applicationStatusIndex.OFFER_ACCEPTED
          ? "active"
          : steps > applicationStatusIndex.OFFER_ACCEPTED
          ? "completed"
          : "pending",
      component: (
        <>
          <DocumentValidation
            appId={application?.id}
            application={application}
            documents={application?.documents}
            customer={application?.customer}
            refetch={refetch}
          />
          <Payments application={application} />
        </>
      ),
      buttonOption: application?.mortgagePlan?.type === "DEVELOPER_PLAN" && "",
      validated: true,
    },
    {
      id: [
        applicationStatusIndex.PENDING_FEE_PAYMENT,
        applicationStatusIndex.PENDING_EQUITY_PAYMENT,
      ],
      name: "PAYMENT",
      status:
        steps === applicationStatusIndex.PENDING_FEE_PAYMENT ||
        steps === applicationStatusIndex.PENDING_EQUITY_PAYMENT
          ? "active"
          : steps > applicationStatusIndex.PENDING_FEE_PAYMENT ||
            steps > applicationStatusIndex.PENDING_EQUITY_PAYMENT
          ? "completed"
          : "pending",
      component: (
        <>
          <DocumentValidation
            appId={application?.id}
            application={application}
            documents={application?.documents}
            customer={application?.customer}
            refetch={refetch}
          />
          <Payments application={application} />
        </>
      ),
      buttonOption: application?.mortgagePlan?.type === "DEVELOPER_PLAN" && "",
      validated: true,
    },
    {
      id: [
        applicationStatusIndex.COMPLETED,
        applicationStatusIndex.REVIEW_REJECTED,
      ],
      name: "COMPLETED",
      status:
        steps === applicationStatusIndex.COMPLETED
          ? "active"
          : steps > applicationStatusIndex.COMPLETED
          ? "completed"
          : "pending",
      component: (
        <>
          <DocumentValidation
            appId={application?.id}
            application={application}
            documents={application?.documents}
            customer={application?.customer}
            refetch={refetch}
          />
          <Completed application={application} />
        </>
      ),
      validated: false,
    },
  ],
});

export const TabFlow3 = (
  requiredDocuments = [],
  application = null,
  steps,
  setSteps,
  showSubmitModal,
  setShowSubmitModal,
  refetch,
  isRefetching
) => ({
  flow: "tabFlow3",
  tabs: [
    {
      id: [applicationStatusIndex.LEGAL_SEARCH],
      name: "DUE DILIGENCE",
      status:
        steps === applicationStatusIndex.LEGAL_SEARCH
          ? "active"
          : steps > applicationStatusIndex.LEGAL_SEARCH
          ? "completed"
          : "pending",
      component: <LegalSearch application={application} />,
      buttonOption: {
        primary:
          application?.mortgagePlan?.type === "PAYMENT_PLAN" &&
          application?.status === "LEGAL_SEARCH"
            ? "Upload Legal Search Report"
            : null,
        secondary:
          application?.mortgagePlan?.type === "PAYMENT_PLAN" &&
          application?.status === "LEGAL_SEARCH"
            ? "End Application"
            : null,
      },
    },
    {
      id: [
        applicationStatusIndex.IDENTITY_VERIFICATION,
        applicationStatusIndex.DOCUMENT_SUBMISSION,
      ],
      name: "IDENTITY VERIFICATION & DOCUMENT SUBMISSION",
      status:
        steps === applicationStatusIndex.IDENTITY_VERIFICATION
          ? "active"
          : steps > applicationStatusIndex.IDENTITY_VERIFICATION
          ? "completed"
          : "pending",
      component: "",
      buttonOption: {
        primary: null,
        secondary:
          application?.mortgagePlan?.type === "PAYMENT_PLAN" &&
          (application?.status === "IDENTITY_VERIFICATION" ||
            application?.status === "DOCUMENT_SUBMISSION")
            ? "End Application"
            : null,
      },
      validated: !getValidation(
        application,
        requiredDocuments,
        steps,
        applicationStatusIndex.PENDING
      ),
    },
    {
      id: [applicationStatusIndex.PENDING],
      name: "DOCUMENT VALIDATION",
      status:
        steps === applicationStatusIndex.PENDING &&
        application?.documents?.filter((x) => x.isValidated === false)
          ? "active"
          : steps > applicationStatusIndex.PENDING
          ? "completed"
          : "pending",
      component: (
        <DocumentValidation
          appId={application?.id}
          application={application}
          documents={application?.documents}
          customer={application?.customer}
          refetch={refetch}
          isRefetching={isRefetching}
        />
      ),
      buttonOption: {
        primary:
          application?.mortgagePlan?.type === "PAYMENT_PLAN" &&
          application?.status === "PENDING"
            ? "Send for Review"
            : null,
        secondary:
          application?.mortgagePlan?.type === "PAYMENT_PLAN" &&
          application?.status === "PENDING"
            ? "End Application"
            : null,
      },
      validated: !getValidation(
        application,
        requiredDocuments,
        steps,
        applicationStatusIndex.PENDING
      ),
    },
    {
      id: [
        applicationStatusIndex.UNDER_REVIEW,
        applicationStatusIndex.REVIEW_REJECTED,
      ],
      name: "APPLICATION REVIEW",
      status:
        steps === applicationStatusIndex.UNDER_REVIEW
          ? "active"
          : steps > applicationStatusIndex.UNDER_REVIEW
          ? "completed"
          : "pending",
      component: (
        <DocumentValidation
          appId={application?.id}
          application={application}
          documents={application?.documents}
          customer={application?.customer}
          refetch={refetch}
        />
      ),
      buttonOption: {
        primary: null,
        secondary: null,
      },
      validated: !getValidation(
        application,
        requiredDocuments,
        steps,
        applicationStatusIndex.UNDER_REVIEW
      ),
    },
    {
      id: [
        applicationStatusIndex.REVIEW_APPROVED,
        applicationStatusIndex.LOAN_TERM_OFFER,
        applicationStatusIndex.LOAN_TERM_ACCEPTED,
        applicationStatusIndex.OFFER_LETTER_UPLOADED,
        applicationStatusIndex.MORTGAGE_TERMS_UPLOADED,
      ],
      name: "SUBMIT DETAILED TERMS",
      status:
        steps === applicationStatusIndex.REVIEW_APPROVED
          ? "active"
          : steps > applicationStatusIndex.REVIEW_APPROVED
          ? "completed"
          : "pending",
      component: (
        <>
          <DocumentValidation
            appId={application?.id}
            application={application}
            documents={application?.documents}
            customer={application?.customer}
            refetch={refetch}
          />
          <PaymentPlan
            application={application}
            refetch={refetch}
            showSubmitModal={showSubmitModal}
            setShowSubmitModal={setShowSubmitModal}
          />
        </>
      ),
      buttonOption: {
        primary:
          application?.mortgagePlan?.type === "PAYMENT_PLAN" &&
          (application?.status === "REVIEW_APPROVED"
            ? "Send Loan Terms"
            : application?.status === "OFFER_TERMS"
            ? "Update Loan Terms"
            : application?.status === "TERMS_ACCEPTED"
            ? "Upload Loan Terms Offer"
            : null),
        secondary:
          application?.mortgagePlan?.type === "PAYMENT_PLAN" &&
          [
            "REVIEW_APPROVED",
            "OFFER_TERMS",
            "TERMS_ACCEPTED",
            "UPLOADED_OFFER",
          ].includes(application?.status)
            ? "End Application"
            : null,
      },
      validated: false,
    },
    {
      id: [
        applicationStatusIndex.PENDING_FEE_PAYMENT,
        applicationStatusIndex.PENDING_EQUITY_PAYMENT,
      ],
      name: "PAYMENT",
      status:
        steps === applicationStatusIndex.PENDING_FEE_PAYMENT ||
        steps === applicationStatusIndex.PENDING_EQUITY_PAYMENT
          ? "active"
          : steps > applicationStatusIndex.PENDING_FEE_PAYMENT ||
            steps > applicationStatusIndex.PENDING_EQUITY_PAYMENT
          ? "completed"
          : "pending",
      component: (
        <>
          <DocumentValidation
            appId={application?.id}
            application={application}
            documents={application?.documents}
            customer={application?.customer}
            refetch={refetch}
          />
          <Payments application={application} />
        </>
      ),
      buttonOption: application?.mortgagePlan?.type === "DEVELOPER_PLAN" && "",
      validated: true,
    },
    {
      id: [
        applicationStatusIndex.COMPLETED,
        applicationStatusIndex.REVIEW_REJECTED,
      ],
      name: "COMPLETED",
      status:
        steps === applicationStatusIndex.COMPLETED
          ? "active"
          : steps > applicationStatusIndex.COMPLETED
          ? "completed"
          : "pending",
      component: (
        <>
          <DocumentValidation
            appId={application?.id}
            application={application}
            documents={application?.documents}
            customer={application?.customer}
            refetch={refetch}
          />
          <Completed application={application} />
        </>
      ),
      validated: false,
    },
  ],
});
