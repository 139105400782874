import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useGetDeveloperPartnership = (page) => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["developer"],
    queryFn: () =>
      getRequest({
        url: `/bank/partnership/get-all?pageNumber=${page}`,
      }).then((res) => res.data?.value),
    staleTime: 50000,
  });
};
export default useGetDeveloperPartnership;