import * as yup from "yup";

export const model = yup.object({
  collectionApiKey: yup.string().required("Collection API Key is required"),
  confirmCollectionApiKey: yup
    .string()
    .oneOf(
      [yup.ref("collectionApiKey"), null],
      "Collection API Keys must match"
    )
    .required("Confirm Collection API Key is required"),
  merchantId: yup.string().required("Merchant ID is required"),
  confirmMerchantId: yup
    .string()
    .oneOf([yup.ref("merchantId"), null], "Merchant IDs must match")
    .required("Confirm Merchant ID is required"),
  downpaymentId: yup.string().required("Downpayment ID is required"),
  confirmDownpaymentId: yup
    .string()
    .oneOf([yup.ref("downpaymentId"), null], "Downpayment IDs must match")
    .required("Confirm Downpayment ID is required"),
  applicableFeesId: yup.string().required("Applicable Fees ID is required"),
  confirmApplicableFeesId: yup
    .string()
    .oneOf(
      [yup.ref("applicableFeesId"), null],
      "Applicable Fees IDs must match"
    )
    .required("Confirm Applicable Fees ID is required"),
  repaymentId: yup.string().required("Repayment ID is required"),
  confirmRepaymentId: yup
    .string()
    .oneOf([yup.ref("repaymentId"), null], "Repayment IDs must match")
    .required("Confirm Repayment ID is required"),
});
