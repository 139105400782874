import React from "react";

const DetailSubCard = ({
  leftTitle,
  leftDescription,
  rightTitle,
  rightDescription,
  className,
}) => {
  return (
    <div className={`row d-flex flex-wrap ${className}`}>
      <div className="col-6 flex-50">
        <h6 className="mb-1 p-0 fw-bold">{leftTitle}</h6>
        <p className="mb-1">{leftDescription}</p>
      </div>
      <div className="col-6 flex-50">
        <h6 className="mb-1 p-0 fw-bold">{rightTitle}</h6>
        <p className="mb-1" style={{textWrap: "wrap"}}>
          {rightDescription}
        </p>
      </div>
    </div>
  );
};

export default DetailSubCard;
