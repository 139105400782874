import styled from "styled-components";
import { colors } from "../../../utils/constants";

export const BadgeWrapper = styled.div`
  line-height: 23px;
  font-size: 0.7rem;
  border-color: ${(props) => props.bordercolor ?? colors.successGreen};
  border: ${(props) =>
    props.bordersize ? `${props.bordersize}px solid` : "1px solid"};
  color: ${(props) => props.color ?? colors.successGreen};
  background: ${(props) =>
    props.backgroundcolor ? props.backgroundcolor : colors.lightGreen};
  border-radius: 50px;
  height: ${(props) => props.height ?? "24px"};
  /* width: 100% !important; */
`;
