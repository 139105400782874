import { isNullOrEmpty } from "../constants";

export const applicationReviewManagement = (userAccess) => {
  if (isNullOrEmpty(userAccess)) return null;
  const access =
    userAccess?.find((x) => x.id === "viewing_requests_management")?.rules ||
    [];

  return {
    viewViewingRequestsAccess:
      access?.find((x) => x.id === "view_viewing_requests") || {},
    sendViewingRequestReminderAccess:
      access?.find((x) => x.id === "send_viewing_request_reminder") || {},
  };
};
