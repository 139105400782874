import { isNullOrEmpty } from "../constants";

export const roleManagement = (userAccess) => {
  if (isNullOrEmpty(userAccess)) return null;

  const roleManagement =
    userAccess?.find((x) => x.id === "role_management")?.rules || [];

  return {
    viewRoleAccess: roleManagement?.find((x) => x.id === "view_roles") || {},
    createRoleAccess: roleManagement?.find((x) => x.id === "add_role") || {},
    updateRoleAccess: roleManagement?.find((x) => x.id === "update_role") || {},
    deleteRoleAccess: roleManagement?.find((x) => x.id === "remove_role") || {},
  };
};
