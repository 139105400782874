import React from "react";
import Skeleton from "react-loading-skeleton";
import { SkeletonWrapper } from "./style";

const SkeletonLoader = ({
  count,
  circle,
  baseColor,
  height,
  width,
  borderRadius,
  className,
  ...rest
}) => {
  return (
    <SkeletonWrapper {...rest}>
      <Skeleton
        count={count}
        circle={circle}
        height={height}
        width={width ?? "100%"}
        baseColor={baseColor ?? "#f0f0f0"}
        borderRadius={borderRadius ?? "100px"}
        className={className}
      />
    </SkeletonWrapper>
  );
};

export default SkeletonLoader;
