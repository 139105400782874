import { createContext, useMemo, useState, useEffect } from "react";
import { applicationsManagement } from "../utils/permissions/applicationAccess";
import { applicationReviewManagement } from "../utils/permissions/reviewAccessManagement";
import { prospectAccess } from "../utils/permissions/prospectsAccessManagement";
import { partnershipManagement } from "../utils/permissions/partnershipAccessManagement";
import { planManagement } from "../utils/permissions/planAccessManagement";
import { portfolioManagement } from "../utils/permissions/portfolioAccessManagement";
import { transactionManagement } from "../utils/permissions/transactionAccessManagement";
import { organizationAccessManagement } from "../utils/permissions/organizationAccessManagement";
import { teamManagement } from "../utils/permissions/teamMemberAccessManagement";
import { roleManagement } from "../utils/permissions/roleAccessManagement";
import { propertyManagement } from "../utils/permissions/properties";

const PermissionContext = createContext({});

export const getUser = () => JSON.parse(sessionStorage.getItem("user"));
const userData = getUser();
const userAccess = userData && userData.user?.applicationRole?.access ? JSON.parse(userData.user?.applicationRole?.access) : [];

// PROPERTY ACCESS CONTROL MANAGMENT

// APPLICATION ACCESS MANAGEMENT

// VIEWING REQUESTS MANAGEMENT

//  PROSPECTS MANAGEMENT

// PARTNERSHIP MANAGEMENT

// PLAN MANAGEMENT

// "PORTFOLIO MANAGEMENT"

// TRANSACTION MANAGEMENT

// ORGANIZATION MANAGEMENT

// TEAM MEMBER MANAGEMENT

// ROLE MANAGEMENT

export const AuthPermissonProvider = ({ children }) => {
  const [access, setAccess] = useState(userAccess);
  const [accessHouseViewing, setAccessHouseViewing] = useState(
    propertyManagement(userAccess)?.viewHouseAccess
  );
  const [accessCreateHouse, setAccessCreateHouse] = useState(
    propertyManagement(userAccess)?.createHouseAccess
  );
  const [accessUpdateHouse, setAccessUpdateHouse] = useState(
    propertyManagement(userAccess)?.updateHouseAccess
  );
  const [accessDeleteHouse, setAccessDeleteHouse] = useState(
    propertyManagement(userAccess)?.deleteHouseAccess
  );
  const [accessEstateViewing, setAccessEstateViewing] = useState(
    propertyManagement(userAccess)?.viewEstateAccess
  );
  const [accessCreateEstate, setAccessCreateEstate] = useState(
    propertyManagement(userAccess)?.createEstateAccess
  );
  const [accessUpdateEstate, setAccessUpdateEstate] = useState(
    propertyManagement(userAccess)?.updateEstateAccess
  );
  const [accessDeleteEstate, setAccessDeleteEstate] = useState(
    propertyManagement(userAccess)?.deleteEstateAccess
  );

  // APPLICATION ACCESS MANAGEMENT
  const [accessViewApplication, setAccessViewApplication] = useState(
    applicationsManagement(userAccess)?.viewApplicationAccess
  );
  const [
    accessValidateApplicationDocument,
    setAccessValidateApplicationDocument,
  ] = useState(
    applicationsManagement(userAccess)?.validateApplicationDocumentAccess
  );
  const [accessReviseApplicationDocument, setAccessReviseApplicationDocument] =
    useState(
      applicationsManagement(userAccess)?.reviseApplicationDocumentAccess
    );
  const [
    accessInvalidateApplicationDocument,
    setAccessInvalidateApplicationDocument,
  ] = useState(
    applicationsManagement(userAccess)?.invalidateApplicationDocumentAccess
  );
  const [accessSendApplicationForReview, setAccessSendApplicationForReview] =
    useState(
      applicationsManagement(userAccess)?.sendApplicationForReviewAccess
    );
  const [accessViewApplicationsForReview, setAccessViewApplicationsForReview] =
    useState(
      applicationsManagement(userAccess)?.viewApplicationsForReviewAccess
    );
  const [
    accessAcceptApplicationForReview,
    setAccessAcceptApplicationForReview,
  ] = useState(
    applicationsManagement(userAccess)?.acceptApplicationForReviewAccess
  );
  const [
    accessRejectApplicationForReview,
    setAccessRejectApplicationForReview,
  ] = useState(
    applicationsManagement(userAccess)?.rejectApplicationForReviewAccess
  );

  // VIEWING REQUESTS MANAGEMENT
  const [accessViewViewingRequests, setAccessViewViewingRequests] = useState(
    applicationReviewManagement(userAccess)?.viewViewingRequestsAccess
  );
  const [
    accessSendViewingRequestReminder,
    setAccessSendViewingRequestReminder,
  ] = useState(
    applicationReviewManagement(userAccess)?.sendViewingRequestReminderAccess
  );

  //  PROSPECTS MANAGEMENT
  const [accessViewProspects, setAccessViewProspects] = useState(
    prospectAccess(userAccess)?.viewProspectsAccess
  );
  const [accessSendProspectReminder, setAccessSendProspectReminder] = useState(
    prospectAccess(userAccess)?.sendProspectReminderAccess
  );

  // PARTNERSHIP MANAGEMENT
  const [accessViewPartners, setAccessViewPartners] = useState(
    partnershipManagement(userAccess)?.viewPartnersAccess
  );
  const [accessInvitePartner, setAccessInvitePartner] = useState(
    partnershipManagement(userAccess)?.invitePartnerAccess
  );
  const [accessAddPartner, setAccessAddPartner] = useState(
    partnershipManagement(userAccess)?.addPartnerAccess
  );
  const [accessAcceptPartnerRequest, setAccessAcceptPartnerRequest] = useState(
    partnershipManagement(userAccess)?.acceptPartnerRequestAccess
  );
  const [accessDeclinePartnerRequest, setAccessDeclinePartnerRequest] =
    useState(partnershipManagement(userAccess)?.declinePartnerRequestAccess);
  const [accessRevokePartner, setAccessRevokePartner] = useState(
    partnershipManagement(userAccess)?.revokePartnerAccess
  );
  const [accessDeletePartner, setAccessDeletePartner] = useState(
    partnershipManagement(userAccess)?.deletePartnerAccess
  );

  // PLAN MANAGEMENT
  const [accessViewPlans, setAccessViewPlans] = useState(
    planManagement(userAccess)?.viewPlansAccess
  );
  const [accessCreatePlan, setAccessCreatePlan] = useState(
    planManagement(userAccess)?.createPlanAccess
  );
  const [accessUpdatePlan, setAccessUpdatePlan] = useState(
    planManagement(userAccess)?.updatePlanAccess
  );
  const [accessDeletePlan, setAccessDeletePlan] = useState(
    planManagement(userAccess)?.deletePlanAccess
  );

  // PORTFOLIO MANAGEMENT"
  const [accessViewPortfolios, setAccessViewPortfolios] = useState(
    portfolioManagement(userAccess)?.viewPortfolionsAccess
  );

  // TRANSACTION MANAGEMENT
  const [accessViewTransaction, setAccessViewTransaction] = useState(
    transactionManagement(userAccess)?.viewTransactionAccess
  );
  const [accessViewTransactionReciept, setAccessViewTransactionReciept] =
    useState(transactionManagement(userAccess)?.viewTransactionRecieptAccess);
  const [accessDownloadTransactionReciept, setDownloadTransactionReciept] =
    useState(
      transactionManagement(userAccess)?.downloadTransactionRecieptAccess
    );
  const [accessViewRepaymentSchedule, setAccessViewRepaymentSchedule] =
    useState(transactionManagement(userAccess)?.viewRepaymentScheduleAccess);
  const [accessRemindCustomer, setAccessRemindCustomer] = useState(
    transactionManagement(userAccess)?.remindCustomerAccess
  );

  // ORGANIZATION MANAGEMENT
  const [accessViewOrganizationProfile, setAccessViewOrganizationProfile] =
    useState(
      organizationAccessManagement(userAccess)?.viewOrganizationProfileAccess
    );
  const [accessUpdateOrganizationProfile, setAccessUpdateOrganizationProfile] =
    useState(
      organizationAccessManagement(userAccess)?.updateOrganizationProfileAccess
    );
  const [accessViewOrganizationDocuments, setAccessViewOrganizationDocuments] =
    useState(
      organizationAccessManagement(userAccess)?.viewOrganizationDocumentsAccess
    );
  const [accessAddOrganizationDocument, setAccessAddOrganizationDocument] =
    useState(
      organizationAccessManagement(userAccess)?.addOrganizationDocumentAccess
    );

  // TEAM MEMBER MANAGEMENT
  const [accessViewTeamMember, setAccessViewTeamMember] = useState(
    teamManagement(userAccess)?.viewTeamMemberAccess
  );
  const [accessCreateTeamMember, setAccessCreateTeamMember] = useState(
    teamManagement(userAccess)?.createTeamMemberAccess
  );
  const [accessUpdateTeamMember, setAccessUpdateTeamMember] = useState(
    teamManagement(userAccess)?.updateTeamMemberAccess
  );
  const [accessDeleteTeamMember, setAccessDeleteTeamMember] = useState(
    teamManagement(userAccess)?.deleteTeamMemberAccess
  );

  // ROLE MANAGEMENT
  const [accessViewRole, setAccessViewRole] = useState(
    roleManagement(userAccess)?.viewRoleAccess
  );
  const [accessCreateRole, setAccessCreateRole] = useState(
    roleManagement(userAccess)?.createRoleAccess
  );
  const [accessUpdateRole, setAccessUpdateRole] = useState(
    roleManagement(userAccess)?.updateRoleAccess
  );
  const [accessDeleteRole, setAccessDeleteRole] = useState(
    roleManagement(userAccess)?.deleteRoleAccess
  );

  const values = useMemo(
    () => ({
      access,
      setAccess,
      accessHouseViewing,
      setAccessHouseViewing,
      accessCreateHouse,
      setAccessCreateHouse,
      accessUpdateHouse,
      setAccessUpdateHouse,
      accessDeleteHouse,
      setAccessDeleteHouse,
      accessEstateViewing,
      setAccessEstateViewing,
      accessCreateEstate,
      setAccessCreateEstate,
      accessUpdateEstate,
      setAccessUpdateEstate,
      accessDeleteEstate,
      setAccessDeleteEstate,

      // APPLICATION ACCESS MANAGEMENT
      accessViewApplication,
      setAccessViewApplication,
      accessValidateApplicationDocument,
      setAccessValidateApplicationDocument,
      accessReviseApplicationDocument,
      setAccessReviseApplicationDocument,
      accessInvalidateApplicationDocument,
      setAccessInvalidateApplicationDocument,
      accessSendApplicationForReview,
      setAccessSendApplicationForReview,
      accessViewApplicationsForReview,
      setAccessViewApplicationsForReview,
      accessAcceptApplicationForReview,
      setAccessAcceptApplicationForReview,
      accessRejectApplicationForReview,
      setAccessRejectApplicationForReview,

      // VIEWING REQUESTS MANAGEMENT
      accessViewViewingRequests,
      setAccessViewViewingRequests,
      accessSendViewingRequestReminder,
      setAccessSendViewingRequestReminder,

      //  PROSPECTS MANAGEMENT
      accessViewProspects,
      setAccessViewProspects,
      accessSendProspectReminder,
      setAccessSendProspectReminder,

      // PARTNERSHIP MANAGEMENT
      accessViewPartners,
      setAccessViewPartners,
      accessInvitePartner,
      setAccessInvitePartner,
      accessAddPartner,
      setAccessAddPartner,
      accessAcceptPartnerRequest,
      setAccessAcceptPartnerRequest,
      accessDeclinePartnerRequest,
      setAccessDeclinePartnerRequest,
      accessRevokePartner,
      setAccessRevokePartner,
      accessDeletePartner,
      setAccessDeletePartner,

      // PLAN MANAGEMENT
      accessViewPlans,
      setAccessViewPlans,
      accessCreatePlan,
      setAccessCreatePlan,
      accessUpdatePlan,
      setAccessUpdatePlan,
      accessDeletePlan,
      setAccessDeletePlan,

      // PORTFOLIO MANAGEMENT
      accessViewPortfolios,
      setAccessViewPortfolios,

      // TRANSACTION MANAGEMENT
      accessViewTransaction,
      setAccessViewTransaction,
      accessViewTransactionReciept,
      setAccessViewTransactionReciept,
      accessDownloadTransactionReciept,
      setDownloadTransactionReciept,
      accessViewRepaymentSchedule,
      setAccessViewRepaymentSchedule,
      accessRemindCustomer,
      setAccessRemindCustomer,

      // ORGANIZATION MANAGEMENT
      accessViewOrganizationProfile,
      setAccessViewOrganizationProfile,
      accessUpdateOrganizationProfile,
      setAccessUpdateOrganizationProfile,
      accessViewOrganizationDocuments,
      setAccessViewOrganizationDocuments,
      accessAddOrganizationDocument,
      setAccessAddOrganizationDocument,

      // TEAM MEMBER MANAGEMENT
      accessViewTeamMember,
      setAccessViewTeamMember,
      accessCreateTeamMember,
      setAccessCreateTeamMember,
      accessUpdateTeamMember,
      setAccessUpdateTeamMember,
      accessDeleteTeamMember,
      setAccessDeleteTeamMember,

      // ROLE MANAGEMENT
      accessViewRole,
      setAccessViewRole,
      accessCreateRole,
      setAccessCreateRole,
      accessUpdateRole,
      setAccessUpdateRole,
      accessDeleteRole,
      setAccessDeleteRole,
    }),
    [
      access,
      setAccess,
      accessHouseViewing,
      setAccessHouseViewing,
      accessCreateHouse,
      setAccessCreateHouse,
      accessUpdateHouse,
      setAccessUpdateHouse,
      accessDeleteHouse,
      setAccessDeleteHouse,
      accessEstateViewing,
      setAccessEstateViewing,
      accessCreateEstate,
      setAccessCreateEstate,
      accessUpdateEstate,
      setAccessUpdateEstate,
      accessDeleteEstate,
      setAccessDeleteEstate,

      // APPLICATION ACCESS MANAGEMENT
      accessViewApplication,
      setAccessViewApplication,
      accessValidateApplicationDocument,
      setAccessValidateApplicationDocument,
      accessReviseApplicationDocument,
      setAccessReviseApplicationDocument,
      accessInvalidateApplicationDocument,
      setAccessInvalidateApplicationDocument,
      accessSendApplicationForReview,
      setAccessSendApplicationForReview,
      accessViewApplicationsForReview,
      setAccessViewApplicationsForReview,
      accessAcceptApplicationForReview,
      setAccessAcceptApplicationForReview,
      accessRejectApplicationForReview,
      setAccessRejectApplicationForReview,

      // VIEWING REQUESTS MANAGEMENT
      accessViewViewingRequests,
      setAccessViewViewingRequests,
      accessSendViewingRequestReminder,
      setAccessSendViewingRequestReminder,

      // PROSPECTS MANAGEMENT
      accessViewProspects,
      setAccessViewProspects,
      accessSendProspectReminder,
      setAccessSendProspectReminder,

      // PARTNERSHIP MANAGEMENT
      accessViewPartners,
      setAccessViewPartners,
      accessInvitePartner,
      setAccessInvitePartner,
      accessAddPartner,
      setAccessAddPartner,
      accessAcceptPartnerRequest,
      setAccessAcceptPartnerRequest,
      accessDeclinePartnerRequest,
      setAccessDeclinePartnerRequest,
      accessRevokePartner,
      setAccessRevokePartner,
      accessDeletePartner,
      setAccessDeletePartner,

      // PLAN MANAGEMENT
      accessViewPlans,
      setAccessViewPlans,
      accessCreatePlan,
      setAccessCreatePlan,
      accessUpdatePlan,
      setAccessUpdatePlan,
      accessDeletePlan,
      setAccessDeletePlan,

      // PORTFOLIO MANAGEMENT
      accessViewPortfolios,
      setAccessViewPortfolios,

      // TRANSACTION MANAGEMENT
      accessViewTransaction,
      setAccessViewTransaction,
      accessViewTransactionReciept,
      setAccessViewTransactionReciept,
      accessDownloadTransactionReciept,
      setDownloadTransactionReciept,
      accessViewRepaymentSchedule,
      setAccessViewRepaymentSchedule,
      accessRemindCustomer,
      setAccessRemindCustomer,

      // ORGANIZATION MANAGEMENT
      accessViewOrganizationProfile,
      setAccessViewOrganizationProfile,
      accessUpdateOrganizationProfile,
      setAccessUpdateOrganizationProfile,
      accessViewOrganizationDocuments,
      setAccessViewOrganizationDocuments,
      accessAddOrganizationDocument,
      setAccessAddOrganizationDocument,

      // TEAM MEMBER MANAGEMENT
      accessViewTeamMember,
      setAccessViewTeamMember,
      accessCreateTeamMember,
      setAccessCreateTeamMember,
      accessUpdateTeamMember,
      setAccessUpdateTeamMember,
      accessDeleteTeamMember,
      setAccessDeleteTeamMember,

      // ROLE MANAGEMENT
      accessViewRole,
      setAccessViewRole,
      accessCreateRole,
      setAccessCreateRole,
      accessUpdateRole,
      setAccessUpdateRole,
      accessDeleteRole,
      setAccessDeleteRole,
    ]
  );

  return (
    <PermissionContext.Provider value={values}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionContext;
