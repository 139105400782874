import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../Components/Common/Button"
import {
  colors,
  convertToTitleCase,
  formattedPrice,
  isNullOrEmpty,
  onlyNumbers,
} from "../../../../utils/constants";
import { useMutation, useQuery } from "@tanstack/react-query";
import "../Estate/createEstate.css";
import FormInput from "../../../../Components/Common/FormInput";
import DynamicForm from "../../../../Components/Common/DynamicForm";
import Icons from "../../../../Components/Common/Icons";
import { toast, ToastContainer } from "react-toastify";
import { debounce } from "lodash";
import Dropdown from "../../../../Components/Common/Dropdown";
import useAuth from "../../../../hooks/useAuth"
import { useNavigate } from "react-router-dom";
import { useNav } from "../../../../hooks/useNav";
import useCity from "../../../../hooks/useCity";
import useAllStates from "../../../../hooks/useAllStates";
import useCreateEstate from "./hooks/useCreateEstate";
import useUploadImages from "./hooks/useUploadImages";
import useSaveSingleImage from "../../../../hooks/useSaveSingleImage"
import FooterButtons from "../../../../Components/Common/FooterButtons";
import { useForm } from "react-hook-form";
import Modal from "../../../../Components/Common/Modal";
import "react-toastify/dist/ReactToastify.css";
import { fileExtension, getBase64Image } from "../../../../utils/imageUtils";
import { estateStatus, imageTypes, yes } from "../../../../utils/mockData";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import MultitextInput from "../../../../Components/Common/MultitextInput";
import FormAddressInput from "../../../../Components/Common/FormAddressInput";
import CheckInput from "../../../../Components/Common/CheckInput";

const CreateEstate = () => {
  const { authUser } = useAuth();

  useNav({
    hasBackButton: true,
    mainTitle: "Create an Estate",
  });

  const { postRequest, getRequest } = useAxiosBase();

  // const { createEstate, isLoading } = useCreateEstate();
  const [responseID, setResponseID] = useState();
  const { uploadingEstateImages, isLoading } = useUploadImages(responseID);

  const [cityId, setCityId] = useState();
  const [cityError, setCityError] = useState("");
  const [dynamicForm, setDynamicForm] = useState([]);
  const [footerFixedPosition, setFooterFixedPosition] = useState(true);
  // const estate_image_ref = useRef();
  const [imagesToDisplay, setImagesToDisplay] = useState([]);
  const [imageObject, setImageObject] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [editableImageName, setEditableImageName] = useState("");
  const [editableImageType, setEditableImageType] = useState(imageTypes[0]?.id);
  const [completionStatus, setCompletionStatus] = useState();
  const [address, setAddress] = useState(null);

  const navigate = useNavigate();

  const saveImageName = debounce(() => {
    if (!editableImageName.trim()) {
      toast.error("Please input a valid property name.");
      return;
    }

    if (!editableImageType.trim()) {
      toast.error("Please select a valid image type.");
      return;
    }

    if (selectedImage) {
      // Map through the imagesToDisplay array to update the name and type of the selected image
      const updatedImages = imagesToDisplay.map((img) =>
        img.name === selectedImage.name
          ? { ...img, name: editableImageName, type: editableImageType }
          : img
      );
      const updatedImageObject = imageObject.map((img) =>
        img.name === selectedImage.name
          ? { ...img, name: editableImageName, type: editableImageType }
          : img
      );

      setImagesToDisplay(updatedImages);
      setImageObject(updatedImageObject);

      setShowImageModal(false);
      setSelectedImage(null); // Reset selected image
      setEditableImageName(""); // Reset editable name
      setEditableImageType(imageTypes[0]?.id); // Reset editable type
    }
  }, 300);

  const handleChange = async (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (files.length !== 0 && files[0].length !== 0) {
      for (let file of files) {
        // Check if file is of allowed type
        if (!allowedTypes.includes(file.type)) {
          return toast.error("Only .png, .jpeg, and .jpg files are allowed.");
        }

        if (file.size > 2 * 1024 * 1024) {
          return toast.error("Image size shouldn't be more than 2MB.");
        }

        // Check if an image with the same name already exists
        if (imagesToDisplay.some((img) => img.name === file.name)) {
          return toast.error(
            `An image with the name ${file.name} already exists.`
          );
        }

        const displayImg = URL.createObjectURL(file);
        setShowImageModal(true);
        setSelectedImage({ image: displayImg, name: file.name });
        setEditableImageName(file.name);
        setImagesToDisplay((prevImages) => [
          { image: displayImg, name: file.name },
          ...prevImages,
        ]);
        URL.revokeObjectURL(file);
        const base64 = await getBase64Image(file);
        const newImage = {
          base64: base64,
          name: file.name,
          optionId: "ESTATE_IMAGE",
          description: "",
          extension: fileExtension(file),
        };
        setImageObject((prevImages) => [newImage, ...prevImages]);
      }
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setEditableImageName(image.name);
    setEditableImageType(image.type);
    setShowImageModal(true);
  };

  const [stateId, setStateId] = useState();

  const { data: states } = useAllStates({
    onSuccess: (data) => {
      const sortedStates = data?.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      setStateId(sortedStates[0]?.id);
      refetch();
    },
  });

  const {
    refetch,
    data: city,
    isLoading: isCityLoading,
  } = useCity({ stateId });

  const createEstate = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: "/bank/estate/create",
        data: data,
      }).then((res) => {
        if ([400, 401, 413].includes(res?.response?.data?.statusCode)) {
          toast.error(res?.response?.data?.value?.message);
          return;
        }
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
          setResponseID(res?.data?.value?.value?.id);
        }
        return res.data;
      }),
  });

  const { register, handleSubmit, setValue, reset } = useForm();
  const onSubmit = (data) => {
    if (imageObject?.length <= 0) {
      toast.error("Please select an image");
      return;
    }

    const selectedFeatures = features
      .filter((feature) => feature.checked)
      .reduce((acc, feature) => {
        acc[feature.name] = true;
        return acc;
      }, {});

    data.cityId = cityId;
    // data.address = address;
    data.ownerType = "BANK";
    data.feature = selectedFeatures ?? null;
    data.completionStatus = completionStatus ?? null;
    data.organizationId = authUser?.user?.organizationId;
    data.landSize = data.landSize
      ? parseFloat(data.landSize.toString().replace(/[^0-9.-]+/g, ""))
      : 0;
    data.floors = data.floors
      ? parseFloat(data.floors.toString().replace(/[^0-9.-]+/g, ""))
      : 0;

    if (completionStatus != "UNDER_CONSTRUCTION") {
      delete data.completionDate;
    }

    // console.log("sumbitData", data);
    createEstate.mutate(data);
  };

  const handleImageUploadFunction = () => {
    if (responseID) {
      imageObject.map((image) => {
        uploadingEstateImages.mutate(image);
      });

      navigate(`/properties/my-properties/create-house/${responseID}`);
    }
  };
  useEffect(() => {
    if (responseID) {
      handleImageUploadFunction();
    }
  }, [responseID]);

  //////////////////////////////////////////////////////////////////////////////////////////
  const handleAddDynamicForm = () => {
    if (dynamicForm.length && dynamicForm[dynamicForm.length - 1].name === "") {
      toast.error(`Please add name for Feature Name ${dynamicForm.length}!`);
      return;
    }
    if (dynamicForm.length && dynamicForm[dynamicForm.length - 1].icon === "") {
      toast.error(`Please add an Icon for Feature Icon ${dynamicForm.length}!`);
      return;
    }
    let newForm = { name: "", icon: "" };
    setDynamicForm([...dynamicForm, newForm]);
  };

  const handleRemoveFeature = (index) => {
    let data = [...dynamicForm];
    let newData = data.filter((x, idx) => idx !== index);
    setDynamicForm(newData);
  };

  const handleChangeEvent = (name, value, index) => {
    let dynamicInputData = [...dynamicForm];
    dynamicInputData[index][name] = value;
    setDynamicForm(dynamicInputData);
  };

  useEffect(() => {
    if (dynamicForm.length > 2 && footerFixedPosition) {
      setFooterFixedPosition(false);
    }
    if (dynamicForm?.length === 2 && !footerFixedPosition)
      setFooterFixedPosition(true);
  }, [dynamicForm, footerFixedPosition]);

  const hiddenFileInputRef = useRef(null);
  const handleClick = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    } else {
      console.error("The file input is not yet available.");
    }
  };

  const handleUploadIconClick = () => {
    hiddenFileInputRef.current.click();
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const openDeleteModal = (image) => {
    setImageToDelete(image);
    setShowDeleteModal(true);
  };

  // Function to delete an image
  const handleDelete = () => {
    if (imageToDelete) {
      setImagesToDisplay((prevImages) =>
        prevImages.filter((img) => img !== imageToDelete)
      );

      setImageObject((prevImages) =>
        prevImages.filter((img) => img.name !== imageToDelete.name)
      );
    }
    setShowDeleteModal(false);
  };

  const [allChecked, setAllChecked] = useState(false);
  const [features, setFeatures] = useState([
    {
      name: "hasAmpleParkingSpace",
      checked: false,
      label: "Ample Parking Space",
    },
    {
      name: "hasUniformSecurity",
      checked: false,
      label: "Uniformed Security",
    },
    {
      name: "hasCCTVSurveillanceSystem",
      checked: false,
      label: "CCTV Surveillance System",
    },
    { name: "hasInverter", checked: false, label: "Inverter" },
    {
      name: "has24HoursElectricity",
      checked: false,
      label: "24/7 Electricity",
    },
    {
      name: "hasCinema",
      checked: false,
      label: "Cinema",
    },
    {
      name: "hasBank",
      checked: false,
      label: "Bank",
    },
    { name: "hasInternetServices", checked: false, label: "Internet Services" },
    { name: "hasFiberOptics", checked: false, label: "Fiber Optics" },
    {
      name: "hasReliableWaterSupply",
      checked: false,
      label: "Reliable Water Supply",
    },
    {
      name: "hasChildernPlayground",
      checked: false,
      label: "Children Playground",
    },
    {
      name: "hasEquestrainOrPoloCenter",
      checked: false,
      label: "Equestrian/Polo Centers",
    },
    { name: "hasTennisCourt", checked: false, label: "Tennis Court" },
    { name: "hasGolfCourt", checked: false, label: "Golf Court" },
    { name: "hasLoungeOrBar", checked: false, label: "Lounge/Bar" },
    { name: "hasResturant", checked: false, label: "Resturant" },
    { name: "hasLakesOrPonds", checked: false, label: "Lakes/Ponds" },
    { name: "hasGazebos", checked: false, label: "Gazebos" },
    {
      name: "hasChildcareFacilities",
      checked: false,
      label: "Childcare Facilities",
    },
    { name: "hasSchool", checked: false, label: "School" },
    { name: "hasHospital", checked: false, label: "Hospital" },
    { name: "hasShoppingComplex", checked: false, label: "Shopping Complex" },
    { name: "hasChurchOrMosque", checked: false, label: "Church/Mosque" },
    {
      name: "hasGreeneryAndOpenGardens",
      checked: false,
      label: "Greenery & Open Gardens",
    },
    { name: "hasGym", checked: false, label: "Gym" },
    { name: "hasBasketballCourt", checked: false, label: "Basketball Court" },
    { name: "hasFootballPitch", checked: false, label: "Football Pitch" },
    { name: "hasSwimmingPool", checked: false, label: "Swimming Pool" },
    { name: "hasClubHouse", checked: false, label: "Clubhouse" },
  ]);

  const handleSpecificationClick = (specName) => {
    setFeatures((prevSpecs) =>
      prevSpecs?.map((spec) =>
        spec.name === specName ? { ...spec, checked: !spec.checked } : spec
      )
    );
  };

  const handleToggleAll = () => {
    const newCheckedStatus = !allChecked;
    const updatedfeatures = features.map((spec) => ({
      ...spec,
      checked: newCheckedStatus,
    }));
    setFeatures(updatedfeatures);
    setAllChecked(newCheckedStatus);
  };

  return (
    <div className="ps-2">
      <form
        action=""
        className="form-group g-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="">
          {/* Main Images Display Section */}
          <div className="row">
            <div className="col">
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                  height: "248px",
                  borderRadius: "8px",
                  border: "1px solid #335f32",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <span className="plus-icon d-flex justify-content-center align-items-center">
                  <Icons iconName={"plus"} />
                </span>
                <p className="fw-bold text-success uppercase mt-3">
                  ADD PROPERTY IMAGES
                </p>
              </div>
            </div>

            {/* Display First Three Images */}
            {imagesToDisplay?.slice(0, 3).map((image) => (
              <div
                className="col house_image_wrapper position-relative"
                key={image.name}
                style={{
                  backgroundImage: `url(${image.image})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="img_overlay position-absolute"></div>
                <div onClick={() => handleImageClick(image)}>
                  <p className="img_open">
                    <span className="img_open-icon">
                      <Icons iconName={"open"} className="" />
                    </span>
                    Open
                  </p>
                </div>
                <div className="img_footer position-absolute">
                  <div className="mt-4">
                    <div>
                      <p className="img_name m-0">{image.name}</p>
                    </div>
                    <p className="img_type mt-2">
                      {convertToTitleCase(image.type)}
                    </p>
                  </div>
                  <div className="img_delete_icon">
                    <Button
                      btnText={<Icons iconName={"delete"} />}
                      bordercolor={colors.black}
                      borderHover={colors.red}
                      backgroundcolor={colors.white}
                      backgroundHover={colors.dangerLight}
                      colorHover={colors.danger}
                      color={colors.danger}
                      iconcolorHover={colors.danger}
                      padding={"0"}
                      bordersize={2}
                      btntextcolor={colors.red}
                      width={"40px"}
                      height={"43px"}
                      type={"button"}
                      onBtnClick={() => openDeleteModal(image)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Thumbnails Section */}
        <div className="col h-25 thumbs-wrapper mt-3 mb-4">
          {imagesToDisplay.length > 3 &&
            imagesToDisplay.slice(3).map((image, index) => (
              <div className="thumb position-relative d-flex flex-column me-2">
                <div
                  className="position-relative w-full h-full thumb_img"
                  style={{
                    backgroundImage: `url(${image.image})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="thumb_img_overlay position-absolute"></div>
                  <p
                    className="thumb_open"
                    onClick={() => handleImageClick(image)}
                  >
                    <span className="thumb_open_icon">
                      <Icons iconName={"open"} className="" />
                    </span>
                    Open
                  </p>
                </div>
                <p className="delete_icon_thumbs">
                  <Button
                    btnText={<Icons iconName={"delete"} />}
                    bordercolor={colors.black}
                    borderHover={colors.red}
                    backgroundcolor={colors.white}
                    backgroundHover={colors.dangerLight}
                    colorHover={colors.danger}
                    color={colors.danger}
                    iconcolorHover={colors.danger}
                    padding={"0"}
                    bordersize={2}
                    btntextcolor={colors.red}
                    iconWidth={"15px"}
                    width={"25px"}
                    height={"29px"}
                    type={"button"}
                    onBtnClick={() => openDeleteModal(image)}
                  />
                </p>
                <p
                  className="my-0 text-success"
                  title={image.name}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  {image.name}
                </p>
              </div>
            ))}
        </div>

        {/* File Input */}
        <input
          type="file"
          className="d-none"
          ref={hiddenFileInputRef}
          accept="image/*"
          onChange={handleChange}
          multiple
        />

        <div className="row input-group">
          <div className="col-md-4 col-sm-12 p-2">
            <FormInput
              labelName="Estate Name"
              required={true}
              type="text"
              placeholder="Estate name"
              name="name"
              register={register("name")}
            />
          </div>
          <div className="col-md-4 p-2">
            <Dropdown
              labelName={"State"}
              required
              placeholder={"Select"}
              options={states}
              onChange={(e) => {
                setStateId(e.target.value);
                refetch();
              }}
            />
          </div>
          <div className="col-md-4 p-2">
            <Dropdown
              labelName={"City"}
              required
              placeholder={"Select"}
              options={city}
              onChange={(e) => {
                setCityId(e.target.value);
                setCityError("");
              }}
              isLoading={isCityLoading}
              disabled={city?.length < 0}
              error={cityError}
            />
          </div>

          <div className="col-md-4 col-sm-12 p-2">
            <FormInput
              labelName="Address"
              required={true}
              type="text"
              placeholder="Where exactly is this estate located?"
              name="address"
              register={register("address")}
            />
          </div>
          
          {/* <div className="col-md-4 col-sm-12 p-2">
            <FormAddressInput
              labelName="Address"
              required={true}
              placeholder="Where exactly is this estate located?"
              onChange={(value) => setAddress(value)}
            />
          </div> */}

          <div
            className="col-md-4 col-sm-12 p-2"
            onBlur={(e) => {
              const youtubeLink = e.target.value.trim(); // Trim whitespace
              if (!youtubeLink) return; // If the field is empty, do nothing

              const pattern = /watch\?v=([a-zA-Z0-9_-]+)/;
              const match = youtubeLink.match(pattern);
              const embedPattern =
                /https:\/\/www.youtube.com\/embed\/([a-zA-Z0-9_-]+)/;
              const embedMatch = youtubeLink.match(embedPattern);

              if (embedMatch) {
                // Link is already in embed format, no need for further processing
                return;
              }

              if (match) {
                setValue(
                  "videoUrl",
                  `https://www.youtube.com/embed/${match[1]}`
                );
              } else {
                toast.warning(
                  "Invalid YouTube link. Please provide a valid link."
                );
                setValue("videoUrl", "");
              }
            }}
          >
            <FormInput
              labelName={"Video URL"}
              name={"videoUrl"}
              type={"text"}
              placeholder={"Add a YouTube video of the property"}
              register={register("videoUrl")}
            />
          </div>
          <div className="col-md-4 col-sm-12 p-2">
            <FormInput
              labelName="Popular Landmark"
              type="text"
              placeholder="What popular structure is close to this estate?"
              name="landmark"
              register={register("landmark")}
            />
          </div>
          <div
            className="col-md-4 col-sm-12 p-2"
            onBlur={(e) =>
              setValue(
                "landSize",
                !onlyNumbers(e.target.value)
                  ? formattedPrice("")
                  : formattedPrice(e.target.value)
              )
            }
          >
            <FormInput
              labelName="Estate Land Size (In Hectares)"
              placeholder="Enter estate Land Size"
              register={register("landSize")}
              name="landSize"
            />
          </div>
          <div
            className="col-md-4 col-sm-12 p-2"
            onBlur={(e) =>
              setValue(
                "floors",
                !onlyNumbers(e.target.value)
                  ? formattedPrice("")
                  : formattedPrice(e.target.value)
              )
            }
          >
            <FormInput
              labelName="Number Floors"
              placeholder="Enter the number of floors in this Estate"
              register={register("floors")}
              name="floors"
            />
          </div>
          <div className="col-md-4 p-2">
            <Dropdown
              labelName={"Completion Status"}
              placeholder={"Select"}
              required={true}
              options={estateStatus}
              onChange={(e) => setCompletionStatus(e.target.value)}
            />
          </div>
          {completionStatus === "UNDER_CONSTRUCTION" && (
            <div className="col-md-4 p-2">
              <FormInput
                type={"date"}
                required
                labelName={"Completion Date"}
                placeholder={"When will it be completed?"}
                name="completionDate"
                register={register("completionDate")}
              />
            </div>
          )}
          <div className="col-md-12">
            <MultitextInput
              required
              label={"Estate Description"}
              placeholder={"Enter a detailed description of this estate..."}
              name="description"
              register={register("description")}
            />
          </div>

          {dynamicForm &&
            dynamicForm.map((input, index) => (
              <DynamicForm
                key={index}
                firstLabelName={`Feature Name ${index + 1}`}
                secondLabelName={`Feature Icon ${index + 1}`}
                firstFormName={`name`}
                secondFormName={`icon`}
                firstValue={input.name}
                secondValue={input.icon}
                onChangeEvent={(name, value) =>
                  handleChangeEvent(name, value, index)
                }
                index={index}
                handleRemove={() => handleRemoveFeature(index)}
              />
            ))}
        </div>

        <div className="mt-4">
          <h2>Specifications and Amenities</h2>
          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"generalFeat"} />{" "}
              <span className="me-4 ps-2">General Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allChecked ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allChecked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={handleToggleAll}
              />
            </div>
          </div>
          <div className="row ps-2 mt-3">
            {features?.map((spec) => (
              <div
                key={spec.name}
                className="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
              >
                <p className="m-0 fw-bold">{spec.label}</p>
                <CheckInput
                  name={spec.name}
                  checked={spec.checked}
                  checkTick
                  width={"23px"}
                  height={"23px"}
                  bcolor={"#0000001A"}
                  bordercolor={"#CECECE"}
                  onChange={() => handleSpecificationClick(spec.name)}
                />
              </div>
            ))}
          </div>
        </div>

        <div>
          <FooterButtons
            loading={createEstate.isLoading}
            disabled={createEstate.isLoading}
            largeLoader={createEstate.isLoading}
            cancelBtn={() => navigate("/properties")}
            className={`${
              footerFixedPosition ? "position-fixed position-width_" : ""
            }`}
          />
        </div>
      </form>

      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={"Delete Image"}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Delete"
        confirmModal={() => handleDelete()}
        cancelModal={() => setShowDeleteModal(false)}
      >
        <p className="text-center">
          Are you sure you want to delete this image?
        </p>
      </Modal>

      <Modal
        show={showImageModal}
        closeModal={() => setShowImageModal(false)}
        title="Name Uploaded Image"
        confirmButtonLabel="Save"
        crossLine={false}
        confirmModal={saveImageName}
        cancelModal={() => setShowImageModal(false)}
      >
        <div className="text-center mb-3">
          <p>
            This is how <strong>{editableImageName}</strong> will look to
            customers
          </p>
        </div>
        <div className="d-flex justify-content-center mb-3">
          {selectedImage && (
            <div className="position-relative">
              <div
                style={{
                  maxHeight: "20rem",
                  maxWidth: "100%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={selectedImage.image}
                  alt={selectedImage.name}
                  className="img-fluid w-100 h-100"
                />
              </div>

              <div
                className="position-absolute d-flex justify-content-between align-items-center p-1"
                style={{ width: "100%", bottom: "1rem", left: "0.5rem" }}
              >
                <span
                  className="bg-white text-success px-2 py-1 fw-bold"
                  style={{ marginLeft: "10px", borderRadius: "15px" }}
                >
                  {editableImageName}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="mx-3 mt-4 mb-0">
          <Dropdown
            labelName={"Image Type"}
            width={"100%"}
            required
            placeholder={"Select"}
            options={imageTypes}
            value={convertToTitleCase(editableImageType)}
            onChange={(e) => setEditableImageType(e.target.value)}
          />
        </div>
        <div className="mx-3 mt-2 mb-0">
          <FormInput
            required
            labelName={"Image Name (name the part of this house)"}
            value={editableImageName}
            onChange={(name, value) => setEditableImageName(value)}
          />
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default CreateEstate;
