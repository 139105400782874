import { isNullOrEmpty } from "../constants";

export const partnershipManagement = (userAccess) => {
  if (isNullOrEmpty(userAccess)) return null;
  const partnershipManagement =
    userAccess?.find((x) => x.id === "partnership_management")?.rules || [];

  return {
    viewPartnersAccess:
      partnershipManagement?.find((x) => x.id === "view_partners") || {},
    invitePartnerAccess:
      partnershipManagement?.find((x) => x.id === "invite_partner") || {},
    addPartnerAccess:
      partnershipManagement?.find((x) => x.id === "add_partner") || {},
    acceptPartnerRequestAccess:
      partnershipManagement?.find((x) => x.id === "accept_partner_request") ||
      {},
    declinePartnerRequestAccess:
      partnershipManagement?.find((x) => x.id === "decline_partner_request") ||
      {},
    revokePartnerAccess:
      partnershipManagement?.find((x) => x.id === "revoke_partner ") || {},
    deletePartnerAccess:
      partnershipManagement?.find((x) => x.id === "delete_partnership") || {},
  };
};
