import Icons from "../Icons";

const DocumentDownload = ({ name, icon, onClick }) => {
  return (
    <div className="d-flex flex-row">
      <div>
        <Icons iconName={icon} />
      </div>
      <small className="px-3 text-center mt-1">{name}</small>
      <div className="mt-1" role="button" onClick={onClick}>
        <Icons iconName={"documentDownload"} />
      </div>
    </div>
  );
};

export default DocumentDownload;
