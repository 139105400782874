import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../Components/Common/Button";
import Spinner from "../../../../Components/Spinner";
import CenteredText from "../../../../Components/CenteredText";
import { getImage } from "../../../../utils/generalUtils";
import defaultHouse from "../../../../house.svg";
import { Carousel } from "primereact/carousel";
import useGetAcceptedEstates from "./hooks/useGetAcceptedEstates";
import PendingEstateModal from "../Modals/PendingEstateModal";

const EstateCollection = ({ refetchPendingCount, pendingCount }) => {
  const navigate = useNavigate();

  const {
    data: acceptedEstatesCollection,
    error,
    isLoading,
    refetch,
  } = useGetAcceptedEstates();

  const [openEstateModal, setOpenEstateModal] = useState(false);

  const estateCardTemp = (estate) => {
    const image = getImage(estate.images) ?? defaultHouse;
    return (
      <div
        className="card pr-card mx-2"
        onClick={() =>
          navigate(
            `/properties/partnered-developer-properties/estate/${estate?.id}`
          )
        }
      >
        <div className="card-body pr-cr-cd">
          <img src={image} alt={estate?.name} style={{ width: "100%" }} />
        </div>
        <div className="card-footer text-center bg-danger">
          <div className="row pr-title ">
            <h6>{estate?.name}</h6>
          </div>
          <div className="row pr-desc">
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {estate?.address}
            </p>
          </div>
          <div className="row pr-hou">
            <span>
              {estate.houses?.length}{" "}
              {estate.houses?.length > 1 ? "HOUSES" : "HOUSE"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="my-2">
      <div className="d-flex justify-content-between align-items-center">
        <h3>
          Estates - (<span>{acceptedEstatesCollection?.length ?? 0}</span>)
        </h3>
        <div className={`d-flex justify-content-end mb-3 ${pendingCount ? "me-2" : ""}`}>
          <div className="ps-2 position-relative">
            {pendingCount ? (
              <span className="bage pending_count">{pendingCount}</span>
            ) : null}
            <Button
              btnText={"Add Estate"}
              type={"button"}
              onBtnClick={() => setOpenEstateModal(true)}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <div style={{ height: "10rem" }}>
          <Spinner loadingText={"Loading Estates..."} />
        </div>
      ) : acceptedEstatesCollection?.length ? (
        <Carousel
          value={acceptedEstatesCollection}
          numVisible={4}
          numScroll={1}
          itemTemplate={estateCardTemp}
          style={{
            backgroundColor: "#F0F0F0",
            paddingTop: "33px",
            paddingLeft: "10px",
            borderRadius: "10px",
          }}
        />
      ) : (
        <div className="mt-5">
          <CenteredText title="No Partner Estates Added Yet">
            All available estates from developers you partner with will appear
            here. You can click the "Add estates" button to add their estates to
            your various mortgage plans.
          </CenteredText>
        </div>
      )}

      {openEstateModal ? (
        <PendingEstateModal
          pendingCount={pendingCount}
          refetchPendingCount={refetchPendingCount}
          refetchAcceptedEstate={refetch}
          openEstateModal={openEstateModal}
          setOpenEstateModal={setOpenEstateModal}
        />
      ) : null}
    </div>
  );
};

export default EstateCollection;
