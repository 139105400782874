import { styled } from "styled-components";
import { colors } from "../../../../utils/constants";

export const CardWrapper = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
gap: 0.8rem;
 border-radius: 10px;
  min-height: ${(props) => (props.height ? `${props.height} !important` : "9rem")};
  /* width: ${(props) =>  props.width ? `${props.width} !important` : "260px"}; */
  box-shadow: 0px 3px 6px #0000001a !important;
  overflow: hidden; /* Prevent scrolling */

  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.5rem;

  .card_input_check {
    display: flex;
    justify-content: end;
    width: 100%;
  }

  .card_title {
    font-size: 1.2rem;
  }
`;