import React from "react";
import { CheckInputWrapper } from "./style";

const CheckInput = ({
  label,
  required,
  name,
  register,
  borderradius,
  width,
  height,
  onChange,
  checked,
  className,
  bcolor,
  bordercolor,
  checkTick,
  ...rest
}) => {
  return (
    <CheckInputWrapper
      {...rest}
      className={`form-check ${className}`}
      borderradius={borderradius}
      width={width}
      height={height}
      bordercolor={bordercolor}
      checkTick={checkTick}
    >
      <input
        className="form-check-input"
        type="checkbox"
        id={name}
        a
        required={required}
        {...register}
        onChange={(e) => onChange(e)}
        style={{ backgroundColor: bcolor }}
        checked={checked}
      />

      {label && (
        <label className="form-check-label px-2 " htmlFor={name}>
          {label}
        </label>
      )}
    </CheckInputWrapper>
  );
};

export default CheckInput;
