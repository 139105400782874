import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";


const useGetApplications = (page, planId) => {
  const { getRequest } = useAxiosBase();
  const url = `/bank/application/${planId}/get-by-mortgage-plan?pageNumber=${page}`;
  return useQuery({
    queryKey: ["mortgage-appliaction", planId],
    queryFn: () =>
      getRequest({
        url: url,
      }).then((response) => response?.data?.value?.value),
    onError: (res) => {
      if (res?.statusCode === 404) {
        toast.error("Network Error!");
      }
    },
    staleTime: 5000,
  });
};

export default useGetApplications;
