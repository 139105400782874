import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

export const useGetSuccessfulTransactions = (page) => {
    const { getRequest } = useAxiosBase();
  
    return useQuery({
      queryKey: ["succesful-payments"],
      queryFn: () =>
        getRequest({ url: `/bank/transaction/get-succesful-payments?pageNumber=${page}` }).then(
          (res) => res?.data?.value
        ),
      staleTime: 50000,
    });
  };
  