import React from "react";
import { BadgeWrapper } from "./style";

const Badges = ({ text, title, bordercolor, bordersize, color, backgroundcolor }) => {
  return (
    <BadgeWrapper
    style={{ display: "inline-block", whiteSpace: "nowrap", overflow:"hidden", textOverflow:"ellipsis"}}
      color={color}
      bordersize={bordersize}
      bordercolor={bordercolor}
      backgroundcolor={backgroundcolor}
      className="text-center"
      title={title}
    >
      <span className="py-0 px-2 fw-semibold">{text}</span>
    </BadgeWrapper>
  );
};
export default Badges;
