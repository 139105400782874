import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const CardWrapper = styled.div`
  .pp {
    border: 1px solid #f0f0f0;
    border-radius: 10px;
  }

  .pp > .card-header {
    background: #ffffff !important;
    border: none;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  .card-details {
    padding-top: 0 !important;
    margin-top: 0 !important;
    height: 100% !important;
  }

  .pp > .card-header > .info {
    color: gray !important;
    text-align: end !important;
    padding-bottom: 0.5rem !important;
    cursor: pointer;
  }

  .pp > .card-header > .percentage-points {
    margin-right: 5px;
  }

  .percentage-points {
    border-radius: 15px;
    background: ${(props) => (props.positive ? "#0a7e32" : colors.red)};
    color: #ffffff;
    width: 60px;
    height: 25px;
    margin-right: 10px;
  }

  .percentage-points-content {
    padding-left: 15px;
  }

  .percentage-points-content > svg {
    font-size: 12px;
  }

  .percentage-value {
    font-size: 12px;
    font-weight: 700;
  }

  .p-value,
  .p-subvalue {
    font-size: 30px;
    font-weight: bold;
    font-family: "Red Hat Display", sans-serif;
  }
  .p-subvalue {
    color: #707070;
  }

  .pt-titlee {
    font-weight: 600;
    margin-top: 0.5rem !important;
    font-family: "Red Hat Display", sans-serif;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////

  div.card-header,
  div.card-footer {
    background: #ffffff !important;
    border: none;
  }

  .card-header > span {
    color: #000000;
    font-family: "Red Hat Display", sans-serif;
  }

  .pp-p > .card-header {
    background: #ffffff !important;
    border: none;
  }

  height: ${(props) =>props.height ? `${props.height} !important` : "130px"};
  width: ${(props) =>props.width ? `${props.width} !important` : "260px"};
  box-shadow: 0px 3px 6px #0000001A !important;
  overflow: scroll;
  /* min-width: 300px !important; */
`;
