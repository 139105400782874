import React from "react";
import { TextWrapper } from "./style";

const MultitextInput = ({
  label,
  required,
  row,
  column,
  type,
  name,
  value,
  placeholder,
  onChange,
  fontweight,
  register,
  error,
  ...rest
}) => {
  return (
    <TextWrapper {...rest} fontweight={fontweight}>
      <label htmlFor={name} className="form-label ml-4 custom-form-label">
        {label} {required && <span className="required">*</span>}
      </label>
      <textarea
        required={required}
        type={type ?? "text"}
        rows={row ?? "4"}
        cols={column ?? "10"}
        className={`${error && "input-group-error"} form-control textarea`}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        {...register}
      ></textarea>
      {error && <div className="form-error">{error}</div>}
    </TextWrapper>
  );
};

export default MultitextInput;
