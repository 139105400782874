import React, { useState } from "react";
import "./style.css"

const TabGroupTwo = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const handleClick = (e, newActiveTab) => {
    e.preventDefault();
    setActiveTab(newActiveTab);
  };

  return (
    <div className="tabgroup">
      <div className="tabLists mb-3">
        {children.map((tab) => (
          <div
            key={tab.props.label}
            className={tab.props.label === activeTab ? "tabList is-active" : "tabList"}
            onClick={(e) => handleClick(e, tab.props.label)}
          >
            {tab.props.label}
          </div>
        ))}
      </div>
      <div className="tabContent">
        {children.map((one) => {
          if (one.props.label !== activeTab) return undefined;
          return one.props.children;
        })}
      </div>
    </div>
  );
};

export default TabGroupTwo;
