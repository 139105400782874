import React, { useState } from "react";
import { FormWrapper } from "./style";
import Icons from "../Icons";
import { FaRegCircleQuestion } from "react-icons/fa6";

const FormInput = ({
  labelName,
  bottom,
  right,
  left,
  top,
  onChange,
  value,
  register,
  error,
  hintText,
  inputRef,
  onClick,
  onBlur,
  onFocus,
  inputDescription,
  inputDescriptionText,
  ...rest
}) => {
  const [showInputDescription, setShowInputDescription] = useState();
  return (
    <FormWrapper {...rest}>
      {labelName && (
        <label
          htmlFor={rest.name}
          className="form-label ml-4 custom-form-label"
        >
          {labelName} {rest.required && <span className="required">*</span>}
        </label>
      )}
      {inputDescription && (
        <span className="position-relative" style={{ cursor: "pointer" }}>
          {showInputDescription && (
            <div
              className="animated toolTipText"
              style={{
                left: left ? left : "-2rem",
                bottom: bottom ? bottom : "1.5rem",
                right: right,
                top: top,
              }}
            >
              <p className="description-text m-0">{inputDescriptionText}</p>
            </div>
          )}
          <FaRegCircleQuestion
            size="1rem"
            onMouseEnter={() => setShowInputDescription(true)}
            onMouseLeave={() => setShowInputDescription(false)}
          />
        </span>
      )}

      <div className={`${error && "input-group-error"} input-group mb-3`}>
        {rest.lefticonname && (
          <span className="form_icon left-form_icon">
            <Icons iconName={rest.lefticonname} />
          </span>
        )}
        <input
          className="form-control form-control-lg custom-form-lg"
          id={rest.id}
          max={rest.max}
          maxlength={rest.maxlength}
          pattern={rest.pattern}
          min={rest.min}
          type={rest.type}
          ref={inputRef}
          name={rest.name}
          placeholder={rest.placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={(e) => {
            onChange && onChange(rest.name, e.target.value);
            rest.onChange && rest.onChange(rest.name, e.target.value);
          }}
          value={value}
          {...rest}
          {...register}
        />

        {rest.righticonname && (
          <span onClick={onClick} className="form_icon right-form_icon">
            <Icons iconName={rest.righticonname} />
          </span>
        )}
      </div>
      {error ? (
        <div className="form-error">{error}</div>
      ) : (
        hintText && <div className="form-hintText">{hintText}</div>
      )}
    </FormWrapper>
  );
};

export default FormInput;
