import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const FormWrapper = styled.div`
  position: relative;

  .input-group {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => (props.height ? `${props.height}px` : "52px")};
    width: ${(props) => (props.width ? `${props.width} !important` : "")};
    border: ${(props) =>
      props.bordercolor
        ? `2px solid ${props.bordercolor}`
        : "2px solid transparent"};
    /* border: 2px solid transparent; */
    border-radius: 100px;
    background-color: ${(props) =>
      props.backgroundcolor
        ? `${props.backgroundcolor} !important`
        : colors.grey};
    transition: all 0.25s ease;
    overflow: hidden !important;
  }

  .input-group-error {
    border: 2px solid red !important;
    background-color: white;
  }

  .input-group:focus-within:not(.input-group-error) {
    border: 2px solid ${colors.darkGreen} !important;
    outline: none !important;
    background-color: white;
  }

  .input-group:hover:not(.input-group-error) {
    border: 1px solid ${colors.darkGreen};
    background-color: ${(props) =>
      props.hoverBackgroundcolor
        ? `${props.hoverBackgroundcolor} !important`
        : "white"};
  }

  .custom-form-lg:focus::placeholder {
    color: transparent !important;
  }

  .custom-form-lg::placeholder {
    font: normal normal normal 1rem "Red Hat Display";
    /* font-style: italic; */
    font-size: 14px !important;
    letter-spacing: 0.04px;
    color: #919191;
    opacity: 1;
    text-align: left;
  }

  .custom-form-label {
    font-size: 14px;
    font: normal normal bold 13px "Red Hat Display";
    letter-spacing: 0.04px;
    padding: 0 7px 0 8px;
  }

  .custom-form-label > .required {
    color: red;
  }

  .btn-background {
    background: #f0f0f0;
    height: 135px;
  }

  .input-image {
    display: none;
  }

  .form_icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) =>
      props.iconBackgroundcolor ? props.iconBackgroundcolor : colors.grey};
    font-size: ${(props) => (props.iconSize ? `${props.iconSize}px` : "17px")};
    border-radius: 8px;
  }

  .form_icon span > svg {
    /* fill: #4b4b4b !important; */
    height: 1.5rem;
    fill: ${(props) => (props.iconColor ? props.iconColor : "#4b4b4b")};
    stroke-width: 2px;
  }

  .left-form_icon {
    padding: 0 8px 0 16px;
  }

  .right-form_icon {
    background-color: transparent !important;
    padding: 0 16px 0 8px;
    cursor: pointer;
  }

  .form-error {
    position: absolute;
    left: 0;
    bottom: -1.2rem;

    padding: 0 16px 0 8px;
    font-family: "Red Hat Display";
    font-size: 13px;
    font-weight: 500;
    color: ${colors.red};
  }

  .custom-form-lg,
  .custom-form-lg:disabled {
    cursor: not-allowed;
  }

  .custom-form-lg,
  .custom-form-lg:enabled {
    cursor: pointer;
  }

  input:focus,
  .focused {
    outline: none !important;
    // background-color: #f0f0f0 !important;
    // box-shadow: -7px 2px 15px -8px #0a7e32 !important;
    box-shadow: none !important;
  }

  /* ADDRESS AUTO COMPLETE  */

  .addressAutoComplete {
    width: 100%;
    height: 70px;
    border: none;
    font-size: 14px;
    padding-left: 1.5rem;
    padding-bottom: 1.4rem;
    margin-bottom: 0.8rem;
    background-color: transparent;
  }

  .addressAutoComplete::placeholder {
    font: normal normal normal 1rem "Red Hat Display";
    font-size: 14px !important;
    letter-spacing: 0.04px;
    color: #919191;
    opacity: 1;
    text-align: left;
  }

  .addressAutoComplete:focus::placeholder {
    color: transparent !important;
  }

  .addressAutoComplete[disabled] {
    cursor: not-allowed;
    background-color: #f5f5f5;
    color: #a0a0a0;
    pointer-events: none;
  }
`;
