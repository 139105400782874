import React, { useEffect, useRef, useState } from "react";
import "./house.css";
import { useParams } from "react-router-dom";
import { useNav } from "../../../../hooks/useNav";
import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import Spinner from "../../../../Components/Spinner";
import CenteredText from "../../../../Components/CenteredText";
import { getHouseType } from "../constant";
import {
  convertToTitleCase,
  formattedPrice,
} from "../../../../utils/constants";
import Modal from "../../../../Components/Common/Modal";
import Icons from "../../../../Components/Common/Icons";

const House = () => {
  const { getRequest, deleteRequest } = useAxiosBase();
  const params = useParams();
  const houseId = params?.id;

  const defaultImage =
    "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

  // const { data: house, isLoading } = useGetHouse(houseId);
  const [completionStatus, setCompletionStatus] = useState();
  const [completionPercentage, setCompletionPercentage] = useState();
  const [bathrooms, setBathrooms] = useState();
  const [bedrooms, setBedrooms] = useState();
  const [enSuite, setEnSuite] = useState();
  const [squareMeters, setSquareMeters] = useState();
  const [furnished, setFurnished] = useState();
  const [gatedCompound, setGatedCompound] = useState();
  const [guardedCompound, setGuardedCompound] = useState();

  useNav({
    hasBackButton: true,
    leftButtonDelete: true,
  });

  const {
    data: houseDetails,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["house", houseId],
    queryFn: () =>
      getRequest({ url: `/public/house/${houseId}` }).then(
        (res) => res.data.value?.value
      ),
    staleTime: 50000,
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [editableImageName, setEditableImageName] = useState("");
  const [editableImageType, setEditableImageType] = useState("");

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setEditableImageName(image?.name);
    setEditableImageType(image?.type);
    setShowImageModal(true);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    // Set values for Specifications section
    const specificationsValues =
      houseDetails?.features?.reduce((accumulator, feature) => {
        switch (feature.icon) {
          case "bathrooms":
            accumulator.bathrooms = feature?.name + " Baths";
            break;
          case "bedrooms":
            accumulator.bedrooms = feature?.name + " Rooms";
            break;
          case "furnished":
            accumulator.furnished = feature?.name;
            break;
          case "gated_compound_gated_community":
            accumulator.gated_compound = feature?.name;
            break;
          case "sqm":
            accumulator.square_meters = feature?.name.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            );
            break;
          case "ensuite":
            accumulator.ensuite = feature?.name;
            break;
          case "guarded":
            accumulator.guarded_compound = feature?.name;
            break;
          // Add more cases for other specifications as needed
          default:
            break;
        }
        return accumulator;
      }, {}) || {};

    // Set the form field values for Specifications
    setCompletionStatus(houseDetails?.completionStatus);
    setBathrooms(specificationsValues.bathrooms);
    setBedrooms(specificationsValues.bedrooms);
    setFurnished(specificationsValues.furnished);
    setEnSuite(specificationsValues.ensuite);
    setSquareMeters(specificationsValues.square_meters);
    setGatedCompound(specificationsValues.gated_compound);
    setGuardedCompound(specificationsValues.guarded_compound);
  }, [houseDetails]);

  if (isLoading)
    return (
      <div style={{ height: "60vh" }}>
        {" "}
        <Spinner loadingText={"Loading House Details..."} />{" "}
      </div>
    );

  return (
    <div className="px-2">
      <h1 className=" text-uppercase">
        <span className=" text-success">
          {getHouseType(houseDetails?.features) ?? "NOT SPECIFIED"}
        </span>{" "}
        - {houseDetails?.address}
      </h1>

      {/* Display First 4 Images  */}
      <div className="row">
        {houseDetails?.images?.slice(0, 4)?.map((image) => (
          <div
            className="col house_image_wrapper position-relative"
            key={image.name}
            style={{
              height: "248px",
              backgroundImage: `url(${image.document})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="img_overlay position-absolute"></div>
            <div onClick={() => handleImageClick(image)}>
              <p className="img_open">
                <span className="img_open-icon">
                  <Icons iconName={"open"} className="" />
                </span>
                Open
              </p>
            </div>
            <div className="img_footer position-absolute">
              <div className="mt-4">
                {image.type && (
                  <p className="img_type m-0">
                    {convertToTitleCase(image.type)}
                  </p>
                )}
                <div>
                  <p className="img_name mt-2">{image.name}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Thumbnails Section */}
      <div className="col h-25 thumbs-wrapper mt-3 mb-4">
        {houseDetails?.images?.length > 4 &&
          houseDetails?.images?.slice(3).map((image, index) => (
            <div className="thumb position-relative d-flex flex-column ">
              <div
                className="position-relative w-full h-full thumb_img"
                style={{
                  backgroundImage: `url(${image.document})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="thumb_img_overlay position-absolute"></div>
                <p
                  className="thumb_open mt-4"
                  onClick={() => handleImageClick(image)}
                >
                  <span className="thumb_open_icon">
                    <Icons iconName={"open"} className="" />
                  </span>
                  Open
                </p>
              </div>
              <p
                className="my-0 text-success"
                title={image.name}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                }}
              >
                {image?.name}
              </p>
            </div>
          ))}
      </div>

      <div className="row mt-4">
        <div className="col-md-3 mb-4">
          <h6 className="fw-bold"> House Name</h6>
          <p> {houseDetails?.name ?? "---"} </p>
        </div>

        {houseDetails?.type && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> House Type</h6>
            <p> {convertToTitleCase(houseDetails?.type)} </p>
          </div>
        )}

        {houseDetails?.city?.stateId && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> State </h6>
            <p> {convertToTitleCase(houseDetails?.city?.stateId)} </p>
          </div>
        )}

        {houseDetails?.city?.name && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> City </h6>
            <p> {houseDetails?.city?.name} </p>
          </div>
        )}

        {houseDetails?.price && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Price </h6>
            <p> {formattedPrice(houseDetails?.price)}</p>
          </div>
        )}

        {houseDetails?.videoUr && (
          <div className="col-md-3 mb-4" title={houseDetails?.videoUrl}>
            <h6 className="fw-bold"> Video URL </h6>
            <p>
              {houseDetails?.videoUrl?.length > 35
                ? `${houseDetails?.videoUrl?.substring(0, 35)}...`
                : houseDetails?.videoUrl}
            </p>
          </div>
        )}

        {houseDetails?.address && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Address</h6>
            <p> {houseDetails?.address} </p>
          </div>
        )}

        {houseDetails?.landmark && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Popular Landmark</h6>
            <p> {houseDetails?.landmark} </p>
          </div>
        )}

        {houseDetails?.units && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Units Left </h6>
            <p> {houseDetails?.units} </p>
          </div>
        )}

        {houseDetails?.threeDTourLink && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> 3D Tour Link</h6>
            <p> {houseDetails?.threeDTourLink} </p>
          </div>
        )}

        {houseDetails?.virtualTourLink && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Virtual Tour Link </h6>
            <p> {houseDetails?.virtualTourLink} </p>
          </div>
        )}

        {houseDetails?.category && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Category </h6>
            <p> {convertToTitleCase(houseDetails?.category)} </p>
          </div>
        )}

        {houseDetails?.completionStatus && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Completion Status </h6>
            <p> {convertToTitleCase(houseDetails?.completionStatus)} </p>
          </div>
        )}

        {completionStatus === "UNDER_CONSTRUCTION" && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold">Completion Date</h6>
            <p>
              {houseDetails?.completionDate
                ? formatDate(houseDetails.completionDate)
                : "N/A"}
            </p>
          </div>
        )}
        {houseDetails?.landTitleDocument && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Land Title Document </h6>
            <p>{convertToTitleCase(houseDetails?.landTitleDocument)}</p>
          </div>
        )}

        {houseDetails?.ownershipDocument && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Ownership Document </h6>
            <p> {convertToTitleCase(houseDetails?.ownershipDocument)} </p>
          </div>
        )}
      </div>

      <div className="row mt-3">
        {houseDetails?.description && (
          <div className="col-md-4">
            <h6 className="fw-bold"> House Description</h6>
            <p> {houseDetails?.description} </p>
          </div>
        )}

        {houseDetails?.uniqueSellingProposition && (
          <div className="col-md-4">
            <h6 className="fw-bold"> Unique Selling Proposition</h6>
            <p> {houseDetails?.uniqueSellingProposition} </p>
          </div>
        )}

        {houseDetails?.valueAndInvestment && (
          <div className="col-md-4">
            <h6 className="fw-bold"> Value and Investment</h6>
            <p> {houseDetails?.valueAndInvestment ?? "---"} </p>
          </div>
        )}
      </div>

      <div className="row mt-4">
        <h2>Plans</h2>
        <h6 className="fw-bold">Add plans you plan to sell this house under</h6>
        <div className="row mt-4">
          {houseDetails?.houseMortgagePlans?.length > 0 ? (
            houseDetails?.houseMortgagePlans?.map((plan, index) => (
              <div className="col-md-2 mb-3">
                <div
                  className="card-body m-body"
                  key={plan?.mortgagePlan?.id}
                  // onClick={() =>
                  //   handleCheckMortgagePlan(plan?.mortgagePlan?.id, index)
                  // }
                >
                  <div className="m-checkbox">
                    <input
                      className=" rounded-circle form-check-input"
                      type="checkbox"
                      defaultChecked={true}
                      // value={mortgagePlanIds?.some(
                      //   (x) => x === plan?.mortgagePlan?.id
                      // )}
                      // onChange={() => {
                      //   handleCheckMortgagePlan(
                      //     plan?.mortgagePlan?.id,
                      //     index
                      //   );
                      // }}
                      // ref={(ref) => {
                      //   refArray.current[index] = ref;
                      // }}
                    />
                  </div>
                  <div className="mortgage-text">
                    <span>
                      <strong>{plan?.mortgagePlan?.name}</strong>
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="mt-1">
              <CenteredText title={"This property has no plans."}>
                It seems like there are currently no plans associated with this
                property.
              </CenteredText>
            </div>
          )}
        </div>
      </div>

      <h2 className="mt-4">Specifications</h2>
      <div className="row pt-2 mb-4">
        {houseDetails?.features?.length > 0 ? (
          houseDetails.features.map((feature, index) => (
            <div key={index} className="col-md-2 mb-4">
              <h6 className="fw-bold">{convertToTitleCase(feature.icon)}</h6>
              <p>{convertToTitleCase(feature.name)}</p>
            </div>
          ))
        ) : (
          <div>
            <CenteredText title="No Specifications">
              <p>
                There are no Specifications available for this property at the
                moment.
              </p>
            </CenteredText>
          </div>
        )}
      </div>

      <Modal
        show={showImageModal}
        title={
          <p className="fw-normal">
            This is how <strong>{editableImageName}</strong> will look to
            customers
          </p>
        }
        closeModal={() => setShowImageModal(false)}
        cancelButtonLabel={"Cancel"}
        cancelModal={() => setShowImageModal(false)}
      >
        <div className="text-center mb-3"></div>
        <div className="d-flex justify-content-center mb-3">
          {selectedImage && (
            <div className="position-relative">
              <img
                src={selectedImage.document}
                alt={selectedImage.name}
                className="img-fluid"
                style={{
                  maxHeight: "50vh",
                  maxWidth: "100%",
                  borderRadius: "10px",
                }}
              />
              <div
                className="position-absolute d-flex justify-content-between align-items-center p-1"
                style={{ width: "100%", top: "80%", left: 0 }}
              >
                <span
                  className="bg-white text-success py-1 px-2 fw-bold"
                  style={{ marginLeft: "10px", borderRadius: "15px" }}
                >
                  {editableImageName}
                </span>
              </div>
            </div>
          )}
        </div>
        <div>
          <p className="fw-bold m-0" style={{ fontSize: "1rem" }}>
            Image Type
          </p>
          <p>
            {editableImageType
              ? convertToTitleCase(editableImageType)
              : "Actual Image"}
          </p>
        </div>

        <div>
          <p className="fw-bold m-0" style={{ fontSize: "1rem" }}>
            Image Name (name of the part of the house)
          </p>
          <p>{editableImageName}</p>
        </div>
      </Modal>
    </div>
  );
};

export default House;
