import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useDeletePlans = (planId) => {
  const { deleteRequest } = useAxiosBase();

  const deletePlans = useMutation({
    mutationKey: ["delete-plan", planId],
    mutationFn: (data) =>
      deleteRequest({
        url: `bank/mortgage-plan/${planId}/delete`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.value?.statusCode === 200) {
        toast.success(res?.data?.value?.message);
    }
      if ([400, 404, 500].includes(res?.data?.value?.value?.statusCode)) {
        toast.error(res?.data?.value?.value?.message);
      }
    },
    onError: (error) => {
      console.log("ERRROR", error);
    },
  });

  return { deletePlans };
};

export default useDeletePlans;
