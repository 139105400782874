import * as yup from "yup";
const modelShema = yup
  .object({
    email: yup.string().email().required(),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /\d/,
        'Password must contain at least one number'
      )
      .matches(
        /[!@#$%^&*]/,
        'Password must contain at least one special character (!@#$%^&*)'
      )
      .matches(
        /[A-Z]/,
        'Password must contain at least one uppercase letter'
      ),
  }).required();

export default modelShema;
