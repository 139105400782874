import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";


const useEditCompanyDetails = () => {
    const { putRequest } = useAxiosBase();

    const updateSettings = useMutation({
        mutationFn: (data) =>
            putRequest({
                url: `/bank/update-profile`,
                data: data,
            }).then((res) => {
                if (res?.data?.value?.statusCode === 200) {
                    toast.success("Updated Succefully!");
                }
                if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
                    toast.error(res?.data?.value?.message);
                }
            }),
        onSuccess: (res, res1) => {
            if (res?.data?.value?.statusCode === 200) {
                toast.success("Updated Succefully!");
            }
            if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
                toast.error(res?.data?.value?.message);
            }
        },
        onError: (error) => {
            console.log("ERRROR", error);
        },
    });

    return { updateSettings };
}

export default useEditCompanyDetails;
