import { createContext, useState } from "react";

const NavContext = createContext({});

export const NavContextProvider = ({ children }) => {
  const [navInfo, setNavInfo] = useState({});
  return (
    <NavContext.Provider value={{ navInfo, setNavInfo }}>
      {children}
    </NavContext.Provider>
  );
};

export default NavContext;
