import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useDeleteDocument = (documentId) => {
  const { getRequest } = useAxiosBase();

  const deleteDocument = useMutation({
    mutationKey: ["delete-document", documentId],
    mutationFn: (data) =>
      getRequest({
        url: `/bank/delete-document/${documentId}`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          // toast.success("Document successfully deleted!");
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { deleteDocument };
};

export default useDeleteDocument;

