import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useUpdateRolePermissions = (roleId) => {
  const { putRequest } = useAxiosBase();

  const updateRolePermissions = useMutation({
    mutationKey: ["update-role", roleId],
    mutationFn: (data) =>
      putRequest({
        url: `/bank/role/${roleId}/update`,
        data: data,
      }).then((res) => {
        console.log("DANGExxxxxxR", res.data?.value);
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Role has be updated Successfully");
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.value?.statusCode === 200) {
        toast.success("Role has be updated Successfully");

        console.log("session", res);
      }
      if ([400, 404, 500].includes(res?.data?.value?.value?.statusCode)) {
        toast.error(res?.data?.value?.value?.message);
      }
    },
    onError: (error) => {
      console.log("ERRROR", error);
    },
  });

  return { updateRolePermissions };
};

export default useUpdateRolePermissions;
