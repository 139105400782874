import React, { useState } from "react";
import { useNav } from "../../hooks/useNav";
import MortgagePlanForm from "./MortgagePlanForm";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../hooks/useAxiosBase";
import Spinner from "../../Components/Spinner";
import HeaderTitle from "../../Components/Common/HeaderTitle";
import Button from "../../Components/Common/Button";
import Icons from "../../Components/Common/Icons";
import { colors } from "../../utils/constants";
import Modal from "../../Components/Common/Modal";
import { MdCancel } from "react-icons/md";
import { toast } from "react-toastify";

const MortgagePlanDetails = () => {
  useNav({ hasBackButton: true, checkBox: true });
  const [showModal, setShowModal] = useState(false);
  const { deleteRequest, getRequest } = useAxiosBase();
  const navigate = useNavigate();
  const params = useParams();
  const mortgageId = params?.id;

  const { data: plan, isLoading } = useQuery({
    queryKey: ["mortgage-plan", mortgageId],
    queryFn: () =>
      getRequest({ url: `/bank/mortgage-plan/${mortgageId}` }).then(
        (res) => res.data?.value?.value
      ),
  });

  const deleteMortgagePlan = useMutation({
    mutationFn: async () =>
      await deleteRequest({
        url: `bank/mortgage-plan/${mortgageId}/delete`,
      }).then((res) => {
        console.log("res", res);
        if ([400, 401, 413].includes(res?.response?.data?.statusCode)) {
          toast.error(res?.response?.data?.value?.message);
          return;
        }
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Success!");
          setTimeout(() => navigate("/mortgages/mortgage-plans"), 5100);
          return;
        }
        if ([400, 401, 413].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.response?.data?.value?.message);
          return;
        }
        return res.data;
      }),
  });

  const handleDelete = () => deleteMortgagePlan.mutate();

  if (isLoading)
    return (
      <div style={{ height: "60vh" }}>
        {" "}
        <Spinner loadingText={"Loading Plan Details..."} />{" "}
      </div>
    );

  return (
    <div className="container-fluid mortgage-plan-form">
      <HeaderTitle
        title={`Mortgage Plan - ${isLoading ? <Spinner /> : plan?.name}`}
      >
        <div className="col-2 col-md-2 col-sm-2">
          <Button
            btnText={<Icons iconName={"delete"} />}
            bordercolor={colors.black}
            borderHover={colors.red}
            backgroundcolor={colors.white}
            backgroundHover={colors.dangerLight}
            colorHover={colors.danger}
            color={colors.danger}
            iconcolorHover={colors.danger}
            padding={"0"}
            bordersize={2}
            btntextcolor={colors.red}
            width={"40px"}
            height={"43px"}
            type={"button"}
            onBtnClick={() => setShowModal(true)}
            isLoading={deleteMortgagePlan.isLoading}
            disabled={deleteMortgagePlan.isLoading}
          />
        </div>
      </HeaderTitle>
      {plan && <MortgagePlanForm plan={plan} />}

      <Modal
        show={showModal}
        title={"Delete Mortgage Plan"}
        subTitle={
          <>
            Are you sure you want to delete <strong>{plan?.name}.</strong>{" "}
            <strong className="text-danger">
              This action can't be undone.
            </strong>
          </>
        }
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Delete"}
        closeModal={() => setShowModal(false)}
        confirmModal={handleDelete}
      />
    </div>
  );
};

export default MortgagePlanDetails;
