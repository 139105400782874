import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllPlans } from "./hooks/useGetAllPlans";
import MultitextInput from "../../Components/Common/MultitextInput";
import Dropdown from "../../Components/Common/Dropdown";
import FormInput from "../../Components/Common/FormInput";
import Spinner from "../../Components/Spinner";
import CenteredText from "../../Components/CenteredText";
import {
  colors,
  convertToTitleCase,
  formattedPrice,
} from "../../utils/constants";
import { useNav } from "../../hooks/useNav";
import Button from "../../Components/Common/Button";

const MortgagePlanViewDetails = () => {
  const navigate = useNavigate();
  useNav({
    hasBackButton: true,
  });

  const params = useParams();
  const planId = params?.id;

  const [singlePlan, setSinglePlan] = useState();
  const [showFullDeliveryDescription, setShowFullDeliveryDescription] =
    useState(false);
  const [showFullPlanDescription, setShowFullPlanDescription] = useState(false);
  const [showFullAccessDescription, setShowFullAccessDescription] =
    useState(false);
  const [showFullPolicyDescription, setShowFullPolicyDescription] =
    useState(false);

  const { data: plans, isLoading, refetch, isRefetching } = useGetAllPlans();

  useNav({
    mainTitle: singlePlan?.name,
    // subTitle:
    //   "This shows the different types of plans your company accepts from customers. You can also create custom plans solely for your development company.",
    hasBackButton: true,
  });

  useEffect(() => {
    if (plans) {
      const getSinglePlan = plans?.data.find((plan) => plan.id === planId);
      setSinglePlan(getSinglePlan);
    }
  }, [plans, planId]);

  if (isLoading)
    return (
      <div style={{ height: "60vh" }}>
        {" "}
        <Spinner loadingText={"Loading Plan Details..."} />{" "}
      </div>
    );
  return (
    <div className="mb-5 ps-2">
      <div className="d-flex justify-content-between align-ites-center mt-4 m-3">
        <div className="h4">Plan Details</div>
        <div>
          <Button
            btnText={"Edit"}
            width={"5rem"}
            height={"2rem"}
            padding={"0"}
            lefticonname={"edit"}
            iconHeight={"1rem"}
            gap={"5px"}
            iconcolor={colors.primary}
            btntextcolor={colors.primary}
            backgroundcolor={colors.white}
            iconcolorHover={colors.hover}
            colorHover={colors.hover}
            borderHover={colors.hover}
            backgroundHover={colors.white}
            onBtnClick={() => navigate(`/plans/edit/${planId}`)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Plan Name
          </p>
          <p> {singlePlan?.name ?? "---"}</p>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Property
          </p>
          <p> {singlePlan?.houseName ?? "---"}</p>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Delivery Level Price
          </p>
          <p> ₦{formattedPrice(singlePlan?.maxLoanAmount) ?? "---"}</p>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Interest Rate (%){" "}
          </p>
          <p> {singlePlan?.interestRate ?? "0"}%</p>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Maximum Tenor (In Months)
          </p>
          <p>
            {" "}
            {singlePlan?.loanTenor ?? "0"}{" "}
            {singlePlan?.loanTenor > 1 ? "Months" : "Month"}
          </p>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Minimum Downpayment (%){" "}
          </p>
          <p> {singlePlan?.equityPercentage ?? "0"}%</p>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Processing Time (in days){" "}
          </p>
          <p>
            {" "}
            {singlePlan?.processingPeriod ?? "0"}{" "}
            {singlePlan?.processingPeriod > 1 ? "Days" : "Day"}
          </p>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Default Fee (%)
          </p>
          <p> {formattedPrice(singlePlan?.defaultFee) ?? "0"}%</p>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Grace Period (in days)
          </p>
          <p>
            {" "}
            {singlePlan?.gracePeriod ?? "0"}{" "}
            {singlePlan?.gracePeriod > 1 ? "Days" : "Day"}
          </p>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Number of Missed Payments
          </p>
          <p> {singlePlan?.numberOfMissedPayments ?? "---"}</p>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Delivery Level
          </p>
          <p>
            {" "}
            {singlePlan?.deliveryLevel
              ? convertToTitleCase(singlePlan?.deliveryLevel)
              : "---"}
          </p>
        </div>
        <div className="row d-flex justify-content-between ps-0 m-0">
          <div className="col-md-4 mb-5">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Delivery Level Description
            </p>
            {showFullDeliveryDescription ? (
              <p>
                {singlePlan?.deliveryDescription ??
                  "No description was provided"}
              </p>
            ) : (
              <p className="mb-1">
                {singlePlan?.deliveryDescription?.substring(0, 150) ??
                  "No description was provided"}
              </p>
            )}
            {singlePlan?.deliveryDescription?.length > 150 && (
              <p
                className="fw-bold"
                style={{ cursor: "pointer", color: colors.darkGreen }}
                onClick={() =>
                  setShowFullDeliveryDescription(!showFullDeliveryDescription)
                }
              >
                {showFullDeliveryDescription ? "Show Less" : "Read More"}
              </p>
            )}
          </div>
          <div className="col-md-4 mb-5">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Describe this Plan
            </p>
            {showFullPlanDescription ? (
              <p>{singlePlan?.description ?? "No description was provided"}</p>
            ) : (
              <p className="mb-1">
                {singlePlan?.description?.substring(0, 150) ??
                  "No description was provided"}
              </p>
            )}
            {singlePlan?.description?.length > 150 && (
              <p
                className="fw-bold"
                style={{ cursor: "pointer", color: colors.darkGreen }}
                onClick={() =>
                  setShowFullPlanDescription(!showFullPlanDescription)
                }
              >
                {showFullPlanDescription ? "Show Less" : "Read More"}
              </p>
            )}
          </div>
          <div className="col-md-4">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Who Can Access This Plan?
            </p>
            {showFullAccessDescription ? (
              <p>{singlePlan?.access ?? "No description was provided"}</p>
            ) : (
              <p className="mb-1">
                {singlePlan?.access?.substring(0, 150) ??
                  "No description was provided"}
              </p>
            )}
            {singlePlan?.access?.length > 150 && (
              <p
                className="fw-bold"
                style={{ cursor: "pointer", color: colors.darkGreen }}
                onClick={() =>
                  setShowFullAccessDescription(!showFullAccessDescription)
                }
              >
                {showFullAccessDescription ? "Show Less" : "Read More"}
              </p>
            )}
          </div>
          <div className="col-md-4 mb-5">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Missed Payment Policy
            </p>
            {showFullPolicyDescription ? (
              <p>
                {singlePlan?.propertyRecoveryTerms ??
                  "No missed payment policy was provided"}
              </p>
            ) : (
              <p className="mb-1">
                {singlePlan?.propertyRecoveryTerms?.substring(0, 150) ??
                  "No missed payment policy was provided"}
              </p>
            )}
            {singlePlan?.propertyRecoveryTerms?.length > 150 && (
              <p
                className="fw-bold"
                style={{ cursor: "pointer", color: colors.darkGreen }}
                onClick={() =>
                  setShowFullPolicyDescription(!showFullPolicyDescription)
                }
              >
                {showFullPolicyDescription ? "Show Less" : "Read More"}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="h4 mt-4">Processing Fees</div>
      <div className="row m-0">
        {singlePlan?.applicableFees?.length > 0 ? (
          singlePlan?.applicableFees?.map((plan) => (
            <div className="border rounded-4 px-3 py-2 col-md-4">
              <p
                className="m-0 text-center fw-bold"
                style={{ fontSize: "1rem" }}
              >
                {plan?.option?.name}
              </p>
              {plan?.option?.isRequired && (
                <p className="m-0 redDangerPill">Required</p>
              )}
            </div>
          ))
        ) : (
          <div className="my-4">
            <CenteredText title={"No Fees Required"}>
              <p className="text-center m-0">
                No Application Fee has been added to this plan.
              </p>
            </CenteredText>
          </div>
        )}
      </div>

      <div className="h4 mt-4">Documents</div>
      <div className="row gap-3 m-0">
        {singlePlan?.requiredDocuments?.length > 0 ? (
          singlePlan?.requiredDocuments?.map((plan) => (
            <div className="border rounded-4 px-3 py-2 col-md-3">
              <p
                className="m-0 text-center fw-bold"
                style={{ fontSize: "1rem" }}
              >
                {plan?.option?.name}
              </p>
              {plan?.option?.isRequired && (
                <p className="m-0 redDangerPill">Required</p>
              )}
            </div>
          ))
        ) : (
          <div className="my-4">
            <CenteredText title={"No Documents at the moment"}>
              <p className="text-center m-0">
                No Documents has been added to this plan.
              </p>
            </CenteredText>
          </div>
        )}
      </div>

      <div className="h4 mt-5">Repayment Methods</div>
      <div className="row m-0">
        {singlePlan?.repaymentMethods?.length > 0 ? (
          singlePlan?.repaymentMethods?.map((plan, index) => (
            <div className="border rounded-4 px-3 py-2 col-md-4" key={index}>
              <p
                className="m-0 text-center fw-bold"
                style={{ fontSize: "1rem" }}
              >
                {convertToTitleCase(plan?.option?.id)}
              </p>
              <p className="m-0 py-2">
                Remita is a payment gateway used by the Nigerian Government to
                process all payments. It is also used by most major banks.
              </p>
              <p className="m-0 py-2">
                Remita charges 2% per payment. The maximum amount that can be
                incured in charges is ₦2,000, regarless of payment amount. This
                is paid by the customer.
              </p>
            </div>
          ))
        ) : (
          <div className="mt-4">
            <CenteredText title={"No Repayment Methods at the moment"}>
              <p className="text-center m-0">
                No repayment methods has been added to this plan.
              </p>
            </CenteredText>
          </div>
        )}
      </div>
    </div>
  );
};

export default MortgagePlanViewDetails;
