import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useGetApplicationSummary = () => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["applications-summary"],
    queryFn: () =>
      getRequest({
        url: `bank/application/get-completed-applications-summary`,
      }).then((res) => res.data?.value?.value),
    staleTime: 50000,
  });
};
export default useGetApplicationSummary;