import React from "react";
import { CardWrapper } from "./cardStyle";
import CheckInput from "../../../../Components/Common/CheckInput";

const PlanCard = ({name, checked, onChange, planName, description}) => {
  return (
    <CardWrapper>
      <div className="card_input_check">
        <CheckInput
          name={name}
          checked={checked}
          checkTick
          width={"23px"}
          height={"23px"}
          bcolor={"#0000001A"}
          bordercolor={"#CECECE"}
          onChange={(e) => onChange(e)}
        />
      </div>
      <p className="m-0 card_title fw-semibold">{planName}</p>
      <p className="m-0 card_subtitle mb-2">{description}</p>
    </CardWrapper>
  );
};

export default PlanCard;
