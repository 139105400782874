import { styled } from "styled-components";

export const Wrapper = styled.div`
  margin: 0 !important;
  .spanText {
    font-family: "Red Hat Display, sans-serif";
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    cursor: pointer;
  }
`;
