import React, { useEffect, useState } from "react";
import { useNav } from "../../../hooks/useNav";
import InfoTable from "../../../Components/Common/InfoTable";
import { useNavigate } from "react-router-dom";
import useGetAllApplications from "../hooks/useGetAllApplications";
import Spinner from "../../../Components/Spinner";
import CenteredText from "../../../Components/CenteredText";
import CellSelector from "../../../Components/Common/CellSelector";
import { toDate } from "../../../utils/generalUtils";
import { colors } from "../../../utils/constants";
import { convertToTitleCase } from "../../../utils/constants";

const MyApplication = () => {
  useNav({
    mainTitle: "Applications",
    subTitle:
      "These are applications made by customers for properties through your plans",
  });
  const { data, isLoading, refetch, isRefetching } = useGetAllApplications();
  const navigate = useNavigate();

  const applicationColumns = [
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          PLAN
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          <>
            {row.noOfOpenApplication ? (
              <div className="">
                <span
                  className="badge btn-sm py-1 mb-1"
                  style={{
                    fontSize: "0.5rem",
                    backgroundColor: colors.dangerLight,
                    color: colors.danger,
                    borderRadius: "20px",
                    display: "inline-block",
                    padding: "3px 5px",
                    display: "inline-block",
                  }}
                >
                  ACTION REQUIRED
                </span>
              </div>
            ) : null}
            <>{row.mortgagePlan.name}</>
          </>
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          DELIVERY LEVEL
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          {convertToTitleCase(row.mortgagePlan?.deliveryLevel)}
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          NO OF APPLICATIONS
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          {row.noOfApplication}
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          UNHANDLED APPLICATIONS
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          {row.noOfUnhandledApplication}
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          LAST APPLICATION DATE
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          {toDate(row.lastApplicationDate)}
        </CellSelector>
      ),
    },
  ];

  if (isLoading) return <div style={{height: "60vh"}}> <Spinner loadingText={"Loading Applications..."} /> </div>;
  return (
    <div className="ps-2 pe-3 mt-4">
      {data?.length > 0 ? (
        <InfoTable
          columns={applicationColumns}
          dataCollection={data}
          rightTextAsButton={true}
          pointerOnHover={true}
          highlightOnHover={true}
          onRowClicked={(row, e) =>
            navigate(`/applications/${row.mortgagePlan.id}`)
          }
        />
      ) : (
        <div className="mt-5">
          <CenteredText title={"No Applications Found"}>
            There are currently no applications made by customers for properties
            through your plans. You can check back later as more potential
            customers continue to explore your properties and plans.
          </CenteredText>
        </div>
      )}
    </div>
  );
};

export default MyApplication;
