import Button from "../../../Components/Common/Button";
import { HiDotsVertical } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import { colors } from "../../../utils/constants";
import { convertToTitleCase } from "../../../utils/constants";

export const permissions = [
  {
      "id": "property_management",
      "groupName": "Property Management",
      "rules": [
          {
              "id": "view_houses",
              "name": "View Houses",
              "value": false
          },
          {
              "id": "create_house",
              "name": "Create House",
              "value": false
          },
          {
              "id": "update_house",
              "name": "Update House",
              "value": false
          },
          {
              "id": "delete_house",
              "name": "Delete House",
              "value": false
          },
          {
              "id": "view_estates",
              "name": "View Estates",
              "value": false
          },
          {
              "id": "create_estate",
              "name": "Create Estate",
              "value": false
          },
          {
              "id": "update_estate",
              "name": "Update Estate",
              "value": false
          },
          {
              "id": "delete_estate",
              "name": "Delete Estate",
              "value": false
          }
      ]
  },
  {
      "id": "application_management",
      "groupName": "Application Management",
      "rules": [
          {
              "id": "view_applications",
              "name": "View Applications",
              "value": false
          },
          {
              "id": "validate_application_document",
              "name": "Validate Application Document",
              "value": false
          },
          {
              "id": "revise_application_document",
              "name": "Revise Application Document",
              "value": false
          },
          {
              "id": "invalidate_application_document",
              "name": "Invalidate Application Document",
              "value": false
          },
          {
              "id": "send_application_for_review",
              "name": "Send Application For Review",
              "value": false
          },
          {
              "id": "view_applications_for_review",
              "name": "View Applications For Review",
              "value": false
          },
          {
              "id": "accept_application_for_review",
              "name": "Accept Application For Review",
              "value": false
          },
          {
              "id": "reject_application_for_review",
              "name": "Reject Application For Review",
              "value": false
          }
      ]
  },
  {
      "id": "viewing_requests_management",
      "groupName": "Viewing Requests Management",
      "rules": [
          {
              "id": "view_viewing_requests",
              "name": "View Viewing Requests",
              "value": false
          },
          {
              "id": "send_viewing_request_reminder",
              "name": "Send Viewing Request Reminder",
              "value": false
          }
      ]
  },
  {
      "id": "prospects_management",
      "groupName": "Prospects Management",
      "rules": [
          {
              "id": "view_prospects",
              "name": "View Prospects",
              "value": false
          },
          {
              "id": "send_prospect_reminder",
              "name": "Send Prospect Reminder",
              "value": false
          }
      ]
  },
  {
      "id": "partnership_management",
      "groupName": "Partnership Management",
      "rules": [
          {
              "id": "view_partners",
              "name": "View Partners",
              "value": false
          },
          {
              "id": "invite_partner",
              "name": "Invite Partner",
              "value": false
          },
          {
              "id": "add_partner",
              "name": "Add Partner",
              "value": false
          },
          {
              "id": "accept_partner_request",
              "name": "Accept Partner Request",
              "value": false
          },
          {
              "id": "decline_partner_request",
              "name": "Decline Partner  Request",
              "value": false
          },
          {
              "id": "revoke_partner",
              "name": "Revoke Partner ",
              "value": false
          },
          {
              "id": "delete_partnership",
              "name": "Delete Partner",
              "value": false
          }
      ]
  },
  {
      "id": "plan_management",
      "groupName": "Plan Management",
      "rules": [
          {
              "id": "view_plans",
              "name": "View Plans",
              "value": false
          },
          {
              "id": "create_plan",
              "name": "Create Plan",
              "value": false
          },
          {
              "id": "update_plan",
              "name": "Update Plan",
              "value": false
          },
          {
              "id": "delete_plan",
              "name": "Delete Plan",
              "value": false
          }
      ]
  },
  {
      "id": "mortgages_management",
      "groupName": "Portfolio Management",
      "rules": [
          {
              "id": "view_portfolio",
              "name": "View Portfolio",
              "value": false
          }
      ]
  },
  {
      "id": "transactions_management",
      "groupName": "Transactions Management",
      "rules": [
          {
              "id": "view_transactions",
              "name": "View Transactions",
              "value": false
          },
          {
              "id": "view_transaction_reciept",
              "name": "View Transaction Reciept",
              "value": false
          },
          {
              "id": "download_transaction_reciept",
              "name": "Download Transaction Reciept",
              "value": false
          },
          {
              "id": "view_repayment_schedule",
              "name": "View Repayment Schedule",
              "value": false
          },
          {
              "id": "remind_customer",
              "name": "Remind Customer to pay",
              "value": false
          }
      ]
  },
  {
      "id": "organization_management",
      "groupName": "Organization Management",
      "rules": [
          {
              "id": "view_organization_profile",
              "name": "View Organization Profile",
              "value": false
          },
          {
              "id": "update_organization_profile",
              "name": "Update Organization Profile",
              "value": false
          },
          {
              "id": "view_organization_documents",
              "name": "Update Organization Documents",
              "value": false
          },
          {
              "id": "add_organization_document",
              "name": "Add Organization Document",
              "value": false
          }
      ]
  },
  {
      "id": "team_member_management",
      "groupName": "Team Member Management",
      "rules": [
          {
              "id": "view_team_members",
              "name": "View Team Members",
              "value": false
          },
          {
              "id": "add_team_member",
              "name": "Add Team Member",
              "value": false
          },
          {
              "id": "update_team_member",
              "name": "Update Team Member",
              "value": false
          },
          {
              "id": "remove_team_member",
              "name": "Delete Team Member",
              "value": false
          }
      ]
  },
  {
      "id": "role_management",
      "groupName": "Role Management",
      "rules": [
          {
              "id": "view_roles",
              "name": "View Roles",
              "value": false
          },
          {
              "id": "add_role",
              "name": "Add Role",
              "value": false
          },
          {
              "id": "update_role",
              "name": "Update Role",
              "value": false
          },
          {
              "id": "remove_role",
              "name": "Delete Role",
              "value": false
          }
      ]
  }
]

export const roleColumns = (handleRowId, showEditModal, showDeleteModal) => [
  {
    name: <p className="fw-bold p-0 m-0"> ROLE</p>,
    sortable: true,
    selector: (row) => convertToTitleCase(row.name),
  },
  {
    name: <p className="fw-bold p-0 m-0"> DESCRIPTION </p>,
    sortable: true,
    selector: (row) => row.description || "--",
  },
  {
    name: <p className="fw-bold p-0 m-0"> DATE CREATED </p>,
    sortable: true,
    selector: (row) => row?.dateCreated ? new Date(row.dateCreated).toDateString() : "--",
  },
  {
    name: <p className="fw-bold p-0 m-0"> ACTION </p>,
    sortable: false,
    grow: 0.5,
    center: true,
    selector: (row) => (
      <Dropdown>
        <Dropdown.Toggle
          id={`dropdown-${row.id}`}
          style={{ background: "transparent", border: "none" }}
        >
          <HiDotsVertical onClick={() => handleRowId(row.id)} className="fw-bold text-dark" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="position-fixed">
          <Dropdown.Item onClick={showEditModal}>
            EDIT
          </Dropdown.Item>
          <Dropdown.Item onClick={showDeleteModal}>
            DELETE
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

export const roleData = [
  {
    id: 1,
    role: "josephkalu@godisgood.com",
    email: "Joseph Kalu",
    status: "080345881234",
    whatsapp: "-",
    status: "Joined",
  },
  {
    id: 2,
    email: "james@godisgood.com",
    role: "James Ngutor",
    status: "09041284386",
    whatsapp: "-",
    status: "Pending",
  },
  {
    id: 3,
    email: "sarah@godisgood.com",
    role: "Sarah Connor",
    status: "080345881234",
    whatsapp: "080345127847",
    status: "Pending",
  },
  {
    id: 4,
    email: "steve@godisgood.com",
    role: "Steven Malum",
    status: "080345881234",
    whatsapp: "-",
    status: "Joined",
  },
  {
    id: 5,
    email: "Nathan@godisgood.com",
    role: "Nathan Kalu",
    status: "080345881234",
    whatsapp: "-",
    status: "Pending",
  },
];
