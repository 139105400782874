import * as yup from "yup";

const permissionsSchema = yup.object({
  name: yup
    .string()
    .required("Please provide a name.")
    .min(3, "Name must be at least 3 characters."),
  description: yup
    .string()
    .required("Please provide a description.")
    .min(10, "Description must be at least 10 characters."),
});

export default permissionsSchema;