import { isNullOrEmpty } from "../constants";

export const planManagement = (userAccess) => {
  if (isNullOrEmpty(userAccess)) return null;
  const planManagement =
    userAccess?.find((x) => x.id === "plan_management")?.rules || [];

  return {
    viewPlansAccess: planManagement?.find((x) => x.id === "view_plans") || {},
    createPlanAccess: planManagement?.find((x) => x.id === "create_plan") || {},
    updatePlanAccess: planManagement?.find((x) => x.id === "update_plan") || {},
    deletePlanAccess: planManagement?.find((x) => x.id === "delete_plan") || {},
  };
};
