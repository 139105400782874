import React from "react";
import Skeleton from "react-loading-skeleton";

const CellSelector = ({ loading, children, className }) => (
  <>
    {loading ? (
      <Skeleton
        count={1}
        height={"1rem"}
        baseColor={"#f0f0f0"}
        style={{ position: "absolute", width: "93%" }}
      />
    ) : (
      <div
        className={className}
        style={{ fontFamily: '"Red Hat Display", sans-serif' }}
      >
        {children}
      </div>
    )}
  </>
);

export default CellSelector;
