import { useQuery } from "@tanstack/react-query";
import useAuth from "../../../../../hooks/useAuth";
import useAxiosBase from "../../../../../hooks/useAxiosBase";

const useGetAcceptedEstates = () => {
  const { authUser } = useAuth();
  const { getRequest } = useAxiosBase();
  const organizationId = authUser?.user?.organizationId;

  return useQuery({
    queryKey: ["devEstates", organizationId],
    queryFn: () =>
      getRequest({
        url: `bank/estate/ACCEPTED/get-all`,
      }).then((res) => res.data?.value?.value?.data),
    retry: 1,
  });
};

export default useGetAcceptedEstates;