import { styled } from "styled-components";
import { colors } from "../../../../utils/constants";

export const HouseCardWrapper = styled.div`
  border-radius: 10px;
  height: ${(props) => (props.height ? `${props.height} !important` : "")};
  /* width: ${(props) =>
    props.width ? `${props.width} !important` : "260px"}; */
  box-shadow: 0px 1px 3px #0000001a !important;
  overflow: hidden; /* Prevent scrolling */

  position: relative;
  border: ${(props) => (props.isAdded ? `1px solid ${colors.primary}` : "none")};

  .property_card_header {
    position: relative;
    height: 12rem;
    overflow: hidden;
  }

  .property_card_details {
    padding: 0.8rem;
    background-color: ${(props) => (props.isAdded ? `${colors.primaryLight}` : "white")};
  }

  .property_card_name {
    font-size: 1.2rem;
  }

  .specification > p {
    color: gray;
    margin: 0;
  }

  .orgLogo {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 1px solid gray;
    overflow: hidden;
  }

  .property_status {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    text-transform: uppercase;
    padding: 0.4rem;
  }

  .added-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 128, 0, 0.2); /* Green overlay */
    border-radius: 10px;
    z-index: 1;
    pointer-events: none; /* Allow clicks to pass through */
  }
`;