import React from "react";
import { useNav } from "../../hooks/useNav";
import CardDetails from "../../Components/Common/CardDetails";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import { months, years } from "../../utils/constants";
import Spinner from "../../Components/Spinner";
import CenteredText from "../../Components/CenteredText";
import InfoTable from "../../Components/Common/InfoTable";
import useOfferLetter from "./hooks/useOfferLetter";
import { useNavigate } from "react-router-dom";
import titleDoc from "../../assets/images/titleDoc.jpeg";
import allocationDoc from "../../assets/images/allocationDoc.jpeg";
import leterDoc from "../../assets/images/letterDoc.jpeg";

const PropertyDocuments = () => {
  useNav({
    mainTitle: "Property Documents",
    subTitle:
      "Documents required to complete a customer’s mortgage application process for the different mortgages your company & partners offer.",
  });
  const { getOfferLetter, sendProspectReminder } = useOfferLetter();
  const { data: offerLetter, isLoading } = getOfferLetter;
  const handleSendReminder = (prospectId) =>
    sendProspectReminder.mutate(prospectId);
  const navigate = useNavigate();
  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end gap-3 pt-2 pb-3">
        <div>
          <CustomDropdown
            listItem={months}
            defaultItem={months[new Date().getMonth()].value}
            width={150}
          />
        </div>
        <div>
          <CustomDropdown
            listItem={years()}
            defaultItem={years()[0].value}
            width={140}
          />
        </div>
      </div>

      <div className="d-flex gap-4 my-3">
        <div>
          <CardDetails
            docImg={leterDoc}
            bage={"10"}
            iconname={"leterDoc"}
            title={"OFFER LETTERS"}
            description={"10 Request"}
            subTitle={"2 Unsent Offer Letters"}
            onClick={() => navigate("/offer-letter/list")}
          />
        </div>

        <div>
          <CardDetails
            docImg={allocationDoc}
            iconname={"allocationDoc"}
            title={"ALLOCATION LETTERS"}
            description={"No Request"}
            subTitle={"No Unsent Application Letters"}
            color={"#335F32"}
            borderColor={"#335F32"}
            backgroundColor={"#DFFFDE"}
          />
        </div>

        <div>
          <CardDetails
            docImg={titleDoc}
            iconname={"titleDoc"}
            title={"TITLE DOCUMENTS"}
            description={"No Request"}
            subTitle={"No Unsent Title Documents"}
            color={"#335F32"}
            borderColor={"#335F32"}
            backgroundColor={"#DFFFDE"}
            onClick={() => navigate("/offer-letter/property-documents")}
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyDocuments;
