import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useGetDeveloperPartnershipTerms = (organizationId) => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["PartnershipTerms"],
    queryFn: () =>
      getRequest({
        url: `/public/organization/${organizationId}/partnership/get-terms`,
      }).then((res) => res.data?.value?.value),
    staleTime: 50000,
  });
};
export default useGetDeveloperPartnershipTerms;