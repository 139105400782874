import { isNullOrEmpty } from "../constants";

export const prospectAccess = (userAccess) => {
  if (isNullOrEmpty(userAccess)) return null;
  const access =
    userAccess?.find((x) => x.id === "prospects_management")?.rules || [];
  return {
    viewProspectsAccess: access?.find((x) => x.id === "view_prospects") || {},
    sendProspectReminderAccess:
      access?.find((x) => x.id === "send_prospect_reminder") || {},
  };
};
