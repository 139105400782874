import React from "react";
import { SpinnerWrapper } from "./style";

const Spinner = ({ loadingText, ...rest }) => {
  return (
    <SpinnerWrapper {...rest}>
      <p className="m-0 verifyingLoader"></p>
      {loadingText && <p className="mb-0 mt-2 fw-semibold"> {loadingText}</p>}
    </SpinnerWrapper>
  );
};

export default Spinner;
