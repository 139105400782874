import { isNullOrEmpty } from "../constants";

export const teamManagement = (userAccess) => {
  if (isNullOrEmpty(userAccess)) return null;
  const teamMemberManagement =
    userAccess?.find((x) => x.id === "team_member_management")?.rules || [];

  return {
    viewTeamMemberAccess:
      teamMemberManagement?.find((x) => x.id === "view_team_members") || {},
    createTeamMemberAccess:
      teamMemberManagement?.find((x) => x.id === "add_team_member") || {},
    updateTeamMemberAccess:
      teamMemberManagement?.find((x) => x.id === "update_team_member") || {},
    deleteTeamMemberAccess:
      teamMemberManagement?.find((x) => x.id === "remove_team_member") || {},
  };
};
