import React, { useEffect, useState } from "react";
import useGetPendingEstates from "../Estate/hooks/useGetPendingEstates";
import useGetPlans from "../../../Plans/hooks/useGetPlans";
// import useGetPe
import EstateCards from "../../MyProperties/Estate/EstateCards";
import HouseCard from "../House/HouseCard";
import Modal from "../../../../Components/Common/Modal";
import Spinner from "../../../../Components/Spinner";
import CenteredText from "../../../../Components/CenteredText";
import { Fade } from "react-bootstrap";
import Button from "../../../../Components/Common/Button";
import PlanCard from "../PlanCard.jsx";
import { convertToTitleCase } from "../../../../utils/constants.js";
import useAddDeveloperHouses from "../House/hooks/useAddDeveloperHouses.jsx";
import useGetEstateHouses from "../Estate/hooks/useGetEstateHouses.jsx";

const PendingEstateModal = ({
  pendingCount,
  refetchPendingCount,
  refetchAcceptedEstate,
  openEstateModal,
  setOpenEstateModal,
}) => {
  const {
    data: pendingEstatesCollection,
    isLoading,
    refetch,
  } = useGetPendingEstates();

  const [estateId, setEstateId] = useState("");
  const {
    data: getEstateHouses,
    isLoading: isLoadingEstateHouses,
    refetch: refetchEstateHouses,
    isRefetching: isRefetchingEstateHouses,
  } = useGetEstateHouses(estateId);

  console.log("estateId", estateId);

  const { addDeveloperHouses } = useAddDeveloperHouses();

  const { data: mortgagePlans } = useGetPlans();

  const [houseIds, setHouseIds] = useState([]);
  const [formData, setFormData] = useState({
    houseIds: [],
    mortgagePlanIds: [],
  });

  const [selectedEstate, setSelectedEstate] = useState("");

  const [showHouses, setShowHouses] = useState(false);
  const [showPlans, setShowPlans] = useState(false);

  const [openAddAllModal, setOpenAddAllModal] = useState(false);

  const [houseCount, setHouseCount] = useState(0);

  const handleAddRemoveHouse = (houseId) => {
    const newHouseIds = houseIds.includes(houseId)
      ? houseIds.filter((id) => id !== houseId)
      : [...houseIds, houseId];

    setHouseIds(newHouseIds);
    setFormData({
      ...formData,
      houseIds: newHouseIds,
    });
  };

  // ADD ALL PROPERTIES ID's
  const handleAddAllProperties = () => {
    if (selectedEstate) {
      const allHouseIds = selectedEstate?.houses?.map((house) => house.id);
      setHouseIds(allHouseIds);
      setFormData({
        ...formData,
        houseIds: allHouseIds,
      });
      setOpenAddAllModal(false); // Close the modal after adding all properties
    }
  };

  const handleTogglePlan = (planId) => {
    const newPlanIds = formData?.mortgagePlanIds?.includes(planId)
      ? formData?.mortgagePlanIds?.filter((id) => id !== planId)
      : [...formData?.mortgagePlanIds, planId];

    setFormData({
      ...formData,
      mortgagePlanIds: newPlanIds,
    });
  };

  const isConfirmButtonDisabled = () => {
    if (!showHouses && !showPlans) return true;
    if (showHouses && houseIds.length === 0) return true;
    if (showPlans && formData.mortgagePlanIds.length === 0) return true;
    return false;
  };

  useEffect(() => {
    if (estateId) {
      refetchEstateHouses();
    }
  }, [estateId, showHouses]);

  return (
    <>
      <Modal
        show={openEstateModal}
        title={
          showPlans
            ? "Select Plans"
            : showHouses
            ? `New Houses From Your Partner Developers - ${
                selectedEstate?.houses?.length ?? 0
              }`
            : `New Estates From Your Partner Developers - ${pendingCount ?? 0}`
        }
        subTitle={
          showPlans
            ? "Please select the mortgage plans you wish to add to the selected houses"
            : showHouses
            ? `Select the houses from ${selectedEstate?.name} to proceed with adding mortgage plans.`
            : `Review the new estates from your partner developers and select an estate to see its houses.`
        }
        width={"100%"}
        height={"100%"}
        titleFontSize={"30px"}
        crossLine={false}
        confirmButtonLabel={"Proceed"}
        cancelButtonLabel={"Cancel"}
        closeModalIcon
        backBtn={showHouses || showPlans ? true : false}
        onBtnClick={() => {
          if (showHouses) {
            setShowHouses(false);
            setShowPlans(false);
            setHouseIds([]);
            setFormData({ houseIds: [], mortgagePlanIds: [] });
          }

          if (showPlans) {
            setShowHouses(true);
            setShowPlans(false);
            setFormData({
              ...formData,
              mortgagePlanIds: [],
            });
          }
        }}
        closeModal={() => {
          setShowHouses(false);
          setShowPlans(false);
          setHouseIds([]);
          setFormData({ houseIds: [], mortgagePlanIds: [] });
          setOpenEstateModal(false);
        }}
        confirmModal={() => {
          if (showHouses) {
            setShowPlans(true);
            setShowHouses(false);
            return;
          }

          if (showPlans) {
            addDeveloperHouses.mutate(formData, {
              onSuccess: () => {
                refetch();
                refetchPendingCount();
                refetchAcceptedEstate();
                setShowHouses(false);
                setShowPlans(false);
                setHouseIds([]);
                setFormData({ houseIds: [], mortgagePlanIds: [] });
                setOpenEstateModal(false);
              },
            });
          }
        }}
        isBtnLoading={addDeveloperHouses.isLoading}
        disabled={isConfirmButtonDisabled() || addDeveloperHouses.isLoading}
      >
        <div>
          {/* <div className="row mt-3 mb-3">
      <FormInput
        placeholder="Search for Developers"
        lefticonname={"search"}
        value={search}
        onChange={(name, value) => setSearch(value)}
      />
    </div> */}

          <div className="row px-4 mt-4">
            {isLoading ? (
              <div style={{ height: "50vh" }}>
                <Spinner loadingText={"Loading Estates..."} />
              </div>
            ) : pendingEstatesCollection?.length > 0 ? (
              pendingEstatesCollection?.map((pendingEstate) =>
                showPlans ? (
                  mortgagePlans?.data?.length > 0 ? (
                    mortgagePlans?.data?.map((plan) => (
                      <div key={plan.id} className="col-md-3 mt-1">
                        <PlanCard
                          planName={plan.name}
                          description={convertToTitleCase(plan.deliveryLevel)}
                          checked={formData?.mortgagePlanIds?.includes(plan.id)}
                          onChange={() => handleTogglePlan(plan.id)}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="mt-5">
                      <CenteredText title={"No Plans Available"}>
                        Unfortunately, there are no mortgage plans available at
                        the moment. Please check back later or contact our
                        support for more information.
                      </CenteredText>
                    </div>
                  )
                ) : showHouses ? (
                  <>
                    <div className="d-flex justify-content-end mb-4">
                      <Button
                        btnText={"Add All"}
                        onBtnClick={() => setOpenAddAllModal(true)}
                      />
                    </div>
                    {isLoadingEstateHouses || isRefetchingEstateHouses ? (
                      <div style={{ height: "50vh" }}>
                        <Spinner loadingText={"Loading Estate Houses..."} />
                      </div>
                    ) : (
                      getEstateHouses?.map((house) => (
                        <div key={house.id} className="col-md-3 mt-1">
                          <HouseCard
                            house={house}
                            houseIds={houseIds}
                            handleAddRemoveHouse={handleAddRemoveHouse}
                          />
                        </div>
                      ))
                    )}
                  </>
                ) : (
                  <div
                    key={pendingEstate.id}
                    className="custom_grid mb-5"
                    onClick={() => setEstateId(pendingEstate?.estateId)}
                  >
                    <EstateCards
                      estate={pendingEstate?.estate}
                      gotoEstate={() => {
                        setShowHouses(true);
                        setSelectedEstate(pendingEstate?.estate);
                      }}
                    />
                  </div>
                )
              )
            ) : (
              <div className="mt-5">
                <CenteredText title="No Pending Estates">
                  Currently, there are no pending estates. Please check back
                  later.
                </CenteredText>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        show={openAddAllModal}
        title={"Add all Properties"}
        subTitle={
          <p className="mt-3">
            By adding all, you will be adding all of the properties in{" "}
            <strong>{selectedEstate?.name}</strong> to whatever Mortgage Plan
            you select on the next page.{" "}
            <strong>Do you still want to add all?</strong>
          </p>
        }
        confirmButtonLabel={"Add"}
        cancelButtonLabel={"Cancel"}
        confirmModal={handleAddAllProperties}
        closeModal={() => setOpenAddAllModal(false)}
      ></Modal>
    </>
  );
};

export default PendingEstateModal;
