import React from "react";
import { CardWrapper } from "./style";
import Icons from "../Icons";
import { defaultImage } from "../../../Pages/Developers/constants";

const CardDetails = ({
  bage,
  title,
  subTitle,
  description,
  height,
  onClick,
  docImg,
  ...rest
}) => {
  return (
    <CardWrapper {...rest} height={height} onClick={onClick}>
      <div className="position-relative card-top">
        {bage && <span className="bage doc-bage"> {bage} </span>}
        <img src={docImg ? docImg : defaultImage} alt="doc" className="doc-img" />
        <span className="doc-icon">
          {" "}
          <Icons iconName={rest.iconname} />{" "}
        </span>
      </div>
      <div className="mt-3 px-2 card-body">
        <p className="doc-title w-100"> {title} </p>
        <p className="doc-desc"> {description} </p>
        <p className="doc-btn"> {subTitle} </p>
      </div>
    </CardWrapper>
  );
};

export default CardDetails;
