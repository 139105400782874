import React, { useState } from "react";
import { TbCircleCheckFilled } from "react-icons/tb";
import { LiaInfoCircleSolid } from "react-icons/lia";
import { AiFillCloseCircle } from "react-icons/ai";
import "./affordabilityTag.css";

const AffordabilityPill = ({ text, variant }) => {
  const [showDescription, setShowDescription] = useState(false);
  if (variant === "danger") {
    return (
      <div className="wrapper-danger">
        <div className="icon-left">
          <LiaInfoCircleSolid size={11} color="#D10808" />
        </div>
        <div className="text-ndanger">{text}</div>
        <div className="icon-right-danger">
          <AiFillCloseCircle size={13} color="#D10808" />
        </div>
      </div>
    );
  }

  return (
    <>
      {showDescription && (
        <div className="container description animated">
          <h5 className="text-center description-title">
            WHAT AFFORDABLE MEANS
          </h5>
          <p className="description-text">
            This means the customer can afford the property given their age and
            Income. They can effectively meet the requirements of the
            mortgage/payment plan given their current net monthly income.
          </p>
        </div>
      )}
      <div
        className="wrapper"
        onMouseEnter={() => setShowDescription(true)}
        onMouseLeave={() => setShowDescription(false)}
      >
        <div className="icon-left">
          <LiaInfoCircleSolid size={11} color="#0A7E32" />
        </div>
        <div className="text">{text}</div>
        <div className="icon-right">
          <TbCircleCheckFilled size={13} color="#0A7E32" bbox={50} />
        </div>
      </div>
    </>
  );
};

export default AffordabilityPill;
