import styled from "styled-components";
import { colors } from "../../../utils/constants";

export const LicenseAndCertificationDocWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 12rem;
  border-radius: 9px;
  border: 1px solid ${colors.darkGreen};
  background-color: #335f320d;
  padding: 0.5rem;
  overflow: hidden;

  .docImg {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .viewDoc > a {
    text-decoration: none;
  }

  .name {
    font-weight: 400;
    text-align: center;
    font-size: 0.9rem;
    margin: 0.4rem 0;
  }

  .docImg > span > svg {
    fill: ${colors.darkGreen};
    height: 2.8rem !important;
  }

  .extension {
    font-weight: bold;
    color: ${colors.darkGreen};
    text-align: center;
    margin: 0;
  }
`;
