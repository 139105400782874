import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.height ? `${props.height} !important` : "130px")};
  width: ${(props) => (props.width ? `${props.width} !important` : "260px")};
  box-shadow: 0px 3px 6px #0000001a !important;
  overflow: scroll;
  padding: 0.5rem;

  .card_title {
    font-size: 30px;
    font-weight: bold;
    font-family: "Red Hat Display", sans-serif;
  }

  .card_description {
    font-weight: 600;
    font-family: "Red Hat Display", sans-serif;
  }
`;
