import React, { useState, useEffect } from "react";
import usePermission from "../../hooks/usePermissions";
import { useNav } from "../../hooks/useNav";
import { useParams } from "react-router-dom";
import TabGroup from "../../Components/TabGroup";
import DetailCard from "../../Components/Common/DetailCard";
import Icons from "../../Components/Common/Icons";
import DetailSubCard from "../../Components/Common/DetailSubCard";
import CheckInput from "../../Components/Common/CheckInput";
import { colors } from "../../utils/constants";
import { saveAs } from "file-saver";
import {
  transactionsColumn,
  missedPaymentColumn,
} from "../Transactions/utils/constants";
import {
  successfulTransactionsColumn,
  expectedTransactionsColumn,
  repaymentColumn,
} from "./constant";
import useGetApplication from "../Application/hooks/useGetApplication";
import { useGetRepaymentSchedule } from "./hooks/useGetRepaymentSchedule";
import useAxiosBase from "../../hooks/useAxiosBase";
import { convertToTitleCase, formattedPrice } from "../../utils/constants";
import Spinner from "../../Components/Spinner";
import TabGroupTwo from "../../Components/Common/TabGroupTwo";
import InfoCard from "../../Components/Common/InfoCard";
import CenteredText from "../../Components/CenteredText";
import InfoTable from "../../Components/Common/InfoTable";
import {
  displayValidationValue,
  stringDisplayValidation,
} from "../Application/utils/constants";
import ProgressBar from "@ramonak/react-progress-bar";
import DocumentTable from "../../Components/DocumentTable";
import UploadDocument from "../../Components/Common/UploadDocument";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { applicationNumber } from "../../utils/generalUtils";
import useSendReminder from "./hooks/useSendReminder";
import useSendWarning from "./hooks/useSendWarning";

const PortfolioDetails = () => {
  useNav({ hasBackButton: true });

  const { accessViewPortfolios } = usePermission();

  const { postRequest } = useAxiosBase();
  const params = useParams();
  const applicationId = params?.id;

  const {
    data: application,
    isLoading,
    refetch,
    isRefetching,
  } = useGetApplication({ applicationId });

  const [transactionId, setTransactionId] = useState("");

  const { data: getRepaymentSchedule } = useGetRepaymentSchedule(applicationId);
  const { sendReminder } = useSendReminder(transactionId);
  const { sendWarning } = useSendWarning(transactionId);

  const [bathrooms, setBathrooms] = useState();
  const [bedrooms, setBedrooms] = useState();
  const [squareMeters, setSquareMeters] = useState();
  const [furnished, setFurnished] = useState();
  const [gatedCompound, setGatedCompound] = useState();
  const [successfulTransactions, setSuccessfulTransactions] = useState();
  const [expectedTransactions, setExpectedTransactions] = useState();
  const [defaultTransactions, setDefaultTransactions] = useState();
  const [defaultTransactionsAmount, setDefaultTransactionsAmount] = useState();

  const formatCurrency = (value) => {
    if (value === undefined || value === null) {
      return "N/A"; // You can provide a default value or an appropriate placeholder
    }

    return value.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const handleApplicationBtnClick = () => {};

  const handleButtonClick = (rowId, event) => {
    setTransactionId(rowId);

    if (event === "remind") {
      sendReminder.mutate();
      toast.warning("Sending Reminder!")
    } else {
      sendWarning.mutate();
      toast.warning("Sending Warning!")
    }
  };

  useEffect(() => {
    // Set values for Specifications section
    const specificationsValues =
      application?.house?.features?.reduce((accumulator, feature) => {
        switch (feature.icon) {
          case "bathrooms":
            accumulator.bathrooms = feature.name;
            break;
          case "bedrooms":
            accumulator.bedrooms = feature.name;
            break;
          case "furnished_unfurnished":
            accumulator.furnished = feature.name;
            break;
          case "gated_compound_gated_community":
            accumulator.gated_compound = feature.name;
            break;
          case "sqm":
            accumulator.square_meters = feature.name.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            );
            break;
          case "guarded":
            accumulator.guarded_compound = feature.name;
            break;
          // Add more cases for other specifications as needed
          default:
            break;
        }
        return accumulator;
      }, {}) || {};

    // Set the form field values for Specifications
    setBathrooms(specificationsValues.bathrooms);
    setBedrooms(specificationsValues.bedrooms);
    setFurnished(specificationsValues.furnished);
    setSquareMeters(specificationsValues.square_meters);
    setGatedCompound(specificationsValues.gated_compound);

    if (application) {
      // successfulTransactions
      const successfullTransaction = application?.transactions?.filter(
        (tran) => tran.isPaid === true
      );

      // expectedTransactions
      const expectedTransaction = application?.transactions
        ?.filter((tran) => tran.isPaid === false)
        .map((transaction) => {
          // Convert gracePeriod from days to milliseconds
          const gracePeriodMs =
            (application?.mortgagePlan?.gracePeriod || 0) * 24 * 60 * 60 * 1000;
          const dueDate = new Date(transaction.dueDate);

          // Check if dueDate is a valid Date object
          if (!(dueDate instanceof Date) || isNaN(gracePeriodMs)) {
            console.error("Invalid dueDate or gracePeriod");
            return null; // or handle invalid data as appropriate
          }

          const currentDate = new Date();
          const timeDifferenceMs =
            dueDate.getTime() + gracePeriodMs - currentDate.getTime();

          // Ensure timeDifferenceMs is a valid number
          if (isNaN(timeDifferenceMs)) {
            console.error("Invalid time difference");
            return null; // or handle invalid data as appropriate
          }

          const paymentTracker = Math.round(
            timeDifferenceMs / (1000 * 60 * 60 * 24)
          ); // Convert milliseconds to whole days
          const daysText = timeDifferenceMs > 0 ? "Days left" : "Days passed"; // Determine if it's days left or days passed
          return {
            ...transaction,
            paymentTracker: `${paymentTracker} ${daysText}`,
          };
        })
        .filter((transaction) => transaction !== null)
        .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate)); // Sort by due date
      setExpectedTransactions(expectedTransaction);

      // defaultTransactions
      const defaultTransaction = application?.transactions
        ?.filter((tran) => !tran.isPaid && new Date(tran.dueDate) < new Date())
        .map((transaction) => {
          const daysOverDue = transaction.dueDate - new Date();
          return { ...transaction, daysOverDue };
        });

      // defaultTransactionsAmount
      const defaultTransactionsAmount = defaultTransaction?.reduce(
        (total, transaction) => total + transaction.amount,
        0
      );

      setDefaultTransactionsAmount(defaultTransactionsAmount);
      setDefaultTransactions(defaultTransaction);
      setExpectedTransactions(expectedTransaction);
      setSuccessfulTransactions(successfullTransaction);
    }
  }, [application]);

  // console.log("application", application);
  // console.log("defaultTransactions", defaultTransactions);

  const [totalTansactionAmount, setTotalTansactionAmount] = useState();
  const [totalDuePrincipal, setTotalDuePrincipal] = useState();
  const [totalDueInterest, setTotalDueInterest] = useState();
  const [totalDue, setTotalDue] = useState();
  const [totalPaidIntereste, setTotalPaidIntereste] = useState();
  const [totalPaidPrincipal, setTotalPaidPrincipal] = useState();
  const [totalBalance, setTotalBalance] = useState();
  const [paybackPeriodInYears, setPaybackPeriodInYears] = useState();
  const [annualInterestRate, setAnnualInterestRate] = useState();
  const [monthlyInterestRate, setMonthlyInterestRate] = useState();
  const [numberOfPayments, setNumberOfPayments] = useState();
  const [paymentsLeft, setPaymentsLeft] = useState();
  const [missedPayments, setMissedPayments] = useState();

  useEffect(() => {
    if (
      getRepaymentSchedule &&
      Array.isArray(getRepaymentSchedule) &&
      application
    ) {
      // Calculate Total Transaction Amount
      const totalTransactionAmount = application?.transactions?.reduce(
        (total, transaction) => {
          return total + transaction.amount;
        },
        0
      );

      // CALC TOTAL DUE PRINCIPAL
      const totalDuePrincipal = getRepaymentSchedule?.reduce(
        (total, transaction) => {
          // Check if the transaction is not paid and it has a PPMT value
          if (!transaction.isPaid && transaction.ppmt) {
            return total + transaction.ppmt;
          }
          return total;
        },
        0
      );

      // CALC TOTAL DUE INTEREST
      const totalDueInterest = getRepaymentSchedule?.reduce(
        (total, transaction) => {
          // Check if the transaction is not paid and it has an IPMT value
          if (!transaction.isPaid && transaction.ipmt) {
            return total + transaction.ipmt;
          }
          return total;
        },
        0
      );

      // Calculate Total Due Principal
      const totalDue = totalDuePrincipal + totalDueInterest;

      // Calculate Total Paid Intrest
      const totalPaidInterest = getRepaymentSchedule?.reduce(
        (total, transaction) => {
          // Check if the transaction is paid and it has an IPMT value
          if (transaction.isPaid && transaction.ipmt) {
            return total + transaction.ipmt;
          }
          return total;
        },
        0
      );

      // Calculate Total Paid Principal
      const totalPaidPrincipal = getRepaymentSchedule?.reduce(
        (total, transaction) => {
          // Check if the transaction is paid and it has a PPMT value
          if (transaction.isPaid && transaction.ppmt) {
            return total + transaction.ppmt;
          }
          return total;
        },
        0
      );

      // Calculate Total Balance
      const totalBalance = totalDue - (totalPaidInterest + totalPaidPrincipal);

      // Calculate Payback Period In Years
      const paybackPeriodInYears = application?.payableLoanTenor / 12;

      // Calculate Annual Interest Rate
      const annualInterestRate = application?.mortgagePlan?.interestRate;

      // Calculate Monthly Interest Rate
      const monthlyInterestRate = annualInterestRate / 12;

      // Calculate Number Of Payments
      const numberOfPayments = getRepaymentSchedule?.filter(
        (transaction) => transaction.isPaid
      ).length;

      // Calculate Payments Left
      const paymentsLeft = getRepaymentSchedule?.filter(
        (transaction) => !transaction.isPaid
      ).length;

      // Calculate Missed Payments
      const missedPayments = getRepaymentSchedule?.filter(
        (transaction) =>
          !transaction.isPaid && new Date(transaction.dueDate) < new Date()
      ).length;

      setTotalTansactionAmount(totalTransactionAmount);
      setTotalDuePrincipal(totalDuePrincipal);
      setTotalDueInterest(totalDueInterest);
      setTotalDue(totalDue);
      setTotalPaidIntereste(totalPaidInterest);
      setTotalPaidPrincipal(totalPaidPrincipal);
      setTotalBalance(totalBalance);
      setPaybackPeriodInYears(paybackPeriodInYears);
      setAnnualInterestRate(annualInterestRate);
      setMonthlyInterestRate(monthlyInterestRate);
      setNumberOfPayments(numberOfPayments);
      setPaymentsLeft(paymentsLeft);
      setMissedPayments(missedPayments);
    }
  }, [application, getRepaymentSchedule]);

  if (isLoading)
    return (
      <div style={{ height: "70vh" }}>
        {" "}
        <Spinner loadingText={"Portfolio Details..."} />{" "}
      </div>
    );

  return (
    <div className="px-2">
      {accessViewPortfolios.value ? (
        <>
          {!application ? (
            <>error </>
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="h1 text-uppercase">
                  Application - {applicationNumber(application?.id)}
                </h1>
                <p>
                  <span className="greenSuccessPill">
                    BELONGS TO{" "}
                    <span className="fw-bold">
                      {application?.customer?.name?.toUpperCase() ?? ""}
                    </span>
                  </span>
                </p>
              </div>
              <TabGroup>
                {/* DETAILS TAB */}
                <div label="DETAILS">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <div className="row mt-3">
                      <div className="col-md-6 col-xl-4">
                        <DetailCard
                          title={"Property Details"}
                          subtitle={
                            application?.payableHousePrice ===
                              application?.mortgagePlan?.maxLoanAmount ||
                            !application?.payableHousePrice ? (
                              <p
                                className="m-0 mb-2"
                                style={{ color: colors.darkGreen }}
                              >
                                ₦
                                {formattedPrice(
                                  application?.mortgagePlan?.maxLoanAmount
                                )}
                              </p>
                            ) : (
                              <>
                                <p
                                  className="m-0 mb-2"
                                  style={{
                                    fontSize: "0.6rem",
                                    color: colors.danger,
                                    backgroundColor: colors.dangerLight,
                                    border: `1px solid ${colors.danger}`,
                                    borderRadius: "100px",
                                    display: "inline-block",
                                    padding: "0.1rem 0.3rem",
                                  }}
                                >
                                  PRICE CHANGE
                                </p>
                                <div className="d-flex align-items-end gap-3 m-0">
                                  <div>
                                    <p
                                      className="m-0"
                                      style={{ fontSize: "0.6rem" }}
                                    >
                                      INITIAL PRICE
                                    </p>
                                    <p
                                      className="m-0"
                                      style={{ color: colors.darkGreen }}
                                    >
                                      ₦{" "}
                                      {formattedPrice(
                                        application?.mortgagePlan?.maxLoanAmount
                                      )}
                                    </p>
                                  </div>
                                  <Icons
                                    iconName={"rightArrow"}
                                    className="priceChangeIcon"
                                  />
                                  <div>
                                    <p
                                      className="m-0"
                                      style={{ fontSize: "0.6rem" }}
                                    >
                                      {" "}
                                      NEGOTIATED PRICE
                                    </p>
                                    <p
                                      className="m-0"
                                      style={{ color: colors.darkGreen }}
                                    >
                                      ₦{" "}
                                      {formattedPrice(
                                        application?.payableHousePrice
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )
                          }
                          isBold={true}
                          className={"vertical-divider"}
                        >
                          <p className="m-0 fw-semibold">
                            {" "}
                            {application?.house?.name ?? ""}
                          </p>
                          <div className="d-flex flex-wrap column-gap-3 mt-2">
                            <p className="d-flex align-items-center gap-1 p-0 mb-3">
                              <p className="p-0 m-0 prop_icon">
                                <Icons iconName={"bedrooms"} />{" "}
                              </p>
                              <p className="p-0 m-0">{bedrooms} Beds</p>
                            </p>
                            <p className="d-flex align-items-center gap-1 p-0 mb-3">
                              <p className="p-0 m-0 prop_icon">
                                <Icons iconName={"bathrooms"} />
                              </p>
                              <p className="p-0 m-0">{bathrooms} Baths</p>
                            </p>
                            {squareMeters && (
                              <p className="d-flex align-items-center gap-1 p-0 mb-3">
                                <p className="p-0 m-0 prop_icon">
                                  <Icons iconName={"sqm"} />
                                </p>
                                <p className="p-0 m-0">{squareMeters} Sqm</p>
                              </p>
                            )}
                            <p className="d-flex align-items-end gap-1 p-0 mb-3">
                              <p className="p-0 m-0 prop_icon_location">
                                <Icons iconName={"terrace"} />
                              </p>
                              <p className="p-0 m-0">
                                {convertToTitleCase(application?.house.type)}
                              </p>
                            </p>
                          </div>
                          <div className="d-flex flex-wrap column-gap-3">
                            <p className="d-flex align-items-center gap-1 p-0 mb-3">
                              <p className="p-0 m-0 prop_icon">
                                {" "}
                                <Icons iconName={"furnished_unfurnished"} />
                              </p>
                              <p className="p-0 m-0">
                                {" "}
                                {furnished === "yes"
                                  ? "Furnished"
                                  : "Unfurnished"}{" "}
                              </p>
                            </p>
                            <p className="d-flex align-items-center gap-1 p-0 mb-3">
                              <p className="p-0 m-0 prop_icon">
                                {" "}
                                <Icons
                                  iconName={"gated_compound_gated_community"}
                                />
                              </p>
                              <p className="p-0 m-0">
                                {" "}
                                {gatedCompound === "yes"
                                  ? "Gated Compound"
                                  : "No Gate"}{" "}
                              </p>
                            </p>
                          </div>
                          <div className="d-flex align-items-center flex-wrap column-gap-1]">
                            <p className="p-0 m-0 prop_icon_location">
                              <Icons iconName={"mapIcon"} />
                            </p>
                            <p className="p-0 m-0">
                              {" "}
                              {application?.house.address}{" "}
                            </p>
                          </div>
                        </DetailCard>
                      </div>

                      <div className="col-md-6 col-xl-4">
                        <DetailCard
                          title={"Plan Details"}
                          className={"vertical-divider"}
                        >
                          <DetailSubCard
                            leftTitle={"Plan Type"}
                            leftDescription={application?.mortgagePlan?.name}
                            rightTitle={"Downpayment"}
                            rightDescription={`${
                              application?.payableEquity
                            }% (₦ ${formattedPrice(
                              application?.payableHousePrice *
                                (application?.payableEquity / 100)
                            )})`}
                          />
                          <DetailSubCard
                            className="pt-2"
                            leftTitle={"Payment Period"}
                            leftDescription={`${
                              application?.payableLoanTenor
                            } ${
                              application?.payableLoanTenor > 1
                                ? "Months"
                                : "Month"
                            }`}
                          />
                        </DetailCard>
                      </div>

                      <div className="col-md-6 col-xl-4">
                        <DetailCard title={"Customer Details"}>
                          <DetailSubCard
                            leftTitle={"Name"}
                            leftDescription={application?.customer?.name}
                            rightTitle={"Email"}
                            rightDescription={application?.customer?.email}
                          />
                          <DetailSubCard
                            className="pt-2"
                            leftTitle={"Phone No"}
                            leftDescription={application?.customer.phoneNumber}
                            rightTitle={"Monthly Affordability"}
                            rightDescription={formattedPrice(
                              application?.customer?.affordability
                                ?.developerPlan
                            )}
                          />
                          <DetailSubCard
                            className="pt-2"
                            leftTitle={
                              application?.withInNigeria
                                ? "NIN Verification"
                                : "Int’l Passport"
                            }
                            leftDescription={
                              application?.withInNigeria ? (
                                <div>
                                  {application?.verifications?.map((x) => {
                                    if (x.type === "NIN") {
                                      return <p className="m-0">{x.number}</p>;
                                    }
                                    return null;
                                  })}
                                  <div className="m-0">
                                    <CheckInput
                                      checked={true}
                                      checkTick={true}
                                      width={"15px"}
                                      height={"15px"}
                                      bcolor={colors.darkGray}
                                      labelColor={colors.darkGreen}
                                      borderradius={"50%"}
                                      fontSize={"9px"}
                                      tickWidth={"0.3rem"}
                                      tickHeight={"0.5rem"}
                                      bordercolor={colors.grey}
                                      tickBorderWidth={"0 2px 2px 0"}
                                      labelMT={"0.3rem"}
                                      onChange={(e) => {}}
                                      label={"NATIONAL IDENTITY VALIDATED"}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  {application?.verifications?.map((x) => {
                                    if (x.type === "PASSPORT") {
                                      return <p className="m-0">{x.number}</p>;
                                    }
                                    return null;
                                  })}
                                  <div className="d-flex align-items-center m-0">
                                    <Icons
                                      iconName={"viewings"}
                                      className={"viewDoc"}
                                    />
                                    <p
                                      className="m-0 anchor"
                                      style={{
                                        fontSize: "0.6rem",
                                        fontWeight: "bold",
                                        color: "#335f32",
                                      }}
                                    >
                                      VIEW DOCUMENT
                                    </p>
                                  </div>
                                </div>
                              )
                            }
                            // rightTitle={application?.verifications?.map((x) => {
                            //   if (x.type === "NHF") {
                            //     return <>{x.type}</>;
                            //   }
                            //   return null;
                            // })}
                            // rightDescription={application?.verifications?.map((x) => {
                            //   if (x.type === "NHF") {
                            //     return <p>{x.number}</p>;
                            //   }
                            //   return null;
                            // })}
                          />
                        </DetailCard>
                      </div>

                      <div className="col-md-6 col-xl-4">
                        <DetailCard
                          title={"Other Details"}
                          className={"vertical-divider"}
                        >
                          <DetailSubCard
                            leftTitle={"Handling Staff"}
                            leftDescription={`${application?.handler?.firstName} ${application?.handler?.lastName}`}
                            rightTitle={"Application Date"}
                            rightDescription={new Date(
                              application?.dateCreated
                            ).toDateString()}
                          />

                          <DetailSubCard
                            leftTitle={"Region"}
                            leftDescription={
                              application?.withInNigeria
                                ? "Within Nigeria"
                                : "Outside Nigeria"
                            }
                          />
                        </DetailCard>
                      </div>
                    </div>
                  )}
                </div>

                {/* TRANSACTIONS TAB */}
                <div label="TRANSACTIONS">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <TabGroupTwo>
                      <div label="Successful Transactions">
                        <div>
                          <InfoCard
                            mainValue={successfulTransactions?.length ?? 0}
                            description={"Successful Transactions"}
                          />
                        </div>
                        <div className="">
                          {isLoading ? (
                            <Spinner />
                          ) : !successfulTransactions?.length ? (
                            <div className="mt-5">
                              <CenteredText title={"No Transactions"}>
                                There are no Successful Transactions at this
                                time
                              </CenteredText>
                            </div>
                          ) : (
                            <div
                              style={{
                                fontFamily: '"Red Hat Display", sans-serif',
                              }}
                            >
                              <InfoTable
                                columns={successfulTransactionsColumn(
                                  handleApplicationBtnClick,
                                  isRefetching
                                )}
                                dataCollection={successfulTransactions}
                                pointerOnHover={true}
                                paginationServer={false}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div label="Expected Transactions">
                        <div className="row">
                          <div className="col-md-3">
                            <InfoCard
                              info={true}
                              mainValue={expectedTransactions?.length ?? "0"}
                              description={"Total Transactions"}
                            />
                          </div>
                          <div className="col-md-3">
                            <InfoCard
                              info={true}
                              mainValue={
                                `₦${
                                  formattedPrice(
                                    totalTansactionAmount?.toFixed()
                                  ) ?? 0
                                }` ?? "0"
                              }
                              description={"Total Transactions Amount"}
                            />
                          </div>
                        </div>
                        <p className="trans-text m-0 mt-3">
                          Expected transactions involve payments awaiting the
                          payment due date, anticipated to be fulfilled by
                          customers, and primarily representing future
                          repayments.
                        </p>
                        <div>
                          {isLoading ? (
                            <Spinner />
                          ) : !expectedTransactions?.length ? (
                            <div className="mt-5">
                              <CenteredText title={"No Transactions"}>
                                There are no Expected Transactions at this time
                              </CenteredText>
                            </div>
                          ) : (
                            <div
                              style={{
                                fontFamily: '"Red Hat Display", sans-serif',
                              }}
                            >
                              <InfoTable
                                columns={expectedTransactionsColumn(
                                  handleApplicationBtnClick
                                )}
                                dataCollection={expectedTransactions}
                                pointerOnHover={true}
                                paginationServer={false}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div label="Defaults">
                        <div className="mt-3">
                          <div className="row">
                            <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                              <InfoCard
                                info={true}
                                width={"100%"}
                                mainValue={
                                  `₦${formattedPrice(
                                    defaultTransactionsAmount?.toFixed()
                                  )}` ?? "0"
                                }
                                description={"Transaction Default Amount"}
                              />
                            </div>
                            <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                              <InfoCard
                                info={true}
                                width={"100%"}
                                mainValue={defaultTransactions?.length ?? "0"}
                                description={"Missed Payments"}
                              />
                            </div>
                          </div>
                        </div>
                        <p className="trans-text m-0">
                          Data on payments that should have beeen made but
                          weren’t and the customers who should have paid
                        </p>
                        <div>
                          {isLoading ? (
                            <Spinner />
                          ) : !defaultTransactions?.length ? (
                            <div className="mt-5">
                              <CenteredText title={"No Transactions"}>
                                There are no Transactions at this time
                              </CenteredText>
                            </div>
                          ) : (
                            <div
                              style={{
                                fontFamily: '"Red Hat Display", sans-serif',
                              }}
                            >
                              <InfoTable
                                columns={missedPaymentColumn(
                                  handleApplicationBtnClick
                                )}
                                dataCollection={defaultTransactions}
                                pointerOnHover={true}
                                paginationServer={false}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </TabGroupTwo>
                  )}
                </div>

                {/* REPAYMENT SCHEDULE TAB */}
                <div label="REPAYMENT SCHEDULE">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="row mt-3">
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            TOTAL DUE - PRINCIPAL
                          </h6>
                          <p className="m-0">(The Property Purchase Price)</p>
                          <p>
                            ₦{" "}
                            {formattedPrice(totalDuePrincipal?.toFixed()) ?? 0}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            TOTAL DUE - INTEREST
                          </h6>
                          <p>
                            ₦ {formattedPrice(totalDueInterest?.toFixed()) ?? 0}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            TOTAL DUE
                          </h6>
                          <p>₦ {formattedPrice(totalDue?.toFixed()) ?? 0}</p>
                        </div>
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            TOTAL PAID - INTEREST
                          </h6>
                          <p>
                            ₦{" "}
                            {formattedPrice(totalPaidIntereste?.toFixed()) ?? 0}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            TOTAL PAID - PRINCIPAL
                          </h6>
                          <p>
                            ₦{" "}
                            {formattedPrice(totalPaidPrincipal?.toFixed()) ?? 0}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            TOTAL BALANCE
                          </h6>
                          <p>
                            ₦ {formattedPrice(totalBalance?.toFixed()) ?? 0}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            PAYBACK PERIOD
                          </h6>
                          <p>
                            {" "}
                            {application?.payableLoanTenor ?? 0}{" "}
                            {application?.payableLoanTenor > 1
                              ? "Months"
                              : "Month"}{" "}
                            ({paybackPeriodInYears?.toFixed(2) ?? 0}{" "}
                            {paybackPeriodInYears?.toFixed(2) > 1
                              ? "Years"
                              : "Year"}
                            )
                          </p>
                        </div>
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            ANNUAL INTEREST RATE
                          </h6>
                          <p>
                            {formattedPrice(annualInterestRate?.toFixed()) ?? 0}
                            %
                          </p>
                        </div>
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            MONTHLY INTEREST RATE
                          </h6>
                          <p>{formattedPrice(monthlyInterestRate) ?? 0}%</p>
                        </div>
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            NUMBER OF PAYMENTS
                          </h6>
                          <p>{formattedPrice(numberOfPayments)} payments</p>
                        </div>
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            PAYMENTS MADE
                          </h6>
                          <p>
                            {numberOfPayments} out of {paymentsLeft}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <h6
                            className="m-0 mb-1"
                            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          >
                            MISSED PAYMENTS
                          </h6>
                          <p>₦ {missedPayments?.toFixed() ?? 0}</p>
                        </div>
                        {/* <div className="col-md-3">
                     <h6
                       className="m-0 mb-1"
                       style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                     >
                       DEFAULT PAYMENT FEES MADE
                     </h6>
                     <p>N80,000 (8 Payments)</p>
                   </div> */}
                      </div>
                      <div>
                        {isLoading ? (
                          <Spinner />
                        ) : !getRepaymentSchedule?.length ? (
                          <div className="mt-5">
                            <CenteredText title={"No Transactions"}>
                              There are no Transactions at this time
                            </CenteredText>
                          </div>
                        ) : (
                          <div
                            style={{
                              fontFamily: '"Red Hat Display", sans-serif',
                            }}
                          >
                            <InfoTable
                              columns={repaymentColumn((rowId, event) =>
                                handleButtonClick(rowId, event)
                              )}
                              dataCollection={getRepaymentSchedule}
                              pointerOnHover={true}
                              paginationServer={false}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>

                {/* DOCUMENTS TAB */}
                <div label="DOCUMENTS">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="row mb-4">
                        <DetailCard title={"Relevant Documents"} />
                        {application?.documents
                          .filter((document) => {
                            const name =
                              document?.document?.option?.name.toUpperCase();
                            return (
                              name === "OFFER LETTER" ||
                              name === "ALLOCATION LETTER" ||
                              name === "TITLE DOCUMENT"
                            );
                          })
                          .map((document) => (
                            <div className="col-sm-6 col-md-4 mb-3">
                              <UploadDocument
                                header={convertToTitleCase(
                                  document?.document?.option?.name
                                ).toUpperCase()}
                                title={convertToTitleCase(
                                  document?.document?.option?.name
                                ).toUpperCase()}
                                badgeText={"Sent to Customer"}
                                badgeTextBackgroundColor={
                                  convertToTitleCase(
                                    document?.document?.option?.name
                                  ).toUpperCase() === "OFFER LETTER"
                                    ? colors.lightGreen
                                    : "transparent"
                                }
                                onClick={() =>
                                  saveAs(
                                    document?.document?.document,
                                    document?.document?.option?.name
                                  )
                                }
                                badgeTextColor={
                                  convertToTitleCase(
                                    document?.document?.option?.name
                                  ).toUpperCase() === "OFFER LETTER"
                                    ? colors.darkGreen
                                    : "transparent"
                                }
                                height={130}
                                variant={"download"}
                              />
                            </div>
                          ))}
                      </div>

                      <h4>Credit & Document Verification</h4>
                      <div className="col-md-6 progress-bar">
                        <ProgressBar
                          completed={displayValidationValue(
                            application?.documents
                          )}
                          customLabel={stringDisplayValidation(
                            application?.documents
                          )}
                          labelAlignment="center"
                          bgColor="#0A7E32"
                          height="20px"
                          labelSize="12px"
                          transitionDuration="2s"
                          animateOnRender={true}
                          customLabelStyles={{
                            paddingLeft: "40px",
                            fontFamily: '"Red Hat Display", sans-serif',
                          }}
                        />
                        <div className="stats d-flex justify-content-start gap-3 py-2">
                          <span
                            style={{
                              fontFamily: '"Red Hat Display", sans-serif',
                            }}
                            className="item-value p-0"
                          >
                            {application?.documents?.length} items &bull;{" "}
                          </span>
                          <span
                            style={{
                              fontFamily: '"Red Hat Display", sans-serif',
                            }}
                            className="item-value p-0"
                          >
                            {
                              application?.documents?.filter(
                                (doc) => doc.isValidated === true
                              )?.length
                            }{" "}
                            validated &bull;
                          </span>
                          <span
                            style={{
                              fontFamily: '"Red Hat Display", sans-serif',
                            }}
                            className="item-value p-0"
                          >
                            {
                              application?.documents?.filter(
                                (docs) => docs?.revisions?.length > 0
                              )?.length
                            }{" "}
                            sent for revision
                          </span>
                        </div>
                      </div>

                      <div className="pe-0 me-0 w-100">
                        <>
                          <DocumentTable
                            application={application}
                            icon={"creditCheck"}
                            documentName={"Credit Check"}
                            isValidated={true}
                            hasButton={false}
                          />
                          {application?.documents.map((document) => (
                            <>
                              <DocumentTable
                                application={application}
                                hasButton={true}
                                key={document?.applicationDocumentId}
                                documentName={convertToTitleCase(
                                  document?.document?.option?.name
                                )}
                                icon={document.icon}
                                document={document}
                                isValidated={document?.isValidated}
                                applicationStatus={application.status}
                                url={document?.document?.document}
                              />
                            </>
                          ))}
                        </>
                      </div>
                    </>
                  )}
                </div>
              </TabGroup>
            </>
          )}
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view portfolio. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}
    </div>
  );
};

export default PortfolioDetails;
