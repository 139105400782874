import { useMutation, useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";

const useProspect = (reminderName, page) => {
  const { getRequest, postRequest } = useAxiosBase();
  const { authUser } = useAuth();
  const organizationId = authUser?.user?.organizationId;

  const getProspects = useQuery({
    queryKey: ["Prospect"],
    queryFn: () =>
      getRequest({ url: `/bank/prospect/get-all?pageNumber=${page}` }).then(
        (res) => res?.data?.value
      ),

    staleTime: 50000,
  });

  const sendProspectReminder = useMutation({
    mutationFn: (prospectId) =>
      postRequest({
        url: `/bank/prospect/${prospectId}/send-reminder`,
      }),
    onSuccess: (data) => {
      toast.success(`A reminder has been sent to ${reminderName} to revisit your property`);
    },
  });

  return { getProspects, sendProspectReminder };
};

export default useProspect;
