import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const EditorWrapper = styled.div`
  .editor {
    background: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : colors.grey};
    border: ${`2px solid ${colors.grey}`};
    font-size: 14px;
    color: #4b4b4b;
    transition: all 0.25s ease;
    border-radius: 10px;
    box-shadow: none !important;
    padding-bottom: 0.5rem;
    overflow: hidden;
  }

  .editor:hover {
    background-color: white;
    border: 2px solid ${colors.darkGreen} !important;
  }

  .ql-container {
    height: ${(props) => (props.height ? `${props.height}` : "9rem")};
  }

  .editor:focus-within {
    background-color: white;
  }

  .ql-container.ql-snow {
    border: none;
  }

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #cccccc !important;
  }

  .editor:hover .ql-toolbar.ql-snow {
    /* border-bottom: 1px solid #f0f0f0 !important; */
    /* border: 2px solid ${colors.darkGreen} !important; */
  }
`;
