import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

export const useGetTransactions = () => {
    const { getRequest } = useAxiosBase();
  
    return useQuery({
      queryKey: ["transactions"],
      queryFn: () =>
        getRequest({ url: `/bank/transaction/get-all` }).then(
          (res) => res?.data?.value?.data
        ),
      staleTime: 50000,
    });
  };
  