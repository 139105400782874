import React, { useState, useEffect } from "react";
import usePermission from "../../hooks/usePermissions";
import { useNav } from "../../hooks/useNav";
import "./mortgagePlans.css";
import { useNavigate } from "react-router";
import useAxiosBase from "../../hooks/useAxiosBase";
import Button from "../../Components/Common/Button";
import Modal from "../../Components/Common/Modal";
import useGetPlans from "./hooks/useGetPlans";
import InfoTable from "../../Components/Common/InfoTable";
import InfoCard from "../../Components/Common/InfoCard";
import CenteredText from "../../Components/CenteredText";
import useDeletePlans from "./hooks/useDeletePlans";
import TableLoader from "../../Components/Common/TableLoader";
import SkeletonLoader from "../../Components/SkeletonLoader";
import CellSelector from "../../Components/Common/CellSelector";
import { convertToTitleCase, formattedPrice } from "../../utils/constants";
import { Dropdown } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";

const Plans = () => {
  const navigate = useNavigate();
  const { getRequest } = useAxiosBase();
  useNav({
    mainTitle: "Mortgage Plans",
    subTitle:
      "This shows the different types of mortgages your company accepts from customers. You can also create custom mortgage plans solely sold by your bank.",
  });

  const {
    accessViewPlans,
    accessCreatePlan,
    accessUpdatePlan,
    accessDeletePlan,
    setAccessDeletePlan,
    setAccessUpdatePlan,
  } = usePermission();

  const [planId, setPlanId] = useState("");
  const [pagination, setPagination] = useState(1);

  const {
    data: plans,
    isLoading,
    refetch,
    isRefetching,
  } = useGetPlans(pagination);

  const { deletePlans } = useDeletePlans(planId);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // PLANS COLUMN
  const plansColumns = (showEditModal, showDeleteModal, loading) => {
    const columns = [
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            PLAN TYPE
          </p>
        ),
        sortable: true,
        wrap: true,
        grow: 1.5,
        selector: (row) => (
          <CellSelector loading={loading}>
            {convertToTitleCase(row?.type)}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            PLAN NAME
          </p>
        ),
        sortable: true,
        wrap: true,
        grow: 1.5,
        selector: (row) => (
          <CellSelector loading={loading}>{row?.name}</CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            DELIVERY LEVEL
          </p>
        ),
        sortable: true,
        wrap: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {convertToTitleCase(row?.deliveryLevel)}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            DELIVERY PRICE
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            <>₦ {formattedPrice(row?.deliveryLevelPrice)}</>
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            MAX LOAN AMOUNT
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row?.maxLoanAmount === 0 ? (
              "---"
            ) : (
              <>₦ {formattedPrice(row?.maxLoanAmount)}</>
            )}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            DOWNPAYMENT
          </p>
        ),
        sortable: true,
        wrap: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row?.deliveryLevelPrice > row?.maxLoanAmount ? (
              <>
                {row?.equityPercentage}% (₦{" "}
                {formattedPrice(
                  (
                    row?.deliveryLevelPrice *
                    (row.equityPercentage / 100)
                  )?.toFixed(2)
                )}
                )`
              </>
            ) : (
              <>
                {row?.equityPercentage}% (₦{" "}
                {formattedPrice(
                  (row?.maxLoanAmount * (row.equityPercentage / 100))?.toFixed(
                    2
                  )
                )}
                )`
              </>
            )}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            PERIOD
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row?.loanTenor
              ? `${row?.loanTenor} ${row?.loanTenor > 1 ? "Months" : "Month"}`
              : "---"}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            INTEREST RATE
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            <>{row?.interestRate}%</>
          </CellSelector>
        ),
      },
    ];

    // Check if accessSendProspectReminder.value is true before adding the action column
    if (accessUpdatePlan?.value || accessDeletePlan?.value) {
      columns.push({
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            {" "}
            ACTION{" "}
          </p>
        ),
        sortable: false,
        grow: 0.5,
        center: true,
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle id={`dropdown-${row.id}`}>
              <HiDotsVertical className="fw-bold text-dark" />
            </Dropdown.Toggle>

            {!loading && (
              <Dropdown.Menu>
                {accessUpdatePlan?.value && (
                  <Dropdown.Item onClick={() => showEditModal(row.id)}>
                    Edit
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            )}
          </Dropdown>
        ),
      });
    }
    return columns;
  };

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination]);

  return (
    <div className="container-fluid mortgage-plans">
      <div className="col-12 text-end">
        <Button
          btnText={"Create Plans"}
          onBtnClick={() => navigate("/plans/create")}
        />
      </div>

      <div className="">
        <InfoCard
          mainValue={
            isLoading || isRefetching ? (
              <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
            ) : (
              plans?.totalRecords ?? "0"
            )
          }
          description={
            isLoading || isRefetching ? <SkeletonLoader /> : "Total Plans"
          }
        />
      </div>

      <div className="mb-4">
        {isLoading ? (
          <div className="mt-4">
            <TableLoader />
          </div>
        ) : !plans?.data?.length ? (
          <div className="mt-5">
            <CenteredText title={"You have no plans at this time"}>
              {" "}
              When a customer shows interest in a property, their information
              will be displayed here.
            </CenteredText>
          </div>
        ) : (
          <div className="col-12">
            <InfoTable
              columns={plansColumns(
                (rowId) =>  navigate(`/plans/edit/${rowId}`),
                (rowId) => {
                  setPlanId(rowId);
                  setShowDeleteModal(true);
                },
                isRefetching
              )}
              dataCollection={plans?.data}
              pointerOnHover={true}
              highlightOnHover={true}
              loadingTable={isRefetching}
              paginationTotalRows={plans?.totalRecords}
              onChangePage={(page) => setPagination(page)}
              onRowClicked={(row, event) => navigate(`/plans/edit/${row?.id}`)}
            />
          </div>
        )}
      </div>

      {/* DELETE MODAL */}
      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={"Delete this Plan."}
        confirmModal={() =>
          deletePlans.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowDeleteModal(false);
            },
          })
        }
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Delete"}
        isBtnLoading={deletePlans.isLoading}
        disabled={deletePlans.isLoading}
      >
        <p className="m-0 text-center">
          Are you sure you want to delete?{" "}
          <strong className="text-danger">This action can't be undone.</strong>
        </p>
      </Modal>
    </div>
  );
};

export default Plans;
