import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosService from "../services/AxiosService";
import useAuth from "./useAuth";
import { toast } from "react-toastify";
import usePermission from "./usePermissions";
import setPermission from "../utils/permissions/setPermission";

const http = new AxiosService("/account/login");
const useLogin = (reset) => {
  const { setAuthUser } = useAuth();
  const permissionFunctions = usePermission();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleSuccess = (response) => {
    const { value } = response;
    if (value.statusCode === 200) {
      const { user, token } = value.value;
      setAuthUser({ user, token });
      sessionStorage.setItem("user", JSON.stringify(value.value));
      setPermission(
        user?.applicationRole?.access &&
          JSON.parse(user?.applicationRole?.access),
        permissionFunctions
      );
      // mixpanel.identify(value.value?.user?.id);
      navigate(from, { replace: true });
    } else if ([400, 500].includes(value.statusCode)) {
      reset();
      toast.error("Incorrect Email or Password");
    }
  };

  return useMutation({
    mutationFn: ({ email, password, type }) => http.create({ email, password, type }),
    onSuccess: handleSuccess,
    onError: () => toast.error("Incorrect Email or Password"),
  });
};

export default useLogin;
