import { useState } from "react";
import { fileExtension, getBase64Image } from "../utils/imageUtils";
import { toast } from "react-toastify";

const useSaveSingleImage = ({ optionsId }) => {
  const [imageToDisplay, setImageToDisplay] = useState();
  const [imageObject, setImageObject] = useState();

  const handleChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        // Check if the file size is greater than 2MB
        toast.error("File size should be less than 2MB");
        return;
      }
      if (!["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
        // Check if the file type is jpeg, png, or jpg
        toast.error("Please upload a JPEG, PNG, or JPG image");
        return;
      }
      setImageToDisplay(URL.createObjectURL(file));
      URL.revokeObjectURL(file);
      const base64 = await getBase64Image(file);
      let newImage = [
        {
          base64: base64,
          name: file.name,
          optionId: optionsId,
          description: "description",
          extension: fileExtension(file),
        },
      ];
      setImageObject(newImage);
    }
  };

  const deleteImage = () => {
    setImageToDisplay(null);
    setImageObject(null);
  };
  return { imageToDisplay, imageObject, handleChange, deleteImage };
};
export default useSaveSingleImage;
