import React from "react";
import { useNav } from "../../hooks/useNav";
import InfoCard from "../../Components/Common/InfoCard";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import { colors, months, years } from "../../utils/constants";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const Earnings = () => {
  useNav({ mainTitle: "Earnings", subTitle: "Know how much you’ve earned" });
  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="d-flex justify-content-end gap-3 pt-2 pb-3">
          <div>
            <CustomDropdown
              listItem={months}
              defaultItem={months[new Date().getMonth()].value}
              width={150}
            />
          </div>
          <div>
            <CustomDropdown
              listItem={years()}
              defaultItem={years()[0].value}
              width={140}
            />
          </div>
        </div>
        <div className="d-flex gap-4 my-3">
          <div className="">
          <InfoCard
                positive={true}
                topValue={"15%"}
                percentage={true}
                mainValue={"N120,000,000"}
                description={"Total Earnings"}
                height={161}
              />
          </div>
          <div className="">
          <InfoCard
                positive={true}
                topValue={"15%"}
                percentage={true}
                mainValue={"N120,000"}
                description={"Average Earnings Per Transaction"}
                height={161}
              />
          </div>
          <div className="">
          <InfoCard
                positive={true}
                topValue={"15%"}
                percentage={true}
                mainValue={"N3,000,000"}
                description={"Highest Grossing Property"}
                miniDescription={"No 23 Joseph Waku Street, Gwarimpa, Abuja"}
                height={161}
              />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-8 mt-5">
          <div className="border border-2 rounded">
            <Bar
              options={{
                responsive: true,
                plugins: {
                  datalabels: { display: true },
                  legend: { position: "top", display: false },
                  title: { display: true, text: "Earnings by attribution" },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    display: false,
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
              data={{
                labels: ["Fast Track", "NHF", "Diaspora", "NHF Diaspora"],
                datasets: [
                  {
                    data: [5, 5, 3, 6],
                    backgroundColor: colors.darkGreen,
                    borderRadius: [100, 100, 100, 1],
                    barThickness: 30,
                    borderWidth: 1,
                    datalabels: {
                      color: "white",
                    },
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="col-4 mt-5">
          <div className="border border-2 rounded">
            <Bar
              height={495}
              options={{
                responsive: true,
                maintainAspectRatio: false,

                plugins: {
                  datalabels: { display: true },
                  legend: { position: "top", display: false },
                  title: { display: true, text: "Earnings by region" },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    display: false,
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
              data={{
                labels: [
                  "Within Nigeria",
                  "",
                  "",
                  "Outside Nigeria",
                  "NHF Diaspora",
                ],
                datasets: [
                  {
                    data: [6, 0.0005, 0.0005, 8, 6],
                    backgroundColor: colors.darkGreen,
                    borderRadius: [100, 100, 100, 1],
                    barThickness: 30,
                    borderWidth: 1,
                    datalabels: {
                      color: "white",
                    },
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Earnings;
