import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";


const useSendInvite = () => {
    const { postRequest } = useAxiosBase();

    const inviteMember = useMutation({
        mutationFn: (data) =>
            postRequest({
                url: `/bank/user/send-invite`,
                data: data,
            }).then((res) => {
                console.log("DANGExxxxxxR", res);
                if (res?.data?.value?.statusCode === 200) {
                    toast.success("Invite has been Succefully sent to the created user email.");
                }
                if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
                    toast.error(res?.data?.value?.message);
                }
            }),
        onSuccess: (res, res1) => {
            if (res?.data?.value?.statusCode === 200) {
                toast.success("Invite has been Succefully sent to the created user email.");
            }
            if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
                toast.error(res?.data?.value?.message);
            }
        },
        onError: (error) => {
            console.log("ERRROR", error);
            toast.error(error);
        },
    });

    return { inviteMember };
}

export default useSendInvite;
