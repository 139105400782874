const houseModel = {
  //   mortgagePlanId: "",
  //   estateId: "",
  price: 0,
  cityId: "",
  address: "",
  units: 0,
  popularLandmark: "",
  //   longitude: 0,
  //   latitude: 0,
  description: "",
  features: [
    {
      name: "",
      icon: "",
    },
  ],
  images: [
    {
      base64: "",
      optionId: "",
      extension: "",
    },
  ],
};
export default houseModel;
