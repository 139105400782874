import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useSendReminder = (transactionId) => {
  const { postRequest } = useAxiosBase();

  const sendReminder = useMutation({
    mutationKey: ["send-reminder", transactionId],
    mutationFn: (data) =>
      postRequest({
        url: `bank/transaction/${transactionId}/send-reminder`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.statusCode === 200) {
          toast.success("A reminder has benn sent");
        } else {
          toast.error(res?.response?.data?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { sendReminder };
};

export default useSendReminder;
