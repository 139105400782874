import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "./useAxiosBase";

const useEstate = () => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryFn: () =>
      getRequest({
        url: `/bank/estate/get-all`,
      }).then((res) => res.data?.value?.value?.data),
    retry: 2,
    staleTime: 5000,
  });
};

export default useEstate;
