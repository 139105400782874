import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useUploadOfferLetter = (applicationId) => {
  const { postRequest, postReq } = useAxiosBase();

  const uploadOfferLetter = useMutation({
    mutationFn: (data) =>
      postReq({
        url: `bank/application/${applicationId}/upload-mortgage-term`,
        data: data,
      }),
    onError: (error) => {
      toast.error(error);
    },
  });

  return { uploadOfferLetter };
};

export default useUploadOfferLetter;
