import React from "react";
import DataTable from "react-data-table-component";
import { tableLengthSelection } from "./constants";
import Button from "../Button";
import { colors } from "../../../utils/constants";
import { Wrapper } from "./style";

const InfoTable = ({
  columns,
  leftButton,
  rightButton,
  dataCollection,
  leftBtnClick,
  rightBtnClick,
  mainTitle,
  subTitle,
  rightTextAsButton,
  handleSpanTextClick,
  spanText,
  className,
  pageTotal,
  pointerOnHover,
  highlightOnHover,
  onRowClicked,
  onChangePage,
  paginationServer,
  paginationTotalRows,
  loadingTable = false,
}) => {
  return (
    <Wrapper className={className}>
      <div className="row m-0 mb-2">
        <div className="col-lg-6 col-md-6 col-sm-4">
          <h5 className="mb-0 mt-2">
            {mainTitle}
            {subTitle && -(<span>{subTitle}</span>)}
          </h5>
        </div>
        {(leftButton || rightButton || rightTextAsButton) && (
          <div className="col-lg-6 col-md-6 col-sm-4">
            <div className="row">
              {leftButton && (
                <div className="col-md-8 d-flex justify-content-end">
                  {" "}
                  <Button
                    btnText="Sort & filter"
                    lefticonname={"filter"}
                    type={"button"}
                    padding={"12px 20px"}
                    iconcolor={colors.primary}
                    btntextcolor={colors.primary}
                    backgroundcolor={colors.white}
                    iconcolorHover={colors.hover}
                    colorHover={colors.hover}
                    borderHover={colors.hover}
                    backgroundHover={colors.white}
                    onBtnClick={leftBtnClick}
                  />
                </div>
              )}
              {rightButton && (
                <div className="col-md-4 d-flex justify-content-start">
                  <Button
                    type={"button"}
                    btnText="Add House"
                    onBtnClick={rightBtnClick}
                  />
                </div>
              )}
              {rightTextAsButton && (
                <div className="col-md-11 d-flex justify-content-end">
                  <span className="spanText" onClick={handleSpanTextClick}>
                    {spanText}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="dt-bn" style={{ position: "relative" }}>
        <DataTable
          columns={columns}
          data={dataCollection}
          fixedHeader
          pagination
          striped
          persistTableHead
          paginationPerPage={pageTotal ?? 10}
          paginationRowsPerPageOptions={tableLengthSelection(
            dataCollection?.length
          )}
          pointerOnHover={pointerOnHover}
          highlightOnHover={highlightOnHover}
          paginationServer={paginationServer ?? true}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          // progressPending={loadingTable}
          // progressComponent={<Skeleton count={5} baseColor={"#f0f0f0"} style={{position: "absolute"}}/>}
          paginationTotalRows={paginationTotalRows}
          onChangePage={onChangePage}
          onRowClicked={onRowClicked}
        />
      </div>
    </Wrapper>
  );
};

export default InfoTable;
