import ReactQuill from "react-quill";
import { EditorWrapper } from "./style";
import { useCallback } from "react";

const CustomTextEditor = ({ labelName, error, value, name, register, onChange, placeholder, ...rest }) => {

  return (
    <EditorWrapper {...rest}>
      {labelName && (
        <label
          htmlFor={rest.name}
          className="form-label ml-4 custom-form-label"
        >
          {labelName} {rest.required && <span className="required">*</span>}
        </label>
      )}
      <div>
        <ReactQuill
          theme="snow"
          required={rest.required}
          name={name}
          value={value}
          className="editor"
          onChange={onChange}
        />
      </div>
      {error && <div className="form-error">{error}</div>}
    </EditorWrapper>
  );
};

export default CustomTextEditor;
