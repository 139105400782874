import { HiDotsVertical } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import { colors } from "../../utils/constants";
import CellSelector from "../../Components/Common/CellSelector";
import Badges from "../../Components/Common/Badges";

export const listItem = [
  { id: "PENDING", name: "Active Viewings" },
  { id: "VIEWED", name: "Past Viewings" },
];

export const defalultOptions = [
  { id: "Yes", name: "Yes" },
  { id: "No", name: "No" },
];

export const settingsOptions = [
  { id: "No", name: "No" },
  { id: "Yes", name: "Yes" },
];

const defaultImage =
  "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

export const customerColums = (handleAction, loading) => [
  // {
  //   name: <p className="fw-bold p-0 m-0">ID</p>,
  //   sortable: true,
  //   selector: (row) => toApplicationNumber(row.id),
  //   grow: 0,
  // },
  {
    name: <p className="fw-bold p-0 m-0"> CUSTOMER </p>,
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.customer?.name}</CellSelector>
    ),
  },
  {
    name: <p className="fw-bold p-0 m-0">PHONE NO</p>,
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.customer?.phoneNumber}</CellSelector>
    ),
  },
  {
    name: <p className="fw-bold p-0 m-0">PROPERTY</p>,
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row.house?.name ?? "---"}
        {/* <>
          <span className="fw-semibold">
            {convertToTitleCase(row.house?.type)}
          </span>{" "}
          <span className="circle_bar"></span> {row.house?.address}
        </> */}
      </CellSelector>
    ),
    wrap: true,
    grow: 2,
  },
  {
    name: <p className="fw-bold p-0 m-0">INSPECTION STAFF</p>,
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.agent?.name}</CellSelector>
    ),
  },
  {
    name: <p className="fw-bold p-0 m-0">DATE</p>,
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row.viewingDate ? new Date(row.viewingDate).toDateString() : "--"}
      </CellSelector>
    ),
  },
  {
    name: <p className="fw-bold p-0 m-0">TIME</p>,
    sortable: true,
    grow: 0.5,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row.viewingDate
          ? new Date(row.viewingDate).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
          : "--"}
      </CellSelector>
    ),
  },
  {
    name: <p className="fw-bold p-0 m-0">STATUS</p>,
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {(() => {
          let displayText, tooltipText, borderColor, bgColor, textColor;

          if (
            row.status === "PENDING" &&
            row.isAgentConfirmed &&
            row.isCustomerConfirmed
          ) {
            displayText = "Booking Confirmed";
            tooltipText =
              "The booking is confirmed by both agent and customer.";
            borderColor = colors.darkGreen;
            bgColor = colors.lightGreen;
            textColor = colors.darkGreen;
          } else if (
            row.status === "HOUSE_INSPECTION_COMPLETED" &&
            row.isAgentConfirmed &&
            row.isCustomerConfirmed
          ) {
            displayText = "Completed";
            tooltipText =
              "The booking has been confirmed and inspection has been completed.";
            borderColor = colors.darkGreen;
            bgColor = colors.lightGreen;
            textColor = colors.darkGreen;
          } else if (
            row.status === "CUSTOMER_RESCHEDULED" &&
            !row.isAgentConfirmed
          ) {
            displayText = "Booking Rescheduled";
            tooltipText =
              "Customer rescheduled the booking, pending agent confirmation.";
            borderColor = colors.darkOrange;
            bgColor = colors.orange;
            textColor = colors.darkOrange;
          } else if (
            row.status === "AGENT_RESCHEDULED" &&
            !row.isCustomerConfirmed
          ) {
            displayText = "Pending Confirmation";
            tooltipText =
              "Agent rescheduled the booking, pending customer confirmation.";
            borderColor = colors.darkOrange;
            bgColor = colors.orange;
            textColor = colors.darkOrange;
          } else if (
            row.status === "VIEWED" &&
            row.isAgentConfirmed &&
            row.isCustomerConfirmed
          ) {
            displayText = "Viewing Acknowledged";
            tooltipText =
              "The viewing has been acknowledged by both agent and customer.";
            borderColor = colors.darkGreen;
            bgColor = colors.lightGreen;
            textColor = colors.darkGreen;
          } else if (
            row.status === "AGENT_CANCELLED_BOOKING" ||
            row.status === "CUSTOMER_CANCELLED_BOOKING"
          ) {
            displayText = "Booking Cancelled";
            tooltipText = "The booking was cancelled.";
            borderColor = colors.danger;
            bgColor = colors.dangerLight;
            textColor = colors.danger;
          } else {
            displayText = "Pending";
            tooltipText = "The application has not been reviewed yet.";
            borderColor = colors.darkOrange;
            bgColor = colors.orange;
            textColor = colors.darkOrange;
          }

          return (
            <Badges
              text={displayText}
              title={tooltipText}
              backgroundcolor={bgColor}
              bordercolor={borderColor}
              color={textColor}
            />
          );
        })()}
      </CellSelector>
    ),
  },
  // {
  //   name: <p className="fw-bold p-0 m-0">VIEWING FEE</p>,
  //   sortable: true,
  //   selector: (row) => (
  //     <CellSelector loading={loading}>
  //       ₦ {formattedPrice(row.viewingFee)}
  //     </CellSelector>
  //   ),
  // },
  {
    name: <p className="fw-bold p-0 m-0">ACTION</p>,
    sortable: true,
    center: true,
    grow: 0.3,
    cell: (row) => {
      let dropdownItems = [];

      if (
        row.status === "PENDING" &&
        row.isAgentConfirmed &&
        row.isCustomerConfirmed
      ) {
        dropdownItems = [
          { label: "View Details", action: "viewDetails" },
          // { label: "Acknowledge Booking", action: "acknowledgeBooking" },
          { label: "Cancel Booking", action: "cancelBooking" },
        ];
      } else if (
        row.status === "HOUSE_INSPECTION_COMPLETED" &&
        row.isAgentConfirmed &&
        row.isCustomerConfirmed
      ) {
        dropdownItems = [{ label: "View Details", action: "viewDetails" }];
      } else if (
        row.status === "CUSTOMER_RESCHEDULED" &&
        !row.isAgentConfirmed
      ) {
        dropdownItems = [
          { label: "View Details", action: "viewDetails" },
          { label: "Confirm Booking", action: "confirmBooking" },
          { label: "Reschedule Booking", action: "rescheduleBooking" },
          { label: "Cancel Booking", action: "cancelBooking" },
        ];
      } else if (
        row.status === "AGENT_RESCHEDULED" &&
        !row.isCustomerConfirmed
      ) {
        dropdownItems = [
          { label: "View Details", action: "viewDetails" },
          { label: "Reschedule Booking", action: "rescheduleBooking" },
          { label: "Cancel Booking", action: "cancelBooking" },
        ];
      } else if (
        row.status === "VIEWED" &&
        row.isAgentConfirmed &&
        row.isCustomerConfirmed
      ) {
        dropdownItems = [{ label: "View Details", action: "viewDetails" }];
      } else if (
        row.status === "AGENT_CANCELLED_BOOKING" ||
        row.status === "CUSTOMER_CANCELLED_BOOKING"
      ) {
        dropdownItems = [{ label: "View Details", action: "viewDetails" }];
      } else {
        dropdownItems = [
          { label: "View Details", action: "viewDetails" },
          { label: "Confirm Booking", action: "confirmBooking" },
          { label: "Reschedule Booking", action: "rescheduleBooking" },
          { label: "Cancel Booking", action: "cancelBooking" },
        ];

        if (
          row.status?.isAgentConfirmed &&
          row.status?.isCustomerConfirmed
        ) {
          dropdownItems.push({
            label: "Mark as Viewed",
            action: "markAsViewed",
          });
        }
      }

      return (
        <Dropdown>
          <Dropdown.Toggle id={`dropdown-${row.id}`}>
            <HiDotsVertical className="fw-bold text-dark" />
          </Dropdown.Toggle>

          {!loading && (
            <Dropdown.Menu>
              {dropdownItems.map((item) => (
                <Dropdown.Item
                  key={item.action}
                  onClick={() => handleAction(row.id, item.action)}
                  className={
                    item.action === "cancelBooking" ? "text-danger" : ""
                  }
                >
                  {item.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          )}
        </Dropdown>
      );
    },
  },
];
