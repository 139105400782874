import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";

const useGetRemitaPaymentConfiguration = () => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["remita-payment-configuration"],
    queryFn: () =>
      getRequest({
        url: "/developer/system-configuration/get-remita-payment-configuration",
      }).then((res) => res.data?.value?.value),
    staleTime: 50000,
  });
};
export default useGetRemitaPaymentConfiguration;