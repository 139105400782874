import React from "react";
import { colors } from "../../../../utils/constants";
import "../Table/table.css";
import CheckInput from "../../../../Components/Common/CheckInput";

const TSubHeader = ({ title, allChecked, handleToggleAll }) => {
  return (
    <div className="d-flex justify-content-between sub-header-wrapper" style={{ background: colors.grey }}>
      <h4 className="sub-header-text p-4" style={{ background: colors.grey }}>
        {title}
      </h4>
      <div className="d-flex gap-2 align-items-center fw-semibold me-3" style={{ background: colors.grey }}>
        <p className="m-0 pt-1">{allChecked ? "Unselect All" : "Select All"}</p>
        <CheckInput
          name="toggleAll"
          checked={allChecked}
          checkTick
          width={"23px"}
          height={"23px"}
          bcolor={"#0000001A"}
          bordercolor={"#CECECE"}
          onChange={handleToggleAll}
        />
      </div>
    </div>
  );
};

export default TSubHeader;
