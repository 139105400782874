import React, { useState } from "react";
import InfoTable from "../../../Components/Common/InfoTable";
import DetailCard from "../../../Components/Common/DetailCard";
import { PiWarningDuotone } from "react-icons/pi";
import { colors } from "../../../utils/constants";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../../Components/Spinner";
import CenteredText from "../../../Components/CenteredText";

const EmploymentLetter = ({ columns, columnData, applicationId }) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const { getRequest } = useAxiosBase();
  const { isLoading } = useQuery({
    queryKey: ["employment_letter_report", applicationId],
    queryFn: () =>
      getRequest({
        url: `/developer/application/${applicationId}/get-analyzed-employment-report`,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          setData(res.data?.value?.value);
        }
        return res?.data;
      }),
    staleTime: 5000,
  });
  if (isLoading) return <Spinner />;
  return data === null || !data ? (
    <div className=" mt-5 pt-5">
      <CenteredText
        title={"No Report Generated"}
        children={
          "Unable to generate employment report for this application at this time."
        }
      />
    </div>
  ) : (
    <div className="container mt-5">
      <div className="row justify-content-center d-flex">
        <div className="col-9">
          <DetailCard
            title={"Employment Date"}
            subtitle={data?.employment_date ?? "NIL"}
          />
        </div>
        <div className="col-3 justify-content-end">
          <DetailCard title={"Job Role"} subtitle={data?.job_role ?? "NIL"} />
        </div>
        <div className="col-9 mt-5">
          <DetailCard
            title={"Verification Details"}
            subtitle={
              "You can contact the following people to verify Ngutor John Ikpaahindi’s current employment status"
            }
          />
        </div>
        <div className="col-3 justify-content-end mt-5">
          <DetailCard title={"Salary"} subtitle={data?.salary ?? "NIL"} />
        </div>
        {data?.sources !== null || !data?.sources ? (
          <>
            <div className="col-12 mt-3">
              <InfoTable columns={columns} dataCollection={data?.sources} />
            </div>
            <div className="col-12 mt-3">
              <div className="row border border-3  rounded-2 warning-alert">
                <div className="col-1 d-flex align-items-center">
                  <PiWarningDuotone
                    color={colors.yellow}
                    size={50}
                    stroke={colors.yellow}
                    strokeWidth={3}
                    fill={colors.black}
                  />
                </div>
                <div className="col-11 p-2 g-0">
                  <p>
                    While we advise you to contact the above-named person(s) if
                    you want to perform an additional layer of verification, you
                    can validate the document by closing this popup if you think
                    the applicant’s employment is valid. Also, we automatically
                    sent out emails to the above-named person(s). Once they
                    verify the applicant’s employment status, we will
                    automatically validate this document.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="col-12 mt-3">
            <div className="row border border-3  rounded-2 warning-alert">
              <div className="col-1 d-flex align-items-center">
                <PiWarningDuotone
                  color={colors.yellow}
                  size={50}
                  stroke={colors.yellow}
                  strokeWidth={3}
                  fill={colors.black}
                />
              </div>
              <div className="col-11 p-2 g-0">
                <p>
                  We could not find any people we could contact from the
                  applicant’s organization about their employment status. This
                  could happen if the applicant’s company has little to no
                  presence on the Internet. We advise you to close this popup
                  and view or download the applicant’s employment letter to see
                  if their employment is valid.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmploymentLetter;
