import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useCreateConfiguration = () => {
  const { postRequest } = useAxiosBase();

  const createRemitaConfiguration = useMutation({
    mutationKey: ["create-remita-configuration"],
    mutationFn: (data) =>
      postRequest({
        url: `/developer/system-configuration/create`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Updated Successfully!");
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.value?.statusCode === 200) {
        toast.success("Updated Successfully!");
      }
      if ([400, 404, 500].includes(res?.data?.value?.value?.statusCode)) {
        toast.error(res?.data?.value?.value?.message);
      }
    },
    onError: (error) => {
      console.log("ERRROR", error);
    },
  });

  return { createRemitaConfiguration };
};

export default useCreateConfiguration;
