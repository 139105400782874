import React, { useEffect, useState } from "react";
import DetailCard from "../../../Components/Common/DetailCard";
import DetailSubCard from "../../../Components/Common/DetailSubCard";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { useQuery } from "@tanstack/react-query";
import {
  displayCreditCheckValues,
  loanHistoryColumn,
  loanSummaryColumn,
} from "../utils/constants";
import InfoTable from "../../../Components/Common/InfoTable";
import CenteredText from "../../../Components/CenteredText";
import { formattedPrice } from "../../../utils/constants";
import Spinner from "../../../Components/Spinner";
import useGetCreditReport from "../hooks/useGetCreditReport";

const CreditCheck = ({ applicationId, defaultPage = 1 }) => {
  const { data: creditReport, isLoading } = useGetCreditReport(applicationId);

  const [page, setPage] = useState(defaultPage);

  const [loanHistory, setLoanHistory] = useState();
  const [summary, setSummary] = useState();

  // const tabs = [
  //   {
  //     title: "CRC",
  //     label: "CRC",
  //     element: {},
  //   },
  //   {
  //     title: "First Central",
  //     label: "FIRST_CENTRAL",
  //     element: {},
  //   },
  //   {
  //     title: "Credit Registry",
  //     label: "CREDIT_REGISTRY",
  //     element: {},
  //   },
  // ];

  // const handleTabChange = (index) => {
  //   setPage(index);
  // };

  useEffect(() => {
    if (creditReport?.score) {
      setSummary(creditReport?.score);
    }
    if (creditReport?.score?.loanHistory) {
      setLoanHistory(creditReport?.score?.loanHistory);
    }
  }, [creditReport]);

  if (isLoading) return <Spinner />;
  return (
    <div className="ps-4 pe-2">
      {/* <div className="tab d-flex justify-content-between">
          {tabs.map((tab, index) => (
            <div
              className={`tab-item w-100  border text-centerp-3 ${
                index === 0 ? "round-start" : ""
              } ${index === tabs?.length - 1 ? "round-end" : ""} ${
                page === index ? "bg-active" : "bg-white"
              }`}
              onClick={() => handleTabChange(index)}
              key={tab.label}
            >
              <div
                className={`tab-item-link ${page === index ? "bg-active" : ""}`}
              >
                {tab.title}
              </div>
            </div>
          ))}
        </div> */}

      {creditReport?.score?.length === 0 ? (
        <div className="mt-5">
          <CenteredText title={"No Existing Loan History"}>
            This user has no existing loan history with any financial
            institution or financial technology (FinTech) company.
          </CenteredText>
        </div>
      ) : (
        <div className="tab-content">
          <h4 className="p-0 m-0">Summary</h4>
          <div
            className="row bg-light rounded p-0 mt-2"
            style={{ border: "1px solid gray" }}
          >
            <>
              <div className="col-2 mx-0 p-3 my-1">
                <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
                  Total Borrowed
                </p>
                <p className="p-0 m-0 mt-1">
                  ₦ {formattedPrice(summary?.totalBorrowed) ?? "0"}
                </p>
              </div>
              <div className="col-2 mx-0 p-3 my-1">
                <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
                  Total Outstanding
                </p>
                <p className="p-0 m-0 mt-1">
                  ₦ {formattedPrice(summary?.totalOutstanding) ?? "0"}
                </p>
              </div>
              <div className="col-2 mx-0 p-3 my-1">
                <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
                  Total Loans
                </p>
                <p className="p-0 m-0 mt-1">{summary?.totalNoOfLoans ?? "0"}</p>
              </div>
              <div className="col-2 mx-0 p-3 my-1">
                <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
                  Total Institutions
                </p>
                <p className="p-0 m-0 mt-1">{summary?.totalNoOfInstitutions ?? "0"}</p>
              </div>
              <div className="col-2 mx-0 p-3 my-1">
                <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
                  Total Overdue
                </p>
                <p className="p-0 m-0 mt-1">{summary?.totalOverdue ?? "0"}</p>
              </div>
              <div className="col-2 mx-0 p-3 my-1">
                <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
                  Total Monthly Instalments
                </p>
                <p className="p-0 m-0 mt-1">
                  ₦ {formattedPrice(summary?.totalMonthlyInstallment) ?? "0"}
                </p>
              </div>
              <div className="col-2 mx-0 p-3 my-1">
                <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
                  Highest Loan Amount
                </p>
                <p className="p-0 m-0 mt-1">
                  ₦ {formattedPrice(summary?.highestLoanAmount) ?? "0"}
                </p>
              </div>
              <div className="col-2 mx-0 p-3 my-1">
                <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
                  Active Loans
                </p>
                <p className="p-0 m-0 mt-1">{summary?.totalNoOfActiveLoans ?? "0"}</p>
              </div>
              <div className="col-2 mx-0 p-3 my-1">
                <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
                  Closed Loans
                </p>
                <p className="p-0 m-0 mt-1">{summary?.totalNoOfClosedLoans ?? "0"}</p>
              </div>
              <div className="col-2 mx-0 p-3 my-1">
                <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
                  Bad Debt/Deliquent Facilities
                </p>
                <p className="p-0 m-0 mt-1">
                  {summary?.totalNoOfDelinquentFacilities ?? "0"}
                </p>
              </div>
            </>
            {/* {displayCreditCheckValues(data?.score).data.map((val) => (
                  <div className="col-2 mx-0  px-0 my-1" key={val.title}>
                    <p className="text-uppercase fw-bold creidt-title m-0 py-3 px-4">
                      {val.title}
                    </p>
                    <p className="px-4 mt-1">
                      {formattedPrice(val.value
                        ?.filter((x) => x.source === tabs[page].label)[0]
                        ?.value?.toString())}
                    </p>
                  </div>
                ))} */}
          </div>

          <div className="mt-4">
            <h4 className="p-0 m-0">Loan History</h4>
            {loanHistory?.length > 0 ? (
              <div>
                <InfoTable
                  columns={loanHistoryColumn}
                  dataCollection={loanHistory}
                />
              </div>
            ) : (
              <div className="mt-5">
                <CenteredText title={"No Recorded Loan History"}>
                  <p className="text-center">
                    This customer has no recorded loans from the two major credit
                    bureaus, CRC and First Central, we pull data from.
                  </p>
                </CenteredText>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditCheck;
