import { isNullOrEmpty } from "../constants";

export const transactionManagement = (userAccess) => {
  if (isNullOrEmpty(userAccess)) return null;
  const access =
    userAccess?.find((x) => x.id === "transactions_management")?.rules || [];

  return {
    viewTransactionAccess:
      access?.find((x) => x.id === "view_transactions") || {},
    viewTransactionRecieptAccess:
      access?.find((x) => x.id === "view_transaction_reciept") || {},
    downloadTransactionRecieptAccess:
      access?.find((x) => x.id === "download_transaction_reciept") || {},
    viewRepaymentScheduleAccess:
      access?.find((x) => x.id === "view_repayment_schedule") || {},
    remindCustomerAccess: access?.find((x) => x.id === "remind_customer") || {},
  };
};
