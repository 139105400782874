import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { singleHouseColumns } from "../../../../utils/constants";
import { useNav } from "../../../../hooks/useNav";
import InfoTable from "../../../../Components/Common/InfoTable";
import CenteredText from "../../../../Components/CenteredText";
import Spinner from "../../../../Components/Spinner";
import { useNavigate } from "react-router-dom";
import { convertToTitleCase } from "../../../../utils/constants";
import Icons from "../../../../Components/Common/Icons";
import Modal from "../../../../Components/Common/Modal";

export const defaultImage =
  "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80";

const Estate = () => {
  useNav({ hasBackButton: true });
  const { getRequest } = useAxiosBase();
  const navigate = useNavigate();
  const params = useParams();
  const estateId = params?.id;

  const {
    data: estateDetails,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["estate", estateId],
    queryFn: () =>
      getRequest({ url: `/bank/estate/${estateId}` }).then(
        (res) => res.data.value?.value
      ),
    staleTime: 50000,
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [editableImageName, setEditableImageName] = useState("");
  const [editableImageType, setEditableImageType] = useState("");

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setEditableImageName(image.name);
    setEditableImageType(image.type);
    setShowImageModal(true);
  };

  if (isLoading)
    return (
      <div style={{ height: "60vh" }}>
        {" "}
        <Spinner loadingText={"Loading Estate Details..."} />{" "}
      </div>
    );

  return (
    <div className="container-fulid px-2">
      <h1> {estateDetails?.name} </h1>

      {/* Display First 4 Images  */}
      <div className="row">
        {estateDetails?.images?.slice(0, 4)?.map((image) => (
          <div
            className="col house_image_wrapper position-relative"
            key={image.name}
            style={{
              height: "248px",
              backgroundImage: `url(${image.document})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="img_overlay position-absolute"></div>
            <div onClick={() => handleImageClick(image)}>
              <p className="img_open">
                <span className="img_open-icon">
                  <Icons iconName={"open"} className="" />
                </span>
                Open
              </p>
            </div>
            <div className="img_footer position-absolute">
              <div className="mt-4">
                {image.type && (
                  <p className="img_type m-0">
                    {convertToTitleCase(image.type)}
                  </p>
                )}
                <div>
                  <p className="img_name mt-2">{image.name}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Thumbnails Section */}
      <div className="col h-25 thumbs-wrapper mt-3 mb-4">
        {estateDetails?.images?.length > 4 &&
          estateDetails?.images?.slice(3).map((image, index) => (
            <div className="thumb position-relative d-flex flex-column ">
              <div
                className="position-relative w-full h-full thumb_img"
                style={{
                  backgroundImage: `url(${image.document})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="thumb_img_overlay position-absolute"></div>
                <p
                  className="thumb_open mt-4"
                  onClick={() => handleImageClick(image)}
                >
                  <span className="thumb_open_icon">
                    <Icons iconName={"open"} className="" />
                  </span>
                  Open
                </p>
              </div>
              <p
                className="my-0 text-success"
                title={image.name}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                }}
              >
                {image.name}
              </p>
            </div>
          ))}
      </div>

      <div className="row mt-4">
        {estateDetails?.name && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold">Name</h6>
            <p> {estateDetails?.name}</p>
          </div>
        )}

        {estateDetails?.city.stateId && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> State </h6>
            <p> {convertToTitleCase(estateDetails.city.stateId)} </p>
          </div>
        )}

        {estateDetails?.city.name && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> City </h6>
            <p> {estateDetails.city.name} </p>
          </div>
        )}

        {estateDetails?.address && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Address</h6>
            <p> {estateDetails.address} </p>
          </div>
        )}

        {estateDetails?.videoUrl && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Video URL</h6>
            <p> {estateDetails.videoUrl ?? "---"} </p>
          </div>
        )}

        {estateDetails?.landmark && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold"> Popular Landmark</h6>
            <p> {estateDetails.landmark} </p>
          </div>
        )}

        {estateDetails?.landSize > 0 && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold">Land Size (In Hectares)</h6>
            <p> {estateDetails.landSize} </p>
          </div>
        )}

        {estateDetails?.completionStatus && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold">Completion Status</h6>
            <p> {convertToTitleCase(estateDetails.completionStatus)} </p>
          </div>
        )}

        {estateDetails.completionDate && (
          <div className="col-md-3 mb-4">
            <h6 className="fw-bold">Completion Date</h6>
            <p> {estateDetails.completionDate} </p>
          </div>
        )}
      </div>

      {estateDetails?.description && (
        <div className="row mb-4">
          <h6 className="fw-bold">Description</h6>
          <p> {estateDetails.description ?? "---"} </p>
        </div>
      )}

      <h3 className="">Houses in this Estate</h3>
      <div>
        {estateDetails?.houses?.length ? (
          <div className="mt-0 mb-4 ">
            <InfoTable
              columns={singleHouseColumns}
              dataCollection={estateDetails?.houses}
              pointerOnHover
              highlightOnHover
              onRowClicked={(row) =>
                navigate(
                  `/properties/partnered-developer-properties/house/${row.id}`
                )
              }
            />
          </div>
        ) : (
          <div className="my-5">
            <CenteredText title="No Houses Added Yet">
              If a house exists in an estate, create an estate and add the house
              under that estate. Houses added under estates will populate here.
              You can also add an individual house (a house not in an estate) by
              clicking the “add house” button above
            </CenteredText>
          </div>
        )}
      </div>

      <Modal
        show={showImageModal}
        title={
          <p className="fw-normal">
            This is how <strong>{editableImageName}</strong> will look to
            customers
          </p>
        }
        closeModal={() => setShowImageModal(false)}
        cancelButtonLabel={"Cancel"}
        cancelModal={() => setShowImageModal(false)}
      >
        <div className="text-center mb-3"></div>
        <div className="d-flex justify-content-center mb-3">
          {selectedImage && (
            <div className="position-relative">
              <img
                src={selectedImage.document}
                alt={selectedImage.name}
                className="img-fluid"
                style={{
                  maxHeight: "50vh",
                  maxWidth: "100%",
                  borderRadius: "10px",
                }}
              />
              <div
                className="position-absolute d-flex justify-content-between align-items-center p-1"
                style={{ width: "100%", top: "80%", left: 0 }}
              >
                <span
                  className="bg-white text-success py-1 px-2 fw-bold"
                  style={{ marginLeft: "10px", borderRadius: "15px" }}
                >
                  {editableImageName}
                </span>
              </div>
            </div>
          )}
        </div>
        <div>
          <p className="fw-bold m-0" style={{ fontSize: "1rem" }}>
            Image Type
          </p>
          <p>
            {editableImageType
              ? convertToTitleCase(editableImageType)
              : "Actual Image"}
          </p>
        </div>

        <div>
          <p className="fw-bold m-0" style={{ fontSize: "1rem" }}>
            Image Name (name of the part of the house)
          </p>
          <p>{editableImageName}</p>
        </div>
      </Modal>
    </div>
  );
};

export default Estate;
