import { isNullOrEmpty } from "../constants";

// PROPERTY ACCESS CONTROL MANAGMENT

export const propertyManagement = (userAccess) => {
  if (isNullOrEmpty(userAccess)) return null;
  const property =
    userAccess?.find((x) => x.id === "property_management")?.rules || [];
  return {
    viewHouseAccess: property?.find((x) => x.id === "view_houses") || {},
    createHouseAccess: property?.find((x) => x.id === "create_house") || {},
    updateHouseAccess: property?.find((x) => x.id === "update_house") || {},
    deleteHouseAccess: property?.find((x) => x.id === "delete_house") || {},
    viewEstateAccess: property?.find((x) => x.id === "view_estates") || {},
    createEstateAccess: property?.find((x) => x.id === "create_estate") || {},
    updateEstateAccess: property?.find((x) => x.id === "update_estate") || {},
    deleteEstateAccess: property?.find((x) => x.id === "delete_estate") || {},
  };
};
