import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useNav } from "../../../hooks/useNav";
import useAuth from "../../../hooks/useAuth";
import Dropdown from "../../../Components/Common/Dropdown";
import { weeks } from "../../../utils/mockData";
import PropertyCompletionStatus from "../../../Components/Common/PropertyCompletionStatus";
import UploadDocument from "../../../Components/Common/UploadDocument";
import FooterButtons from "../../../Components/Common/FooterButtons";
import useGetPartnershipTerms from "./hooks/useGetPartnershipTerms";
import useCreatePertnershipTerms from "./hooks/useCreatePartnershipTerms";
import useDeleteDocument from "./hooks/useDeleteDocument";
import { getBase64Image, fileExtension } from "../../../utils/imageUtils";
import { toast } from "react-toastify";
import Spinner from "../../../Components/Spinner";
import Button from "../../../Components/Common/Button";
import { colors } from "../../../utils/constants";
import LicenseAndCertificationDoc from "../../../Components/Common/License&CertificationDoc";
import { convertToTitleCase } from "../../../utils/constants";
import Modal from "../../../Components/Common/Modal";
import CenteredText from "../../../Components/CenteredText";

const PartnershipTerms = () => {
  useNav({
    mainTitle: "Partnership Terms",
    subTitle: "Setup the terms you want to partner with developers on.",
  });

  const docRef = useRef();
  const [documentId, setDocumentId] = useState("");
  const {
    data: getPartnershipTerms,
    isLoading,
    refetch,
    isRefetching,
  } = useGetPartnershipTerms();

  const { createPertnershipTerms } = useCreatePertnershipTerms();
  const { deleteDocument } = useDeleteDocument(documentId);

  const { authUser } = useAuth();
  const orgId = authUser?.user?.organization?.id;

  const [update, setUpdate] = useState(false);
  const [files, setFiles] = useState([]);
  const [termsObject, setTermsObject] = useState({ organizationId: orgId });
  const [previousCreatedTerms, setPreviousCreatedTerms] = useState();
  const [previousDocument, setPreviousDocument] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!previousDocument && !termsObject?.agreement?.optionId) {
      return toast.error("Please upload a Standard Agreement Document");
    }

    if (documentId) {
      deleteDocument.mutate();
    }

    createPertnershipTerms.mutate(termsObject, {
      onSuccess: () => {
        refetch();
        setUpdate(false);
      },
    });
  };

  const handleFileChange = async (event, document) => {
    const file = event.target.files[0];

    // Check if the uploaded file is a PDF
    if (!file.type.includes("pdf")) {
      toast.error("Please upload a PDF file");
      return;
    }

    URL.revokeObjectURL(file);
    const base64 = await getBase64Image(file);
    let newImage = {
      base64: base64,
      ownerId: orgId,
      optionId: "BANK_PARTNERSHIP_TERM",
      extension: fileExtension(file),
      name: file.name,
      description: "Bank partnership terms",
    };

    setFiles([newImage, ...files]);
    setTermsObject((prev) => ({
      ...prev,
      agreement: newImage,
    }));
  };

  const prevFinished = [
    {
      label:
        previousCreatedTerms?.fullyFinishedFurnished &&
        "Fully Finished (Furnished)",
    },
    {
      label:
        previousCreatedTerms?.fullyFinishedNotFurnished &&
        "Fully Finished (Not Furnished)",
    },
  ];

  const prevConstruction = [
    {
      label: previousCreatedTerms?.semiFinished && "Semi-Finished",
    },
    {
      label: previousCreatedTerms?.shell && "Shell",
    },
    {
      label: previousCreatedTerms?.carcass && "Carcass",
    },
  ];

  const finished = [
    {
      label: "Fully Finished (Not Furnished)",
      selected: termsObject?.fullyFinishedNotFurnished,
      onChange: () => {
        setTermsObject((prev) => ({
          ...prev,
          fullyFinishedNotFurnished: !prev.fullyFinishedNotFurnished,
        }));
      },
    },
    {
      label: "Fully Finished (Furnished)",
      selected: termsObject?.fullyFinishedFurnished,
      onChange: () => {
        setTermsObject((prev) => ({
          ...prev,
          fullyFinishedFurnished: !prev.fullyFinishedFurnished,
        }));
      },
    },
  ];

  const construction = [
    {
      label: "Semi-Finished",
      selected: termsObject?.semiFinished,
      onChange: () => {
        setTermsObject((prev) => ({
          ...prev,
          semiFinished: !prev.semiFinished,
        }));
      },
    },
    {
      label: "Shell",
      selected: termsObject?.shell,
      onChange: () => {
        setTermsObject((prev) => ({
          ...prev,
          shell: !prev.shell,
        }));
      },
    },
    {
      label: "Carcass",
      selected: termsObject?.carcass,
      onChange: () => {
        setTermsObject((prev) => ({
          ...prev,
          carcass: !prev.carcass,
        }));
      },
    },
  ];

  useEffect(() => {
    if (getPartnershipTerms) {
      if (getPartnershipTerms?.agreement?.optionId) {
        setPreviousDocument(true);
      }

      setTermsObject((prev) => ({
        ...prev,
        id: getPartnershipTerms?.id ?? null,
        shell: getPartnershipTerms?.shell,
        carcass: getPartnershipTerms?.carcass,
        agreement: null,
        semiFinished: getPartnershipTerms?.semiFinished,
        paymentPeriod: `Not more than ${getPartnershipTerms?.paymentPeriod} weeks`,
        fullyFinishedFurnished: getPartnershipTerms?.fullyFinishedFurnished,
        fullyFinishedNotFurnished:
          getPartnershipTerms?.fullyFinishedNotFurnished,
      }));

      const filterTerms = (terms) => {
        return Object.keys(terms).reduce((filtered, key) => {
          if (terms[key]) {
            filtered[key] = terms[key];
          }
          return filtered;
        }, {});
      };

      const filteredTerms = filterTerms({
        id: getPartnershipTerms?.id ?? null,
        shell: getPartnershipTerms?.shell,
        carcass: getPartnershipTerms?.carcass,
        agreement: getPartnershipTerms?.agreement,
        semiFinished: getPartnershipTerms?.semiFinished,
        paymentPeriod: `Not more than ${
          getPartnershipTerms?.paymentPeriod ?? 0
        } Weeks`,
        fullyFinishedFurnished: getPartnershipTerms?.fullyFinishedFurnished,
        fullyFinishedNotFurnished:
          getPartnershipTerms?.fullyFinishedNotFurnished,
      });

      setPreviousCreatedTerms(filteredTerms);
    }
  }, [getPartnershipTerms]);

  if (isLoading)
    return (
      <div style={{ height: "60vh" }}>
        {" "}
        <Spinner loadingText={"Loading Partnership Terms Details..."} />{" "}
      </div>
    );

  return (
    <>
      {update ? (
        <form className="row mt-4 px-1">
          <div className="col-md-4">
            <Dropdown
              required
              labelName={"PAYMENT PERIOD"}
              subLabel={
                "When do you pay developers after an initial downpayment has been made?"
              }
              options={weeks}
              placeholder={"Select"}
              onChange={(e) => {
                setTermsObject((prev) => ({
                  ...prev,
                  paymentPeriod: e.target.value,
                }));
              }}
            />
          </div>

          <div className="col-md-8">
            <label className="mainLabel">
              PROPERTY COMPLETION STATUS <sapn className="required">*</sapn>
            </label>
            <label className="subLabel">
              What completion status do you want the developers properties to be
              on before you agree to partner? Select all that apply.
            </label>
            <div className="d-md-flex gap-3">
              <div className="col-md-6">
                <PropertyCompletionStatus
                  title={"MOVE-IN READY"}
                  iconName={"moveInReady"}
                  checkInputs={finished}
                  isCheckbox
                />
              </div>
              <div className="col-md-6">
                <PropertyCompletionStatus
                  title={"UNDER CONSTRUCTION"}
                  iconName={"underConstruction"}
                  checkInputs={construction}
                  isCheckbox
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 mt-4 ps-3">
            {previousDocument ? (
              <div className="col-6">
                <LicenseAndCertificationDoc
                  name={convertToTitleCase(
                    getPartnershipTerms?.agreement?.optionId
                  )}
                  labelName={"STANDARD AGREEMENT"}
                  required
                  extension={getPartnershipTerms?.agreement?.extension?.toUpperCase()}
                  document={getPartnershipTerms?.agreement?.document}
                  deleteDoc
                  handleDelete={() => {
                    setDocumentId(getPartnershipTerms?.agreement?.id);
                    setShowDeleteModal(true);
                  }}
                />
              </div>
            ) : termsObject?.agreement?.optionId ? (
              <div className="col-6 bg-dagner">
                <LicenseAndCertificationDoc
                  name={convertToTitleCase(
                    termsObject?.agreement?.name?.replace(/\.pdf$/i, "")
                  )}
                  labelName={"STANDARD AGREEMENT"}
                  required
                  extension={termsObject?.agreement?.extension?.toUpperCase()}
                  deleteDoc
                  handleDelete={() => setShowDeleteModal(true)}
                />
              </div>
            ) : (
              <div>
                <UploadDocument
                  header={"STANDARD AGREEMENT"}
                  title={"Standard Partnership Agreement"}
                  required
                  docRef={docRef}
                  variant={"upload"}
                  docObject={files}
                  type="pdf/*"
                  handleChange={(event) => handleFileChange(event, files)}
                />
              </div>
            )}
          </div>

          <FooterButtons
            handleClick={handleSubmit}
            cancelBtn={() => setUpdate(false)}
            loading={
              createPertnershipTerms.isLoading || deleteDocument.isLoading
            }
            disabled={
              createPertnershipTerms.isLoading || deleteDocument.isLoading
            }
          />
        </form>
      ) : (
        <>
          {isRefetching ? (
            <div style={{ height: "60vh" }}>
              <Spinner loadingText={"Loading Partnership Terms Details..."} />{" "}
            </div>
          ) : (
            <>
              {previousCreatedTerms?.paymentPeriod ? (
                <div>
                  <div className="d-flex justify-content-end pt-2 pb-3">
                    <Button
                      btnText={"Update"}
                      padding={"10px"}
                      lefticonname={"edit"}
                      iconcolor={colors.primary}
                      btntextcolor={colors.primary}
                      backgroundcolor={colors.white}
                      iconcolorHover={colors.hover}
                      colorHover={colors.hover}
                      borderHover={colors.hover}
                      backgroundHover={colors.white}
                      onBtnClick={() => setUpdate(true)}
                    />
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-3">
                      <p className="mainLabel">PROPERTY COMPLETION STATUS</p>
                      <p className="ms-2">
                        {previousCreatedTerms?.paymentPeriod}
                      </p>
                    </div>

                    <div className="col-md-6 me-md-4">
                      <p className="mainLabel"> PROPERTY COMPLETION STATUS</p>
                      <p className="subLabel">
                        What completion status do you want the developers
                        properties to be on before you agree to partner?{" "}
                        <strong>Select all that apply.</strong>
                      </p>
                      <div className="d-md-flex gap-3">
                        <div className="col-md-6">
                          <PropertyCompletionStatus
                            title={"MOVE-IN READY"}
                            iconName={"moveInReady"}
                            checkInputs={prevFinished}
                          />
                        </div>
                        <div className="col-md-6">
                          <PropertyCompletionStatus
                            title={"UNDER CONSTRUCTION"}
                            iconName={"underConstruction"}
                            checkInputs={prevConstruction}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <LicenseAndCertificationDoc
                        name={convertToTitleCase(
                          previousCreatedTerms?.agreement?.optionId
                        )}
                        extension={previousCreatedTerms?.agreement?.extension?.toUpperCase()}
                        document={previousCreatedTerms?.agreement?.document}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-5">
                  <div className="d-flex justify-content-end pt-2 pb-5">
                    <Button
                      btnText={"Create"}
                      padding={"10px"}
                      onBtnClick={() => setUpdate(true)}
                    />
                  </div>
                  <CenteredText title={"Create Partnership Terms"}>
                    Create the different terms for your partnerhsips with
                    property developers. Partnering with developers allows you
                    sell their properties on your mortgage plans, thereby
                    expanding your market. Click the button above to create your
                    partnership terms.
                  </CenteredText>
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* DELETE MODAL */}
      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={"Delete this Document."}
        confirmModal={() => {
          setPreviousDocument(false);
          setTermsObject((prev) => ({
            ...prev,
            agreement: [],
          }));
          setFiles([]);
          setShowDeleteModal(false);
        }}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Delete"}
      >
        <div>
          <p className="text-center">
            Are you sure you want to delete?{" "}
            <strong className="text-danger">
              This action can't be undone.
            </strong>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default PartnershipTerms;
