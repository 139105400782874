import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useResetPassword = () => {
  const navigate = useNavigate();
  const { postRequest } = useAxiosBase();

  const resetPassword = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `/account/reset-password`,
        data: data,
      }).then((res) => {
        console.log("DANGExxxxxxR", res);
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
          navigate("/account/login")
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success(res?.data?.value?.message);
      }

      if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
        toast.error(res?.data?.value?.message);
      }
    },
    onError: (error) => {
      console.log("ERRROR", error);
    },
  });

  return { resetPassword };
};

export default useResetPassword;
