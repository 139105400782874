import React, { Fragment, useState } from "react";
import { Wrapper } from "./style";
import Icons from "../Icons";

const CustomDropdown = ({
  listItem,
  defaultItem,
  width,
  height,
  placeholder,
  labelName,
  hover,
  onChange,
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const [mainValue, setMainValue] = useState(defaultItem);
  const handleClick = (item) => {
    setMainValue(item.value);
    setShow(false);
    onChange(item.label, item.value);
  };
  const openDropdown = () => {
    setShow(!show);
  };
  return (
    <Wrapper show={show} width={width} height={height} hover={hover} {...rest}>
      {labelName && (
        <label
          htmlFor={rest.name}
          className="form-label ml-4 custom-form-label"
        >
          {labelName} {rest.required && <span className="required">*</span>}
        </label>
      )}
      <div className="select-btn" onClick={openDropdown}>
        <span
          title={mainValue}
          className={mainValue ? "selected-value" : "select-placeholder"}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {mainValue ? mainValue : placeholder}
        </span>
        <Icons className="" iconName={"arrowDown"} />
      </div>
      <div className="content">
        <div className="options">
          {listItem?.length > 0 &&
            listItem.map(
              (item) => (
                <React.Fragment key={item.label}>
                  <p
                    className=""
                    key={item.label}
                    onClick={() => handleClick(item)}
                  >
                    <span
                      className=""
                      title={item.value}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.value}
                    </span>
                    <span className="circle"></span>
                  </p>
                  <hr />
                </React.Fragment>
              ),
              this
            )}
        </div>
      </div>
    </Wrapper>
  );
};

export default CustomDropdown;
