import { Dropdown } from "react-bootstrap";
import CellSelector from "../../../Components/Common/CellSelector";
import { convertToTitleCase } from "../../../utils/constants";
import { HiDotsVertical } from "react-icons/hi";

export const columns = (handleAction, loading) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TYPE
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {convertToTitleCase(convertToTitleCase(row?.type))}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        EMAIL ENABLED
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {convertToTitleCase(row?.emailEnabled ? "Yes" : "No")}
      </CellSelector>
    ),
  },
  // {
  //   name: (
  //     <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
  //       NOTIFICATION USERS
  //     </p>
  //   ),
  //   sortable: true,
  //   selector: (row) => (
  //     <CellSelector loading={loading}>
  //       {row?.notificationUsers?.length > 1
  //         ? convertToTitleCase(row?.notificationUsers[0])
  //         : "---"}
  //     </CellSelector>
  //   ),
  // },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DATE CREATED
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {new Date(row.dateCreated).toDateString()}
      </CellSelector>
    ),
  },
  {
    name: <p className="fw-bold p-0 m-0"> ACTION </p>,
    sortable: false,
    grow: 0.5,
    center: true,
    cell: (row) => (
      <Dropdown>
        <Dropdown.Toggle id={`dropdown-${row.id}`}>
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>
        {!loading && (
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleAction(row.id, "edit")}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleAction(row.id, "delete")}
              className="text-danger"
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    ),
  },
];
