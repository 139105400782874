import React from "react";
import { defaultImage } from "../../../Pages/Developers/constants";
import { styled } from "styled-components";
import Icons from "../Icons";
const PropertyAddress = ({ text, name, element }) => {
  return (
    <div className="row">
      <div className="col-12 xc d-flex flex-row p-3">
        {element === "image" ? (
          <AvatarWrapper>
            <img src={defaultImage} alt="property-avatar" />
          </AvatarWrapper>
        ) : (
          <Icons iconName={name} className="p-1" />
        )}
        <span className="align-items-center ps-3 p-1">{text}</span>
      </div>
    </div>
  );
};
const AvatarWrapper = styled.div`
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;
export default PropertyAddress;
