import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const PropertyCompletionStatusWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  padding: 0.5rem 1rem;

  .header {
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 0.5rem;
    margin-bottom: 0.8rem;
  }
  .iconTitle {
    color: ${colors.darkGreen};
    font-weight: bold;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  .header > span > svg {
    height: 1.2rem;
  }
`;
