import React from "react";
import { colors } from "../../../../utils/constants";

const TDivider = () => {
  return (
        <div
          colSpan={"4"}
          className="mx-2 px-5 rounded"
          style={{height: "30px" }}
        ></div>
  );
};

export default TDivider;
