import React from "react";
import useAxiosBase from "./useAxiosBase";
import { useQuery } from "@tanstack/react-query";

const useGetAllDevelopers = () => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["all-developers"],
    queryFn: () =>
      getRequest({ url: `/public/organization/developer/get-all?PageSize=50` }).then((res) => {
        return res?.data?.value?.value?.data;
      }),
  });
};

export default useGetAllDevelopers;
