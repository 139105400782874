import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useDeleteNotificationConfiguration = (notificationConfigurationId) => {
  const { deleteRequest } = useAxiosBase();

  const deleteNotificationConfiguration = useMutation({
    mutationKey: ["delete-notification-configurationId", notificationConfigurationId],
    mutationFn: (data) =>
      deleteRequest({
        url: `bank/notification-configuration/${notificationConfigurationId}/delete`,
        data: data,
      }).then((res) => {
        console.log("response", res)
        if (res?.data?.statusCode === 200) {
          toast.success("Deleted Successfully!");
        } else {
          toast.error(res?.data?.message)
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.value?.statusCode === 200) {
        toast.success("Deleted Successfully!");
      }
      if ([400, 404, 500].includes(res?.data?.value?.value?.statusCode)) {
        toast.error(res?.data?.response?.message);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  return { deleteNotificationConfiguration };
};

export default useDeleteNotificationConfiguration;
