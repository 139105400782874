import { useMutation, useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";

const useOfferLetter = () => {
  const { getRequest, postRequest } = useAxiosBase();
  const { authUser } = useAuth();
  const organizationId = authUser?.user?.organizationId;

  const getOfferLetter = useQuery({
    queryKey: ["devOfferLetter"],
    queryFn: () =>
      getRequest({ url: `/bank/${organizationId}/offer-letter` }).then(
        (res) => res?.data?.value
      ),

    staleTime: 50000,
  });

  const sendProspectReminder = useMutation({
    mutationFn: (prospectId) =>
      postRequest({
        url: `/bank/${organizationId}/prospect/${prospectId}/send-reminder`,
      }),
    onSuccess: (data) => {
      toast.success("Reminder sent to " + data);
    },
  });

  return { getOfferLetter, sendProspectReminder };
};

export default useOfferLetter;
