import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useHandleApplication = (applicationId) => {
  const { getRequest } = useAxiosBase();
  const { authUser } = useAuth();
  const appUserId = authUser?.user?.id;
  const navigate = useNavigate();

  return useQuery({
    queryKey: ["handleApplication", applicationId],
    queryFn: () =>
      getRequest({
        url: `/bank/application/${applicationId}/handle-application/${appUserId}`,
      }).then((res) => {
        const responseCode = res?.data?.value?.statusCode;
        if (responseCode === 200) {
          toast.success("You have been assigned to this application. You are in charge of reviewing it and sending it to an admin for review");
          setTimeout(
            () =>
              navigate(`/applications/details/${applicationId}`),
            2000
          );
          return 1;
        }
        if ([400, 500].includes(responseCode)) {
          toast.error(res?.data?.value?.message);
          return 0;
        }
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export default useHandleApplication;
