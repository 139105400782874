import { isNullOrEmpty } from "../constants";

export const organizationAccessManagement = (userAccess) => {
  if (isNullOrEmpty(userAccess)) return null;
  const organizationManagement =
    userAccess?.find((x) => x.id === "organization_management")?.rules || [];

  return {
    viewOrganizationProfileAccess:
      organizationManagement?.find(
        (x) => x.id === "view_organization_profile"
      ) || {},
    updateOrganizationProfileAccess:
      organizationManagement?.find(
        (x) => x.id === "update_organization_profile"
      ) || {},
    viewOrganizationDocumentsAccess:
      organizationManagement?.find(
        (x) => x.id === "view_organization_documents"
      ) || {},
    addOrganizationDocumentAccess:
      organizationManagement?.find(
        (x) => x.id === "add_organization_document"
      ) || {},
  };
};
