import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useGetSingleDeveloper = (devId) => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["singleBank"],
    queryFn: () =>
      getRequest({
        url: `bank/partnership/get-organization/${devId}`,
      }).then((res) => res.data?.value),
    staleTime: 50000,
  });
};
export default useGetSingleDeveloper;