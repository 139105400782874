import React, { useEffect, useState } from "react";
import InfoTable from "../../../Components/Common/InfoTable";
import { creditAndDebitProfileColumn } from "../utils/constant";
import { displayCreditCheckValues } from "../../Application/utils/constants";
import Modal from "../../../Components/Common/Modal";
import CreditCheck from "../../Application/Documents/CreditCheck";
import { formattedPrice } from "../../../utils/constants";
import { loanHistoryColumn } from "../../Application/utils/constants";
import CenteredText from "../../../Components/CenteredText";

const CreditProfile = ({ creditData }) => {
  const [showModal, setShowModal] = useState(false);
  const [source, setSource] = useState(1);
  const [loanHistory, setLoanHistory] = useState();

  const tabs = [
    {
      title: "CRC",
      label: "CRC",
      element: {},
    },
    {
      title: "First Central",
      label: "FIRST_CENTRAL",
      element: {},
    },
    {
      title: "Credit Registry",
      label: "CREDIT_REGISTRY",
      element: {},
    },
  ];
  const viewLoanHistory = (appId) => {
    // setSource()
    // let index = tabs.findIndex((x) => x.label === appId);
    // console.log("APP", appId);
    // console.log("IND", index);
    // setSource(index);
    // setTimeout(() => setShowModal(true), 2000);
  };
  const getData = (score) => {
    let temp = [];
    displayCreditCheckValues(score).data.forEach((internalData) => {
      if (internalData.property === "totalNoOfLoans") {
        internalData.value?.forEach((x) => {
          temp.push({ source: x.source });
        });
      }

      if (internalData.property === "totalBorrowed") {
        internalData.value.forEach((x, index) => {
          temp[index].totalBorrowed = x.value;
        });
      }

      if (internalData.property === "totalOutstanding") {
        internalData.value.forEach((x, index) => {
          temp[index].totalOutstanding = x.value;
        });
      }

      if (internalData.property === "totalNoOfLoans") {
        internalData.value.forEach((x, index) => {
          temp[index].totalNoOfLoans = x.value;
        });
      }

      //////////////////////////////////////////////
      if (internalData.property === "totalNoOfInstitutions") {
        internalData.value.forEach((x, index) => {
          temp[index].totalNoOfInstitutions = x.value;
        });
      }

      if (internalData.property === "totalMonthlyInstallment") {
        internalData.value.forEach((x, index) => {
          temp[index].totalMonthlyInstallment = x.value;
        });
      }

      if (internalData.property === "totalNoOfActiveLoans") {
        internalData.value.forEach((x, index) => {
          temp[index].totalNoOfActiveLoans = x.value;
        });
      }

      //////////////////////////////////////////////////
      if (internalData.property === "totalNoOfClosedLoans") {
        internalData.value.forEach((x, index) => {
          temp[index].totalNoOfClosedLoans = x.value;
        });
      }
    });

    return temp;
  };

  useEffect(() => {
    if (creditData?.loanHistory) {
      setLoanHistory(creditData?.loanHistory);
    }
  }, [creditData]);

  return (
    <div className="container-fluid">
      <div className="row mt-5">
        <h4>Debt Profile</h4>
        <p className="m-0">
          Due to reporting inconsistency in the financial sector, we display the
          debt profile from two major credit bureaus (FirstCentral Credit Bureau
          and CRC Credit Bureau).
        </p>
      </div>
      <div
        className="row bg-light rounded p-0 mt-2 mx-0"
        style={{ border: "1px solid gray" }}
      >
        <>
          <div className="col-2 mx-0 p-3 my-1">
            <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
              Total Borrowed
            </p>
            <p className="p-0 m-0 mt-1">
              ₦ {formattedPrice(creditData?.totalBorrowed) ?? "0"}
            </p>
          </div>
          <div className="col-2 mx-0 p-3 my-1">
            <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
              Total Outstanding
            </p>
            <p className="p-0 m-0 mt-1">
              ₦ {formattedPrice(creditData?.totalOutstanding) ?? "0"}
            </p>
          </div>
          <div className="col-2 mx-0 p-3 my-1">
            <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
              Total Loans
            </p>
            <p className="p-0 m-0 mt-1">{creditData?.totalNoOfLoans ?? "0"}</p>
          </div>
          <div className="col-2 mx-0 p-3 my-1">
            <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
              Total Institutions
            </p>
            <p className="p-0 m-0 mt-1">
              {creditData?.totalNoOfInstitutions ?? "0"}
            </p>
          </div>
          <div className="col-2 mx-0 p-3 my-1">
            <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
              Total Overdue
            </p>
            <p className="p-0 m-0 mt-1">{creditData?.totalOverdue ?? "0"}</p>
          </div>
          <div className="col-2 mx-0 p-3 my-1">
            <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
              Total Monthly Instalments
            </p>
            <p className="p-0 m-0 mt-1">
              ₦ {formattedPrice(creditData?.totalMonthlyInstallment) ?? "0"}
            </p>
          </div>
          <div className="col-2 mx-0 p-3 my-1">
            <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
              Highest Loan Amount
            </p>
            <p className="p-0 m-0 mt-1">
              ₦ {formattedPrice(creditData?.highestLoanAmount) ?? "0"}
            </p>
          </div>
          <div className="col-2 mx-0 p-3 my-1">
            <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
              Active Loans
            </p>
            <p className="p-0 m-0 mt-1">
              {creditData?.totalNoOfActiveLoans ?? "0"}
            </p>
          </div>
          <div className="col-2 mx-0 p-3 my-1">
            <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
              Closed Loans
            </p>
            <p className="p-0 m-0 mt-1">
              {creditData?.totalNoOfClosedLoans ?? "0"}
            </p>
          </div>
          <div className="col-2 mx-0 p-3 my-1">
            <p className="text-uppercase fw-bold creidt-title m-0 p-0 m-0">
              Bad Debt/Deliquent Facilities
            </p>
            <p className="p-0 m-0 mt-1">
              {creditData?.totalNoOfDelinquentFacilities ?? "0"}
            </p>
          </div>
        </>
      </div>

      <div className="mt-4">
        <h4 className="p-0 m-0">Loan History</h4>
        {loanHistory?.length > 0 ? (
          <div>
            <InfoTable
              columns={loanHistoryColumn}
              dataCollection={loanHistory}
            />
          </div>
        ) : (
          <div className="mt-5">
            <CenteredText title={"No Recorded Loan History"}>
              <p className="text-center">
                This customer has no recorded loans from the two major credit
                bureaus, CRC and First Central, we pull data from.
              </p>
            </CenteredText>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreditProfile;
