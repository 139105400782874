import React, { useContext } from "react";
import { createContext, useState } from "react";

export const SignUpContext = createContext({
  bankAdminData: {},
  setBankAdminData: (value) => {},
});

export const SignUpContextProvider = ({ children }) => {
  const [bankAdminData, setBankAdminData] = useState({});

  return (
    <SignUpContext.Provider
      value={{ bankAdminData, setBankAdminData }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignupContext = () => useContext(SignUpContext);

