import React from "react";
import Icons from "../Icons";
import CheckInput from "../../../Components/Common/CheckInput";
import { colors } from "../../../utils/constants";
import { PropertyCompletionStatusWrapper } from "./style";

const PropertyCompletionStatus = ({
  iconName,
  title,
  checkInputs,
  isCheckbox,
}) => {
  return (
    <PropertyCompletionStatusWrapper>
      <div className="d-flex align-items-center header">
        <Icons iconName={iconName} />
        <p className="iconTitle m-0 mt-2 ms-2">{title}</p>
      </div>
      <div>
        {checkInputs.map((input, index) => (
          <div className="my-1" key={index}>
            {isCheckbox ? (
              <CheckInput
                name={input.label}
                label={input.label}
                fontSize={"0.9rem"}
                bordercolor={colors.darkGreen}
                width={"1.3rem"}
                height={"1.3rem"}
                margin={"0.3rem 0 0 0.4rem"}
                checkTick
                checked={input.selected}
                onChange={input.onChange}
              />
            ) : (
              <p style={{ fontSize: "0.9rem", margin: "0.5rem 0 0 0.6rem" }}>
                {input.label}
              </p>
            )}
          </div>
        ))}
      </div>
    </PropertyCompletionStatusWrapper>
  );
};

export default PropertyCompletionStatus;
