import React from "react";
import { HouseCardWrapper } from "./HouseCardStyle";
import noimage from "../../../../noimage.png";
import { colors } from "../../../../utils/constants";
import Icons from "../../../../Components/Common/Icons";
import {
  formattedPrice,
  convertToTitleCase,
} from "../../../../utils/constants";
import Button from "../../../../Components/Common/Button";


const HouseCard = ({ house, houseIds, handleAddRemoveHouse }) => {
  const image = (image) => {
    if (image) {
      if (image?.length > 0) {
        return image[0];
      }
    }
    return noimage;
  };

  const isAdded = houseIds.includes(house.id);

  return (
    <HouseCardWrapper isAdded={isAdded}>
      <div className="property_card_header">
        <p className="price">₦ {formattedPrice(house?.price)}</p>
        <img
          src={image(house?.images)}
          alt={house?.address}
          className="img-fluid h-100 w-100"
        />
        {house?.completionStatus && (
          <div className="property_status p-2">
            <div className="">
              <Icons iconName={"underConstruction"} />
            </div>
            <p className="m-0 pt-1">{convertToTitleCase(house?.completionStatus)}</p>
          </div>
        )}
         {isAdded && <div className="added-overlay"></div>}
      </div>

      <div className="property_card_details pt-4">
        <p className="m-0 text-center fw-semibold property_card_name">{house?.name}</p>
        <div className="d-flex justify-content-center align-items-center gap-2 specification my-2">
          <p>
            {house?.bedrooms}{" "}<span>Beds</span>
          </p>
          <span className="circle_bar"></span>
          <p>
            {house?.bathrooms}{" "}<span>Baths</span>
          </p>
          <span className="circle_bar"></span>
          <p>{convertToTitleCase(house?.type)}</p>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div className="d-flex align-items-center gap-2">
            <div className="orgLogo">
              <img src={house?.organization?.logo ?? noimage} className="w-100 h-100"/>
            </div>
            <p className="m-0 pt-2 fw-semibold text-uppercase" style={{fontSize: "0.7rem"}}>BY {house?.organization?.name}</p>
          </div>

          <div className="d-flex gap-2">
            <Button
              btnText={isAdded ? "Remove" : "Add"}
              padding={"0"}
              onBtnClick={() => handleAddRemoveHouse(house.id)}
            />
          </div>
        </div>
      </div>
    </HouseCardWrapper>
  );
};

export default HouseCard;
