import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

export const useGetRepaymentSchedule = (applicationId, page) => {
    const { getRequest } = useAxiosBase();
  
    return useQuery({
      queryKey: ["summary"],
      queryFn: () =>
        getRequest({ url: `/bank/application/${applicationId}/calculate-repayment-schedule?pageNumber=${page}` }).then(
          (res) => res?.data?.value?.value
        ),
      staleTime: 50000,
    });
  };
  