import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";


const useAddViewingAgent = (applicationUserId, viewingStatus) => {
    const { getRequest } = useAxiosBase();

    const addViewingAgent = useMutation({
        mutationFn: (data) =>
            getRequest({
                url: `/bank/user/${applicationUserId}/update-as-viewing-agent/${viewingStatus}`,
                data: data,
            }).then((res) => {
                if (res?.data?.value?.statusCode === 200) {
                    toast.success(res?.data?.value?.message);
                }
                if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
                    toast.error(res?.data?.value?.message);
                }
            }),
        onSuccess: (res, res1) => {
            if (res?.data?.value?.statusCode === 200) {
                toast.success(res?.data?.value?.message);
            }
            if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
                toast.error(res?.data?.value?.message);
            }
        },
        onError: (error) => {
            console.log("ERRROR", error);
            toast.error(error);
        },
    });

    return { addViewingAgent };
}

export default useAddViewingAgent;
