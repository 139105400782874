import React from "react";
import { ButtonWrapper } from "./style.js";
import Icons from "../Icons/index.jsx";
import Spinner from "../../Spinner";
import LargeLoader from "../../Common/LargeLoader";

const Button = ({
  btnText,
  onBtnClick,
  isLoading,
  largeLoader,
  spinnerWidth,
  spinnerHeight,
  borderWidth,
  ...rest
}) => {
  return (
    <ButtonWrapper
      {...rest}
      onClick={onBtnClick}
      disabled={rest.disabled}
      className={rest.className}
    >
      {largeLoader && <LargeLoader />}
      <span className="button_label_wrapper">
        {rest.lefticonname && (
          <span className="button_icon">
            <Icons iconName={rest.lefticonname} />
          </span>
        )}
        <span className="d-flex align-items-center gap-2 button_text">
          {btnText}
          {isLoading && (
            <Spinner
              className={rest.className}
              width={spinnerWidth ? spinnerWidth : "1.2rem"}
              height={spinnerHeight ? spinnerHeight : "1.2rem"}
              borderWidth={borderWidth ? borderWidth : "4px"}
            />
          )}{" "}
        </span>
        {rest.righticonname && (
          <span className="button_icon">
            <Icons iconName={rest.righticonname} />
          </span>
        )}
      </span>
    </ButtonWrapper>
  );
};

export default Button;
