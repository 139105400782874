import * as yup from "yup";

const modelSchema = yup.object({
  firstName: yup
    .string()
    .required("First Name is required")
    .min(3, "First Name must be at least 3 characters"),
  lastName: yup
    .string()
    .required("Last Name is required")
    .min(3, "Last Name must be at least 3 characters"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .nullable()
    .notRequired()
    .matches(/^[0-9]*$/, "Invalid phone number format"),
  gender: yup
    .string()
    .lowercase() // Convert to lowercase before validation
    .oneOf(["male", "female"], "Gender must be either 'male' or 'female'")
    .required("Gender is required"),
  dateOfBirth: yup
    .date()
    .required("Date of Birth is required")
    .test("is-older-than-18", "Must be 18 years or older", function (value) {
      let today = new Date();
      let birthDate = new Date(value);
      let age = today.getFullYear() - birthDate.getFullYear();
      let monthDiff = today.getMonth() - birthDate.getMonth();
      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age >= 18;
    }),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character (!@#$%^&*)"
    )
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export default modelSchema;
