import React, { useEffect, useState } from "react";
import "./sidenav.css";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { useToggle } from "../../Context/AppContext";
import Icons from "../Common/Icons";
import Modal from "../Common/Modal";
import useAuth from "../../hooks/useAuth";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi";
import usePermission from "../../hooks/usePermissions";

const SideNav = () => {
  const {
    accessHouseViewing,
    accessViewApplication,
    accessViewApplicationsForReview,
    accessViewViewingRequests,
    accessViewProspects,
    accessViewPartners,
    accessViewPlans,
    accessViewPortfolios,
    accessViewTransaction,
    accessViewOrganizationProfile,
    accessViewOrganizationDocuments,
    accessViewTeamMember,
    accessViewRole,
  } = usePermission();

  const [openModal, setOpenModal] = useState(false);
  const [toggler, setToggler] = useState(false);
  const location = useLocation();

  const { setAuthUser } = useAuth();
  const navigate = useNavigate();
  const toggle = useToggle();
  const [showOpenSettings, setShowOpenSettings] = useState(false);
  const [showOpenProperties, setShowOpenProperties] = useState(false);

  const propertiesPaths = [
    "/properties/my-properties",
    "/properties/partnered-developer-properties",
  ];
  const settingsPaths = [
    "/settings/profile",
    "/settings/company",
    "/settings/account",
    "/settings/team",
    "/settings/permission-and-roles",
    "/settings/documents",
    "/settings/payments",
    "/settings/partnership-terms",
    "/settings/notification-configuration",
  ];

  const togglePropertiesDropdown = () => {
    const isAnyApplicationChildActive = propertiesPaths.some((path) =>
      location.pathname.includes(path)
    );

    if (isAnyApplicationChildActive) {
      setShowOpenProperties(true);
      setShowOpenSettings(false);
    } else {
      setShowOpenProperties((prev) => !prev);
    }
  };

  const toggleSettingsDropdown = () => {
    const isAnySettingsChildActive = settingsPaths.some((path) =>
      location.pathname.includes(path)
    );

    if (isAnySettingsChildActive) {
      setShowOpenSettings(true);
      setShowOpenProperties(false);
    } else {
      setShowOpenSettings((prev) => !prev);
    }
  };

  const handleSettingsNavLinkClick = () => {
    setShowOpenProperties(false);
  };

  const handlePropertiesNavLinkClick = () => {
    setShowOpenSettings(false);
  };

  const resetDropdowns = () => {
    setShowOpenSettings(false);
    setShowOpenProperties(false);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    setAuthUser(null);
    navigate("/account/login");
  };

  useEffect(() => {
    if (toggle) {
      document.body.classList.add("sb-sidenav-toggled");
    } else {
      document.body.classList.remove("sb-sidenav-toggled");
    }
  }, [toggle]);

  useEffect(() => {
    if (toggler) {
      document.documentElement.style.setProperty(
        "--sidebar-width",
        `0rem`
      );
    } else {
      document.documentElement.style.setProperty(
        "--sidebar-width",
        `17rem`
      );
    }
  }, [toggler])

  useEffect(() => {
    if (propertiesPaths?.some((path) => location.pathname.includes(path))) {
      setShowOpenProperties(true);
    }

    if (settingsPaths?.some((path) => location.pathname.includes(path))) {
      setShowOpenSettings(true);
    }
  }, [location.pathname]);

  return (
    <div className={`${toggler ? "hidde-sideNav" : "sideNav"}`}>
      <div
        className={toggler ? "hidde-toggler" : "sideNav_toggler"}
        onClick={() => setToggler(!toggler)}
      >
        {toggler ? <HiChevronRight /> : <HiChevronLeft />}
      </div>

      <div
        id="layoutSidenav_nav"
        className={`${
          toggler ? "hidde-layoutSidenav_nav" : "layoutSidenav_nav"
        } pt-3`}
      >
        <div className="sidenav-wrapper position-relative">
          <div className="org-logo my-3">
            <Link className="navbar-brand ps-4" to="/">
              <img
                src="//s3.amazonaws.com/appforest_uf/f1675031132769x885606220423525500/GiddaaLogoGreen.svg"
                width="80"
                height="80"
                className="d-inline-block align-top"
                alt=""
              />
            </Link>
          </div>

          <nav
            className="sb-sidenav accordion sb-sidenav-dark custom-nav sb-nav-fixed"
            id="sidenavAccordion"
          >
            <div className="sb-sidenav-menu ps-1 flex-column ">
              <div className="nav w-100">
                <NavLink className="nav-link collapsed" to="/">
                  <span className="sb-nav-link-icon p-1 mr-5">
                    <Icons iconName={"dashboard"} />
                  </span>
                  DASHBOARD
                </NavLink>

                {accessHouseViewing?.value ? (
                  <div className="nav-link-2" role="button">
                    <div
                      onClick={togglePropertiesDropdown}
                      className={
                        showOpenProperties
                          ? "active-nav-link-setting"
                          : "nav-link-setting"
                      }
                    >
                      <span
                        className={`sb-nav-link-icon p-1 ${
                          showOpenProperties && "active_icon"
                        }`}
                      >
                        <Icons iconName={"property"} />
                      </span>
                      <span
                        className={`m-0 hover-effect ${
                          showOpenProperties && "active-settings"
                        }`}
                      >
                        PROPERTIES
                      </span>
                      <span className="sb-sidenav-collapse-arrow ps-2 hover-effect">
                        {showOpenProperties ? (
                          <MdOutlineKeyboardArrowUp size={20} />
                        ) : (
                          <MdOutlineKeyboardArrowDown size={20} />
                        )}
                      </span>
                    </div>

                    {/* {showOpenProperties && ( */}
                    <span className="nav-link-3 collapsed">
                      <ul
                        className={`${
                          showOpenProperties ? "nav-link-ul" : "hidde"
                        } sub-menu`}
                      >
                        <li>
                          <NavLink
                            onClick={handlePropertiesNavLinkClick}
                            className="nav-link-li sub-menu"
                            to="/properties/my-properties"
                          >
                            My Properties
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={handlePropertiesNavLinkClick}
                            className="nav-link-li sub-menu"
                            to="/properties/partnered-developer-properties"
                          >
                            Partner Developer Properties
                          </NavLink>
                        </li>
                      </ul>
                    </span>
                    {/* )} */}
                  </div>
                ) : null}

                {accessViewApplication?.value ? (
                  <NavLink className="nav-link collapsed" to="/applications">
                    <span className="sb-nav-link-icon p-1">
                      <Icons iconName={"applications"} />
                    </span>
                    <span className="position-relative">
                      APPLICATIONS
                      {/* <span className="bage"> 2 </span> */}
                    </span>
                  </NavLink>
                ) : null}

                {accessViewApplicationsForReview?.value ? (
                  <NavLink
                    className="nav-link collapsed position-relative"
                    to="/application-review"
                  >
                    <span className="sb-nav-link-icon p-1">
                      <Icons iconName={"application_review"} />
                    </span>
                    <span className="position-relative">
                      APPLICATION REVIEW
                      {/* <span className="bage sidenav-bage"> 2 </span> */}
                    </span>
                  </NavLink>
                ) : null}

                {accessViewViewingRequests?.value ? (
                  <NavLink
                    onClick={resetDropdowns}
                    className="nav-link collapsed"
                    to="/house-inspections"
                  >
                    <span className="sb-nav-link-icon p-1">
                      <Icons iconName={"inspection"} />
                    </span>
                    <span className="position-relative">
                      HOUSE INSPECTIONS
                      {/* <span className="bage sidenav-bage"> 2 </span> */}
                    </span>
                  </NavLink>
                ) : null}

                {accessViewProspects?.value ? (
                  <NavLink to="/prospects" className="nav-link">
                    <span className="sb-nav-link-icon p-1 mr-5">
                      <Icons iconName={"prospects"} />
                    </span>
                    PROSPECTS
                  </NavLink>
                ) : null}

                {/* {accessViewOrganizationDocuments?.value ? (
                  <NavLink className="nav-link collapsed" to="/offer-letter">
                    <span className="sb-nav-link-icon p-1">
                      <Icons iconName={"documents"} />
                    </span>
                    PROPERTY DOCUMENTS
                  </NavLink>
                ) : null} */}

                {accessViewPartners?.value ? (
                  <NavLink className="nav-link collapsed" to="/developers">
                    <span className="sb-nav-link-icon p-1">
                      <Icons iconName={"developers"} />
                    </span>
                    DEVELOPERS
                  </NavLink>
                ) : null}

                {accessViewPlans?.value ? (
                  <NavLink className="nav-link collapsed" to="/plans">
                    <span className="sb-nav-link-icon plan_icon p-1">
                      <Icons iconName={"plans"} />
                    </span>
                    PLANS
                  </NavLink>
                ) : null}

                {accessViewPortfolios?.value ? (
                  <NavLink className="nav-link collapsed" to="/portfolio">
                    <span className="sb-nav-link-icon p-1">
                      <Icons iconName={"account"} />
                    </span>
                    PORTFOLIO
                  </NavLink>
                ) : null}

                {accessViewTransaction?.value ? (
                  <NavLink
                    className="nav-link collapsed"
                    to="/transactions-earnings"
                  >
                    <span className="sb-nav-link-icon p-1">
                      <Icons iconName={"transaction_earnings"} />
                    </span>
                    TRANSACTIONS & EARNINGS
                  </NavLink>
                ) : null}

                <div className="nav-link-2" role="button">
                  <div
                    onClick={toggleSettingsDropdown}
                    className={
                      showOpenSettings
                        ? "active-nav-link-setting"
                        : "nav-link-setting"
                    }
                  >
                    <span
                      className={`sb-nav-link-icon p-1 ${
                        showOpenSettings && "active_icon"
                      }`}
                    >
                      <Icons iconName={"settings"} />
                    </span>
                    <span
                      className={`m-0 hover-effect ${
                        showOpenSettings && "active-settings"
                      }`}
                    >
                      {" "}
                      ACCOUNT & SETTINGS
                    </span>
                    <span className="sb-sidenav-collapse-arrow ps-2 hover-effect">
                      {showOpenSettings ? (
                        <MdOutlineKeyboardArrowUp size={20} />
                      ) : (
                        <MdOutlineKeyboardArrowDown size={20} />
                      )}
                    </span>
                  </div>

                  {/* {showOpenSettings && ( */}
                  <span className="nav-link-3 collapsed">
                    <ul
                      className={`${
                        showOpenSettings ? "nav-link-ul" : "hidde"
                      } sub-menu`}
                    >
                      <li>
                        <NavLink
                          onClick={handleSettingsNavLinkClick}
                          className="nav-link-li sub-menu"
                          to="/settings/profile"
                        >
                          User Profile
                        </NavLink>
                      </li>
                      {accessViewOrganizationProfile?.value ? (
                        <li>
                          <NavLink
                            onClick={handleSettingsNavLinkClick}
                            className="nav-link-li sub-menu"
                            to="/settings/company"
                          >
                            Company Profile
                          </NavLink>
                        </li>
                      ) : null}
                      <li>
                        <NavLink
                          onClick={handleSettingsNavLinkClick}
                          className="nav-link-li sub-menu"
                          to="/settings/account"
                        >
                          Change Password
                        </NavLink>
                      </li>
                      {accessViewTeamMember?.value ? (
                        <li>
                          <NavLink
                            onClick={handleSettingsNavLinkClick}
                            className="nav-link-li sub-menu"
                            to="/settings/team"
                          >
                            Team Members
                          </NavLink>
                        </li>
                      ) : null}
                      {accessViewRole?.value ? (
                        <li>
                          <NavLink
                            onClick={handleSettingsNavLinkClick}
                            className="nav-link-li sub-menu"
                            to="/settings/permission-and-roles"
                          >
                            Permission & Roles
                          </NavLink>
                        </li>
                      ) : null}
                      {accessViewOrganizationDocuments?.value ? (
                        <li>
                          <NavLink
                            onClick={handleSettingsNavLinkClick}
                            className="nav-link-li sub-menu"
                            to="/settings/documents"
                          >
                            Documents
                          </NavLink>
                        </li>
                      ) : null}
                      <li>
                        <NavLink
                          onClick={handleSettingsNavLinkClick}
                          className="nav-link-li sub-menu"
                          to="/settings/payments"
                        >
                          Payments
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={handleSettingsNavLinkClick}
                          className="nav-link-li sub-menu"
                          to="/settings/partnership-terms"
                        >
                          Partnership Terms
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={handleSettingsNavLinkClick}
                          className="nav-link-li sub-menu"
                          to="/settings/notification-configuration"
                        >
                          Notification Configuration
                        </NavLink>
                      </li>
                    </ul>
                  </span>
                  {/* )} */}
                </div>
              </div>
            </div>
            <div
              className="sb-sidenav-footer d-flex flex-row justify-content-start align-items-center ps-4"
              onClick={() => setOpenModal(true)}
            >
              <div className="small">Log Out</div>
              <div className="logout-icon">
                <Icons iconName={"logout"} />
              </div>
            </div>
          </nav>
        </div>

        <Modal
          show={openModal}
          title={"Logout"}
          confirmModal={handleLogout}
          closeModal={() => setOpenModal(false)}
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Logout"
          backgroundcolor={"red"}
          // bordercolor={'red'}
        >
          <div className="text-center text-danger m-0">
            Are you sure you want to log out?
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default SideNav;
